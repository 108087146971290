import React, {useCallback, useEffect, useState} from "react";
import {toJS} from "mobx";
import {showAlertPopup} from "../../../ui/alert";
import {useStore} from "../../../store";
import {observer} from "mobx-react-lite";
import {Button, EButtonVariant} from "../../../ui";
import {EFontVariant} from "../../../assets/styles/enums";
import {showConfirmAlertPopup} from "../../../ui/confirm-alert";
import {useLocation, useNavigate} from "react-router";
import type {OperationStatus} from "../../registry/types";
import DeleteModal from "./deleteModal";
import {updateDocumentStatus} from "../service";

interface HeaderPendingStatusButtonsProps {
  deleteModalIsActive: boolean;
  setDeleteModalIsActive: (arg0: boolean) => void;
  isDeleteOnly?: boolean;
  operationStatus?: OperationStatus;
}

const HeaderPendingStatusButtons = observer(
  ({
    deleteModalIsActive,
    setDeleteModalIsActive,
    isDeleteOnly,
    operationStatus,
  }: HeaderPendingStatusButtonsProps) => {
    const { RootStore } = useStore();
    const {
      RegistryDocumentStore: {
        setModalIsActive,
        advanceReport,
        approveStatus,
        isStatusChangePending,
        setIsStatusChangePending,
        fetchCheckInventory,
        documentType,
        fetchReport,
      },
    } = RootStore;

    const location = useLocation();
    const navigate = useNavigate();

    const onDocumentApprove = useCallback(() => {
      const currentLocation = location.pathname.split('/').slice(-1)[0];

      if (
        (toJS(advanceReport).positions &&
          toJS(advanceReport).positions.every((item: any) => {
            return (
              item.value !== null &&
                item.value !== "" &&
                item.hasOwnProperty("value_fact")
                ? item.value_fact !== null &&
                item.value_fact !== "" &&
                item.value_fact !== 0
                : true,
              item.unit !== null &&
              item.unit !== "" &&
              item.name !== null &&
              item.sum !== null &&
              item.sum !== "" &&
              item.name !== 0
            );
          }) &&
          toJS(advanceReport).deal_num !== null &&
          toJS(advanceReport).number !== null) ||
        !toJS(advanceReport).positions
      ) {
        approveStatus();
        fetchReport(currentLocation);
        setIsStatusChangePending(true);
      } else {
        showAlertPopup("Ошибка","Пожалуйста, заполните все поля","error");
      }
    },[toJS(advanceReport)]);

    const approveInventoryText = useCallback(async () => {
      const responsibles = await fetchCheckInventory();

      if (responsibles === null) return null;

      let alertText = `Утвердить остатки по инвентаризации? После отправки документ будет недоступен для редактирования.`;

      if (responsibles && responsibles.length > 0) {
        alertText = `Внимание! Не все сотрудники отправили заявки на пересчёт.<br/>`;

        alertText = responsibles.reduce((text,responsibleStatus) =>
          text += `<br/><strong>${responsibleStatus.responsible}</strong> ― ${responsibleStatus.status}<br/>`,alertText);

        alertText += `<br/>После утверждения инвентаризации все неотправленные заявки на пересчёт будут удалены!`;
      }

      return alertText;
    },[]);

    const onApproveClick = useCallback(async () => {
      let alertText = `Вы уверены, что хотите утвердить документ и сохранить результаты?`;

      if (documentType === 'inventory') {
        const inventoryAlertText = await approveInventoryText();

        if (!inventoryAlertText) return;

        alertText = inventoryAlertText;
      }


      showConfirmAlertPopup(
        "Подвердите действие",
        alertText,
        () => { },
        () => {
          onDocumentApprove();
        },
      );
    },[onDocumentApprove,documentType,approveInventoryText]);

    const updateStatus = async () => {
      const params = {
        document_id: toJS(advanceReport).id,
        status: 'pending',
        document_type: 'invoice',
        comment: toJS(advanceReport).comment,
      };

      const response = await updateDocumentStatus(params);

      if (response) {
        showAlertPopup(
            "Сохранено!",
            "Данные успешно сохранены!",
            "success"
        );
        navigate("/main/registry");
      }
    }

    const onDocumentDecline = useCallback(() => {
      setDeleteModalIsActive(true);
      setModalIsActive(true);
      RootStore.setModalIsActive(true);
      setIsStatusChangePending(true);
    },[]);

    return (
      <>
        {!isDeleteOnly && <Button
          variant={EButtonVariant.fillButton}
          font={EFontVariant.body2Regular}
          height={"32px"}
          maxWidth={"170px"}
          borderRadius={"10px"}
          text="Утвердить документ"
          disabled={isStatusChangePending}
          onClick={onApproveClick}
        />}
        <Button
          variant={EButtonVariant.fillLightButton}
          font={EFontVariant.body2Regular}
          height={"32px"}
          maxWidth={"184px"}
          borderRadius={"10px"}
          text="Пометить на удаление"
          disabled={isStatusChangePending}
          onClick={onDocumentDecline}
        />
        {operationStatus && operationStatus === 'delivery' &&
          <Button
              variant={EButtonVariant.fillLightButton}
              font={EFontVariant.body2Regular}
              height={"32px"}
              maxWidth={"184px"}
              borderRadius={"10px"}
              text="На рассмотрение"
              disabled={operationStatus !== 'delivery'}
              onClick={updateStatus}
          />
        }
        {deleteModalIsActive && (
          <DeleteModal setDeleteModalIsActive={setModalIsActive} />
        )}
      </>
    );
  },
);

export default HeaderPendingStatusButtons;

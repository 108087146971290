import { observer } from 'mobx-react-lite';
import { FC,useMemo } from 'react';
import { useStore } from 'store';
import { DateHeader } from './date-header';
import cls from './date-row-styles.module.scss';
import { useScroll } from 'react-use';
import { autoUpdate,offset,shift,useFloating } from '@floating-ui/react';
interface DateRowProps {
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const DateRowComponent: FC<DateRowProps> = ({ parentRef }) => {
  const { RootStore: { SchedulePlanningStore: { selectedDate } } } = useStore();
  const year = selectedDate.getFullYear();
  const month = selectedDate.getMonth();
  const days = new Date(year,month + 1,0).getDate();
  const arr = Array.from(Array(days).keys());
  arr.splice(15,0,100);
  arr.splice(arr.length,0,100,100);
  const { y } = useScroll(parentRef);

  const { refs,floatingStyles } = useFloating({
    placement: 'top',middleware: [shift({ crossAxis: true,mainAxis: false }),
    offset({ crossAxis: 1343 })],whileElementsMounted: autoUpdate,transform: true
  });

  const dateHeaders = useMemo(() => {
    return arr.map((d,i,arr) =>
      <DateHeader key={i} date={new Date(year,month,d + 1)} isLast={i === arr.length - 1} timeSum={d === 100} />
    );
  },[arr,month,year]);

  if (y < 1) return null;

  return (<>
    <div ref={refs.setReference} style={{ position: 'absolute',zIndex: -10,visibility: 'hidden' }}></div>
    <div ref={refs.setFloating} style={floatingStyles} className={cls['container']}>{dateHeaders}</div>
  </>
  );
};

export const DateRow = observer(DateRowComponent);
// фильтр - статус

import { Checkbox, Dropdown, Menu, MenuProps, Space } from "antd";
import React from "react";
import { FilterArrow, FilterCross } from "../../icons";
import { useStore } from "store";

export const StatusFilter = () => {
  const { RootStore } = useStore();
  const {
    RegistryStore: {
      pickedRegistryStatus,
      setPickedRegistryStatus,
      pickedRegistryStatusTitle,
      setPickedRegistryStatusTitle,
      setCurrentPage,
    },
  } = RootStore;

  const statusItems: MenuProps["items"] = [
    {
      label: "В ожидании",
      key: "pending",
    },
    {
      label: "Утверждено",
      key: "approved",
    },
    {
      label: "На удаление",
      key: "deleted",
    },
    {
      label: "Ожидает заполнения",
      key: "waiting",
    },
    {
      label: 'Ожидает доставки',
      key: 'delivery'
    }
  ];

  const handleStatusFilterClick = (event: any) => {
    setCurrentPage(1);
    if (pickedRegistryStatus.includes(event.key)) {
      setPickedRegistryStatus(
        pickedRegistryStatus.filter((item: any) => item !== event.key),
      );
    } else {
      setPickedRegistryStatus([...pickedRegistryStatus, event.key]);
    }
    if (pickedRegistryStatusTitle.includes(event.item.props.title)) {
      setPickedRegistryStatusTitle(
        pickedRegistryStatusTitle.filter(
          (item) => item !== event.item.props.title,
        ),
      );
    } else {
      setPickedRegistryStatusTitle([
        ...pickedRegistryStatusTitle,
        event.item.props.title,
      ]);
    }
  };

  const statusFilterMenu = (
    <Menu onClick={handleStatusFilterClick}>
      {statusItems.map((item: any) => (
        <Menu.Item key={item.key} title={item.label}>
          <Checkbox
            checked={pickedRegistryStatus.includes(item.key)}
          ></Checkbox>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={statusFilterMenu} trigger={["click"]}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {pickedRegistryStatus.length ? (
            <>
              <span>{pickedRegistryStatusTitle.join(", ")}</span>

              <FilterCross
                onClick={(
                  event: React.MouseEvent<SVGSVGElement, MouseEvent>,
                ) => {
                  event.stopPropagation();
                  setPickedRegistryStatus([]);
                  setPickedRegistryStatusTitle([]);
                }}
              />
            </>
          ) : (
            <>
              Статус
              <FilterArrow />
            </>
          )}
        </Space>
      </a>
    </Dropdown>
  );
};

import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./styles.scss";

export const RevenuePlanningSkeleton = () => {
  return (
    <SkeletonTheme duration={2} baseColor="#bcbcbc" highlightColor="#dddddd">
      <div className="revenuePlanningSkeleton__container">
        <div className="revenuePlanningSkeleton__summary-container">
          <Skeleton
            className="revenuePlanningSkeleton__content-container__header"
            width={269}
            height={57}
            borderRadius={12}
          />
        </div>
        <div className="revenuePlanningSkeleton__parts-container">
          <Skeleton
            className="revenuePlanningSkeleton__content-container__header"
            width={1128}
            height={130}
            borderRadius={12}
          />
        </div>
        <div className="revenuePlanningSkeleton__calendar-container">
          <Skeleton
            className="revenuePlanningSkeleton__content-container__header"
            height={400}
            borderRadius={12}
          />
        </div>
      </div>
    </SkeletonTheme>
  );
};

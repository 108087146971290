export const AddIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clipRule="evenodd"
      d="M10.8839 10L16.3345 15.4506L15.4506 16.3345L10 10.8839L4.54941 16.3345L3.66553 15.4506L9.11614 10L3.66553 4.54938L4.54941 3.6655L10 9.11611L15.4506 3.6655L16.3345 4.54938L10.8839 10Z"
      fill="currentColor"
    />
  </svg>
);

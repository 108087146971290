import { observer } from 'mobx-react-lite';
import cls from '../styles.module.scss';
import { useEffect,useMemo } from 'react';
import { EmployeesTable } from './employees-table';
import { addSpaces } from 'assets/utils';
import { useStore } from 'store';

const GeneralInformationComponent = () => {
  const { RootStore: { SalaryFundStore: { selectedDate,fetchGetAwards,generalTableItems: { barista,helpers,joe },netOrGross } } } = useStore();

  useEffect(() => {
    fetchGetAwards();
  },[fetchGetAwards,selectedDate]);

  const footerIsVisible = [helpers.length,barista.length,joe.length].filter(l => l > 0).length > 1;
  const sum = useMemo(() => {
    if (!footerIsVisible) return [0,0,0,0,0,0,0,0,0];

    let monthPlan = 0;
    let monthApproved = 0;
    let monthPay = 0;
    let firstPeriodPlan = 0;
    let firstPeriodApproved = 0;
    let firstPeriodPay = 0;
    let secondPeriodPlan = 0;
    let secondPeriodApproved = 0;
    let secondPeriodPay = 0;

    barista.forEach((val) => {
      monthPlan += val[`monthly_plan_${netOrGross}`];
      monthApproved += val[`monthly_approved_${netOrGross}`];
      monthPay += val[`monthly_paid_${netOrGross}`];
      firstPeriodPlan += val[`first_period_plan_${netOrGross}`];
      firstPeriodApproved += val[`first_period_approved_${netOrGross}`];
      firstPeriodPay += val[`first_period_paid_${netOrGross}`];
      secondPeriodPlan += val[`second_period_plan_${netOrGross}`];
      secondPeriodApproved += val[`second_period_approved_${netOrGross}`];
      secondPeriodPay += val[`second_period_paid_${netOrGross}`];
    });
    helpers.forEach((val) => {
      monthPlan += val[`monthly_plan_${netOrGross}`];
      monthApproved += val[`monthly_approved_${netOrGross}`];
      monthPay += val[`monthly_paid_${netOrGross}`];
      firstPeriodPlan += val[`first_period_plan_${netOrGross}`];
      firstPeriodApproved += val[`first_period_approved_${netOrGross}`];
      firstPeriodPay += val[`first_period_paid_${netOrGross}`];
      secondPeriodPlan += val[`second_period_plan_${netOrGross}`];
      secondPeriodApproved += val[`second_period_approved_${netOrGross}`];
      secondPeriodPay += val[`second_period_paid_${netOrGross}`];
    });
    joe.forEach((val) => {
      monthPlan += val[`monthly_plan_${netOrGross}`];
      monthApproved += val[`monthly_approved_${netOrGross}`];
      monthPay += val[`monthly_paid_${netOrGross}`];
      firstPeriodPlan += val[`first_period_plan_${netOrGross}`];
      firstPeriodApproved += val[`first_period_approved_${netOrGross}`];
      firstPeriodPay += val[`first_period_paid_${netOrGross}`];
      secondPeriodPlan += val[`second_period_plan_${netOrGross}`];
      secondPeriodApproved += val[`second_period_approved_${netOrGross}`];
      secondPeriodPay += val[`second_period_paid_${netOrGross}`];
    });

    monthPlan = Math.floor(monthPlan * 100) / 100;
    monthApproved = Math.floor(monthApproved * 100) / 100;
    monthPay = Math.floor(monthPay * 100) / 100;
    firstPeriodPlan = Math.floor(firstPeriodPlan * 100) / 100;
    firstPeriodApproved = Math.floor(firstPeriodApproved * 100) / 100;
    firstPeriodPay = Math.floor(firstPeriodPay * 100) / 100;
    secondPeriodPlan = Math.floor(secondPeriodPlan * 100) / 100;
    secondPeriodApproved = Math.floor(secondPeriodApproved * 100) / 100;
    secondPeriodPay = Math.floor(secondPeriodPay * 100) / 100;

    return [monthPlan,monthApproved,monthPay,firstPeriodPlan,firstPeriodApproved,firstPeriodPay,secondPeriodPlan,secondPeriodApproved,secondPeriodPay];
  },[footerIsVisible,barista,helpers,joe,netOrGross]);
  const sumData = useMemo(() => sum.map(s => <td className={cls['td']}>{addSpaces(s)}</td>),[sum]);

  return (
    <div>
      {barista.length > 0 && <EmployeesTable title='Работники бара' className={cls['table-rounding__top']} generalItems={barista} />}
      {helpers.length > 0 && <EmployeesTable title='Хелперы' className={barista.length === 0 ? cls['table-rounding__top'] : ''} generalItems={helpers} />}
      {joe.length > 0 && <EmployeesTable title='Работники кухни'
        className={(barista.length === 0 && helpers.length === 0) ? cls['table-rounding__top'] : ''} generalItems={joe} />}
      {footerIsVisible && <table style={{ position: 'sticky',bottom: '0' }} className={`${cls['table']} ${cls['table-rounding__bottom']}`}>
        <colgroup>
          <col width={44} />
          <col width={207} />
          <col width={181} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
        </colgroup>
        <tbody className={`${cls['tfoot']} ${cls['total-tfoot']} ${cls['rounding']}`}>
          <tr className={cls['tr']}>
            <td className={cls['td']} colSpan={3}>Всего</td>
            {sumData}
          </tr>
        </tbody>
      </table>}
    </div>
  );
};

export const GeneralInformation = observer(GeneralInformationComponent);
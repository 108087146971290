import type {DataTableHeader} from "ui-new/table";
import type {AdditionalData, TableItem} from "../../../pages/create-loan";

export const headers: DataTableHeader<TableItem>[] = [
    {
        title: 'Номенклатура',
        value: 'nomenclature_id',
        width: '60%',
    },
    {
        title: 'Количество',
        value: 'value',
        width: '10%',
    },
    {
        title: 'Ед. изм.',
        value: 'unit',
        width: '10%',
    },
    {
        title: 'Себестоимость за ед.',
        value: 'cost',
        width: '10%',
    },
];

export const pageHeaderValues: AdditionalData[] = [
    {
        id: 'contragent',
        title: 'Контрагент',
        value: null,
        label: '',
    },
    {
        id: 'responsible',
        title: 'Ответственный',
        value: null,
        label: '',
    },
    {
        id: 'number',
        title: 'Номер',
        value: '',
        label: '',
    }
]

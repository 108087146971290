import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

// import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
// import 'react-big-calendar/lib/css/react-big-calendar.css';

import "react-big-calendar/lib/sass/styles.scss";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import "./styles.scss";

// Для дат и локализации. Для ру сегмента только оно работает корректно
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { ru } from "date-fns/locale";
import { useStore } from "store";
import { Button, EButtonVariant } from "ui";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import moment from "moment";
import { ShiftModal } from "widgets/shift-modal";

export const AllWorkShifts = ({ defaultView }) => {
  const { RootStore } = useStore();
  const { AuthStore } = RootStore;
  const { setOnboardingStep } = AuthStore;
  const [deleteModalIsActive, setDeleteModalIsActive] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const [resourceMap, setResourceMap] = useState();

  useEffect(() => {
    AuthStore.setOnboardingStep(1, false);
    AuthStore.setOnboardingStep(2, true);
    AuthStore.setOnboardingStep(3, false);
    if (location.pathname === "/onboarding/step2/alldays") {
      setResourceMap([{ resourceId: 1, resourceTitle: "" }]);
    } else if (location.pathname === "/onboarding/step2/weekdays-weekend") {
      setResourceMap([
        { resourceId: 1, resourceTitle: "Будни" },
        { resourceId: 2, resourceTitle: "Выходные" },
      ]);
    }
  }, []);

  const DnDCalendar = withDragAndDrop(Calendar);

  const views = ["day", "work_week"];

  // Фиксация даты
  // const defaultDate = useMemo(() => new Date().toISOString().slice(0, 10), []);
  const defaultDate = useMemo(
    () => new Date("2023-04-19").toISOString().slice(0, 10),
    [],
  );

  const locales = {
    ru: ru,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  // Мок массива событий

  let events = [
    {
      id: Date.now(),
      title: "Первая! Длительность 04:30",
      subtitle: "Тест1",
      start: new Date("2023-04-19 01:00"),
      end: new Date("2023-04-19 05:30"),
      resourceId: 1,
    },
    {
      id: Date.now() + 1,
      title: "Смена наба ту! Длительность 04:00",
      subtitle: "Тест2",
      start: new Date("2023-04-19 01:00"),
      end: new Date("2023-04-19 05:00"),
      resourceId: 2,
    },
  ];

  // Костылиз... Кастомизация выводимого ивента
  const customEvent = (event) => {
    // console.log(event)
    return (
      <div>
        <span className="event-title body1Regular">
          {event.title.substring(0, event.title.length - 19)}
        </span>
        <span
          className="event-length captionRegular"
          style={{ marginLeft: 20, color: "#D2D6DB" }}
        >
          {event.title.substring(event.title.length - 19)}
        </span>
      </div>
    );
  };

  // Стейт с ивентами и для модалки (модалка - предварительно)

  // console.log(myEvents);
  const [myEvents, setMyEvents] = useState(events);
  const [isModalActive, setIsModalActive] = useState(false);
  const [shiftId, setShiftId] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [draftStartTime, setDraftStartTime] = useState("");
  const [draftEndTime, setDraftEndTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [shiftTitle, setShiftTitle] = useState("");
  const [isEventNew, setIsEventNew] = useState(false);
  const [draftResourceId, setDraftResourceId] = useState();

  const deleteEvent = (shiftId) => {
    setMyEvents(myEvents.filter((event) => event.id !== shiftId));
    console.log("1");
  };

  // Вспомогательная функция для вычисления длительности смены

  const shiftTimeCalculate = (start, end) => {
    let hours = end.getHours() - start.getHours();
    if (hours.toString().length === 1) {
      hours = `0${hours}`;
    }

    let minutes = end.getMinutes() - start.getMinutes();
    if (minutes === 0) {
      minutes = "00";
    }

    return `${hours}:${minutes}`;
  };

  // Создание ивента

  const onSelectSlot = useCallback(
    ({ start, end, id, resourceId }) => {
      // Смена не может быть 30 минут - добавляем ещё 30.
      setIsEventNew(true);
      setDraftResourceId(resourceId);
      setShiftId(Date.now());
      // setShiftTitle(title.substring(0, title.length - 19));
      // Демонический ритуал для выведение корректного времени в инпуты

      setIsModalActive(true);

      // Задать часовую смену при "схлопывании" или если выбран получавой период
      if (
        start.getHours() + ":" + start.getMinutes() === "23:0" ||
        start.getHours() + ":" + start.getMinutes() === "23:30"
      ) {
        start.setHours(23);
        start.setMinutes(0);
        end.setHours(23);
        end.setMinutes(59);
      } else if (
        end.getHours() - start.getHours() < 1 &&
        end.getMinutes() - start.getMinutes() === 30
      ) {
        end.setMinutes(end.getMinutes() + 30);
      } else if (end.getMinutes() - start.getMinutes() === -30) {
        start.setMinutes(start.getMinutes() + 30);
        start.setHours(start.getHours() - 1);
      } else if (
        start.getHours() + ":" + start.getMinutes() ===
        end.getHours() + ":" + end.getMinutes()
      ) {
        end.setMinutes(end.getMinutes() + 60);
      }

      setDraftStartTime(
        `${
          start.getHours().toString().length === 1
            ? `0${start.getHours()}`
            : start.getHours()
        }:${
          start.getMinutes().toString().length === 1
            ? `0${start.getMinutes()}`
            : start.getMinutes()
        }`,
      );
      setDraftEndTime(
        `${
          end.getHours().toString().length === 1
            ? `0${end.getHours()}`
            : end.getHours()
        }:${
          end.getMinutes().toString().length === 1
            ? `0${end.getMinutes()}`
            : end.getMinutes()
        }`,
      );

      // Для отметки времени в тайтле
      // let shiftTime = shiftTimeCalculate(start, end);

      // // Алертик для ввода времени. Временно для теста - будет красивое окошечко
      // let title = window.prompt('Введите название ивента')
      // // let title;
      // // if (title !== "" && isActive)
      // if (title) {
      //   // id нет по умолчанию, но он нужен для корректной работы, чтобы при
      //   // драг'н'дропе не "съедались" ивенты
      //   let id = Date.now();
      //   title = `${title} Длительность: ${shiftTime}`
      //   setMyEvents((prev) => [...prev, { id, start, end, title, resourceId }])
      // }
    },
    // [myEvents, setMyEvents, shiftTitle, isActive]
    [myEvents, setMyEvents],
  );

  const addNewEvent = () => {
    setMyEvents((prev) => [
      ...prev,
      {
        id: shiftId,
        start: new Date(`2023-04-19 ${draftStartTime}`),
        end: new Date(`2023-04-19 ${draftEndTime}`),
        title: `${shiftTitle} Длительность: ${shiftTimeCalculate(
          new Date(`2023-04-19 ${draftStartTime}`),
          new Date(`2023-04-19 ${draftEndTime}`),
        )}`,
        resourceId: draftResourceId,
      },
    ]);
  };

  // Передвинуть ивент

  const moveEvent = useCallback(
    ({
      event,
      start,
      end,
      resourceId,
      isAllDay: droppedOnAllDaySlot = false,
    }) => {
      const maxEndTime = moment(start).endOf("day").toDate();

      // если ивент перетаскивается за 23:59
      if (end.getHours() + ":" + end.getMinutes() === "0:0") {
        end.setHours(23);
        end.setMinutes(58);
      }

      if (end > maxEndTime) {
        return;
      }

      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, allDay, resourceId }];
      });
    },
    [setMyEvents],
  );

  // Изменить длителльность ивента драг'н'дропом

  const resizeEvent = useCallback(
    ({ event, start, end, resourceId }) => {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);

        // Задать часовую смену при "схлопывании" или если выбран получавой период
        if (
          start.getHours() + ":" + start.getMinutes() === "23:0" ||
          start.getHours() + ":" + start.getMinutes() === "23:30"
        ) {
          start.setHours(23);
          start.setMinutes(0);
          end.setHours(23);
          end.setMinutes(59);
        } else if (
          (end.getHours() - start.getHours() < 1 &&
            end.getMinutes() - start.getMinutes() === 30) ||
          end.getMinutes() - start.getMinutes() === -30
        ) {
          end.setMinutes(end.getMinutes() + 30);
        } else if (
          start.getHours() + ":" + start.getMinutes() ===
          end.getHours() + ":" + end.getMinutes()
        ) {
          end.setMinutes(end.getMinutes() + 60);
        }

        // Вырезать название, запилить его с новым временем длительности смены
        let shiftTime = shiftTimeCalculate(start, end);
        let title = existing.title.substring(0, existing.title.length - 19);
        title = `${title} Длительность: ${shiftTime}`;

        return [...filtered, { ...existing, start, end, title, resourceId }];
      });
    },
    [setMyEvents],
  );

  // Клик по ивенту
  const onEventClick = useCallback(({ id, title, start, end, resourceId }) => {
    setIsEventNew(false);
    setShiftId(id);
    setShiftTitle(title.substring(0, title.length - 19));
    // Выведение корректного времени в инпуты
    setDraftStartTime(
      `${
        start.getHours().toString().length === 1
          ? `0${start.getHours()}`
          : start.getHours()
      }:${
        start.getMinutes().toString().length === 1
          ? `0${start.getMinutes()}`
          : start.getMinutes()
      }`,
    );
    setDraftEndTime(
      `${
        end.getHours().toString().length === 1
          ? `0${end.getHours()}`
          : end.getHours()
      }:${
        end.getMinutes().toString().length === 1
          ? `0${end.getMinutes()}`
          : end.getMinutes()
      }`,
    );
    setIsModalActive(true);
  }, []);
  function saveShiftChanges() {
    if (startTime == "" && endTime == "") return;

    const updatedEvent = {
      ...events[shiftId - 1],
      start: new Date(`2023-04-19 ${draftStartTime}`),
      end: new Date(`2023-04-19 ${draftEndTime}`),
    };
    const updatedEvents = [...events];
    updatedEvents[shiftId - 1] = updatedEvent;
    setMyEvents(updatedEvents);
  }

  const saveEventChanges = (shiftId, startTime, endTime) => {
    const foundEvent = myEvents.find((event) => event.id === shiftId);
    foundEvent.start.setHours(startTime.split(":")[0]);
    foundEvent.start.setMinutes(startTime.split(":")[1]);
    foundEvent.end.setHours(endTime.split(":")[0]);
    foundEvent.end.setMinutes(endTime.split(":")[1]);
    console.log(foundEvent);
  };

  // закрытие модалок по esc

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsModalActive(false);
        setDeleteModalIsActive(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setIsModalActive]);

  return (
    <div className="work-shits__wrapper">
      {deleteModalIsActive && (
        <div className="delete-modal__outer">
          <div className="delete-modal">
            <div className="delete-modal__main">
              <div className="delete-modal__content">
                <p className="title">Вы уверены, что хотите удалить шаблон?</p>
                <p className="subtitle">Действие нельзя будет отменить.</p>
              </div>
              <Button
                variant={EButtonVariant.closeButton}
                maxWidth={"15px"}
                height={"15px"}
                onClick={() => {
                  setDeleteModalIsActive(false);
                }}
              />
            </div>
            <div className="delete-modal__buttons">
              <Button
                variant={EButtonVariant.strokeButton}
                text={"Отменить"}
                height={"40px"}
                maxWidth={"95px"}
                borderRadius={"12px"}
                onClick={() => setDeleteModalIsActive(false)}
              />
              <Button
                className="deleteButton"
                variant={EButtonVariant.fillButton}
                text={"Удалить"}
                height={"40px"}
                maxWidth={"95px"}
                borderRadius={"12px"}
                onClick={() => {
                  deleteEvent(shiftId);
                  setDeleteModalIsActive(false);
                  setIsModalActive(false);
                }}
              />
            </div>
          </div>
          <div
            className="overlay"
            onClick={() => setDeleteModalIsActive(false)}
          ></div>
        </div>
      )}

      <ShiftModal
        myEvents={myEvents}
        setMyEvents={setMyEvents}
        isModalActive={isModalActive}
        setIsModalActive={setIsModalActive}
        shiftId={shiftId}
        startTime={startTime}
        setStartTime={setStartTime}
        draftStartTime={draftStartTime}
        setDraftStartTime={setDraftStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
        draftEndTime={draftEndTime}
        setDraftEndTime={setDraftEndTime}
        shiftTitle={shiftTitle}
        setShiftTitle={setShiftTitle}
        saveShiftChanges={saveShiftChanges}
        deleteEvent={deleteEvent}
        saveEventChanges={saveEventChanges}
        setDeleteModalIsActive={setDeleteModalIsActive}
        isEventNew={isEventNew}
        addNewEvent={addNewEvent}
      />

      <DnDCalendar
        className="work-shifts__calendar"
        defaultDate={defaultDate}
        startAccessor="start"
        endAccessor="end"
        defaultView={Views.DAY}
        views={views}
        resourceIdAccessor="resourceId"
        resources={resourceMap}
        resourceTitleAccessor="resourceTitle"
        localizer={localizer}
        min={new Date("2023-04-19 00:00")} // Пригодиться когда будет выбран график работы заведения
        max={new Date("2023-04-19 23:59")} // Пригодиться когда будет выбран график работы заведения
        culture="ru" // что-то важное, локалайзер без этого не фыр-фыр
        // showMultiDayTimes={true}
        timeslots={2} // Поле со временем x2, чтобы появилась полоса посередине
        step={30} // Шаг, по умолчанию пол часа
        // popup={true} // Если ивентов на дату больше 2-х, то они скрываются. Показывает попап с ними
        resizable // Нужно для следующих
        selectable={true} // Нужно для следующих
        onSelectSlot={onSelectSlot} // По клику / драгу пустых слотов при дропе
        onSelectEvent={onEventClick} // По клику на события
        onEventResize={resizeEvent} // Когда ивент тянется за край и производится дроп
        onEventDrop={moveEvent} // Когда ивент целеком драгается и производится дроп
        onNavigate={() => {
          console.log("onNavigate!");
        }} // ??? предстоит выяснить
        dayLayoutAlgorithm="no-overlap" // Созданные события не пересекаются
        messages={{
          // Список кнопок с надписями
          next: "Дальше",
          previous: "Назад",
          today: "Сегодня",
          month: "Месяц",
          week: "Неделя",
          day: "День",
          work_week: "Рабочие дни",
          agenda: "Список",
          noEventsInRange: "Нет установленных смен",
        }}
        events={myEvents} // Ивентики из мока
        components={{
          event: customEvent,
        }}
      />
      <Button
        className={"work-shifts__continue-button linkButton"}
        variant={EButtonVariant.fillButton}
        text={"Продолжить"}
        height={"48px"}
        borderRadius={"16px"}
        maxWidth={"300px"}
        onClick={() => navigate("/onboarding/step3")}
      />
    </div>
  );
};

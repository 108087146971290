import { useMemo } from "react";
import { Checkbox, Dropdown, Menu, Space } from "antd";
import { observer } from "mobx-react-lite";
import { CloseFilterIcon, FilterArrowIcon } from "pages/money-transactions/icons";
import { useStore } from "store";
import { MenuInfo } from "rc-menu/lib/interface";
import { toJS } from "mobx";

const ContragentsFilterComponent = () => {
  const { RootStore } = useStore();
  const { MoneyTransactionsStore: BankStatementsStore } = RootStore;
  const { contragents, filterContragents, setFilterContragents, toggleContragent } =
    BankStatementsStore;

  const contragentsData = useMemo(() => {
    if (!(contragents.length > 0)) return null;
    return toJS(contragents).map((contragent, index) => (
      <Menu.Item key={index} title={contragent.title}>
        <Checkbox
          checked={filterContragents ? filterContragents.includes(contragent.id) : undefined}
        />
        {contragent.title}
      </Menu.Item>
    ));
  }, [contragents, filterContragents]);

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => (
        <Menu
          style={{ height: "300px" }}
          onClick={(info: MenuInfo) => {
            toggleContragent(contragents[+info.key]);
          }}
        >
          {contragentsData}
        </Menu>
      )}
    >
      <Space className="cursor-pointer" style={{ gap: "4px" }} title={"Контрагенты"}>
        {filterContragents.length > 0 ? (
          <>
            <span>Контрагенты</span>
            <div className="clear-single-filter">
              <CloseFilterIcon onClick={() => setFilterContragents([])} />
            </div>
          </>
        ) : (
          <>
            {" "}
            Контрагенты
            <FilterArrowIcon />
          </>
        )}
      </Space>
    </Dropdown>
  );
};

export const ContragentsFilter = observer(ContragentsFilterComponent);

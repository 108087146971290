import { FC,useCallback,useEffect,useMemo,useRef,useState } from 'react';
import cls from './worker-styles.module.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { IWorkerDate,WorkerShortInfo } from 'pages/spot/schedule-planning-new/types';
import { ShiftClear } from './shift-clear';
import { toJS } from 'mobx';
import { arrow,autoUpdate,FloatingArrow,offset,shift,useFloating,useHover,useInteractions } from '@floating-ui/react';
import { Unwanted } from './unwanted';
import Comment from './comment';
import { ShiftBusy } from './shift-busy';

interface ShiftProps {
  date: Date;
  workerDate: IWorkerDate;
  workerInfo: WorkerShortInfo;
}

const ShiftComponent: FC<ShiftProps> = ({ date,workerDate: { shift: workShifts,total,unwanted },workerInfo }) => {
  const { RootStore: { SchedulePlanningStore: { setCreateShiftIsOpen,setEditShiftIsOpen } } } = useStore();
  const [isOpen,setIsOpen] = useState(false);
  const arrowRef = useRef(null);
  const { refs,floatingStyles,context } = useFloating({
    open: isOpen,onOpenChange: setIsOpen,middleware: [offset(10),arrow({ element: arrowRef }),shift()],
    whileElementsMounted: autoUpdate,placement: 'top',strategy: 'fixed'
  });
  const hover = useHover(context,{ move: false,delay: 100 });
  const { getReferenceProps,getFloatingProps } = useInteractions([hover]);

  const workShift = useMemo(() => workShifts[0] ?? null,[workShifts]);

  const onCellClick = useCallback(() => {
    if (workShift?.id === undefined) {
      setCreateShiftIsOpen(true,date,unwanted,workerInfo);
      return;
    }

    setEditShiftIsOpen(true,workShift,unwanted,workerInfo);
  },[date,setCreateShiftIsOpen,setEditShiftIsOpen,toJS(workShift),toJS(unwanted)]);

  const unwantedData = useMemo(() => {
    if (unwanted.length === 0) return null;

    return unwanted.map((unwanted,i) => <Unwanted key={i} unwanted={unwanted} />);
  },[toJS(unwanted)]);

  if (workShift?.id === undefined)
    return <ShiftClear ref={refs.setReference} referenceProps={getReferenceProps()} onCellClick={onCellClick} unwanted={unwanted} >
      {(isOpen && unwanted.length > 0) &&
        <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()} className={cls['tooltip']}>
          {unwantedData}
          <FloatingArrow ref={arrowRef} context={context} width={12} height={9} fill='white' className='tooltip-arrow' />
        </div>}
    </ShiftClear>;

  return (
    <ShiftBusy ref={refs.setReference} referenceProps={getReferenceProps()} onCellClick={onCellClick} shift={workShift} unwanted={unwanted}>
      {(isOpen && (workShift.comment || unwanted.length > 0)) &&
        <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()} className={cls['tooltip']}>
          {unwantedData}
          {workShift.comment && <Comment message={workShift.comment} />}
          <FloatingArrow ref={arrowRef} context={context} width={12} height={9} fill='white' className='tooltip-arrow' />
        </div>}
    </ShiftBusy>
  );
};

export const Shift = observer(ShiftComponent);
import { makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "../../store/rootStore";

export class EmployeesStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  testJobTittleArray = [
    { id: 2 - 1, jobTitle: "Бариста", cost: "250 ₽/час" },
    { id: 2 - 2, jobTitle: "Тест", cost: "300 ₽/час" },
    { id: 2 - 3, jobTitle: "Тестов", cost: "500 ₽/час" },
  ];
}

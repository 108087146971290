import {Modal} from "ui-new/modal";
import styles from './styles.module.scss';
import {ModalCard} from "ui-new/modal-card/modalCard";
import {Button} from "ui-new/button";

export interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    minWidth?: string;
    maxWidth?: string;
    cash: number;
    nonCash: number;
}

export const CheckInfoModal = ({ isOpen, onClose, onSave, cash, nonCash }: Props) => {

    return <Modal isOpen={isOpen} onClose={onClose} className={styles.modalContainer}>
        <ModalCard onClose={onClose} className={styles.modalWrapper}>
            <div className={styles.modalTitleContainer}>
                <h3 className={styles.modalTitleContainerText}>
                    Ты уверен, что состояние
                    кассы и расчетного счета
                    верно?
                </h3>
            </div>

            <div className={styles.modalDescriptionContainer}>
                <span className={styles.modalDescriptionContainerText}>
                    В кассе - {new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(cash)}
                </span>
                <span className={styles.modalDescriptionContainerText}>
                    На расчетном счету - {new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(nonCash)}
                </span>
            </div>

            <div className={styles.modalButtons}>
                <Button onClick={onClose} theme='outlined'>Отменить</Button>
                <Button onClick={onSave}>Да, я все проверил</Button>
            </div>
        </ModalCard>
    </Modal>
}
import React, { useEffect } from "react";
import "./styles.scss";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { MonthPicker } from "widgets/datepickers/month-picker";
import { toJS } from "mobx";
import TimesheetModal from "./components/timesheetModal";
import TimesheetLeftTable from "./components/timesheetLeftTable";
import TimesheetRightTable from "./components/timesheetRightTable";
import { EmptyTableBody } from "../../ui/empty-table-body";
import { BigSkeleton } from "../../ui";

export const TimesheetPage = observer(() => {
  const {
    RootStore: {
      TimeSheetStore: {
        selectedDate,
        setSelectedDate,
        getTable,
        pickedCellShifts,
        shiftsToDelete,
        updateTimesheet,
        tableData,
        isLoading,
      },
    },
  } = useStore();

  // useEffect(() => {
  //   if (
  //     toJS(shiftsToDelete).length === 0 &&
  //     toJS(pickedCellShifts).length !== 0
  //   ) {
  //     updateTimesheet();
  //   }
  // }, [shiftsToDelete]);

  useEffect(() => {
    getTable();
  }, [selectedDate]);

  useEffect(() => {
    getTable();
  }, []);

  return (
    <div className="timesheet-wrapper">
      <TimesheetModal />
      <div className="timesheet-header">Табель рабочего времени</div>
      <div className="timesheet-filters">
        <MonthPicker date={selectedDate} dateSetter={setSelectedDate} />
      </div>

      <div
        className={`timesheet-tables__wrapper ${
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
            ? "safari"
            : ""
        }`}
      >
        {isLoading ? (
          <BigSkeleton padding={"0px 16px"} />
        ) : (
          <div className="timesheet-tables__inner">
            {toJS(tableData).length > 0 ? (
              <>
                <TimesheetLeftTable />
                <TimesheetRightTable />
              </>
            ) : (
              <EmptyTableBody />
            )}
          </div>
        )}
      </div>
    </div>
  );
});

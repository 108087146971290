import { observer } from 'mobx-react-lite';
import { ITemplate } from 'pages/spot/schedule-planning-new/types';
import { FC,useMemo } from 'react';
import { Modal } from 'widgets/modal';
import cls from '../styles.module.scss';
import { useStore } from 'store';
import { Template } from 'pages/spot/schedule-planning-new/templates/template';
import { toJS } from 'mobx';

interface SelectTemplateProps {
  onSelect: (template_id: ITemplate['id']) => void;
  onClose: () => void;
  onBack: () => void;
  selectedTemplate: ITemplate['id'] | null;
}

const SelectTemplateComponent: FC<SelectTemplateProps> = ({ onClose,onBack,onSelect,selectedTemplate }) => {
  const { RootStore: { SchedulePlanningStore: { templates } } } = useStore();

  const templatesData = useMemo(() => {
    return toJS(templates).map((template: ITemplate) => {
      const isSelected = selectedTemplate === template.id;

      return <div key={template.id} onClick={() => onSelect(template.id)}>
        <Template isNotEditable template={template}
          className={`${cls['shift-create__template']} ${isSelected ? cls['shift-create__template-selected'] : ''}`}
        />
      </div>;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedTemplate,onSelect,toJS(templates)]);

  return (
    <Modal
      title='Выбери шаблон для смены'
      maxWidth='580px'
      onClose={onBack}
      onBackButton={onBack}
    >
      <div className={cls['templates__list']}>
        {templatesData}
      </div>
    </Modal>
  );
};

export const SelectTemplate = observer(SelectTemplateComponent);
import "./styles.scss";
import { useEffect } from "react";
import { OFRTableRow } from "./components/row";
import { ReportsSkeleton } from "ui/skeletons/index";
import { MonthPicker } from "widgets/datepickers/month-picker";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { toJS } from "mobx";
import NoData from "../../../ui/no-data/noData";

export const OFR = observer(() => {
  const { RootStore } = useStore();
  const {
    OFRStore: {
      dataOFR,
      isLoadingOFR,
      dateOFR,
      handleDateChangeOFR,
      getDataOFR,
    },
  } = RootStore;

  useEffect(() => {
    getDataOFR();
  }, []);

  console.log(toJS(dataOFR));
  return (
    <>
      <div className="ofr__datepicker-wrapper">
        <MonthPicker date={dateOFR} dateSetter={handleDateChangeOFR} />
      </div>
      {isLoadingOFR ? (
        <ReportsSkeleton />
      ) : (
        <div className="ofr__wrapper" data-testid={"ofr"}>
          {dataOFR && dataOFR.length > 0 ? (
            <div className="ofr__table">
              <div className="ofr__table__header">
                <p className="ofr__table__header__name table-item">
                  Наименование
                </p>
                <p className="ofr__table__header__sum ">Сумма, ₽</p>
              </div>
              {toJS(dataOFR) &&
                toJS(dataOFR).map((item: any, index: any) => {
                  console.log(index, toJS(dataOFR).length);
                  return (
                    <OFRTableRow
                      key={index}
                      name={item.title}
                      number={item.sum}
                      isBold={index + 1 === toJS(dataOFR).length && true}
                      isGrey={index + 1 === toJS(dataOFR).length && true}
                    />
                  );
                })}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </>
  );
});

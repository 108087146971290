import cls from "./styles.module.scss";
import { LoansHeader } from "./components/loans-header";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ContragentsTable } from "./contragents/contragents-table";
import { useStore } from "store";
import { showNotificationModal } from "ui-new/alert";
import { InvoicesTable } from "./invoices/invoices-table";

const viewTabs: { [key: number]: JSX.Element } = { 0: <ContragentsTable />, 1: <InvoicesTable /> };

const ContragentsLoans = () => {
  const {
    RootStore: {
      ContragentsLoansPage: { fetchGetContragentsLoans, selectedDate },
      HeaderStore: { profileData },
    },
  } = useStore();
  const [activeTab, setActiveTab] = useState(
    Number(window.sessionStorage.getItem("loans-contragents-active-tab")) ?? 0
  );

  useEffect(() => {
    fetchGetContragentsLoans();
  }, [fetchGetContragentsLoans, selectedDate]);

  useEffect(() => {
    window.sessionStorage.setItem("loans-contragents-active-tab", activeTab.toString());
  }, [activeTab]);

  if (profileData?.job_title !== "Управляющий") {
    showNotificationModal({
      title: "Ошибка прав доступа",
      type: "failure",
      errors: ["Не соответствие прав доступа, невозможно выполнить операцию"],
    });
    return null;
  }

  return (
    <div className={cls["loans-wrapper"]}>
      <h1 className={cls["loans-title"]}>Взаиморасчеты с поставщиками</h1>
      <LoansHeader activeTab={activeTab} setActiveTab={setActiveTab} />
      {viewTabs[activeTab]}
    </div>
  );
};

export const ContragentLoansPage = observer(ContragentsLoans);

import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { SpotWorkingHours } from "./spotWorkingHours";
import "./styles.scss";

export const OnboardingPageOne = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("./alldays");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="onboardingPageOne__container">
      <SpotWorkingHours />
    </div>
  );
};

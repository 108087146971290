import React from "react";
import { observer } from "mobx-react-lite";
import cls from "./styles.module.scss";
import { FieldValues,useController,UseControllerProps } from 'react-hook-form';

interface ITextareaType<T extends FieldValues> extends UseControllerProps<T> {
  className?: string;
  label?: string;
  placeholder?: string;
  rows?: number;
}

const TextareaComponent = <T extends FieldValues>({ rows,className,label,placeholder,...props }: ITextareaType<T>) => {
  const { field,fieldState } = useController(props);
  const { name } = props;

  return (
    <div className={cls["textareaField"]}>
      {label && <label className={cls['label']} htmlFor={name}>{label}</label>}
      <textarea rows={rows} {...field} id={name} placeholder={placeholder} className={`${cls['basicTextarea']} ${className ? cls[className] : ''} ${fieldState.error ? cls['basicInputError'] : ''} `} />
      <p className={cls['errorText']}>{fieldState.error?.message}</p>
    </div>
  );
};

export const Textarea = observer(TextareaComponent);;
import { observer } from 'mobx-react-lite';
import cls from '../styles.module.scss';
import { WarningIcon } from '../icons';
import { useStore } from 'store';

const TableInfoMessageComponent = () => {
  const { RootStore: { SalaryFundStore: { infoMessage } } } = useStore();

  if (!infoMessage) return <div style={{ height: '54px' }}></div>;

  return (<div style={{ justifySelf: 'end' }}>
    <div className={cls['info-message']}>
      <WarningIcon />
      <p title={infoMessage}>
        {infoMessage}
      </p>
    </div>
  </div>
  );
};

export const TableInfoMessage = observer(TableInfoMessageComponent);
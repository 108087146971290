import "./styles.scss";
import { useEffect } from "react";
import { Button, EButtonVariant } from "ui";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { EmployeesRoster } from "widgets/employees-roster";

export const EmployeesList = observer(() => {
  const { RootStore } = useStore();
  const { AuthStore } = RootStore;
  const { setOnboardingStep } = AuthStore;
  useEffect(() => {
    AuthStore.setOnboardingStep(1, false);
    AuthStore.setOnboardingStep(2, false);
    AuthStore.setOnboardingStep(3, true);
  }, []);

  return (
    <div className="employeesList__container">
      <div>
        <h2 className={`employeesList__header`}>Формирование команды</h2>
        <div className="employeesList__roster">
          <div className="employeesList__roster__headers">
            <span className={`employeesList__roster__first-header`}>
              ФИО сотрудника
            </span>
            <span className={`employeesList__roster__second-header`}>
              Должность
            </span>
            <span className={`employeesList__roster__third-header`}>Почта</span>
          </div>
          {/*<div className='employeesList__roster__empoyees'>*/}
          {/*  {AuthStore.testEmploeeArray.map((empoyee) => {*/}
          {/*    return <EmployeesRoster key={empoyee.id} empoyee={empoyee} />;*/}
          {/*  })}*/}
          {/*</div>*/}
        </div>
      </div>
      <Button
        className="body2Regular"
        variant={EButtonVariant.fillButton}
        maxWidth={"300px"}
        height={"48px"}
        margin={"16px auto 32px"}
        borderRadius={"16px"}
        text={"Завершить настройку"}
      />
    </div>
  );
});

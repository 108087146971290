import React from "react";
import "./styles.scss";

export enum ECheckboxChecked {
  checkedVariant1 = "checkedVariant1",
}

export enum ECheckboxUnchecked {
  uncheckedVariant1 = "uncheckedVariant1",
}

interface ICheckboxType {
  checkedVariant: ECheckboxChecked;
  uncheckedVariant: ECheckboxUnchecked;
  styles?: object;
  onChange?: () => void;
}

export const Checkbox: React.FC<ICheckboxType> = ({
  checkedVariant,
  uncheckedVariant,
  styles,
  onChange,
}) => {
  return (
    <label className={uncheckedVariant} style={styles}>
      <input
        onChange={onChange}
        className="checkboxInput"
        type="checkbox"
        name="checkbox"
      />
      <span className={checkedVariant}></span>
    </label>
  );
};

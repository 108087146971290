import React from "react";
import { toJS } from "mobx";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
import { Table } from "../types";
import { TableDividerIcon } from "../icons";

const TimesheetLeftTable = observer(() => {
  const {
    RootStore: {
      TimeSheetStore: { tableData },
    },
  } = useStore();

  return (
    <div className="timesheet__names-table timesheet__table">
      <div className="timesheet__table-header">
        <div className="timesheet__table-header-element">
          Сотрудник
          <TableDividerIcon />
        </div>
        <div className="timesheet__table-header-element">Должность</div>
      </div>
      <div className="timesheet__table-body">
        {tableData && toJS(tableData).length > 0 ? (
          toJS(tableData).map((object: Table) => (
            <div
              className="timesheet__table-body-row"
              key={object.id}
              id={object.id.toString()}
            >
              <div className="timesheet__table-body-element">
                {object.surname +
                  " " +
                  object.name +
                  " " +
                  (object.third_name ? object.third_name : "")}
              </div>
              <div className="timesheet__table-body-element bold">
                {object.role}
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
});

export default TimesheetLeftTable;

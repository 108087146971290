import React from "react";
import "./styles.scss";

export enum ESpinnerVariant {
  spinnerGeneral = "spinnerGeneral",
}

interface ISpinnerType {
  variant: ESpinnerVariant;
  maxWidth: string;
  height: string;
  borderRadius?: string;
}

export const Spinner: React.FC<ISpinnerType> = ({
  variant,
  maxWidth,
  height,
  borderRadius,
}) => {
  return (
    <div
      className={`${variant}`}
      style={{ maxWidth: maxWidth, height: height, borderRadius: borderRadius }}
    >
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

import { observer } from 'mobx-react-lite';
import cls from '../styles.module.scss';
import { useEffect,useMemo } from 'react';
import { addSpaces } from 'assets/utils';
import { useStore } from 'store';
import { FieldTypes } from '../types';

const ManagerInfoComponent = () => {
  const { RootStore: { SalaryFundStore: { managerTableItems,selectedDate,fetchGetManagers,setManagerModalIsOpen,setSelectedEmployee,netOrGross,managerCurrentStatus } } } = useStore();

  useEffect(() => {
    fetchGetManagers();
  },[fetchGetManagers,selectedDate]);

  const employeesData = useMemo(() => managerTableItems.map((manager,i) => <tr className={`${cls['tr']} ${cls['clickable']}`} key={i}
    onClick={() => setSelectedEmployee(manager.user_id)}>
    <td className={`${cls['td']} ${cls['employee']} ${cls['num']}`}>{i + 1}</td>
    <td className={`${cls['td']} ${cls['employee']}`}><span title={manager.user}>{manager.user}</span></td>
    <td className={`${cls['td']} ${cls['employee']}`}> <span title={manager.job}>{manager.job}</span></td>
    <td className={cls['td']} style={managerCurrentStatus?.value === 1 ? { color: '#999CA6' } : {}}
      onClick={() => setManagerModalIsOpen(true,FieldTypes['plan'],'prepaid',manager[`first_period_plan_gross`],manager.id)}>
      {addSpaces(manager[`first_period_plan_${netOrGross}`] ?? 0)}
    </td>
    <td className={cls['td']} style={((managerCurrentStatus?.value ?? 0) < 5 || managerCurrentStatus?.value === 8) ? { color: '#999CA6' } : {}}
      onClick={() => setManagerModalIsOpen(true,FieldTypes['fact'],'prepaid',manager[`first_period_fact_gross`],manager.id)}>
      {addSpaces(manager[`first_period_fact_${netOrGross}`] ?? 0)}
    </td>
    <td className={cls['td']} style={(managerCurrentStatus?.value ?? 0) < 6 ? { color: '#999CA6' } : {}}
      onClick={() => setManagerModalIsOpen(true,FieldTypes['plan'],'pay',manager[`second_period_plan_gross`],manager.id)}>
      {addSpaces(manager[`second_period_plan_${netOrGross}`] ?? 0)}
    </td>
    <td className={cls['td']} style={(managerCurrentStatus?.value ?? 0) < 9 ? { color: '#999CA6' } : {}}
      onClick={() => setManagerModalIsOpen(true,FieldTypes['fact'],'pay',manager[`second_period_fact_gross`],manager.id)}>
      {addSpaces(manager[`second_period_fact_${netOrGross}`] ?? 0)}
    </td>
    <td className={cls['td']} style={!([6,7,9,10] as any[]).includes(managerCurrentStatus?.value) ? { color: '#999CA6' } : {}}
      onClick={() => setManagerModalIsOpen(true,FieldTypes['approved'],'sum',manager[`total_approved_gross`],manager.id)}>
      {addSpaces(manager[`total_approved_${netOrGross}`] ?? 0)}
    </td>
    <td className={cls['td']} style={!([7,9,10] as any[]).includes(managerCurrentStatus?.value) ? { color: '#999CA6' } : {}}
      onClick={() => setManagerModalIsOpen(true,FieldTypes['paid'],'sum',manager[`total_paid_gross`],manager.id)}>
      {addSpaces(manager[`total_paid_${netOrGross}`] ?? 0)}
    </td>
  </tr>),[managerCurrentStatus?.value,managerTableItems,netOrGross,setManagerModalIsOpen,setSelectedEmployee]);

  return (<div>
    <table className={`${cls['table']} ${cls['table-rounding__top']} ${cls['table-rounding__bottom']}`}>
      <colgroup>
        <col width={44} />
        <col width={207} />
        <col width={181} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
      </colgroup>
      <thead className={`${cls['thead']} ${cls['rounding']}`}>
        <tr className={cls['tr']}>
          <th className={cls['th']} colSpan={3}>Вознаграждение управляющего</th>
          <th className={cls['th']} colSpan={2}>Аванс - Сумма, ₽</th>
          <th className={cls['th']} colSpan={2}>Зарплата - Сумма, ₽</th>
          <th className={cls['th']} colSpan={2}>Всего за месяц</th>
        </tr>
        <tr className={cls['tr']}>
          <th className={cls['th']}>№</th>
          <th className={cls['th']}>Работник</th>
          <th className={cls['th']}>Должность</th>
          <th className={cls['th']}>План</th>
          <th className={cls['th']}>Факт</th>
          <th className={cls['th']}>План</th>
          <th className={cls['th']}>Факт</th>
          <th className={cls['th']}>Утверждено</th>
          <th className={cls['th']}>Выплачено</th>
        </tr>
      </thead>
      <tbody className={`${cls['tbody']}`}>
        {employeesData}
      </tbody>
    </table>
  </div>);
};

export const ManagerInfo = observer(ManagerInfoComponent);
import React, { useState } from "react";
import { EColorVariant, EFontVariant } from "assets/styles/enums";
import { Button, EButtonVariant, ELogoVariant, Logo } from "ui";
import "./styles.scss";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

export const OnboardingHeader = observer(() => {
  const { RootStore } = useStore();
  const { AuthStore } = RootStore;
  const [isModalActive, setIsModalActive] = useState(false); // Временно отключено
  // <button onClick={() => setIsActive(true)}>Открыть модальное окно!</button>
  // Testing
  const testUserName = "Сергей";
  return (
    <div className="onboarding-header__container">
      <div className="onboarding-header__logo">
        <Logo variant={ELogoVariant.logo1} />
      </div>
      <div className="onboarding-header__main">
        <h1 className={`onboarding-header__main-header`}>
          Первая настройка спота
        </h1>

        <span className={`onboarding-header__main-address`}>
          Москва, ул. Новый Арбат 7а
        </span>
        <div className="onboarding-header__main-steps">
          <div className="onboarding-header__main-steps__step">
            {AuthStore.onboardingStep1 ? (
              <div className="onboarding-header__main-steps__step">
                <span
                  className={`onboarding-header__main-steps__step-number-active`}
                >
                  1
                </span>
                <span className={`onboarding-header__main-steps__step-text`}>
                  График работы
                </span>
              </div>
            ) : (
              <div className="onboarding-header__main-steps__step">
                <span className={`onboarding-header__main-steps__step-number`}>
                  1
                </span>
                <span className={`onboarding-header__main-steps__step-text `}>
                  График работы
                </span>
              </div>
            )}
          </div>
          <span className="onboarding-header__main-steps__line"></span>
          <div className="onboarding-header__main-steps__step">
            {AuthStore.onboardingStep2 ? (
              <div className="onboarding-header__main-steps__step">
                <span
                  className={`onboarding-header__main-steps__step-number-active `}
                >
                  2
                </span>
                <span className={`onboarding-header__main-steps__step-text `}>
                  Смены
                </span>
              </div>
            ) : (
              <div className="onboarding-header__main-steps__step">
                <span className={`onboarding-header__main-steps__step-number `}>
                  2
                </span>
                <span className={`onboarding-header__main-steps__step-text`}>
                  Смены
                </span>
              </div>
            )}
          </div>
          <span className="onboarding-header__main-steps__line"></span>
          <div className="onboarding-header__main-steps__step">
            {AuthStore.onboardingStep3 ? (
              <div className="onboarding-header__main-steps__step">
                <span
                  className={`onboarding-header__main-steps__step-number-active`}
                >
                  3
                </span>
                <span className={`onboarding-header__main-steps__step-text `}>
                  Команда
                </span>
              </div>
            ) : (
              <div className="onboarding-header__main-steps__step">
                <span className={`onboarding-header__main-steps__step-number`}>
                  3
                </span>
                <span className={`onboarding-header__main-steps__step-text `}>
                  Команда
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="onboarding-header__instruction"
        onClick={() => setIsModalActive(true)}
      >
        <div className="onboarding-header__instruction-logo"></div>
        <span className={`onboarding-header__instruction-text`}>
          Инструкция
        </span>
      </div>
      <div
        className={
          isModalActive
            ? "onboardingProcess__modal onboardingProcess__modal-active"
            : "onboardingProcess__modal"
        }
        //При клике по тёмной части
        // onClick={() => setIsModalActive(false)}
      >
        <div
          className="onboardingProcess__modal-content"
          onClick={(event) => event.stopPropagation()}
        >
          <h2 className={`onboardingProcess__modal-content__header`}>
            Добро пожаловать в SURF IS
          </h2>
          <div className="onboardingProcess__modal-content__line"></div>
          <div className="onboardingProcess__modal-content__container">
            <div className="onboardingProcess__modal-content__container__point">
              <div className="onboardingProcess__modal-content__container__point-top">
                <span
                  className={`onboardingProcess__modal-content__container__point-number`}
                >
                  1
                </span>
                <h3
                  className={`onboardingProcess__modal-content__container__point-header `}
                >
                  График работы
                </h3>
              </div>
              <p
                className={`onboardingProcess__modal-content__container__point-paragraph `}
              >
                Приветствуем, {testUserName}!
              </p>
              <p
                className={`onboardingProcess__modal-content__container__point-paragraph`}
              >
                Прежде всего, пожалуйста, заполните основную информацию:
                название вашего спота, режим работы.
              </p>
            </div>
            <div className="onboardingProcess__modal-content__container__point">
              <div className="onboardingProcess__modal-content__container__point-top">
                <span
                  className={`onboardingProcess__modal-content__container__point-number `}
                >
                  2
                </span>
                <h3
                  className={`onboardingProcess__modal-content__container__point-header `}
                >
                  Смены
                </h3>
              </div>
              <p
                className={`onboardingProcess__modal-content__container__point-paragraph `}
              >
                На этом шаге вам необходимо сформировать рабочие смены. Вы
                можете использовать стандартные смены, либо настроить свои.
              </p>
            </div>
            <div className="onboardingProcess__modal-content__container__point">
              <div className="onboardingProcess__modal-content__container__point-top">
                <span
                  className={`onboardingProcess__modal-content__container__point-number `}
                >
                  3
                </span>
                <h3
                  className={`onboardingProcess__modal-content__container__point-header `}
                >
                  Моя команда
                </h3>
              </div>
              <p
                className={`onboardingProcess__modal-content__container__point-paragraph `}
              >
                На этом шаге вы можете установить ставку за час, исходя из
                которой будет формироваться зарплата ваших сотрудников.
              </p>
            </div>
            <Button
              variant={EButtonVariant.fillButton}
              className={"body2Regular"}
              text={"Все понятно"}
              height={"48px"}
              borderRadius={"16px"}
              onClick={() => {
                setIsModalActive(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

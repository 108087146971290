import React from "react";
import { useNavigate } from "react-router";
import { Operation } from "../types";
import { ApproveStatus, DeleteStatus } from "../icons";

interface TableElementProps {
  operation: Operation;
}

const TableElement: React.FC<TableElementProps> = (props) => {
  const { operation } = props;
  const navigate = useNavigate();

  return (
    <div
      className="operation-input__table__inner-body__element"
      onClick={() => navigate(`edit#${operation.id}`)}
    >
      <div
        className="operation-input__table__inner-body__element__part svgWrapper"
        data-title="В ожидании"
      >
        {operation.status === 2 ? <DeleteStatus /> : <ApproveStatus />}
      </div>
      <div className="operation-input__table__inner-body__element__part">
        {operation.operation_date.split("T")[0]}
      </div>
      <div className="operation-input__table__inner-body__element__part">
        {operation.operation_date.split("T")[1].slice(0, 5)}
      </div>
      <div className="operation-input__table__inner-body__element__part">
        {operation.amount}
      </div>
      <div
        className="operation-input__table__inner-body__element__part"
        title={operation.responsible_user}
      >
        {operation.responsible_user}
      </div>
      <div
        className="operation-input__table__inner-body__element__part"
        title={operation.comment}
      >
        {operation.comment}
      </div>
    </div>
  );
};

export default TableElement;

import { observer } from 'mobx-react-lite';
import { IUser } from 'pages/salary-fund/types';
import { FC,useEffect,useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from 'store';
import SelectField from 'ui-new/select';
import { Modal } from 'widgets/modal';
import { ModalButtons } from 'widgets/modal/buttons';
import { CheckBoxController } from '../checkbox-controller';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';
import cls from './styles.module.scss';
import { PaymentTypes } from 'pages/access-rights/types';
import { InfoIcon } from 'pages/salary-fund/icons';
import { Tooltip } from 'ui-new/tooltip/tooltip';

const schema = yup.object({
  type: yup.number().typeError(ValidationMessages.get(ValidationTypes.required) ?? '')
    .required(ValidationMessages.get(ValidationTypes.required)),
  period: yup.string().typeError(ValidationMessages.get(ValidationTypes.required) ?? '')
    .required(ValidationMessages.get(ValidationTypes.required)),
  manager: yup.bool().required(),
  shifts: yup.bool().required(),
  contracts: yup.bool(),
  bonus: yup.bool(),
});

interface FormValues {
  type: number;
  period: string;
  manager: boolean;
  shifts: boolean;
  contracts?: boolean;
  bonus?: boolean;
}

interface EmployeeTypeModalProps {
  user: IUser | null;
  onClose: () => void;
}

const EmployeeTypeModalComponent: FC<EmployeeTypeModalProps> = ({ user,onClose }) => {
  const { RootStore: { RoleStore: { fetchGetEmploymentTypes,employmentTypes,fetchGetEmploymentPeriods,employmentPeriods,updateEmploymentConfirm } } } = useStore();

  const employmentTypesData = useMemo(() => Object.entries(employmentTypes).map(([key,val]) => ({ value: Number(key),label: val })),[employmentTypes]);
  const employmentPeriodsData = useMemo(() => employmentPeriods.map(({ value,text }) => ({ value,label: text })),[employmentPeriods]);

  const { control,handleSubmit,watch,getValues,setValue } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      shifts: false,
      manager: false,
      contracts: false,
    }

  });

  const awardTypes = useMemo(() => {
    if (!user || !user.award_types) return [];

    return user.award_types.filter(aT => aT.user_employee_type_id === 1).map(awardType => awardType.award_id);
  },[user]);

  const type = watch('type');

  const checkboxesDisabled = useMemo(() => !(getValues().type === 1),[type]);

  useEffect(() => {
    setValue('shifts',type === 1);
    setValue('manager',type === 1);

    if (type !== 1) {
      setValue('bonus',false);
      setValue('contracts',false);
    }
  },[type]);

  useEffect(() => {
    if (type !== 1) return;
    setValue('contracts',awardTypes.includes(PaymentTypes.contract));
    setValue('bonus',awardTypes.includes(PaymentTypes.bonus));
  },[awardTypes,type]);

  useEffect(() => {
    fetchGetEmploymentTypes();
    fetchGetEmploymentPeriods();
  },[fetchGetEmploymentTypes,fetchGetEmploymentPeriods]);

  const onSubmit = (values: FormValues) => {
    if (!user) return;

    updateEmploymentConfirm({
      id: user.id,
      type: values.type,period: values.period,
      types: {
        contracts: !!values.contracts,
        bonus: !!values.bonus,
      }
    },onClose);

  };

  return (<Modal
    title='Трудоустройство и вознаграждения'
    maxWidth='520px'
    minWidth='520px'
    onClose={onClose}
  >
    <div style={{ display: 'flex',flexDirection: 'column' }}>
      <SelectField control={control} defaultValue={user?.employee_type}
        name='type' options={employmentTypesData} label='Вид трудоустройства'
        selectProps={{ placeholder: 'Выбери вид трудоустройства работника' }}
      />
      <SelectField control={control} name='period' options={employmentPeriodsData}
        selectProps={{ placeholder: 'Выбери период' }}
        label={<div style={{ display: 'flex',gap: '9px' }}>
          Период, с которого будут применены изменения
          <Tooltip activator={<InfoIcon color='#6D37F6' />} placement={'top'} offset={10}
            tooltip={<span className={cls['tooltip-info']}>Выбрать можно только ближайшие периоды, без утвержденных или выплаченных вознаграждений</span>}
          />
        </div>}
      />
      <div className={cls['payment-types']}>
        <label>Тип выплаты вознаграждения</label>
        <p className={cls['subtitle']}>Выберите вознаграждения, которые рассчитываются и выплачиваются в рамках трудового договора</p>
        <div className={cls['checkbox-container']}>
          {user?.job_title_en === 'Upravlyayushtiy' &&
            <CheckBoxController disabled name={'manager'} control={control}>
              <span className={cls['checkbox-label']}>Фиксированное вознаграждение управляющего</span>
            </CheckBoxController>
          }
          <CheckBoxController disabled name={'shifts'} control={control}>
            <span className={cls['checkbox-label']}>Вознаграждение за смены</span>
          </CheckBoxController>
          {user?.job_title_en !== 'Upravlyayushtiy' &&
            <CheckBoxController disabled={checkboxesDisabled} name={'contracts'} control={control}>
              <span className={cls['checkbox-label']}>Контракты</span>
            </CheckBoxController>
          }
          <CheckBoxController disabled={checkboxesDisabled} name={'bonus'} control={control}>
            <span className={cls['checkbox-label']}>Премия</span>
          </CheckBoxController>
        </div>
      </div>
      <div style={{ marginTop: '10px',alignSelf: 'flex-end' }}>
        <ModalButtons onPrimaryButtonClick={handleSubmit(onSubmit)} primaryButtonLabel={'Сохранить'} onSecondaryButtonClick={onClose} secondaryButtonLabel='Отменить' />
      </div>
    </div>
  </Modal>);
};

export const EmployeeTypeModal = observer(EmployeeTypeModalComponent);
import { observer } from "mobx-react-lite";
import { FC, useMemo, useState } from "react";
import cls from "../table-styles.module.scss";
import { InfoContainer } from "../components/info-tooltip";
import { useStore } from "store";
import { addSpaces } from "assets/utils";
import { IContragentLoan, IInvoice } from "../types";
import { SortArrowsComponent } from "ui-new/table/sort-arrows";
import dayjs from "dayjs";
import { toJS } from "mobx";

const INFO_MESSAGE =
  "Дата, до которой необходимо оплатить задолженность (рассчитана на основании договора)";

interface InvoicesTableProps {}

const InvoicesTableComponent: FC<InvoicesTableProps> = () => {
  const {
    RootStore: {
      ContragentsLoansPage: { byInvoices, byContragents },
    },
  } = useStore();
  const [sortBy, setSortBy] = useState<"dateFor" | "credit" | null>("dateFor");
  const [dist, setDist] = useState<1 | -1>(1);

  const onSort = (key: "dateFor" | "credit") => {
    setSortBy(prev => {
      if (prev === key) {
        setDist(prev => (prev * -1) as 1 | -1);
        return prev;
      }
      setDist(1);
      return key;
    });
  };

  const invoicesData = useMemo(
    () =>
      toJS(byInvoices)
        .sort((a, b) => {
          console.log(sortBy, dist);

          if (sortBy === null) return 1;
          if (typeof a[sortBy] === "number" && typeof b[sortBy] === "number")
            return ((a[sortBy] as number) - (b[sortBy] as number)) * dist;
          return dayjs(a[sortBy], "DD.MM.YYYY").diff(dayjs(b[sortBy], "DD.MM.YYYY")) * dist;
        })
        .map(invoice => {
          const contragent = byContragents.find(c => c.id === invoice.contragent_id);
          return <Invoice key={invoice.id} invoice={invoice} contragent={contragent} />;
        }),
    [byContragents, byInvoices, dist, sortBy]
  );

  const [sum, paid, credit] = useMemo(() => {
    let sum = 0;
    let paid = 0;
    let credit = 0;

    byInvoices.forEach(invoice => {
      sum += invoice.sum;
      paid += invoice.paid;
      credit += invoice.credit;
    });

    return [sum, paid, credit];
  }, [byInvoices]);

  return (
    <table className={cls["table"]}>
      <colgroup>
        <col width={258} />
        <col width={136} />
        <col width={136} />
        <col width={160} />
        <col width={130} />
        <col width={130} />
        <col width={210} />
      </colgroup>
      <thead className={cls["thead"]}>
        <tr className={cls["tr"]}>
          <th className={cls["th"]}>Поставщик</th>
          <th className={cls["th"]}>№ Договора</th>
          <th className={cls["th"]}>№ накладной</th>
          <th className={cls["th"]} style={{ paddingRight: "19px" }}>
            <InfoContainer message={INFO_MESSAGE}>
              <SortArrowsComponent onClick={() => onSort("dateFor")}>
                Погасить до
              </SortArrowsComponent>
            </InfoContainer>
          </th>
          <th className={cls["th"]}>Сумма общая</th>
          <th className={cls["th"]}>Оплачено</th>
          <th className={cls["th"]}>
            <SortArrowsComponent onClick={() => onSort("credit")}>
              Остаток задолженности
            </SortArrowsComponent>
          </th>
        </tr>
      </thead>
      <tbody className={cls["tbody"]}>{invoicesData}</tbody>
      <tfoot className={cls["tfoot"]}>
        <tr className={cls["tr"]}>
          <td className={`${cls["td"]} ${cls["sum-title"]}`}>Всего</td>
          <td colSpan={3} className={cls["td"]}></td>
          <td className={cls["td"]}>{addSpaces(sum.toFixed(2))}</td>
          <td className={cls["td"]}>{addSpaces(paid.toFixed(2))}</td>
          <td className={cls["td"]}>{addSpaces(credit.toFixed(2))}</td>
        </tr>
      </tfoot>
    </table>
  );
};

interface InvoiceProps {
  invoice: IInvoice;
  contragent?: IContragentLoan;
}

const Invoice: FC<InvoiceProps> = ({
  invoice: { paid, credit, number, dateFor, sum, expired },
  contragent,
}) => {
  const { title, inn, deal } = contragent ?? {
    title: "Нет данных",
    inn: "нет данных",
    deal: "Нет данных",
  };

  return (
    <tr className={cls["tr"]}>
      <td className={cls["td"]}>
        <p className={cls["contragent-title"]}>{title}</p>
        <span className={cls["contragent-inn"]}>ИНН {inn}</span>
      </td>
      <td className={cls["td"]}>{deal}</td>
      <td className={cls["td"]}>{number}</td>
      <td className={`${cls["td"]} ${expired ? cls["date-expired"] : ""}`}>{dateFor}</td>
      <td className={cls["td"]}>{addSpaces(sum.toFixed(2))}</td>
      <td className={cls["td"]}>{addSpaces(paid.toFixed(2))}</td>
      <td className={cls["td"]}>{addSpaces(credit.toFixed(2))}</td>
    </tr>
  );
};

export const InvoicesTable = observer(InvoicesTableComponent);

import { ExpandPlusMinus } from "ui";

export const OSVItem = (props: {
  data: any;
  showItems?: boolean | undefined;
  isBold?: boolean | undefined;
  opener?: () => void;
}) => {
  const { data, showItems, isBold, opener } = props;

  return (
    <>
      <div className="item__code">{data.title.split(". ")[0]}</div>
      {/* <!-- наименование --> */}
      <div className="item__name br">
        <div className="expand__icon" onClick={opener}>
          {showItems !== undefined && <ExpandPlusMinus expanded={showItems} />}
        </div>
        <p>{data.title.split(". ")[1]}</p>
      </div>
      {/* <!-- СНН Дебют --> */}
      <div className=" item__sum ">{data.saldoBegin.debet}</div>
      {/* <!-- СНН Кредит --> */}
      <div className=" item__sum br ">{data.saldoBegin.credit}</div>
      {/* <!-- ОЗП Дебют --> */}
      <div className=" item__sum">{data.periodTransactions.debet}</div>
      {/* <!-- ОЗП Кредит --> */}
      <div className=" item__sum br">{data.periodTransactions.credit}</div>
      {/* <!-- СНК Дебют --> */}
      <div className=" item__sum">{data.saldoEnd.debet}</div>
      {/* <!-- СНК Кредит --> */}
      <div className=" item__sum">{data.saldoEnd.credit}</div>
    </>
  );
};

import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { EInputStyleVariant, Input } from "ui-new/input";

interface SumFieldProps {
  allDisabled: boolean;
}

const SumFieldComponent: FC<SumFieldProps> = ({ allDisabled }) => {
  const { control } = useFormContext<{ amount: number }>();

  return (
    <Input
      label="Сумма"
      control={control}
      variant={EInputStyleVariant.basicInput}
      placeholder="Введи сумму"
      type="number"
      name="amount"
      disabled={allDisabled}
    />
  );
};

export const SumField = SumFieldComponent;

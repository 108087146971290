import { PopupModal } from "../../../../widgets";
import { Radio,RadioChangeEvent,Space } from "antd";
import React,{ useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

export const AddDocumentModal = observer(() => {
  const {
    RootStore: {
      RegistryStore: {
        setFirstRegistryModalIsOpen,
        setPickedRegistryArticle,
        setShownRegistryArticle,
        addedDocumentType,
        setAddedDocumentType,
        fetchCheckCreateInventory,
        checkRegistryIsLoading,
      },
    },
  } = useStore();

  const onSave = useCallback(() => {
    if (!addedDocumentType) return;
    fetchCheckCreateInventory();
  },[addedDocumentType]);

  const onClose = () => setFirstRegistryModalIsOpen(false);

  const onAddedDocumentTypeChange = useCallback((e: RadioChangeEvent) => {
    setAddedDocumentType(e.target.value);
    setPickedRegistryArticle(null);
    setShownRegistryArticle("Выберите статью");
  },[]);




  return (
    <PopupModal
      title={"Добавить документ"}
      subtitle={"Выберите тип документа для добавления"}
      onSave={onSave}
      maxWidth={"440px"}
      buttonText={"Далее"}
      onClose={onClose}
      isButtonDisabled={!addedDocumentType || checkRegistryIsLoading}
    >
      <div
        style={{
          width: "100%",
          padding: "8px 20px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Radio.Group
          onChange={onAddedDocumentTypeChange}
          value={addedDocumentType}
        >
          <Space direction="vertical">
            {/* <Radio data-testid={'input-order'} value={1}>Приходный кассовый ордер</Radio>
            <Radio data-testid={'output-order'} value={2}>Расходный кассовый ордер</Radio> */}
            <Radio data-testid={"inventory"} value={3}>
              Инвентаризация
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      {checkRegistryIsLoading &&
        //! Временный loader, поэтому стили прописаны сразу здесь
        <div style={{ alignSelf: 'center',position: 'absolute',right: '30px',bottom: '25px' }}>
          <div className="loader">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div className="loader-background"></div>
            </div>
          </div>
        </div>
      }
    </PopupModal>
  );
});

import { api, IBaseResponse } from "./api";
import { IUser } from "pages/salary-fund/types";
import { onError } from "./schedulePlanning";
import { IEmploymentPeriod } from "pages/access-rights/types";

export async function getEmployees() {
  try {
    const response = await api.get<IBaseResponse<IUser[]>>("spot/users");
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getEmploymentTypes() {
  try {
    const response = await api.get<IBaseResponse<{ [key: number]: string }[]>>(
      "user/employment/types"
    );
    return response.data.data[0];
  } catch (error) {
    return onError(error);
  }
}

export async function getEmploymentPeriods() {
  try {
    const response = await api.get<IBaseResponse<IEmploymentPeriod[]>>(
      "user/employment/periods"
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export interface IEmploymentTypeUpdate {
  id: number;
  type: number;
  period: string;
  types: { [kye: string]: boolean };
}

export async function updateEmploymentType(body: IEmploymentTypeUpdate) {
  try {
    const response = await api.post<IBaseResponse<IUser>>(
      "user/employment",
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export interface IEmployeeInfoUpdate {
  id: IUser["id"];
  name: IUser["name"];
  surname: IUser["surname"];
  third_name: IUser["third_name"];
  date_of_born: IUser["date_of_born"];
  inn: IUser["inn"];
}

export async function updateEmployeeInfo(body: IEmployeeInfoUpdate) {
  try {
    const response = await api.post<IBaseResponse<IUser>>("user/update", body);
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

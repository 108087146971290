export const tableHeaders = [
    {
        title: 'Наименование',
        value: 'nomenclature_title',
        width: '40%',
    },
    {
        title: 'Цена',
        value: 'cost',
    },
    {
        title: 'Количество',
        value: 'value',
    },
    {
        title: 'Ед.изм',
        value: 'unit_title',
    },
    {
        title: 'Сумма',
        value: 'sum',
    },
];

export const headerTitles: Record<string, { title: string, value?: unknown }> = {
    from_title: {
        title: 'Контрагент',
    },
    sum: {
        title: 'Сумма',
    },
    status: {
        title: 'Статус'
    },
    number: {
        title: 'Номер документа',
    },
    date: {
        title: 'Дата документа'
    },
};

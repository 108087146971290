import { FC,useMemo } from 'react';
import { ITemplate } from '../types';
import { toJS } from 'mobx';
import cls from './styles.module.scss';
import { Template } from './template';

interface TemplateListProps {
  templates: ITemplate[];
}

const TemplateList: FC<TemplateListProps> = ({ templates }) => {

  const templatesData = useMemo(() =>
    templates.map(template => <Template key={template.id} template={template} />)
    ,[toJS(templates),templates]);

  return (<div className={cls['schedule-templates__list']}>
    {templatesData}
  </div>);
};

export default TemplateList;
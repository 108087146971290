import React from "react";
import { Route, Routes } from "react-router";
import { lazy, Suspense } from "react";
import { AuthSkeleton } from "ui";

const FirstAuthPage = lazy(() =>
  import("pages").then((module) => ({ default: module.FirstAuthPage })),
);
const AuthPage = lazy(() =>
  import("pages").then((module) => ({ default: module.AuthPage })),
);
const ResetPassPage = lazy(() =>
  import("pages").then((module) => ({ default: module.ResetPassPage })),
);

export const LoginProcess = () => {
  return (
    <Routes>
      <Route
        path="first-auth"
        element={
          <Suspense fallback={<AuthSkeleton />}>
            <FirstAuthPage />
          </Suspense>
        }
      />
      <Route
        path="auth"
        element={
          <Suspense fallback={<AuthSkeleton />}>
            <AuthPage />
          </Suspense>
        }
      />
      <Route
        path="reset-pass"
        element={
          <Suspense fallback={<AuthSkeleton />}>
            <ResetPassPage />
          </Suspense>
        }
      />
      <Route
        path=":token"
        element={
          <Suspense fallback={<AuthSkeleton />}>
            <FirstAuthPage />
          </Suspense>
        }
      />
    </Routes>
  );
};

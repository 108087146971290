import React, { memo } from "react";
import { readDocument } from "../../../../api";
import {Operation, type OperationStatus} from "../../types";
import {
  ApproveStatus,
  DeleteStatus,
  PendingStatus,
  WaitingStatus,
} from "../../icons";
import { toJS } from "mobx";
import { useNavigate } from "react-router";
import { useStore } from "../../../../store";
import IsReadElement from "./isReadElement";
import {Tooltip} from "ui-new/tooltip/tooltip";
import {tooltipStatusText} from "../../utils";

interface TableElementProps {
  operation: Operation;
}

const TableElement = memo(({ operation }: TableElementProps) => {
  const { RootStore } = useStore();
  const {
    RegistryStore: { operationsList, setOperationsList, setPickedDocument },
  } = RootStore;

  const navigate = useNavigate();

  const handleDocumentClick = (
    event: any,
    documentId: number,
    documentType: string,
    isRead: boolean,
  ) => {
    const target = event.currentTarget as HTMLDivElement;

    const obj = toJS(
      operationsList.find(
        (obj: Operation) =>
          obj.document_id == documentId && obj.type == documentType,
      ),
    );

    if (!obj) return;
    setPickedDocument(obj);
    if (!isRead) {
      readDocument(documentId, documentType, true);
    }

    navigate(`/main/registry/${obj.type}#${documentId}`);
  };

  const operationStatus = () => {
    switch (operation.status) {
      case "waiting":
      case "delivery":
        return <WaitingStatus />

      case "approved":
        return <ApproveStatus />

      case "deleted":
        return <DeleteStatus />

      case "pending":
        return <PendingStatus/>

    }
  }

  const getSumResult = (value: number) => {
    return parseFloat(value.toFixed(2));
  };

  return (
    <div
      className="registry-table__element"
      key={operation.document_id}
      id={operation.document_id.toString()}
      data-testid={"document-elem"}
      onClick={(event) =>
        handleDocumentClick(
          event,
          operation.document_id,
          operation.type,
          operation.is_read,
        )
      }
    >
      <div className="registry-table__element-cell registry-table__element-cell_center registry-table__seen f28">
        <IsReadElement
          operationId={operation.document_id}
          operationType={operation.type}
        />
      </div>
      <div className="registry-table__element-cell registry-table__element-cell_center  registry-table__status f28">
        {<Tooltip text={tooltipStatusText[operation.status]} activator={operationStatus()} />}
      </div>
      <div className="registry-table__element-cell  registry-table__data f130">
        {operation.date}
      </div>
      <div className="registry-table__element-cell  registry-table__document f200">
        {operation.document.split("№")[0]}
      </div>
      <div className="registry-table__element-cell  registry-table__number f180">
        {operation.document.split("№")[1]}
      </div>
      <div
        className={`registry-table__element-cell  registry-table__contragent f232 ${
          operation.inn && `inn`
        }`}
        title={operation.contragent}
      >
        {operation.contragent}
        <p>{operation.inn && `ИНН: ${operation.inn}`}</p>
      </div>
      <div className="registry-table__element-cell  registry-table__sum f130">
        {getSumResult(operation.sum_debet)}
      </div>
      <div className="registry-table__element-cell  registry-table__person f232 ">
        {operation.responsible}
      </div>
    </div>
  );
});

export default TableElement;

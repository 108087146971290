import { FC,useState } from 'react';
import { BucketIcon } from '../../../icons';
import { IWorker } from '../../../types';
import cls from '../../styles.module.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';

interface WorkerTitleProps {
  worker: IWorker;
  tableType: string;
}

const WorkerTitleComponent: FC<WorkerTitleProps> = ({ worker: { id,name,title },tableType }) => {
  const { RootStore: { SchedulePlanningStore: { confirmDeleteHelper } } } = useStore();
  const [bucketColor,setBucketColor] = useState<'#999999' | '#000000'>('#999999');

  return (<div className={cls['column-employee']}>
    <strong title={name}>
      {name}
    </strong>
    <div className={cls['job-title']}>
      <span title={title}>{title}</span>
      {tableType === 'Хелперы' &&
        <button onClick={() => confirmDeleteHelper(id)}
          onMouseEnter={() => setBucketColor('#000000')} onMouseLeave={() => setBucketColor('#999999')}
        >
          <BucketIcon color={bucketColor} />
        </button>
      }
    </div>
  </div>);
};

export const WorkerTitle = observer(WorkerTitleComponent);

import {api} from "./api";
import {IPermissions} from "../pages/access-rights/types";
import {AxiosResponse} from "axios/index";

export async function userSetSpot(spotId: number | string) {
  try {
    const response = await api.post("user/setSpot", { spotId });
    return response.data;
  } catch {
    return null;
  }
}

export async function userGetAvailableSpots(spotId: number) {
  try {
    const response = await api.post("user/getAvailableSpots", {
      spotId
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function userGetRolesOnSpot(spotId: number, userId: number): Promise<AxiosResponse<IPermissions> | null> {
  try {
    const response = await api.post("user/getPermissionsOnSpot", {
      spotId,
      userId
    });
    return response.data;
  } catch {
    return null;
  }
}

export async function userSetPermissionsOnSpot(body: any) {
  try {
    const response = await api.post("user/setPermissionsOnSpot", body);
    return response.data;
  } catch {
    return null;
  }
}

export async function getAvailableUser() {
  try {
    const response = await api.get("spot/users");
    return response.data;
  } catch {
    return null;
  }
}

export async function getEmployeesList(pickedTimetableId: string) {
  try {
    const response = await api.get("employees/list", {
      params: {
        timetable_id: +pickedTimetableId,
      },
    });
    return response.data;
  } catch {
    return null
  }
}

import styles from './styles.module.scss';
import {useEffect, useMemo, useState} from "react";
import {
    getMonthCheckAwards,
    getMonthCheckDocuments,
    getMonthCheckLoans,
    getMonthCheckMoneyTransactions, getMonthClose,
    getMonthLast
} from "../../api/monthEnd";
import {Button} from "../../ui-new/button";
import {dayjs} from "utils/plugins/dayjs"
import {CalendarIcon} from "./icons/Calendar";
import {List} from "./components/list";
import {Link} from "react-router-dom";
import {CheckInfoModal} from "./components/check-info-modal";

export const MonthEnd = () => {

    const [items, setItems] = useState([
        { title: 'Проверь, есть ли незакрытые Реализации с другими спотами', errorMessage: <span>Есть незакрытые <Link to="/main/loan">Реализации</Link></span>, value: 'loan', isLoading: false, success: false, message: '' },
        { title: 'Проверь, выполнено ли начисление зарплаты за отчетный период', errorMessage: <span>У тебя есть неутвержденные <Link to="/main/fund">вознаграждения</Link></span>, value: 'award', isLoading: false, success: false, message: '' },
        { title: 'Убедись, что все необходимые документы утверждены, а неактуальные помечены на удаление', errorMessage: <span>Перейди в <Link to="/main/registry" className={styles.link}>Реестр документов</Link> и утверди или пометь на удаление неутвержденные документы</span>, value: 'document', isLoading: false, success: false, message: '' },
        { title: <span>Убедись, что у тебя не осталось просроченных <Link to="/main/loans/contragents" className={styles.link}>задолженностей</Link></span> },
        { title: <span>Проверь, что вся необходимая информация о <Link to="/main/statements">движении денежных средств</Link> отражена в виде банковских выписок и кассовых ордеров. Сверься с состоянием кассы и расчетного счета и подтверди корректность остатков</span>, value: 'accepts' },
        { title: 'Проверь отчет “Остатки на складе” и в случае расхождений внеси необходимые исправления - добавь накладные и авансовые отчеты, устрани недостатки инвентаризации. Если все проверки выполнены и отрицательных остатков нет - жми кнопку', value: 'month-close', isLoading: false, success: false },
    ])
    const [isFirstRequest, setFirstRequest] = useState(false);
    const [currentMonthName, setCurrentMonthName] = useState('');
    const [isUpdateButtonDisabled, setUpdateButtonDisabled] = useState(true);
    const [isMonthEndLoading, setMonthEndLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState('');
    const [isCheckInfoModal, setCheckInfoModal] = useState(false);
    const [isMonthAccepted, setMonthAccepted] = useState(false);
    const [isMonthClosed, setMonthClosed] = useState(false);

    const currentMonthLabel = useMemo(() => {
        const nextMonth = dayjs(new Date(currentDate)).add(1, 'month').toDate();
        const longMonthName = new Date(nextMonth).toLocaleDateString('ru', { month: 'long', year: 'numeric', day: 'numeric' });
        return isUpdateButtonDisabled ? `Будет доступно ${longMonthName}` : `Сейчас мы закрываем ${currentMonthName}`
    }, [currentMonthName, isUpdateButtonDisabled, currentDate]);

    const isSubmitDisabled = useMemo(() => {
        const someUnsuccess = items.some((item) => !item.success)
        return !isMonthAccepted && someUnsuccess
    }, [items, isMonthAccepted])

    const setItemsLoading = (value: boolean) => {
        setItems((prev: any) => {
            return prev.map((item: any) => ({
                ...item,
                isLoading: value,
            }));
        });
    }

    const openModal = () => {
        setCheckInfoModal(true)
    }

    const closeModal = () => {
        setCheckInfoModal(false)
    }

    const onModalSave = () => {
        setMonthAccepted(true);
        setCheckInfoModal(false);
    }

    const initMonthEnd = async () => {
        setMonthEndLoading(true);

        try {
            const { month, available_approve_day_start, available_approve_day_end } = await getMonthLast();
            const currentDate = dayjs().add(1, 'month').date();
            setCurrentMonthName(dayjs(month).format("MMMM"));
            setUpdateButtonDisabled(!(currentDate >= available_approve_day_start && currentDate < available_approve_day_end));
            setCurrentDate(month);
        } catch (e) {
            console.log(e);
        } finally {
            setMonthEndLoading(false);
        }
    }

    const applyItemMethods = async () => {
        setItemsLoading(true);
        setFirstRequest(true);

        const [checkedLoans, checkedAwards, checkedDocuments] = await Promise.all([
            getMonthCheckLoans(currentDate),
            getMonthCheckAwards(currentDate),
            getMonthCheckDocuments(currentDate),
            getMonthCheckMoneyTransactions(currentDate),
        ]);

        setItems((prev) => {
            const temp = [...prev];

            temp[0].success = checkedLoans.success;
            temp[0].message = checkedLoans.message;
            temp[1].success = checkedAwards.success;
            temp[1].message = checkedAwards.message;
            temp[2].success = checkedDocuments.success;
            temp[2].message = checkedDocuments.message;

            return temp;
        });

        setItemsLoading(false);
    }

    const onMonthClose = async () => {
        try {
            const response = await getMonthClose(currentDate);

            if (response.success) {
                setMonthClosed(true);
                return;
            }
        } catch (e) {
            // @ts-ignore
            const message = e.response.data.message;
            setItems((prev) => {
                const temp = [...prev];

                temp[5].success = false;
                temp[5].message = message;

                return temp;
            });
        }
    }

    useEffect(() => {
        initMonthEnd();
    }, []);

    return <div className={styles.pageWrapper}>
        <header className={styles.pageHeader}>
            <h1 className={styles.pageHeaderText}>
                Закрытие месяца
            </h1>

            <div className={styles.headerActions}>
                <span className={styles.subtitle}>
                    Выполни проверки, внеси недостающую информацию в систему, исправь ошибки учета и <span className={styles.filledText}>закрой отчетный период до 5 числа.</span>
                    Чтобы закрыть последний отчетный период - все предыдущие периоды должны быть закрыты
                </span>

                <div className={styles.closePeriodContainer}>
                    <div className={styles.currentMonth}>
                        <span className={styles.currentMonthLabel}>{currentMonthLabel}</span>

                        <div className={styles.monthWrapper}>
                            <CalendarIcon className={styles.icon} />
                            <span>{currentMonthName}</span>
                        </div>
                    </div>

                    <Button onClick={applyItemMethods} disabled={isUpdateButtonDisabled} isLoading={isMonthEndLoading}>
                        Закрыть период
                    </Button>
                </div>
            </div>
        </header>

        <div className={styles.listContainer}>
            <List items={items} openModal={openModal} isMonthAccepted={isMonthAccepted} isSubmitDisabled={isSubmitDisabled} onMonthClose={onMonthClose} isFirstRequest={isFirstRequest} />
        </div>

        <CheckInfoModal isOpen={isCheckInfoModal} onClose={closeModal} onSave={onModalSave} cash={0} nonCash={0} />
    </div>
}
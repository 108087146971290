import React from "react";
import { NavLink } from "react-router-dom";
import "./styles.scss";

export enum ENavigateStyleVariant {
  standartNavItem = "standartNavItem",
  choosenStandartNavItem = "choosenStandartNavItem",
  textNavItem1 = "textNavItem1",
  strokeNavItem = "strokeNavItem",
  sideNavItem = "sideNavItem",
  choosenSideNavItem = "choosenSideNavItem",
  subSideNavItem = "subSideNavItem",
  lastSubSideNavItem = "lastSubSideNavItem",
  choosenSubSideNavItem = "choosenSubSideNavItem",
  disabledReportsTab = "disabledReportsTab",
}

export enum ENavigatePictogramVariant {
  navDashboard = "navDashboard",
  navDashboardFilled = "navDashboardFilled",
  navReport = "navReport",
  navReportfilled = "navReportfilled",
  navStaff = "navStaff",
  navStaffFilled = "navStaffFilled",
  navSpot = "navSpot",
  navSpotFilled = "navSpotFilled",
  navSettings = "navSettings",
  navSettingsFilled = "navSettingsFilled",
  navInfo = "navInfo",
  navInfoFilled = "navInfoFilled",
  navSub = "navSub",
  lastNavSub = "lastNavSub",
}

interface INavlinkType {
  variant:
    | string
    | ((props: {
        isActive: boolean;
        isPending: boolean;
      }) => string | undefined);
  path: string;
  maxWidth?: string;
  height?: string;
  text?: string;
  borderRadius?: string;
  id?: string;
  onClick?: () => void;
  dataTitle?: string;
}

export const Navlink: React.FC<INavlinkType> = ({
  path,
  variant,
  maxWidth,
  height,
  borderRadius,
  text,
  id,
  onClick,
  dataTitle,
}) => {
  return (
    <NavLink
      className={variant}
      id={id}
      to={`${path}`}
      style={{
        maxWidth: maxWidth,
        height: height,
        borderRadius: borderRadius,
        whiteSpace: "nowrap",
      }}
      onClick={onClick}
      data-title={dataTitle}
    >
      {text}
    </NavLink>
  );
};

import "./styles.scss";
import { useEffect } from "react";
import { OSVGroup } from "./components/group";
import { OSVItem } from "./components/item";
import { ReportsSkeleton } from "ui";
import { PeriodPicker } from "widgets/datepickers/period-picker";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import NoData from "../../../ui/no-data/noData";
import { Value } from "react-calendar/src/shared/types";

export const OSV = observer(() => {
  const {
    RootStore: {
      OSVStore: {
        dataOSV,
        isLoadingOSV,
        periodStartOSV,
        periodEndOSV,
        handlePeriodChangeOSV,
        getDataOSV,
      },
    },
  } = useStore();

  useEffect(() => {
    getDataOSV();
  }, []);

  return (
    <div className="osv" data-testi={"osv"}>
      <div className="osv__datepicker-wrapper">
        <PeriodPicker
          periodStart={periodStartOSV}
          periodEnd={periodEndOSV}
          periodSetter={(period: Value) => handlePeriodChangeOSV(period)}
          isFilter={false}
        />
      </div>

      {isLoadingOSV ? (
        <ReportsSkeleton />
      ) : dataOSV !== null && dataOSV.table.length > 0 ? (
        <>
          <div className="table">
            <div className="table__header__code bt bb">
              <p>Код</p>
            </div>
            <div className="table__header__name bt br bb">
              <p>Наименование</p>
            </div>
            <div className="header-group">
              <div className="header-item bt br">Сальдо на начало</div>
              <div className="header-debcred">
                <div className=" header-item bt br bb">Дебет</div>
                <div className="header-item bt br bb">Кредит</div>
              </div>
            </div>
            <div className="header-group">
              <div className="header-item br bt">Обороты за период</div>
              <div className="header-debcred">
                <div className=" header-item bt br bb">Дебет</div>
                <div className="header-item bt br bb">Кредит</div>
              </div>
            </div>
            <div className="header-group">
              <div className="header-item bt">Сальдо на конец</div>
              <div className="header-debcred">
                <div className="header-item bt br bb">Дебет</div>
                <div className=" header-item bt bb">Кредит</div>
              </div>
            </div>
            {dataOSV.table.map((obj: any, index: number) => {
              return obj.items.length > 0 ? (
                <OSVGroup groupData={obj} items={obj.items} key={index} />
              ) : (
                <OSVItem data={obj} isBold />
              );
            })}
          </div>
          <div className="table-footer">
            <div className="" />
            <div className="br" />
            <div className="item__sum" />
            <div className="item__sum br" />
            <div className="item__sum" />
            <div className="item__sum br" />
            <div className="item__sum" />
            <div className="item__sum" />
            <div className="table-footer__empty br" />
            <div className=" footer__item bt">
              {dataOSV.total.saldoBegin.totalDebetBegin}
            </div>
            <div className=" footer__item bt br">
              {dataOSV.total.saldoBegin.totalCreditBegin}
            </div>
            <div className="footer__item bt">
              {dataOSV.total.periodTransactions.totalDebetPeriod}
            </div>
            <div className="footer__item bt br">
              {dataOSV.total.periodTransactions.totalCreditPeriod}
            </div>
            <div className="footer__item bt">
              {dataOSV.total.saldoEnd.totalDebetEnd}
            </div>
            <div className="footer__item bt">
              {dataOSV.total.saldoEnd.totalCreditEnd}
            </div>
          </div>
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
});

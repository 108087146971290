import React, { useRef } from 'react';
import { toJS } from 'mobx';
import { useStore } from '../../../store';
import { UpdatePositionTypes } from '../types';

interface SumInputProps{
  value: any,
  index: number
}

const SumInput: React.FC<SumInputProps> = ({value, index}) => {
  const timerRef = useRef<number | NodeJS.Timeout | null>(null);
  const typingInterval = 1000;
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: {
      advanceReport,
      setAdvanceReport,
      updatePosition,
    },
  } = RootStore;

  return (
    <input
      className={`registry-document__table-element input`}
      value={value.sum !== null ? value.sum : ""}
      onChange={(event) => {
        if (timerRef.current !== null) {
          clearTimeout(timerRef.current);
        }
        const { value: val } = event.target as HTMLInputElement;
        if (val === "" || /^\d+(\.\d{0,3})?$/.test(val)) {
          timerRef.current = setTimeout(() => updatePosition(value.id, UpdatePositionTypes.SUM, +val), typingInterval)
          const updatedPositions = [...advanceReport.positions];
          updatedPositions[index].sum = val;
          setAdvanceReport({
            ...toJS(advanceReport),
            positions: updatedPositions,
          });
        }
      }}
    />
  );
};

export default SumInput;
import React, { useState } from "react";
import { readDocument } from "../../../../api";
import { Operation } from "../../types";
import { useStore } from "../../../../store";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

export interface IsReadElementProps {
  // isRead?: boolean,
  // operation?: Operation,
  operationId: number;
  operationType: string;
}

const IsReadElement = observer(
  ({ operationId, operationType }: IsReadElementProps) => {
    const { RootStore } = useStore();
    const {
      RegistryStore: { operationsDraftList, setOperationsDraftList },
    } = RootStore;

    const [operation, setOperation] = useState<Operation | undefined>(
      toJS(operationsDraftList).find(
        (item: Operation) =>
          item.document_id === operationId && item.type === operationType,
      ),
    );

    if (!operation) return <></>;
    const [clickedState, setClickedState] = useState<boolean>(
      operation.is_read,
    );

    const handleItemClick = () => {
      setClickedState((prev: boolean) => !prev);
      readDocument(operation.document_id, operation.type, !operation.is_read);
      const updatedList = toJS(operationsDraftList).map((item: Operation) => {
        if (
          item.document_id === operation.document_id &&
          item.type === operation.type
        ) {
          console.log(item);

          return {
            ...item,
            is_read: !item.is_read,
          };
        }
        return item;
      });
      setOperationsDraftList(updatedList);
    };

    return (
      <>
        {!clickedState ? (
          <div
            className="registry-table__dot"
            data-title="Пометить прочитанным"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                e.stopPropagation();
                handleItemClick();
              }}
            >
              <g id="Indicator">
                <rect
                  id="Rectangle"
                  x="7"
                  y="7"
                  width="6"
                  height="6"
                  rx="3"
                  fill="#212129"
                />
              </g>
            </svg>
          </div>
        ) : (
          <div
            className="registry-table__dot"
            data-title="Пометить непрочитанным"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              data-title={"Пометить прочитанным"}
              onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                e.stopPropagation();
                handleItemClick();
              }}
            >
              <rect x="7" y="7" width="6" height="6" rx="3" fill="#B4BAC6" />
            </svg>
          </div>
        )}
      </>
    );
  },
);

export default IsReadElement;

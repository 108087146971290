import { useEffect } from "react";
import "./styles.scss";
import { ORPTableRow } from "./components/row";
import { ReportsSkeleton } from "ui";
import { PeriodPicker } from "widgets/datepickers/period-picker";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import NoData from "../../../ui/no-data/noData";
import { Value } from "react-calendar/src/shared/types";
import { toJS } from 'mobx';

export const ORP = observer(() => {
  const { RootStore } = useStore();
  const {
    ORPStore: {
      dataORP,
      isLoadingORP,
      periodStartORP,
      periodEndORP,
      handlePeriodChangeORP,
      getDataORP,
    },
  } = RootStore;

  useEffect(() => {
     getDataORP();
  }, []);


  return (
    <>
      <div className="orp__datepicker-wrapper" data-testid={"orp"}>
        <PeriodPicker
          periodEnd={periodEndORP}
          periodStart={periodStartORP}
          periodSetter={(period: Value) => handlePeriodChangeORP(period)}
          isFilter={false}
        />
      </div>

      {isLoadingORP ? (
        <ReportsSkeleton />
      ) : (
        <div className="orp__wrapper">
          {dataORP !== null && dataORP.days.length > 0 ? (
            <div className="orp__table">
              <div className="orp__table__header">
                <div className=" orp__table__row__name ">
                  <div className={`orp__table__row__arrow table-item `}></div>
                  <p>Дата</p>
                </div>
                <div
                  className="  orp__table__row__sum orp__table__header__item-bordered"
                  style={{ border: 0 }}
                >
                  Итого, ₽
                </div>
                <div className="  orp__table__row__ammount orp__table__header__item-bordered">
                  Кол-во
                </div>
                <div className="  orp__table__row__vv orp__table__header__item-bordered">
                  Валовая выручка, ₽
                </div>
                <div className="  orp__table__row__sebes orp__table__header__item-bordered">
                  Себестоимость, ₽
                </div>
                <div className="  orp__table__row__vp orp__table__header__item-bordered">
                  Валовая прибыль, ₽
                </div>
              </div>
              {dataORP &&
                toJS(dataORP).days.map((item: any, index: any) => {
                  return <ORPTableRow key={index} {...item} visible />;
                })}
              <ORPTableRow
                isGrey
                isBold
                name={toJS(dataORP).sum.name}
                sum={toJS(dataORP).sum.sum}
                level={0}
                visible
                ammount={toJS(dataORP).sum.ammount}
                vv={toJS(dataORP).sum.vv}
                sebes={toJS(dataORP).sum.sebes}
                vp={toJS(dataORP).sum.vp}
              />
            </div>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </>
  );
});

import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "../../store/rootStore";
import { showConfirmModal } from "ui-new/alert/confirm";
import { showNotificationModal } from "ui-new/alert";
import {
  FieldTypes,
  IBonusAward,
  IBonusTableItem,
  IContract,
  IContractAward,
  IContractStatus,
  IContractTableItem,
  IGeneralAward,
  IGeneralTableItem,
  IJobTitle,
  IManagerAward,
  IManagerTableItem,
  IShiftAward,
  IShiftTableItem,
  IStatus,
  ITableItems,
  IUser,
} from "./types";
import {
  approveBonus,
  approveContract,
  approveContractAward,
  approveManager,
  approveShifts,
  cancelContract,
  createContract,
  getAwards,
  getBonuses,
  getContracts,
  getManager,
  getShifts,
  ICreateContractProps,
  IGetAwardsResponse,
  IGetBonusesResponse,
  IGetContractsResponse,
  IGetManagerResponse,
  IGetShiftsResponse,
  IUpdateBonusParams,
  IUpdateContractProps,
  IUpdateManagerParams,
  payBonus,
  payContractAward,
  payManager,
  payShifts,
  planBonus,
  planContractAward,
  planManager,
  planShifts,
  setLastPeriodContracts,
  updateBonus,
  updateContract,
  updateManager,
} from "api";
import dayjs from "dayjs";

export class SalaryFundStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  selectedDate = new Date();

  setSelectedDate = (date: Date) => {
    this.selectedDate = date;
  };

  netOrGross: "net" | "gross" = "gross";
  setNetOrGross = (type: typeof this.netOrGross) => {
    this.netOrGross = type;
  };

  //#region Общая информация
  users: IUser[] = [];
  helpers: IUser[] = [];
  jobTitles: IJobTitle[] = [];
  ndfl: number = 1;

  infoMessage: string | null = null;
  //#endregion

  fetchGetInfo = async () => {
    this.fetchGetBonuses();
    this.fetchGetContracts();
    this.fetchGetShifts();
    this.fetchGetAwards();
    this.fetchGetManagers();
  };

  //#region Общая информация вкладка
  generalAwards: IGeneralAward[] = [];
  generalTableItems: ITableItems<IGeneralTableItem> = {
    barista: [],
    helpers: [],
    joe: [],
  };

  private setGeneralInfo = (response: IGetAwardsResponse) => {
    this.generalAwards = response.awards;
    this.users = response.users;
    //TODO: Временный костыль
    this.helpers = response.helpers.map(h => ({ ...h, employee_type: 2 }));
    this.jobTitles = response.job_titles;
    this.infoMessage = response.message ?? null;
  };

  private generalAwardsToGeneralItems = (user: IUser) => {
    const userAwards = this.generalAwards.filter(s => s.user_id === user.id);
    if (userAwards.length === 0) return null;
    const jobTitle =
      this.jobTitles.find(j => j.id === user.job_title_id)?.title_ru ??
      "Не указана";
    const awardItems = userAwards.map(award => {
      const awardItem: IGeneralTableItem = {
        ...award,
        user: `${user.surname ?? ""} ${user.name ?? ""}`,
        job: jobTitle,
      };
      return awardItem;
    });

    return awardItems;
  };

  setTableGeneralItems = () => {
    const joeJobs = this.jobTitles
      .filter(job => ["Pizzayolo", "Pizzayolo-intern"].includes(job.title_en))
      .map(j => j.id);
    const baristaGeneralItems: IGeneralTableItem[] = [];
    const joeGeneralItems: IGeneralTableItem[] = [];
    this.users.forEach(user => {
      const generalItems = this.generalAwardsToGeneralItems(user);
      if (!generalItems) return;
      if (joeJobs.includes(user.job_title_id)) {
        joeGeneralItems.push(...generalItems);
      } else {
        baristaGeneralItems.push(...generalItems);
      }
    });

    const helperShiftItems = this.helpers.reduce((acc, helper) => {
      const generalItems = this.generalAwardsToGeneralItems(helper);
      if (generalItems) acc.push(...generalItems);

      return acc;
    }, [] as IGeneralTableItem[]);

    this.generalTableItems.barista = baristaGeneralItems;
    this.generalTableItems.helpers = helperShiftItems;
    this.generalTableItems.joe = joeGeneralItems;
  };

  fetchGetAwards = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await getAwards(month);
    if (!response) return;

    this.setGeneralInfo(response);
  };
  //#endregion

  //Модалки
  selectedEmployee: IUser | null = null;
  bonusModalIsOpen = false;
  managerModalIsOpen = false;
  managerPaidType: "pay" | "prepaid" | "sum" | null = null;
  selectedField: FieldTypes | null = null;
  setSelectedEmployee = (userId?: IUser["id"]) => {
    let selectedEmployee = this.users.find(u => u.id === userId);
    if (!selectedEmployee)
      selectedEmployee = this.helpers.find(u => u.id === userId);
    this.selectedEmployee = selectedEmployee ?? null;
  };

  //#region BonusTable
  bonusStatuses: IStatus[] = [];
  bonusList: IBonusAward[] = [];
  currentBonusStatus: IStatus | null = null;

  selectedBonus: IBonusTableItem | null = null;

  setSelectedBonus = (bonus?: IBonusTableItem) => {
    this.selectedBonus = bonus ?? null;
  };
  setBonusModalIsOpen = (
    isOpen: boolean,
    fieldType: typeof this.selectedField
  ) => {
    this.bonusModalIsOpen = isOpen;
    this.selectedField = fieldType;
  };

  private setBonusInfo = (response: IGetBonusesResponse) => {
    this.bonusList = response.bonusAwards;
    this.users = response.users;
    //TODO: Временный костыль
    this.helpers = response.helpers.map(h => ({ ...h, employee_type: 2 }));
    this.jobTitles = response.job_titles;
    this.bonusStatuses = response.statuses;
    this.currentBonusStatus = response.statuses.find(s => s.is_active) ?? null;
    this.ndfl = response.ndfl;
    this.infoMessage = response.message ?? null;
  };

  fetchGetBonuses = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await getBonuses(month);
    if (!response) return;

    this.setBonusInfo(response);
  };

  fetchUpdateBonuses = async (body: IUpdateBonusParams) => {
    const response = await updateBonus(body);

    if (!response) return false;

    this.setBonusInfo(response);
    showNotificationModal({ title: "Данные обновлены", type: "success" });
    return true;
  };

  approveBonusConfirm = () => {
    showConfirmModal({
      title: "Утвердить премии?",
      confirmButtonLabel: "Да",
      onConfirm: this.fetchApproveBonus,
      cancelButtonLabel: "Отмена",
    });
  };
  fetchApproveBonus = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await approveBonus(month);

    if (!response) return;

    this.setBonusInfo(response);
    showNotificationModal({ title: "Премии утверждены", type: "success" });
  };

  planBonusConfirm = () => {
    showConfirmModal({
      title: "Запланировать премии?",
      confirmButtonLabel: "Да",
      onConfirm: this.fetchPlanBonus,
      cancelButtonLabel: "Отмена",
    });
  };

  fetchPlanBonus = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await planBonus(month);

    if (!response) return;

    this.setBonusInfo(response);
    showNotificationModal({ title: "Запланировано успешно", type: "success" });
  };

  payBonusConfirm = () => {
    showConfirmModal({
      title: "Выплатить премии?",
      confirmButtonLabel: "Да",
      onConfirm: this.fetchPayBonus,
      cancelButtonLabel: "Отмена",
    });
  };

  fetchPayBonus = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await payBonus(month);

    if (!response) return;

    this.setBonusInfo(response);
    showNotificationModal({ title: "Выплачено успешно", type: "success" });
  };
  //#endregion
  //Табличные массивы
  tableBonusItems: ITableItems<IBonusTableItem> = {
    barista: [],
    helpers: [],
    joe: [],
  };

  private bonusToBonusItem = (user: IUser) => {
    const userBonuses = this.bonusList.filter(b => b.user_id === user.id);
    if (userBonuses.length === 0) return null;
    const jobTitle =
      this.jobTitles.find(j => j.id === user.job_title_id)?.title_ru ??
      "Не указана";
    const bonusItems = userBonuses.map(bonus => {
      const bonusItem: IBonusTableItem = {
        ...bonus,
        user: `${user.surname ?? ""} ${user.name ?? ""}`,
        job: jobTitle,
      };
      return bonusItem;
    });

    return bonusItems;
  };

  setTableBonusItems = () => {
    const joeJobs = this.jobTitles
      .filter(job => ["Pizzayolo", "Pizzayolo-intern"].includes(job.title_en))
      .map(j => j.id);
    const baristaBonusItems: IBonusTableItem[] = [];
    const joeBonusItems: IBonusTableItem[] = [];
    this.users.forEach(user => {
      const bonusItems = this.bonusToBonusItem(user);
      if (!bonusItems) return;
      if (joeJobs.includes(user.job_title_id)) {
        joeBonusItems.push(...bonusItems);
      } else {
        baristaBonusItems.push(...bonusItems);
      }
    });
    const helperBonusItems = this.helpers.reduce((acc, helper) => {
      const bonusItems = this.bonusToBonusItem(helper);
      if (bonusItems) acc.push(...bonusItems);

      return acc;
    }, [] as IBonusTableItem[]);
    this.tableBonusItems.barista = baristaBonusItems;
    this.tableBonusItems.helpers = helperBonusItems;
    this.tableBonusItems.joe = joeBonusItems;
  };

  tableContractsItems: IContractTableItem[] = [];

  //#region Контракты
  contractStatuses: IContractStatus[] = [];
  contractAwardStatuses: IStatus[] = [];
  currentContractStatus: IStatus | null = null;
  editableContract: IContractTableItem | null = null;
  contracts: IContract[] = [];
  contractAwards: IContractAward[] = [];

  setEditableContract = (contract: IContractTableItem | null) => {
    this.editableContract = contract;
  };

  private setContractsInfo = (response: IGetContractsResponse) => {
    this.users = response.users;
    this.jobTitles = response.job_titles;
    this.ndfl = response.ndfl;
    this.infoMessage = response.message ?? null;

    this.contractAwards = response.contractAwards;
    this.contracts = response.contracts;
    this.contractAwardStatuses = response.statuses;
    this.contractStatuses = response.contract_statuses;
    this.currentContractStatus =
      response.statuses.find(s => s.is_active) ?? null;
  };

  setTableContractItems = () => {
    const contractTableItems: (IContractTableItem | null)[] = toJS(
      this.contractAwards
    ).map(cA => {
      const contract = this.contracts.find(
        contract => contract.id === cA.contract_id
      );
      if (!contract) return null;
      const contractTitle = contract.title;
      const contractStatus = contract.status;
      const user = this.users.find(user => user.id === cA.user_id);
      const fio = !user
        ? "Не задан"
        : `${user.surname ?? ""} ${user.name ?? ""}`;

      return {
        ...cA,
        user: fio,
        contract: contractTitle,
        contract_status: contractStatus,
      };
    });

    this.tableContractsItems = contractTableItems.filter(
      c => c !== null
    ) as IContractTableItem[];
  };

  fetchGetContracts = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await getContracts(month);

    if (!response) return;

    this.setContractsInfo(response);
  };

  fetchCreateContract = async (newContract: ICreateContractProps) => {
    const response = await createContract(newContract);

    if (!response) return;

    this.setContractsInfo(response);
    showNotificationModal({ title: "Контракт добавлен", type: "success" });
  };

  approveContractConfirm = async (
    contractInfo: IUpdateContractProps,
    onConfirm?: () => void
  ) => {
    showConfirmModal({
      title: "Подтвердить выполнение контракта?",
      minWidth: "375px",
      confirmButtonLabel: "Да, подтвердить",
      onConfirm: () => {
        this.fetchApproveContract(contractInfo).then(onConfirm);
      },
    });
  };

  fetchApproveContract = async (contractInfo: IUpdateContractProps) => {
    const responseUpdate = await updateContract(contractInfo);
    if (!responseUpdate) return;

    const response = await approveContract(contractInfo.id);
    if (!response) return;

    this.setContractsInfo(response);
    showNotificationModal({ title: "Контракт выполнен!", type: "success" });
  };

  editContractConfirm = async (
    contractInfo: IUpdateContractProps,
    onConfirm?: () => void
  ) => {
    showConfirmModal({
      title: "Сохранить изменения?",
      minWidth: "375px",
      confirmButtonLabel: "Да, сохранить",
      onConfirm: () => this.fetchEditContract(contractInfo).then(onConfirm),
    });
  };

  fetchEditContract = async (contractInfo: IUpdateContractProps) => {
    const response = await updateContract(contractInfo);
    if (!response) return;

    this.setContractsInfo(response);
    showNotificationModal({ title: "Изменения сохранены", type: "success" });
  };

  cancelContractConfirm = (onClose: () => void) => {
    // const editableContract = this.editableContract;

    if (typeof this.editableContract?.contract_id !== "number") return;

    let message =
      "Незапланированный контракт будет полностью удален из списка контрактов.";

    if (this.editableContract.contract_status > 1) {
      message =
        "Запланированные контракты невозможно полностью удалить. Фактическое вознаграждение контракта будет обнулено.";
    }

    showConfirmModal({
      title: "Удалить контракт?",
      minWidth: "375px",
      messages: [message],
      confirmButtonLabel: "Да, удалить",
      onConfirm: () => {
        this.fetchCancelContract(this.editableContract!.contract_id).then(
          onClose
        );
      },
    });
  };

  fetchCancelContract = async (contractId: IContract["id"]) => {
    const response = await cancelContract(contractId);
    if (!response) return;

    this.setContractsInfo(response);
    showNotificationModal({ title: "Контракт удален", type: "success" });
  };

  setLastPeriodContractsConfirm = () => {
    showConfirmModal({
      title: "Перенести контракты?",
      messages: [
        "В этот месяц будут перенесены все контракты из прошлого периода",
      ],
      minWidth: "400px",
      confirmButtonLabel: "Да, перенести",
      onConfirm: this.fetchSetLastPeriodContracts,
    });
  };

  fetchSetLastPeriodContracts = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await setLastPeriodContracts(month);
    if (!response) return;
    console.log(response);
    showNotificationModal({ title: "Контракты перенесены", type: "success" });
  };

  planShiftsConfirm = () => {
    if (!this.currentShiftsStatus) return;

    const props = {
      title: "Запланировать вознаграждения за смены?",
      confirmButtonLabel: "Да",
      onConfirm: this.fetchPlanShifts,
      cancelButtonLabel: "Отменить",
      messages: [] as string[],
      minWidth: "500px",
    };

    if (this.currentShiftsStatus.value === 1) {
      props.title = "Запланировать Аванс?";
      props.messages = [
        "Планирование первой половины месяца станет недоступно",
      ];
      props.confirmButtonLabel = "Запланировать";
    }

    if (this.currentShiftsStatus.value === 5) {
      props.title = "Запланировать Зарплату?";
      props.messages = [
        "Дальнейшие изменения в графике смен не будут отображаться в Плане.",
      ];
      props.confirmButtonLabel = "Запланировать";
    }

    showConfirmModal(props);
  };

  planContractAwardConfirm = () => {
    showConfirmModal({
      title: "Запланировать контракты?",
      messages: [
        "Ты не сможешь редактировать значения плана после подтверждения.",
      ],
      minWidth: "450px",
      confirmButtonLabel: "Да, запланировать",
      onConfirm: this.fetchPlanContractAward,
    });
  };

  fetchPlanContractAward = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await planContractAward(month);
    if (!response) return;

    this.setContractsInfo(response);
    showNotificationModal({
      title: "Контракты запланированы",
      type: "success",
    });
  };
  approveContractAwardConfirm = () => {
    showConfirmModal({
      title: "Утвердить контракты?",
      messages: [
        "После утверждения нельзя будет изменить фактическое вознаграждение",
      ],
      minWidth: "450px",
      confirmButtonLabel: "Утвердить",
      onConfirm: this.fetchApproveContractAward,
    });
  };

  fetchApproveContractAward = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await approveContractAward(month);
    if (!response) return;

    this.setContractsInfo(response);
    showNotificationModal({
      title: "Контракты утверждены",
      type: "success",
    });
  };

  payContractAwardConfirm = () => {
    showConfirmModal({
      title: "Выплатить контракты?",
      minWidth: "450px",
      confirmButtonLabel: "Выплатить",
      onConfirm: this.fetchPayContractAward,
    });
  };

  fetchPayContractAward = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await payContractAward(month);
    if (!response) return;

    this.setContractsInfo(response);
    showNotificationModal({
      title: "Контракты выплачены",
      type: "success",
    });
  };
  //#endregion
  //#region Смены
  shifts: IShiftAward[] = [];
  shiftStatuses: IStatus[] = [];
  currentShiftsStatus: IStatus | null = null;

  tableShiftItems: ITableItems<IShiftTableItem> = {
    barista: [],
    helpers: [],
    joe: [],
  };
  private setShiftsInfo = (response: IGetShiftsResponse) => {
    this.users = response.users;
    //TODO: Временный костыль
    this.helpers = response.helpers.map(h => ({ ...h, employee_type: 2 }));
    this.jobTitles = response.job_titles;
    this.ndfl = response.ndfl;
    this.infoMessage = response.message ?? null;

    this.shifts = response.shiftAwards;
    this.shiftStatuses = response.statuses;
    this.currentShiftsStatus = response.statuses.find(s => s.is_active) ?? null;
  };

  private shiftsToShiftsItems = (user: IUser) => {
    const userShifts = this.shifts.filter(s => s.user_id === user.id);
    if (userShifts.length === 0) return null;
    const jobTitle =
      this.jobTitles.find(j => j.id === user.job_title_id)?.title_ru ??
      "Не указана";
    const shiftItems = userShifts.map(shift => {
      const shiftItem: IShiftTableItem = {
        ...shift,
        user: `${user.surname ?? ""} ${user.name ?? ""}`,
        job: jobTitle,
      };
      return shiftItem;
    });

    return shiftItems;
  };

  setTableShiftItems = () => {
    const joeJobs = this.jobTitles
      .filter(job => ["Pizzayolo", "Pizzayolo-intern"].includes(job.title_en))
      .map(j => j.id);
    const baristaShiftItems: IShiftTableItem[] = [];
    const joeShiftItems: IShiftTableItem[] = [];
    this.users.forEach(user => {
      const shiftItems = this.shiftsToShiftsItems(user);
      if (!shiftItems) return;
      if (joeJobs.includes(user.job_title_id)) {
        joeShiftItems.push(...shiftItems);
      } else {
        baristaShiftItems.push(...shiftItems);
      }
    });
    const helperShiftItems = this.helpers.reduce((acc, helper) => {
      const shiftItems = this.shiftsToShiftsItems(helper);
      if (shiftItems) acc.push(...shiftItems);

      return acc;
    }, [] as IShiftTableItem[]);
    this.tableShiftItems.barista = baristaShiftItems;
    this.tableShiftItems.helpers = helperShiftItems;
    this.tableShiftItems.joe = joeShiftItems;
  };

  fetchGetShifts = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await getShifts(month);
    if (!response) return;

    this.setShiftsInfo(response);
  };

  fetchPlanShifts = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await planShifts(month);

    if (!response) return;

    this.setShiftsInfo(response);

    showNotificationModal({
      title: `Вознаграждение для ${
        this.shiftStatuses.find(s => s.is_active)?.value === 1
          ? "аванса"
          : "зарплаты"
      } запланировано`,
      type: "success",
      width: "340px",
    });
  };

  approveShiftsConfirm = () => {
    if (!this.currentShiftsStatus) return;

    const props = {
      title: "Утвердить вознаграждения за смены?",
      confirmButtonLabel: "Да",
      onConfirm: this.fetchApproveShifts,
      cancelButtonLabel: "Отмена",
      messages: [] as string[],
    };

    if (this.currentShiftsStatus.value === 8) {
      props.title = "Утвердить Аванс?";
      props.messages = [
        "Редактирование смен с 1 по 15 число станет недоступно.",
      ];
      props.confirmButtonLabel = "Утвердить";
    }

    if (this.currentShiftsStatus.value === 7) {
      props.title = "Утвердить Зарплату?";
      props.confirmButtonLabel = "Утвердить";
    }

    showConfirmModal(props);
  };
  fetchApproveShifts = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await approveShifts(month);

    if (!response) return;

    this.setShiftsInfo(response);
    showNotificationModal({
      title: `Вознаграждения для ${
        this.managerCurrentStatus?.value === 8 ? "аванса" : "зарплаты"
      } утверждены`,
      type: "success",
      width: "340px",
    });
  };

  payShiftsConfirm = async () => {
    if (!this.currentShiftsStatus) return;

    const props = {
      title: "Выплатить вознаграждения за смены?",
      confirmButtonLabel: "Да",
      onConfirm: this.fetchPayShifts,
      cancelButtonLabel: "Отмена",
      messages: [] as string[],
    };

    if (this.currentShiftsStatus.value === 6) {
      props.title = "Выплатить Аванс?";
      props.confirmButtonLabel = "Подтвердить";
    }

    if (this.currentShiftsStatus.value === 9) {
      props.title = "Выплатить Зарплату?";
      props.confirmButtonLabel = "Подтвердить";
    }

    showConfirmModal(props);
  };

  fetchPayShifts = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await payShifts(month);
    if (!response) return;

    this.setShiftsInfo(response);
    showNotificationModal({
      title: `Вознаграждения для ${
        this.managerCurrentStatus?.value === 6 ? "аванса" : "зарплаты"
      } выплачены`,
      type: "success",
      width: "340px",
    });
  };

  //#endregion
  //#region Управляющий
  managerAwards: IManagerAward[] = [];
  managerStatuses: IStatus[] = [];
  managerCurrentStatus: IStatus | null = null;

  managerTableItems: IManagerTableItem[] = [];
  currentManagerValue: number | null = null;
  managerAwardId: IManagerAward["id"] | null = null;

  setManagerModalIsOpen = (
    isOpen: boolean,
    fieldType: typeof this.selectedField,
    paidType: typeof this.managerPaidType,
    value: typeof this.currentManagerValue,
    managerAwardId: typeof this.managerAwardId
  ) => {
    this.managerModalIsOpen = isOpen;
    this.selectedField = fieldType;
    this.managerPaidType = paidType;
    this.currentManagerValue = value;
    this.managerAwardId = managerAwardId;
  };

  private managerToManagerItem = (user: IUser) => {
    const userManagers = this.managerAwards.filter(m => m.user_id === user.id);
    if (userManagers.length === 0) return null;
    const jobTitle =
      this.jobTitles.find(j => j.id === user.job_title_id)?.title_ru ??
      "Не указана";
    const managerItems = userManagers.map(bonus => {
      const managerItem: IManagerTableItem = {
        ...bonus,
        user: `${user.surname ?? ""} ${user.name ?? ""}`,
        job: jobTitle,
      };
      return managerItem;
    });

    return managerItems;
  };

  setTableManagerItems = () => {
    const managerItems = this.users.reduce((acc, user) => {
      const bonusItems = this.managerToManagerItem(user);
      if (bonusItems) acc.push(...bonusItems);
      return acc;
    }, [] as IManagerTableItem[]);
    this.managerTableItems = managerItems;
  };

  private setManagerInfo = (response: IGetManagerResponse) => {
    this.jobTitles = response.job_titles;
    this.managerAwards = response.managerAwards;
    this.managerStatuses = response.statuses;
    this.managerCurrentStatus =
      response.statuses.find(s => s.is_active) ?? null;
    this.infoMessage = response.message ?? null;
  };

  fetchGetManagers = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await getManager(month);
    if (!response) return;

    this.setManagerInfo(response);
  };

  fetchUpdateManagers = async (body: IUpdateManagerParams) => {
    if (!this.managerAwardId) {
      showNotificationModal({
        title: "Произошла ошибка с выбором управляющего",
        type: "failure",
      });
      return;
    }
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await updateManager({
      ...body,
      month,
      id: this.managerAwardId,
    });

    if (!response) return false;

    this.setManagerInfo(response);
    showNotificationModal({ title: "Данные обновлены", type: "success" });
    return true;
  };

  planManagersConfirm = () => {
    showConfirmModal({
      title:
        this.managerCurrentStatus?.value === 1
          ? "Запланировать аванс?"
          : "Запланировать зарплату?",
      messages: [
        "Вознаграждение будет рассчитано автоматически, на основании данных о фиксированном вознаграждении",
      ],
      minWidth: "450px",
      confirmButtonLabel: "Запланировать",
      onConfirm: this.fetchPlanManagers,
      cancelButtonLabel: "Отмена",
    });
  };

  fetchPlanManagers = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await planManager(month);
    if (!response) return;

    showNotificationModal({
      title: `Вознаграждение для ${
        this.managerCurrentStatus?.value === 1 ? "аванса" : "зарплаты"
      } запланировано`,
      type: "success",
      width: "340px",
    });
    this.setManagerInfo(response);
  };

  approveManagersConfirm = () => {
    showConfirmModal({
      title:
        this.managerCurrentStatus?.value === 8
          ? "Утвердить аванс?"
          : "Утвердить зарплату?",
      confirmButtonLabel: "Утвердить",
      messages: ["Убедитесь, что указали фактическое вознаграждение."],
      onConfirm: this.fetchApproveManagers,
      cancelButtonLabel: "Отмена",
    });
  };

  fetchApproveManagers = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await approveManager(month);
    if (!response) return;

    showNotificationModal({
      title: `Вознаграждения для ${
        this.managerCurrentStatus?.value === 8 ? "аванса" : "зарплаты"
      } утверждены`,
      type: "success",
      width: "340px",
    });
    this.fetchGetManagers();
  };

  payManagersConfirm = () => {
    showConfirmModal({
      title:
        this.managerCurrentStatus?.value === 6
          ? "Выплатить аванс?"
          : "Выплатить зарплату?",
      confirmButtonLabel: "Выплатить",
      onConfirm: this.fetchPayManagers,
      cancelButtonLabel: "Отмена",
    });
  };

  fetchPayManagers = async () => {
    const month = dayjs(this.selectedDate).format("YYYY-MM");
    const response = await payManager(month);
    if (!response) return;

    showNotificationModal({
      title: `Вознаграждения для ${
        this.managerCurrentStatus?.value === 6 ? "аванса" : "зарплаты"
      } выплачены`,
      type: "success",
      width: "340px",
    });
    this.fetchGetManagers();
  };
  //#endregion
}

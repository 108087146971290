export const getOpenPosition = [
    {
        "id": 164,
        "status": "partly_close",
        "loan_id": 45,
        "created_at": "2024-09-11T21:38:52",
        "nomenclature_id": "0ce260f5-07bd-4763-aa42-a7aa002a1668",
        "nomenclature_title": "Цветы мальвы кг",
        "unit_id": 1,
        "unit_title": "кг",
        "sum": 126000,
        "sum_fact": 25200,
        "cost": 8400,
        "cost_fact": 8400,
        "value": 15,
        "value_fact": 3,
        "user_id": 75
    },
    {
        "id": 165,
        "status": "open",
        "loan_id": 45,
        "created_at": "2024-09-11T21:38:52",
        "nomenclature_id": "030ba5ab-2ecc-44f2-b968-a6c2341c6ea7",
        "nomenclature_title": "Винил James Brown and His Famous Flames - Shout and Shimmy",
        "unit_id": 3,
        "unit_title": "шт",
        "sum": 216000,
        "sum_fact": 0,
        "cost": 12000,
        "cost_fact": 0,
        "value": 18,
        "value_fact": 0,
        "user_id": 75
    },
    {
        "id": 166,
        "status": "open",
        "loan_id": 45,
        "created_at": "2024-09-11T21:38:52",
        "nomenclature_id": "0d68c612-b0f9-43bf-a609-2b9bf0668a81",
        "nomenclature_title": "Бальзамический крем-соус кг",
        "unit_id": 1,
        "unit_title": "кг",
        "sum": 2064,
        "sum_fact": 0,
        "cost": 258,
        "cost_fact": 0,
        "value": 8,
        "value_fact": 0,
        "user_id": 75
    },
    {
        "id": 167,
        "status": "open",
        "loan_id": 45,
        "created_at": "2024-09-11T21:38:52",
        "nomenclature_id": "1459b0ec-b686-43f0-a317-08b0826713e3",
        "nomenclature_title": "Чай зеленый кг",
        "unit_id": 1,
        "unit_title": "кг",
        "sum": 2610,
        "sum_fact": 0,
        "cost": 870,
        "cost_fact": 0,
        "value": 3,
        "value_fact": 0,
        "user_id": 75
    }
]
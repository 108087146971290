import React, { useRef } from 'react';
import { UpdatePositionTypes } from '../../types';
import { toJS } from 'mobx';
import { useStore } from '../../../../store';

interface InvoiceValueInputProps{
  value: any,
  index: number
}

const InvoiceValueInput: React.FC<InvoiceValueInputProps> = ({value, index}) => {
  const timerRef = useRef<number | NodeJS.Timeout | null>(null);
  const typingInterval = 1000;
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: {
      advanceReport,
      setAdvanceReport,
      updatePosition,
    },
  } = RootStore;

  return (
    <input
      className={`registry-document__table-element input`}
      value={value.value !== null ? value.value : ""}
      onChange={(event) => {
        if (timerRef.current !== null) {
          clearTimeout(timerRef.current);
        }
        const { value: val } = event.target as HTMLInputElement;
        if (val === "" || /^\d+(\.\d{0,3})?$/.test(val)) {
          timerRef.current = setTimeout(() => updatePosition(value.id, UpdatePositionTypes.VALUE, +val), typingInterval)

          const updatedPositions = [...advanceReport.positions];
          updatedPositions[index].value = val;
          setAdvanceReport({
            ...toJS(advanceReport),
            positions: updatedPositions,
          });
        }
      }}
    />
  );
};

export default InvoiceValueInput;
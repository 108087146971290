import React, { useEffect, useState } from 'react';
import { Checkbox } from "antd";
import "./styles.scss";
import {
  IMatrixRow,
  IMatrixSubRow,
  IPermissions,
  PermissionTypeKey,
  PermissionTypeValue
} from "../../types";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

interface RulesMatrixProps {
  data: IPermissions;
  onChange: (e: any) => void;
  PermissionLabels: Record<PermissionTypeKey, PermissionTypeValue>;
}

export const RulesMatrix = (props: RulesMatrixProps) => {
  const {
    data,
    onChange,
    PermissionLabels,
  } = props;
  const [rows, setRows] = useState<IMatrixRow[]>([]);
  const [matrixLoading, setMatrixLoading] = useState<boolean>(true);

  const initMatrix = () => {
    setMatrixLoading(true);
    const newRows: IMatrixRow[] = [];

    for (const category in data) {
      const rowItem: IMatrixSubRow[] = [];

      for (const key in data[category]) {
        const permission = data[category][key];
        rowItem.push({ label: permission.description, value: permission.active, ...permission });
      }

      newRows.push({ label: category as PermissionTypeKey, rows: rowItem, active: false });
    }

    const result: IMatrixRow[] = newRows.map((item: IMatrixRow) => {
      return {
        ...item, subLabel: PermissionLabels[item.label]
      }
    });

    setRows(result);
    setMatrixLoading(false);
  };

  const onMainCheckboxChange = (rowLabel: string, checked: boolean) => {
    setRows(rows.map(row => {
      if (row.label === rowLabel) {
        return {
          ...row,
          active: checked,
          rows: row.rows.map(item => ({ ...item, value: checked }))
        };
      }
      return row;
    }));
  };

  useEffect(() => {
    initMatrix();
  }, [data]);

  useEffect(() => {
    onChange(rows);
  }, [rows]);

  return (
    <div className="rulesMatrix">
      {matrixLoading && !rows.length && (
        <SkeletonTheme duration={2} baseColor="#bcbcbc" highlightColor="#dddddd">
          <div style={{ display: 'block' }}>
            <div style={{ height: "50px" }} className="mainRow" key={0}>
              <Skeleton style={{ marginBottom: '0.5rem' }} width="150px" />
              <Skeleton />
            </div>
          </div>
        </SkeletonTheme>
      )}
      {!matrixLoading && rows.map((el: IMatrixRow) => (
        <div className="mainRow" key={el.label}>
          <label className="checkboxContainer mainCheckboxContainer">
            <Checkbox
              onChange={(e) => onMainCheckboxChange(el.label, e.target.checked)}
              checked={el.active}
            >
              <span className="mainCheckboxLabel">{el.subLabel}</span>
            </Checkbox>
          </label>
          <Checkbox.Group
            options={el.rows.map(subItem => ({ label: subItem.label, value: subItem.label }))}
            value={el.rows.filter(subItem => subItem.value).map(subItem => subItem.label)}
            onChange={(checkedValues) => {
              setRows(rows.map(row => {
                if (row.label === el.label) {
                  return {
                    ...row,
                    rows: row.rows.map(item => ({ ...item, value: checkedValues.includes(item.label) }))
                  };
                }
                return row;
              }));
            }}
          />
        </div>
      ))}
    </div>
  );
};

import { FC,forwardRef,ReactNode,useRef,useState } from 'react';
import cls from './worker-styles.module.scss';
import { IShift,IUnwanted } from 'pages/spot/schedule-planning-new/types';
import { WarningIcon } from 'pages/spot/schedule-planning-new/icons';

interface ShiftBusyProps {
  shift: IShift;

  unwanted: IUnwanted[];

  onCellClick: () => void;

  children: ReactNode;

  referenceProps: Record<string,unknown>;
}

export const ShiftBusy = forwardRef<any,ShiftBusyProps>(
  ({ shift: { color,duration_fact,comment },onCellClick,unwanted,referenceProps,children },forwardRef) => {

    return (<td ref={forwardRef} className={`${cls['shift']}`}
      style={{ backgroundColor: color ?? '',borderColor: color ?? '' }}
      onClick={onCellClick}
      {...referenceProps}
    >
      {children}
      {comment && <div className={cls['circle']}></div>}
      <span className={cls['duration']}>{Math.floor(duration_fact * 10) / 10}</span>
      {unwanted.length > 0 && <div className={cls['warning']}><WarningIcon /></div>}
    </td>);
  }
);

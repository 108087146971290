import {toJS} from "mobx";
import React, { useState } from "react";
import { useStore } from "store";
import { Checkbox, Dropdown, Menu, Space } from "antd";
import { FilterArrow, FilterCross } from "../../icons";

export const ContragentFilter = () => {
  const { RootStore } = useStore();
  const {
    RegistryStore: {
      pickedRegistryUserContragent,
      setPickedRegistryUserContragent,
      pickedRegistryContragent,
      setPickedRegistryContragent,
      pickedRegistryContragentTitle,
      setPickedRegistryContragentTitle,
      contragentsList,
      setContragentsList,
      setCurrentPage,
    },
  } = RootStore;

  const [contragentsMenuVisible, setContragentsMenuVisible] = useState(false);

  const handleContragentFilterClick = (event: any) => {
    // setContragentsMenuVisible(true);
    setCurrentPage(1);

    if (event.item.props.id) {
      if (pickedRegistryUserContragent.includes(event.item.props.id)) {
        setPickedRegistryUserContragent(
          pickedRegistryUserContragent.filter(
            (item) => item !== event.item.props.id,
          ),
        );
      } else {
        setPickedRegistryUserContragent([
          ...pickedRegistryUserContragent,
          event.item.props.id,
        ]);
      }
    } else {
      if (pickedRegistryContragent.includes(Number(event.key))) {
        setPickedRegistryContragent(
          pickedRegistryContragent.filter((item) => item !== Number(event.key)),
        );
      } else {
        setPickedRegistryContragent([
          ...pickedRegistryContragent,
          Number(event.key),
        ]);
      }
    }
    if (pickedRegistryContragentTitle.includes(event.item.props.title)) {
      setPickedRegistryContragentTitle(
        pickedRegistryContragentTitle.filter(
          (item) => item !== event.item.props.title,
        ),
      );
    } else {
      setPickedRegistryContragentTitle([
        ...pickedRegistryContragentTitle,
        event.item.props.title,
      ]);
    }
  };

  const handleMenuVisibleChange = (visible: boolean) => {
    setContragentsMenuVisible(visible);
  };

  const contragentHandler = (item: any) => {
    setContragentsList([...toJS(contragentsList).map(el => {
      const isItem = el.id === item.id;
      const checked = isItem ? !el.checked : el.checked;

      return { ...el, checked }
    })]);
  }

  const contragentFilterMenu = (
    <Menu onClick={handleContragentFilterClick} className="contragents">
      {contragentsList &&
        contragentsList.map((item: any) => (
          <Menu.Item
            key={item.contragent_id}
            id={item.user_contragent_id ? item.user_contragent_id : null}
            title={item.title ? item.title : `${item.surname} ${item.name}`}
            onClick={() => contragentHandler(item)}
          >
            <div>
              <Checkbox
                checked={item.checked}
              ></Checkbox>
              {item.title ? item.title : `${item.surname} ${item.name}`}
            </div>
            <span className="inn">{item.inn && `ИНН ${item.inn}`}</span>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Dropdown overlay={contragentFilterMenu} trigger={["click"]}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <div>
            {pickedRegistryContragentTitle.length > 0 ? (
              <>
                <span>{pickedRegistryContragentTitle.join(", ")}</span>
                <FilterCross
                  onClick={(
                    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
                  ) => {
                    event.stopPropagation();
                    setPickedRegistryContragentTitle([]);
                    setPickedRegistryUserContragent([]);
                    setPickedRegistryContragent([]);
                    setContragentsList(toJS(contragentsList).map(item => ({ ...item, checked: false })));
                  }}
                />
              </>
            ) : (
              <>
                Контрагент
                <FilterArrow />
              </>
            )}
          </div>
        </Space>
      </a>
    </Dropdown>
  );
};

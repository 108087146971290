import { observer } from 'mobx-react-lite';
import { FC,useEffect,useMemo,useRef } from 'react';
import { useStore } from 'store';
import cls from '../styles.module.scss';
import { TimeIcon } from '../../icons';
import { toJS } from 'mobx';

const round = (duration: number) => {
  return Math.floor(duration * 10) / 10;
};

const stickyObserver = new IntersectionObserver(
  ([e]) => {
    return e.target.classList.toggle(cls['time-sticky'],e.intersectionRatio < 1);
  },
  { threshold: [1] }
);

interface TimelineProps {

}

const TimelineComponent: FC<TimelineProps> = () => {
  const { RootStore: { SchedulePlanningStore: { results } } } = useStore();
  const stickyElm = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!stickyElm || !stickyElm.current) return;
    stickyObserver.observe(stickyElm.current);
  },[stickyElm]);

  const resultsData = useMemo(() => {

    if (!results) return null;
    const resultDates = results.dates.reduce((acc,val) => {
      Object.keys(val).forEach((key) => {
        acc[key] = val[key];
      });
      return acc;
    },{});
    const dates = Object.keys(resultDates).map(k => <div key={k} className={cls['timeline__time']}>
      {round(resultDates[k])}
    </div>
    );

    dates.splice(15,0,<div className={cls['timeline__time']}>{round(results.total.first_part)}</div>);
    dates.push(<div className={cls['timeline__time']}>{round(results.total.last_part)}</div>,
      <div className={cls['timeline__time']}>{round(results.total.all_parts)}</div>);

    return dates;
  },[toJS(results)]);

  return (
    <div className={cls['timeline']}>
      <div ref={stickyElm} className={cls['timeline__title']}>
        <TimeIcon />
        <p>Часы</p>
      </div>
      {resultsData}
    </div>
  );
};

export const Timeline = observer(TimelineComponent);
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./styles.scss";

export const AuthSkeleton = () => {
  return (
    <SkeletonTheme duration={2} baseColor="#bcbcbc" highlightColor="#dddddd">
      <div className="authSkeleton__container">
        <div className="authSkeleton__logo-container">
          <Skeleton width={225} height={100} />
        </div>
        <div className="authSkeleton__content-container">
          <Skeleton
            className="authSkeleton__content-container__header"
            width={250}
            height={24}
          />
          <Skeleton
            className="authSkeleton__content-container__discription"
            height={50}
          />
          <Skeleton
            className="authSkeleton__content-inputs"
            height={150}
            borderRadius={16}
          />
          <Skeleton height={50} borderRadius={16} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

import React from "react";
import { useStore } from "../../../store";
import TableElement from "./tableElement";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { InputSortType, Operation } from "../types";
import { EmptyTableBody } from "../../../ui/empty-table-body";
import { TableFooter } from "../../../widgets/table-footer";
import { SortingArrow } from "../icons";

const Table = observer(() => {
  const {
    RootStore: {
      OperationInputStore: {
        toggleSort,
        manualOperationList,
        setFirstItem,
        setLastItem,
        firstItem,
        lastItem,
        currentPage,
        setCurrentPage,
        maxOperationsNumber,
        setMaxOperationsNumber,
        pickedSortType,
        pickedSortOrder,
      },
    },
  } = useStore();

  return (
    <div className="operation-input__table__inner">
      <div className="operation-input__table__inner-header">
        <div className="operation-input__table__inner-header__element"></div>
        <div
          className="operation-input__table__inner-header__element sort"
          onClick={() => toggleSort(InputSortType.date)}
        >
          Дата
          <SortingArrow
            sortType={pickedSortType === InputSortType.date && pickedSortOrder}
          />
        </div>
        <div className="operation-input__table__inner-header__element">
          Время
        </div>
        <div
          className="operation-input__table__inner-header__element sort"
          onClick={() => toggleSort(InputSortType.sum)}
        >
          Сумма, ₽
          <SortingArrow
            sortType={pickedSortType === InputSortType.sum && pickedSortOrder}
          />
        </div>
        <div
          className="operation-input__table__inner-header__element sort"
          onClick={() => toggleSort(InputSortType.responsible)}
        >
          Ответственный
          <SortingArrow
            sortType={
              pickedSortType === InputSortType.responsible && pickedSortOrder
            }
          />
        </div>
        <div
          className="operation-input__table__inner-header__element sort"
          onClick={() => toggleSort(InputSortType.comment)}
        >
          Комментарий
          <SortingArrow
            sortType={
              pickedSortType === InputSortType.comment && pickedSortOrder
            }
          />
        </div>
      </div>
      <div className="operation-input__table__inner-body">
        {manualOperationList &&
          (toJS(manualOperationList).length > 0 ? (
            toJS(manualOperationList).map((operation: Operation) => {
              return <TableElement operation={operation} />;
            })
          ) : (
            <EmptyTableBody />
          ))}
      </div>

      <div className="operation-input__table__inner-footer">
        {manualOperationList && manualOperationList.length > 0 && (
          <TableFooter
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maxOperationsNumber={maxOperationsNumber}
            setMaxOperationsNumber={setMaxOperationsNumber}
            firstItem={firstItem}
            lastItem={lastItem}
            setFirstItem={setFirstItem}
            setLastItem={setLastItem}
            itemsList={manualOperationList}
          />
        )}
      </div>
    </div>
  );
});

export default Table;

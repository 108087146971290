import React from "react";
import { PopupModal } from "../../../widgets";
import { toJS } from "mobx";
import { Shift } from "../types";
import { EInputStyleVariant, EInputVariant, Input } from "../../../ui";
import { EFontVariant } from "../../../assets/styles/enums";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
import TimeField from "react-simple-timefield";
import { AddShiftIcon, DeleteIcon } from "../icons";

const TimesheetModal = observer(() => {
  const { RootStore } = useStore();
  const {
    TimeSheetStore: {
      pickedCellDateTitle,
      pickedCellUTCShifts,
      pickedCellShifts,
      pickedCellId,
      setPickedCellShifts,
      setPickedCellUTCShifts,
      shiftsToDelete,
      setShiftsToDelete,
      updateTimesheet,
      deleteShift,
      modalSaveButtonIsDisabled,
      setModalSaveButtonIsDisabled,
    },
  } = RootStore;

  const handleTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
    index: number,
  ) => {
    const currentDate = new Date(`2000-01-01T${value}`);
    const updatedShiftsList = [...toJS(pickedCellShifts)];
    const updatedUTCShiftsList = [...toJS(pickedCellUTCShifts)];

    const utcTimeString = currentDate.toLocaleString("en-US", {
      timeZone: "UTC",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });

    updatedShiftsList[index][event.target.name] = value;
    updatedUTCShiftsList[index][event.target.name] = utcTimeString;

    if (event.target.name === "fact_end") {
      const startDate = new Date(
        `2000-01-01T${toJS(pickedCellShifts)[index].fact_st}`,
      );
      setModalSaveButtonIsDisabled(startDate >= currentDate);
    }

    if (event.target.name === "fact_st") {
      const endDate = new Date(
        `2000-01-01T${toJS(pickedCellShifts)[index].fact_end}`,
      );
      setModalSaveButtonIsDisabled(endDate <= currentDate);
    }
    setPickedCellShifts(updatedShiftsList);
    setPickedCellUTCShifts(updatedUTCShiftsList);
  };

  return (
    <PopupModal
      title={"Редактировать время"}
      subtitle={"Изменение времени смены"}
      onSave={() => {
        if (shiftsToDelete.length > 0) {
          toJS(shiftsToDelete).map((id: number) => deleteShift(id));
          setShiftsToDelete([]);
        } else {
          updateTimesheet();
        }
        RootStore.setModalIsActive(false);
      }}
      maxWidth={"440px"}
      isButtonDisabled={modalSaveButtonIsDisabled}
    >
      <div className="timesheet__popup">
        <div className="header">
          {pickedCellDateTitle &&
            pickedCellDateTitle.split(" ")[0] +
              " " +
              pickedCellDateTitle.split(" ")[1].charAt(0).toUpperCase() +
              pickedCellDateTitle.split(" ")[1].slice(1)}
          <button
            onClick={() => {
              setPickedCellUTCShifts([
                ...toJS(pickedCellUTCShifts),
                {
                  id: null,
                  user_id: pickedCellId,
                  fact_st: "21:00",
                  fact_end: "21:01",
                },
              ]);
              setPickedCellShifts([
                ...toJS(pickedCellShifts),
                {
                  id: null,
                  user_id: pickedCellId,
                  fact_st: "00:00",
                  fact_end: "00:01",
                },
              ]);
            }}
          >
            <AddShiftIcon />
            Добавить смену
          </button>
        </div>
        <div className="body">
          {pickedCellShifts &&
            pickedCellUTCShifts &&
            toJS(pickedCellShifts).length > 0 &&
            toJS(pickedCellShifts).map((value: Shift, index: number) => {
              console.log("@", value);
              return (
                <div className={"shift-row"} key={index} id={index.toString()}>
                  <p>Время работы</p>
                  <div className="inputs">
                    <TimeField
                      value={toJS(pickedCellShifts)[index].fact_st}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        value: any,
                      ) => handleTimeChange(event, value, index)}
                      input={
                        <Input
                          type={EInputVariant.text}
                          variant={EInputStyleVariant.basicInput}
                          font={EFontVariant.body1Regular}
                          maxWidth={"196px"}
                          height={"44px"}
                          borderRadius={"16px"}
                          name="fact_st"
                          key={`${value.id}-0`}
                        />
                      }
                    />

                    <TimeField
                      value={toJS(pickedCellShifts)[index].fact_end}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        value: any,
                      ) => handleTimeChange(event, value, index)}
                      input={
                        <Input
                          type={EInputVariant.text}
                          variant={EInputStyleVariant.basicInput}
                          font={EFontVariant.body1Regular}
                          maxWidth={"196px"}
                          height={"44px"}
                          borderRadius={"16px"}
                          name="fact_end"
                          key={`${value.id}-0`}
                        />
                      }
                    />
                    <button
                      className="delete"
                      onClick={() => {
                        if (value.id !== null) {
                          setShiftsToDelete([
                            ...toJS(shiftsToDelete),
                            value.id as number,
                          ]);
                        }
                        setPickedCellShifts(
                          toJS(pickedCellShifts).filter(
                            (shift: Shift) => shift.id !== value.id,
                          ),
                        );
                        setPickedCellUTCShifts(
                          toJS(pickedCellUTCShifts).filter(
                            (shift: Shift) => shift.id !== value.id,
                          ),
                        );
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </PopupModal>
  );
});

export default TimesheetModal;

import {
  IMoneyTransactionCashFlowArticle,
  IMoneyTransactionOperationType,
} from "types/money-transactions";
import { IBaseResponse } from "./api";
import { api } from "./api-new";
import { IContragent } from "types/directories";

export async function getOperationTypes() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionOperationType[]>>(
      "directories/money_transactions/operation_types"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getCashFlowArticles() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionCashFlowArticle[]>>(
      "directories/money_transactions/cash_flow_articles"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export interface IGetContragentsFilter {
  //По title и inn
  search?: string | null;
  //Дефолт 1
  page?: number | null;
  //Дефолт 100
  per_page?: number | null;
}

export interface IContragentMetaProps {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export async function getTransactionContragentsPaginated(params?: IGetContragentsFilter) {
  try {
    const response = await api.get<
      IBaseResponse<{ data: IContragent[]; meta: IContragentMetaProps }>
    >("directories/contragents/paginated", { params });
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getTransactionContragents() {
  try {
    const response = await api.get<IBaseResponse<IContragent[]>>("directories/contragents");
    return response.data.data;
  } catch (error) {
    return null;
  }
}

import React, { useEffect, useState } from "react";
import { toJS } from "mobx";
import { useStore } from "../../../store";
import { convertToLocaleTimeString } from "../../../assets/utils";
import { Dates, Shift, Table } from "../types";
import { observer } from "mobx-react-lite";
import { TableDividerIcon } from "../icons";

const TimesheetRightTable = observer(() => {
  const { RootStore } = useStore();
  const {
    TimeSheetStore: {
      tableData,
      selectedDate,
      setPickedCellDate,
      setPickedCellShifts,
      setPickedCellUTCShifts,
      setPickedCellId,
      setPickedCellDateTitle,
    },
  } = RootStore;

  const [daysArray, setDaysArray] = useState<Date[] | null>(null); // массив со всеми днями месяца

  const getAllDaysInMonth = () =>
    Array.from(
      {
        length: new Date(
          (selectedDate as Date).getFullYear(),
          (selectedDate as Date).getMonth() + 1,
          0,
        ).getDate(),
      },
      (_, i) =>
        new Date(
          (selectedDate as Date).getFullYear(),
          (selectedDate as Date).getMonth(),
          i + 1,
        ),
    );
  const tableCellClick = (date: string, id: number, shifts: Shift[] | null) => {
    const dateParts = date.split(".");
    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[1]) - 1;
    const day = parseInt(dateParts[0]);
    const formattedDate = new Date(year, month, day).toLocaleDateString(
      "ru-RU",
      {
        day: "numeric",
        month: "long",
      },
    );
    setPickedCellDate(date);
    setPickedCellDateTitle(formattedDate);
    setPickedCellId(id);
    RootStore.setModalIsActive(true);
    if (shifts === null) {
      setPickedCellUTCShifts([]);
      setPickedCellShifts([]);
    } else {
      setPickedCellUTCShifts(shifts);
      setPickedCellShifts(
        shifts.map((shift) => ({
          ...shift,
          fact_st: convertToLocaleTimeString(shift.fact_st),
          fact_end: convertToLocaleTimeString(shift.fact_end),
        })),
      );
    }
  };

  useEffect(() => {
    setDaysArray(getAllDaysInMonth());
  }, [selectedDate]);

  useEffect(() => {
    setDaysArray(getAllDaysInMonth()); // заполнения массива днями текущего месяца
  }, []);

  return (
    <div className="timesheet__time-table-wrapper">
      <div className="timesheet__time-table timesheet__table">
        <div className="timesheet__table-header">
          {daysArray &&
            daysArray.map((date: Date) => (
              <div className="timesheet__table-header-element">
                {date.toLocaleString("ru-RU", {
                  weekday: "short",
                  day: "numeric",
                })}
                <TableDividerIcon />
              </div>
            ))}
        </div>
        <div className="timesheet__table-body">
          {tableData &&
            toJS(tableData).length > 0 &&
            toJS(tableData).map((object: Table) => (
              <>
                <div
                  className="timesheet__table-body-row"
                  id={object.id.toString()}
                  key={object.id}
                >
                  {daysArray &&
                    daysArray.map((date: Date, index: number) => {
                      const currentDateObject: Dates | undefined =
                        object.dates.find((dates: Dates) => {
                          const date1 = new Date(
                            dates.date.split(".").reverse().join("-"),
                          );
                          const date2 = new Date(date.setHours(3));

                          const areDatesEqual =
                            date1.getDate() === date2.getDate() &&
                            date1.getMonth() === date2.getMonth() &&
                            date1.getFullYear() === date2.getFullYear();

                          return areDatesEqual;
                        });

                      return (
                        <div
                          className={`timesheet__table-body-element ${
                            date.getDay() === 0 || date.getDay() === 6
                              ? "weekday"
                              : ""
                          }`}
                          id={`${object.id}-${index}`}
                          key={`${object.id}-${index}`}
                          onClick={() =>
                            tableCellClick(
                              currentDateObject
                                ? currentDateObject.date
                                : date.toLocaleDateString("ru-RU", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }),
                              object.id,
                              currentDateObject
                                ? currentDateObject.shifts
                                : null,
                            )
                          }
                          data-title={
                            currentDateObject?.time_plan
                              ? `План: 
                            ${currentDateObject?.time_plan}`
                              : null
                          }
                        >
                          {currentDateObject?.time_fact}
                        </div>
                      );
                    })}
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
});

export default TimesheetRightTable;

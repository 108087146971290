import {type ReactNode, useEffect, useState} from "react";
import styles from './styles.module.scss';
import {TableSkeleton} from "./skeleton/TableSkeleton";
import { Button } from 'ui-new/button';
import {ArrowIcon} from "../icons/ArrowIcon";
import classNames from "classnames";
import {BucketIcon} from "pages/registry-document/icons";
import {DataTableHeaderCell, type TableSortDirection} from "./components/data-table-header-cell";
import type {Nullable} from "../../assets/types";
import {sortBy} from "lodash";

export interface TableSortOptions<T> {
    sortValue: keyof T;
    direction: TableSortDirection;
}

export interface DataTableHeader<T> {
    title: string;
    value: keyof T;
    width?: string;
    checkbox?: boolean;
    sort?: boolean;
    tooltip?: string | string[];
    textAlign?: 'left' | 'center' | 'right';
    textWrap?: boolean;
}

export type TableItem<T> = T & {
    id: number | string;
    isExpand?: boolean;
}

export interface Props<T> {
    headers: DataTableHeader<T>[];
    items: TableItem<T>[];
    itemKey: keyof TableItem<T>;
    isLoading?: boolean;
    isRowClickable?: boolean;
    skeletonCellCount?: number;
    skeletonRowCount?: number;
    withIndex?: boolean;
    withExpand?: boolean;
    cellPadding?: string;
    children?: (item: T, header: DataTableHeader<T>) => ReactNode;
    expandContent?: (item: T) => ReactNode;
    onRowClick?: (id: number | string) => void;
    onDelete?: (id: number | string) => void;
    tableFooter?: () => ReactNode;
}

export function DataTable<T>(props: Props<T>) {
    const {
        headers,
        items,
        itemKey,
        isLoading = false,
        isRowClickable = false,
        children,
        expandContent,
        withIndex = false,
        withExpand = false,
        cellPadding,
        onRowClick,
        onDelete,
        tableFooter,
    } = props;

    const [itemsWithExpand, setItemsWithExpand] = useState<any>(items.map(item => ({ ...item, isExpand: false })));
    const [sortOptions, setSortOptions] = useState<Nullable<TableSortOptions<T>>>(null);

    const handleRowClick = (itemId: number | string) => {
        if (!isRowClickable || !onRowClick) return;

        onRowClick(itemId);
    }

    const expandItem = (e: React.MouseEvent, item: TableItem<T>) => {
        e.stopPropagation();

        setItemsWithExpand((prev: TableItem<T> & { id: number; isExpand: boolean }[]) => {
            return prev.map((element) => {
                if (element.id === item.id) {
                    return {
                        ...element,
                        isExpand: !element.isExpand,
                    }
                }
                return { ...element }
            })
        });
    }

    const onSort = (sortOptions: TableSortOptions<T>) => {
        setSortOptions(sortOptions);
    }

    const tableItems = (): (TableItem<T> & { isExpand: boolean; } | TableItem<T>)[] => {
        return withExpand ? itemsWithExpand : items;
    }

    const sortedTableItems = () => {
        const items = tableItems();
        if (!sortOptions) return items;
        const sorted = sortBy(items, [sortOptions?.sortValue]);
        return sortOptions?.direction === 'ASC' ? sorted : sorted.reverse();
    }

    useEffect(() => {
        if (items.length > 0) {
            setItemsWithExpand(items.map(item => ({ ...item, isExpand: false })));
        }
    }, [items]);

    if (isLoading) {
        return (
            <div className="scrollbar-mini table-wrapper">
                <TableSkeleton  cellCount={6} rowCount={5} />
            </div>
        );
    }

    return (
        <div className={styles.tableWrapper}>
            <table className={styles.table}>
                <thead>
                <tr className={styles.headerRow}>
                    {withIndex && <th className={styles.indexCell} style={({ padding: 0 })} />}
                    {withExpand && <th className={styles.indexCell} style={({ padding: 0 })} />}

                    {headers.map((header) => {
                        return (
                            <DataTableHeaderCell
                                style={({ textAlign: header.textAlign, width: header?.width })}
                                key={header.value as string}
                                className={styles.headerCell}
                                header={header}
                                sortOptions={sortOptions}
                                onSort={(sortOptions) => onSort(sortOptions)}
                            >
                                {header.title}
                            </DataTableHeaderCell>
                        )
                    })}
                    {!!onDelete && <th className={styles.deleteHeaderCell} style={({ padding: 0 })}></th>}
                </tr>
                </thead>

                <tbody>
                {sortedTableItems().map((item, index) => (
                    <>
                        <tr onClick={() => handleRowClick(item.id)} key={item[itemKey] as any} className={isRowClickable ? styles.clickable : ''}>
                            {withIndex && <td style={({ borderLeft: '1px solid #e3e6eb' })}>
                                <span className={styles.indexText}>
                                    {index + 1}
                                </span>
                            </td>}

                            {withExpand && <td style={({ borderLeft: '1px solid #e3e6eb' })}>
                                <Button onClick={(e: any) => expandItem(e, item)} theme="empty" className={styles.expandButton}>
                                    <ArrowIcon className={classNames(styles.arrowIcon, item.isExpand ? styles.rotate : '')} />
                                </Button>
                            </td>}

                            {headers.map((header) => (
                                <td
                                    style={({textAlign: header.textAlign, padding: cellPadding ?? '8px 12px'})}
                                    key={header.value as string}
                                    className={styles.bodyCell}
                                >
                                    {children ? children(item, header) : <span className={styles.cellText}>{item[header.value] as any}</span>}
                                </td>
                            ))}

                            {!!onDelete && <td className={styles.deleteCell} colSpan={2}>
                                <Button className={styles.deleteButton} theme='empty' onClick={() => onDelete(item.id)}>
                                    {<BucketIcon />}
                                </Button>
                            </td>}
                        </tr>

                        {item.isExpand && expandContent && (
                            <tr className={styles.expandRow}>
                                <td colSpan={100}>
                                    {expandContent(item)}
                                </td>
                            </tr>
                        )}
                    </>
                ))}
                </tbody>
                {tableFooter &&
                    <tfoot className={styles.tableFooter}>
                        {tableFooter()}
                    </tfoot>
                }
            </table>
        </div>
    );
}
import {api} from "api/api";
import type {DistributeDocumentParams, DistributionDocumentType, UpdateStatusParams} from "./types";
import {DocumentRequestType} from "./const";

export async function fetchDistributeDocument(params: DistributeDocumentParams, documentType: DistributionDocumentType) {
    const requestDocumentType = DocumentRequestType[documentType];

    try {
        api.defaults.baseURL = `${process.env.REACT_APP_API_BASE}/api/v3`;
        const response = await api.post(`${requestDocumentType}/unapprove`, params);
        api.defaults.baseURL = `${process.env.REACT_APP_API_BASE}/api/web/v1`;
        return response.data;
    } catch {
        return null;
    }
}

export async function updateDocumentStatus(params: UpdateStatusParams) {
    try {
        const response = await api.post('/status/update', params);
        return response.data;
    } catch {
        return null
    }
}
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ReportsSkeleton = () => {
  return (
    <SkeletonTheme duration={2} baseColor="#bcbcbc" highlightColor="#dddddd">
      <div style={{ height: "80%", overflow: "hidden", padding: "16px" }}>
        <Skeleton height={"100%"} borderRadius={16} />
      </div>
    </SkeletonTheme>
  );
};

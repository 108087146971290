import { observer } from 'mobx-react-lite';
import { FC,ReactNode } from 'react';
import { Tooltip } from 'ui-new/tooltip/tooltip';
import { InfoIcon } from '../icons';
import cls from './tooltip-styles.module.scss';

interface ContractsTooltipProps {
  children: ReactNode;

  net: number;
  tax?: number;
}

const ContractsTooltipComponent: FC<ContractsTooltipProps> = ({ children,tax,net }) => {
  return (<div className={cls['container']}>
    {children}
    <Tooltip activator={<Activator />} activatorClassName={cls['activator']} offset={10} tooltip={<Content net={net} tax={tax} />} contentWidth='fit-content' />
  </div>);
};

export const ContractsTooltip = observer(ContractsTooltipComponent);

const Activator = () => {
  return <div><InfoIcon /></div>;
};

const Content = ({ tax,net }: {
  net: number;
  tax?: number;
}) => {
  return <div className={cls['content']}>
    <p>На руки: {net} ₽</p>
    {tax !== undefined && <p>НДФЛ: {tax} ₽</p>}
  </div>;
};
import { IUnwanted } from 'pages/spot/schedule-planning-new/types';
import { FC,useMemo } from 'react';
import unwantedCls from '../../modals/components/unwanted-style.module.scss';
import cls from './worker-styles.module.scss';
import { TimeIcon } from 'pages/spot/schedule-planning-new/icons';
import { formatTime } from 'pages/spot/schedule-planning-new/format-time';


interface UnwantedProps {
  unwanted: IUnwanted;
}

export const Unwanted: FC<UnwantedProps> = ({ unwanted: { type,start_time,end_time,comment,reason } }) => {
  const time = useMemo(() => {
    if (type === 'full_day') return 'Весь день';
    if (!start_time || !end_time) return 'Время указано некорректно';
    else return `${formatTime(start_time)} - ${formatTime(end_time)}`;
  },[end_time,start_time,type]);

  return (<div className={cls['unwanted']}>
    <div className={cls['unwanted-row']}>
      <TimeIcon color='#EB5757' />
      <span className={unwantedCls['unwanted-time']}>{time}</span>
    </div>
    {reason && <p className={unwantedCls['unwanted-reason']}>{reason.title}</p>}
  </div>);
};


import { FC } from "react";

export const BackButton = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_787_14443)">
        <path
          d="M1.93359 8H13.9336"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.93359 8H13.9336"
          stroke="black"
          stroke-opacity="0.2"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.93358 12L1.93359 8L5.93358 4"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.93358 12L1.93359 8L5.93358 4"
          stroke="black"
          stroke-opacity="0.2"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_787_14443">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

interface IconProps {
  color?: string;
}
export const BucketIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? "#999999";

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 3.33325V14.6666H13V3.33325H3Z"
        stroke={fillColor}
        stroke-width="1.4"
        stroke-linejoin="round"
      />
      <path
        d="M6.66699 6.66675V11.0001"
        stroke={fillColor}
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.33301 6.66675V11.0001"
        stroke={fillColor}
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.33301 3.33325H14.6663"
        stroke={fillColor}
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.33301 3.33325L6.42933 1.33325H9.59205L10.6663 3.33325H5.33301Z"
        stroke={fillColor}
        stroke-width="1.4"
        stroke-linejoin="round"
      />
    </svg>
  );
};

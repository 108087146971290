import React from "react";
import "./styles.scss";

export enum ELogoVariant {
  logo1 = "logo1",
  logo1_10 = "logo1_10",
  logo2 = "logo2",
}

interface LogoType {
  variant: ELogoVariant;
}

export const Logo: React.FC<LogoType> = ({ variant }) => {
  return (
    <div className="logoContainer">
      <div className={`${variant}`}></div>
    </div>
  );
};

import styles from './styles.module.scss';
import {Skeleton} from "../skeleton";

export const DocumentHeaderSkeleton = () => {
    return <header className={styles.documentHeader}>
        <ul className={styles.documentHeaderList}>
            {Array.from({ length: 6 }, (_, index) => (
                <li className={styles.columnItem}>
                    <span className={styles.columnItemValue}>{<Skeleton width='90px' height='16px'/>}</span>
                    <span className={styles.columnItemTitle}>{<Skeleton width='150px' height='20px'/>}</span>
                </li>
            ))}
        </ul>
    </header>
}
import { useEffect } from "react";
import "./styles.scss";
import { ODDSTableRow, ODDSGroup } from "./components";
import { ReportsSkeleton } from "ui/skeletons/index";
import { PeriodPicker } from "widgets/datepickers/period-picker";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import NoData from "../../../ui/no-data/noData";
import { Value } from "react-calendar/src/shared/types";

export const ODDS = observer(() => {
  const { RootStore } = useStore();
  const {
    ODDSStore: {
      dataODDS,
      isLoadingODDS,
      periodStartODDS,
      periodEndODDS,
      handlePeriodChangeODDS,
      getDataODDS,
    },
  } = RootStore;

  useEffect(() => {
    getDataODDS();
  }, []);

  return (
    <>
      <div className="odds__datepicker-wrapper">
        <PeriodPicker
          periodStart={periodStartODDS}
          periodEnd={periodEndODDS}
          periodSetter={(period: Value) => handlePeriodChangeODDS(period)}
          isFilter={false}
        />
      </div>

      {isLoadingODDS ? (
        <ReportsSkeleton />
      ) : (
        <div className="odds__wrapper" data-testid={"odds"}>
          {dataODDS ? (
            <div className="odds__table">
              <div className="odds__table__summary ">
                <div className="odds__table__summary__item">
                  <p className=" table-item ">
                    {dataODDS.sum.title ? dataODDS.sum.title : ""}
                  </p>
                </div>
                <div className="odds__table__summary__item">
                  <p className=" table-item">Начало периода: </p>
                  <p className=" table-item ">{dataODDS.sum.beginPeriod}</p>
                </div>
                <div className="odds__table__summary__item">
                  <p className=" table-item">Конец периода:</p>
                  <p className=" table-item">{dataODDS.sum.endPeriod}</p>
                </div>
              </div>
              <div className="odds__table__header">
                <ODDSTableRow
                  visible
                  name={"Наименование"}
                  number={"Сумма, ₽"}
                  isTitle
                  isGrey
                />
              </div>

              {dataODDS.items &&
                dataODDS.items.length > 0 &&
                dataODDS.items.map((section: any, sectionIndex: any) => {
                  return (
                    <>
                      <ODDSTableRow
                        visible
                        key={sectionIndex}
                        name={section.title}
                        isBold
                      />
                      {section.items.map((group: any, groupIndex: any) => {
                        return group.items.length > 0 ? (
                          <ODDSGroup
                            key={groupIndex}
                            items={group.items}
                            name={group.title}
                            number={group.sum}
                          />
                        ) : (
                          <ODDSTableRow
                            visible
                            key={groupIndex}
                            name={group.title}
                            number={group.sum}
                          />
                        );
                      })}

                      <ODDSTableRow
                        visible
                        key={sectionIndex}
                        name={"Сальдо"}
                        number={section.sum}
                      />
                    </>
                  );
                })}

              <div className="odds__table__summary odds__table__row__bold">
                <div className="odds__table__summary__item">
                  <p className=" table-item">{dataODDS.sum.title}</p>
                </div>
                <div className="odds__table__summary__item">
                  <p className=" table-item">Начало периода: </p>{" "}
                  <p className=" table-item">{dataODDS.sum.beginPeriod}</p>
                </div>
                <div className="odds__table__summary__item">
                  <p className=" table-item">Конец периода:</p>
                  <p className=" table-item">{dataODDS.sum.endPeriod}</p>
                </div>
              </div>
            </div>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </>
  );
});

import { FC,useMemo } from 'react';
import { ITemplate } from '../types';
import cls from './styles.module.scss';
import { BucketIcon,EditIcon } from '../icons';
import { observer } from 'mobx-react-lite';
import { getDurationFromHHMM } from 'assets/utils';
import { useStore } from 'store';
import { formatTime } from '../format-time';

interface TemplateProps {
  template: ITemplate;

  isNotEditable?: boolean;

  className?: string;
}


const TemplateComponent: FC<TemplateProps> = ({ template,isNotEditable,className }) => {
  const { RootStore: { SchedulePlanningStore: { setEditTemplateModalIsOpen,confirmTemplateDelete } } } = useStore();
  const { id,title,description,start_time,end_time,color } = template;
  const duration = useMemo(() => getDurationFromHHMM(start_time,end_time),[end_time,start_time]);


  const startTime = useMemo(() => formatTime(start_time),[start_time]);
  const endTime = useMemo(() => formatTime(end_time),[end_time]);


  return (<div className={`${cls['template']} ${className ?? ''}`}>
    <div className={cls['duration']} style={{ backgroundColor: color ?? 'transparent' }}>{duration}</div>
    <div className={cls['info']}>
      <div className={cls['row']}>
        <h6>{title}</h6>
        {!isNotEditable &&
          <div className={cls['buttons']}>
            <button onClick={() => setEditTemplateModalIsOpen(true,template)}><EditIcon /></button>
            <button onClick={() => confirmTemplateDelete(id)}><BucketIcon /></button>
          </div>
        }
      </div>
      <p className={cls['time']}>{startTime}-{endTime}</p>
      <span className={cls['description']}>{description}</span>
    </div>
  </div>);
};

export const Template = observer(TemplateComponent);
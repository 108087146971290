import { useEffect, useState } from "react";
import { BigSkeleton } from "ui";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router";
import { PopupModal } from "widgets";
import { useStore } from "store";
import BreadCrumbs from "../components/breadCrumbs";
import DocumentHeader from "../components/documentHeader";
import InfoBlock from "../components/infoBlock";
import InvoicePopupModal from "./components/invoicePopupModal";
import AttachmentsBlock from "../components/attachmentsBlock";
import NomenclatureTable from "../components/nomenclatureTable";
import {toJS} from "mobx";

export const InvoicePage = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: {
      setDocumentType,
      setDocumentId,
      setModalIsActive,
      advanceReport,
      setPickedContragent,
      fetchReport,
      fetchAllContragents,
      fetchResponsible,
      fetchUnits,
      fetchArticles,
      fetchNomenclatures,
      updateInvoice,
      setCarryingDate,
      isLoading,
    },
  } = RootStore;

  const [pickedDealId, setPickedDealId] = useState<string | null>(null);
  const [deleteModalIsActive, setDeleteModalIsActive] =
    useState<boolean>(false);
  const [editModalIsActive, setEditModalIsActive] = useState<boolean>(false);

  function handleModalContragentChange(contragent: string) {
    setPickedContragent(contragent);
  }

  const onSave = async () => {
    updateInvoice();
    setModalIsActive(false);
    setEditModalIsActive(false);
    RootStore.setModalIsActive(false);
  }

  const openModal = () => {
    setEditModalIsActive(true);
    setCarryingDate(toJS(advanceReport.date))
  }

  const getDocumentTitle = () => {
    const number = toJS(advanceReport.number) || "";
    const invoiceTitle = toJS(advanceReport)?.type === 'in' ? 'Приходная накладная' : 'Расходная накладная';
    return `${invoiceTitle} №${number}`;
  }

  useEffect(() => {
    setDocumentId(+location.hash.slice(1));
    const documentType = location.pathname.split("/")[3];
    setDocumentType(documentType === 'invoice_out' ? 'invoice' : location.pathname.split("/")[3]);
    fetchReport();
    fetchAllContragents();
    fetchResponsible();
    fetchUnits();
    fetchNomenclatures(["GOODS", "SERVICE"]);
    fetchArticles();
  }, [location]);

  return (
    <>
      {advanceReport && (
        <>
          <div className="registry-document__header-container">
            <BreadCrumbs
              navigate={navigate}
              location={location}
              type="Накладная"
            />

            <div className="registry-document__header">
              <DocumentHeader
                navigate={navigate}
                location={location}
                deleteModalIsActive={deleteModalIsActive}
                setDeleteModalIsActive={setDeleteModalIsActive}
                type={getDocumentTitle()}
              />
            </div>
          </div>

          {isLoading ? (
            <div style={{ padding: "0 20px" }}>
              <BigSkeleton />
            </div>
          ) : (
            <div className="registry-document__body">
              <InfoBlock setEditModalIsActive={openModal} />

              {/* блок с вложениями */}
              {advanceReport.files && advanceReport.files.length > 0 && (
                <AttachmentsBlock />
              )}

              <NomenclatureTable />
            </div>
          )}
        </>
      )}
      {editModalIsActive && (
        <div className="registry-popup">
          <PopupModal
            maxWidth={"440px"}
            title={"Редактировать"}
            subtitle={"Изменение документа"}
            onClose={() => setEditModalIsActive(false)}
            onSave={() => onSave()}
          >
            <InvoicePopupModal
              setPickedDealId={setPickedDealId}
              handleModalContragentChange={handleModalContragentChange}
            />
          </PopupModal>
        </div>
      )}
    </>
  );
});

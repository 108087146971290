import React, { useState, ReactNode, useEffect, useRef } from "react";
import "./styles.scss";
import { observer } from "mobx-react-lite";

interface DropdownUIProps {
  children: ReactNode;
  shown: string | null;
  maxWidth?: string;
  height?: string;
  borderRadius?: string;
  disabled?: boolean;
  shownIsPlaceholder?: boolean;
}

export const DropdownUI: React.FC<DropdownUIProps> = observer(
  ({
    shown,
    children,
    height,
    maxWidth,
    borderRadius,
    disabled,
    shownIsPlaceholder,
  }) => {
    const [dropdownIsActive, setDropdownIsActive] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    function dropdownClick() {
      setDropdownIsActive((prev) => !prev);
    }

    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setDropdownIsActive(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div
        ref={dropdownRef}
        className={`dropdown ${disabled && "disabled"}`}
        style={{
          maxWidth: maxWidth,
          height: height,
          borderRadius: borderRadius,
        }}
      >
        <div
          className={`dropdown-header ${
            dropdownIsActive ? "dropdown-header-active" : ""
          } ${shownIsPlaceholder ? "placeholder" : ""}`}
          onClick={() => dropdownClick()}
          style={{
            maxWidth: maxWidth,
            height: height,
            borderRadius: borderRadius,
          }}
        >
          <span className="text">{shown}</span>
          <span
            className={
              dropdownIsActive ? "dropdown-arrow down" : "dropdown-arrow up"
            }
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.7079 8.793L12 13.0859L16.2921 8.793C16.385 8.70011 16.4953 8.62642 16.6167 8.57615C16.7381 8.52588 16.8682 8.5 16.9995 8.5C17.1309 8.5 17.261 8.52588 17.3824 8.57615C17.5038 8.62642 17.6141 8.70011 17.707 8.793C17.7999 8.88589 17.8736 8.99617 17.9238 9.11754C17.9741 9.23891 18 9.369 18 9.50037C18 9.63173 17.9741 9.76182 17.9238 9.88319C17.8736 10.0046 17.7999 10.1148 17.707 10.2077L12.7074 15.2068C12.6146 15.2997 12.5043 15.3735 12.3829 15.4238C12.2615 15.4741 12.1314 15.5 12 15.5C11.8686 15.5 11.7385 15.4741 11.6171 15.4238C11.4957 15.3735 11.3854 15.2997 11.2926 15.2068L6.29303 10.2077C6.20013 10.1148 6.12643 10.0046 6.07615 9.88319C6.02588 9.76182 6 9.63173 6 9.50037C6 9.369 6.02588 9.23891 6.07615 9.11754C6.12643 8.99617 6.20013 8.88589 6.29303 8.793C6.38593 8.70011 6.49622 8.62642 6.6176 8.57615C6.73898 8.52588 6.86908 8.5 7.00046 8.5C7.13185 8.5 7.26194 8.52588 7.38332 8.57615C7.50471 8.62642 7.615 8.70011 7.7079 8.793Z"
                fill="#272729"
              />
            </svg>
          </span>
        </div>

        <ul
          className={
            dropdownIsActive
              ? "dropdown-list dropdown-list-active"
              : "dropdown-list"
          }
          onClick={() => setDropdownIsActive(false)}
        >
          {children}
        </ul>
      </div>
    );
  },
);

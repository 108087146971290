export const CustomTick = (props: any) => {
  const { x,y,payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        className={"x-axis-tick--holiday"}
      >
        {payload.value}
      </text>
    </g>
  );
};

import { api } from "./api";
import {
  IResponsibleStatus,
  NomenclatureTypeKey,
  UpdatePositionTypes,
} from "../pages/registry-document/types";

export async function getReport(documentType: string, documentId: number) {
  try {
    const response = await api.get(`docks/${documentType}/get`, {
      params: {
        [`${documentType}_id`]: documentId,
      },
    });
    return response.data.data;
  } catch (error: any) {
    return null;
  }
}

export async function getDealNumbers(contragentId: number) {
  if (!contragentId) return;
  try {
    const response = await api.post(`contragents/deals/get`, {
      contragent_id: contragentId,
    });
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getAllContragents() {
  try {
    const response = await api.get(`contragents/companies/get`);
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getUserContragents() {
  try {
    const response = await api.get(`contragents/user/get`);
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getResponsible() {
  try {
    const response = await api.get(`responsible/get`);
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getUnits() {
  try {
    const response = await api.get(`docks/unit/get`);
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getArticles() {
  try {
    const response = await api.get(`docks/cash/articles/get`);
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getNomenclatures(body?: NomenclatureTypeKey[]) {
  try {
    const requestBody = body || [];
    const response = await api.post(`docks/nomenclature/get`, {
      type: requestBody,
    });
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function fetchUpdateAdvanceReport(
  documentId: number,
  pickedResponsibleId: string | null
) {
  try {
    const response = await api.post(`docks/advance/update`, {
      advance_id: documentId,
      user_contragent_id: pickedResponsibleId,
    });

    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function fetchUpdateInvoice(
  documentId: number,
  documentNumber: string,
  pickedContragentId: any,
  pickedDocumentId: any,
  formattedDate: string | Date
) {
  try {
    const response = await api.post(`docks/invoice/update`, {
      invoice_id: documentId,
      number: documentNumber,
      [`${pickedContragentId?.split(".")[0]}`]:
        +pickedContragentId?.split(".")[1],
      deal_id: +pickedDocumentId,
      date: formattedDate,
    });

    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function fetchUpdateCashOrder(
  documentId: number,
  pickedContragentId: any,
  pickedDocumentId: any,
  pickedArticleId: number | null
) {
  try {
    const response = await api.post(`docks/cash/update`, {
      cash_id: documentId,
      [`${pickedContragentId?.split(".")[0]}`]:
        +pickedContragentId?.split(".")[1],
      deal_id: +pickedDocumentId,
      article_id: pickedArticleId,
    });
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function fetchApproveStatus(
  documentId: number,
  documentType: string
) {
  try {
    const response = await api.post(`status/update`, {
      document_id: documentId,
      status: "approved",
      document_type: documentType,
      comment: null,
    });
    return response.data.success;
  } catch (error) {
    return null;
  }
}

export async function fetchDeleteStatus(
  draftComment: string,
  documentId: number,
  documentType: string
) {
  try {
    const response = await api.post(`status/update`, {
      document_id: documentId,
      status: "deleted",
      document_type: documentType,
      comment: draftComment,
    });

    return response.data.success;
  } catch (error) {
    return null;
  }
}

export async function fetchUpdatePosition(
  documentType: string,
  documentId: number,
  positionId: number,
  type: UpdatePositionTypes,
  value: number | string
) {
  try {
    const response = await api.post(`docks/position/update`, {
      [`${documentType}_id`]: documentId,
      position_id: positionId,
      [`${type}`]: value,
    });
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function fetchCreateNewPosition(
  documentId: number | null,
  documentType: string | null
) {
  try {
    const response = await api.post(`docks/position/update`, {
      position_id: null,
      [`${documentType}_id`]: documentId,
    });

    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function deletePosition(id: number | null) {
  try {
    const response = await api.post(`docks/position/delete`, {
      position_id: id,
    });
    return response.data.success;
  } catch (error) {
    return null;
  }
}

export async function checkInventory(documentId: number) {
  try {
    const response = await api.get<{
      status: string;
      data: IResponsibleStatus[];
    }>(`docks/checkInventory`, {
      params: {
        [`inventory_id`]: documentId,
      },
    });
    return response.data.data;
  } catch (error: any) {
    return null;
  }
}

import React from "react";
import { observer } from "mobx-react-lite";
import cls from "./styles.module.scss";
import { FieldValues,useController,UseControllerProps } from 'react-hook-form';
import { TimePicker,TimePickerProps } from 'antd';

interface ITextareaType<T extends FieldValues> extends UseControllerProps<T> {
  className?: string;
  popupClassName?: string;
  label?: string;
  placeholder?: string;
  width?: string;
  format?: TimePickerProps['format'];
  hourStep?: TimePickerProps['hourStep'];
  minuteStep?: TimePickerProps['minuteStep'];
  secondStep?: TimePickerProps['secondStep'];
  needConfirm?: boolean;
  disabled?: boolean;
}

const ArrowDown = () => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 7L8 10L12 7" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4 7L8 10L12 7" stroke="black" stroke-opacity="0.2" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>;

};

const TimeComponent =
  <T extends FieldValues>({ className,popupClassName,label,width,placeholder,disabled,
    format = 'HH:mm',hourStep,minuteStep,secondStep,needConfirm = false,...props }: ITextareaType<T>) => {
    const { field,fieldState } = useController(props);
    const { name } = props;

    return (
      <div className={cls["inputField"]}>
        {label && <label className={cls['label']} htmlFor={name}>{label}</label>}
        <TimePicker style={{ width }} {...field} id={name} placeholder={placeholder} format={format}
          hourStep={hourStep ?? 1} minuteStep={minuteStep ?? 1} secondStep={secondStep ?? 1}
          showNow={false} needConfirm={needConfirm} disabled={disabled} suffixIcon={<ArrowDown />}
          popupClassName={`${cls['basicTimePickerPopup']} ${popupClassName}`}
          className={`${cls['basicInput']} ${className} ${fieldState.error ? cls['basicInputError'] : ''} `} />
        <p className={cls['errorText']}>{fieldState.error?.message}</p>
      </div>
    );
  };

export const TimeField = observer(TimeComponent);;
import React from "react";
import { EFontVariant } from "assets/styles/enums";
import "./styles.scss";

export enum EButtonVariant {
  fillButton = "fillButton",
  oldFillButton = "oldFillButton",
  fillLightButton = "fillLightButton",
  strokeButton = "strokeButton",
  ghostButton = "ghostButton",
  strokeDeleteButton = "strokeDeleteButton",
  eyeButton = "eyeButton",
  shapeButton = "shapeButton",
  createButton = "createButton",
  triplePointsButton = "triplePointsButton",
  emploeeChooseButton = "emploeeChooseButton",
  emploeeOpenChooseButton = "emploeeOpenChooseButton",
  closeButton = "c loseButton",
  editButton = "editButton",
}

// content?: string | React.ReactNode; - don't need

interface IButtonType {
  variant: EButtonVariant;
  maxWidth?: string;
  height?: string;
  margin?: string;
  font?: EFontVariant;
  borderRadius?: string;
  text?: string;
  disabled?: boolean;
  tabIndex?: number;
  onClick?: () => void;
  className?: string;
}

export const Button: React.FC<IButtonType> = ({
  variant,
  maxWidth,
  height,
  margin,
  font,
  borderRadius,
  text,
  disabled,
  tabIndex,
  onClick,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      tabIndex={tabIndex}
      className={`buttonCommon ${variant} ${font} ${className}`}
      style={{
        maxWidth: maxWidth,
        height: height,
        borderRadius: borderRadius,
        margin: margin,
      }}
    >
      {text}
    </button>
  );
};

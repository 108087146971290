import "./styles.scss";
import Calendar from "react-calendar";
import { LooseValue, Value } from "react-calendar/src/shared/types";

interface PeriodCalendarProps {
  periodStart: Value;
  periodEnd: Value;
  periodSetter: (period: Value) => void;
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
}

export const PeriodCalendar = ({
  periodStart,
  periodEnd,
  periodSetter,
  isOpen,
  setIsOpen,
}: PeriodCalendarProps) => {
  function changeHandler(period: Value) {
    if (!period) return;
    periodSetter(period);
    setIsOpen(false);
  }

  return (
    <Calendar
      selectRange={true}
      value={[periodStart, periodEnd] as LooseValue}
      onChange={(period: Value) => changeHandler(period)}
      className={`period-calendar ${!isOpen ? "period-calendar--hidden" : ""}`}
      locale={'ru-RU'}
    />
  );
};

import React from "react";
import { EColorVariant, EFontVariant } from "assets/styles/enums";
import {
  Input,
  EInputVariant,
  EInputStyleVariant,
  Logo,
  ELogoVariant,
  Button,
  EButtonVariant,
  Navlink,
  ENavigateStyleVariant,
  Spinner,
  ESpinnerVariant,
} from "ui";
import "./styles.scss";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import {useNavigate} from "react-router";
import {changePassRequest} from "../../api/auth";
import {showAlertPopup} from "../../ui/alert";

export const ResetPass = observer(() => {
  const {
    RootStore: { AuthStore },
  } = useStore();

  const navigate = useNavigate();

  const onPassChangeRequest = async () => {
    AuthStore.setIsLoading(true);
    const result = await changePassRequest(AuthStore.login);

    if (!result) {
      AuthStore.setIsLoading(false);
      return;
    }

    showAlertPopup("Заявка отправлена!", "Заявка отправлена успешно.", "success");
    AuthStore.setIsLoading(false);
    navigate("/login/auth");
  }

  return (
    <div className="resetPass__wrapper">
      <div className="resetPass__container">
        <div className="resetPass__logo-container">
          <Logo variant={ELogoVariant.logo1} />
        </div>
        <span className="resetPass__line"></span>
        <div className="resetPass__authContainer">
          <h2 className={`resetPass__authContainer-header`}>
            Восстановление пароля
          </h2>
          <span className={`resetPass__authContainer-wellcome_message`}>
            Отправь заявку на восстановления пароля указав почту привязанную к
            аккаунту
          </span>
          <label className="resetPass__authContainer-login">
            <span className={`resetPass__authContainer-login__text-container`}>
              Логин
            </span>
            <Input
              type={EInputVariant.text}
              variant={EInputStyleVariant.basicInput}
              height={"44px"}
              borderRadius={"16px"}
              value={AuthStore.login}
              autoFocus={true}
              onChange={(e) => AuthStore.setLogin(e.currentTarget.value)}
            />
          </label>
          <div className="resetPass__buttons-container">
            <Navlink
              variant={`${ENavigateStyleVariant.strokeNavItem} ${EFontVariant.body2Regular} `}
              path={"/login/auth"}
              text={"Отмена"}
              maxWidth={"90px"}
              height={"48px"}
              borderRadius={"16px"}
            />
            {AuthStore.login !== "" &&
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
              AuthStore.login,
            ) ? (
              AuthStore.isLoading ? (
                <Spinner
                  variant={ESpinnerVariant.spinnerGeneral}
                  maxWidth={"225px"}
                  height={"48px"}
                  borderRadius={"16px"}
                />
              ) : (
                <Button
                  variant={EButtonVariant.fillButton}
                  font={EFontVariant.body2Regular}
                  maxWidth={"225px"}
                  height={"48px"}
                  borderRadius={"16px"}
                  text="Отправить заявку"
                  onClick={() => onPassChangeRequest()}
                />
              )
            ) : (
              <Button
                variant={EButtonVariant.fillButton}
                font={EFontVariant.body2Regular}
                disabled={true}
                maxWidth={"225px"}
                height={"48px"}
                borderRadius={"16px"}
                text="Отправить заявку"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

import { observer } from 'mobx-react-lite';
import { ContractsTable } from './contracts-table';
import { Modals } from './modals/modals';
import { TextButton } from 'ui-new/text-button';
import { useStore } from 'store';
import { useEffect } from 'react';

//TODO Просто вернуть кнопку из коммента, когда будет готов функционал

const ContractsInfoComponent = () => {
  const { RootStore: { SalaryFundStore: { fetchGetContracts,selectedDate,setLastPeriodContractsConfirm } } } = useStore();

  useEffect(() => {
    fetchGetContracts();
  },[fetchGetContracts,selectedDate]);

  return (<div style={{ display: 'flex',flexDirection: 'column',gap: '17px' }}>
    <ContractsTable />
    <div style={{ display: 'flex',alignItems: 'center',gap: '32px' }}>
      <Modals />
      {/* <TextButton onClick={setLastPeriodContractsConfirm}>Перенести контракты прошлого месяца</TextButton> */}
    </div>
  </div>);
};

export const ContractsInfo = observer(ContractsInfoComponent);
import React, {useEffect, useRef} from 'react';
import {toJS} from 'mobx';
import {EmptyTableBody} from '../../../ui/empty-table-body';
import {useStore} from '../../../store';
import {Select, Tooltip} from 'antd';
import {Button, EButtonVariant} from '../../../ui';
import {observer} from 'mobx-react-lite';
import {BucketIcon} from '../icons';
import {type Nomenclature, UpdatePositionTypes, NomenclatureType} from '../types';
import InvoiceValueInput from '../invoice/components/invoiceValueInput';
import SumInput from './SumInput';
import "pages/registry-document/customSelectOption.scss";

type NomenclatureTooltipType = Pick<Nomenclature, "path" | "category" | "label">

const NomenclatureTable = observer(() => {
  const {RootStore} = useStore();
  const {
    RegistryDocumentStore: {
      advanceReport,
      fetchDeletePosition,
      createNewPosition,
      setAdvanceReport,
      nomenclaturesList,
      setPickedNomenclatureId,
      setPickedPositionId,
      setPickedUnitId,
      unitsList,
      selectedIdx,
      pickedIdx,
      setSelectedIdx,
      setPickedIdx,
      updatePosition,
      setPickedValue,
      setPickedSum,
      setPickedValueFact
    },
  } = RootStore;

  const tableRef = useRef<HTMLDivElement>(null);
  const dropdownNomenclatureRef = useRef<any>(null);
  const dropdownUnitRef = useRef<any>(null);

  function handleTableElementClick(
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
    id: number,
  ) {
    if (!(advanceReport.status === "pending")) return;

    if (event.detail === 1) {
      if (index === selectedIdx) {
        setPickedIdx(index);
      } else {
        setSelectedIdx(index);
        setPickedIdx(0);
      }
    }

    if (event.detail === 2) {
      if (index === pickedIdx) {
        setPickedIdx(0);
      } else {
        setPickedIdx(index);
      }
    }
    setPickedPositionId(id);

  }

  const getToolTipLabel = (option: Nomenclature | NomenclatureTooltipType) => {
    return (
      <div className="select-tooltip-container">
        <p className="select-tooltip-item select-tooltip-item-label">{option.label} <br/></p>
        <p className="select-tooltip-item">{option?.path || ""} <br/></p>
        <p className="select-tooltip-item">{option?.category || ""} <br/></p>
      </div>
    );
  }

  const onNameSelect = async (
    val: string,
    option: any,
    value: any,
    index: any,
  ) => {
    const result = await updatePosition(value.id, UpdatePositionTypes.NOMENCLATURE_ID, val);
    const unit = toJS(unitsList).find((unit: any) => result?.unit_id === unit.value);

    const updatedPositions = [...advanceReport.positions];
    updatedPositions[index].name =
      nomenclaturesList?.find(
        (obj: any) => obj.value === val
      )?.label;
    updatedPositions[index].unit = unit.label || "";
    setAdvanceReport({
      ...toJS(advanceReport),
      positions: updatedPositions
    });
  }

  async function handleCreatePositon() {
    const id = await createNewPosition();
    setAdvanceReport({
      ...toJS(advanceReport),
      positions: [
        ...toJS(advanceReport).positions,
        {id: id, name: null, sum: "", unit: "", value: ""},
      ],
    });
  }

  useEffect(() => {
    setPickedNomenclatureId(null);
    setPickedSum(null);
    setPickedValue(null);
    setPickedValueFact(null);
    setPickedUnitId(null);

    function handleClickOutside(event: MouseEvent) {
      if (
        tableRef.current &&
        !tableRef.current.contains(event.target as Node)
      ) {
        setSelectedIdx(0);
        setPickedIdx(0);
      }
    }

    document.addEventListener("mousedown", handleClickOutside, {
      capture: true,
    });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, {
        capture: true,
      });
    };
  }, []);

  return (
    <>
      <div className="registry-document__table" ref={tableRef}>
        <div className="registry-document__table-header">
          <div className="registry-document__table-header-element f52"></div>

          <div className="registry-document__table-header-element f586">
            Номенклатура
          </div>

          <div className="registry-document__table-header-element f150">
            Цена, ₽
          </div>
          <div className="registry-document__table-header-element f150">
            Количество
          </div>
          <div className="registry-document__table-header-element f72">
            Ед.изм
          </div>
          <div className="registry-document__table-header-element f150">
            Сумма, ₽
          </div>
          {advanceReport.status === "pending" && (
            <div className="registry-document__inv-table__header__element"></div>
          )}
        </div>
        <div className="registry-document__table-body">
          {advanceReport.positions && advanceReport.positions.length > 0 ? (
            advanceReport.positions.map((value: any, index: number) => (
              <div className="registry-document__table-body-row" key={index}>
                <div className={`registry-document__table-element number f52`}>
                  {index + 1}
                </div>
                <div
                  className={`registry-document__table-element name f586 ${
                    selectedIdx === index + 0.2 ? "selected" : ""
                  } ${pickedIdx === index + 0.2 ? "picked" : ""} ${
                    value.name ? "picked" : "non-picked"
                  }`}
                  onClick={(event) => {
                    handleTableElementClick(event, index + 0.2, value.id);
                  }}
                >
                  <Select
                    className={`search-select ${
                      value.name ? "picked" : "non-picked"
                    }`}
                    showSearch
                    placeholder={
                      value.name !== null ? value.name : "Выберите номенклатуру"
                    }
                    optionFilterProp="children"
                    onChange={
                      async (val: string, option: any) => await onNameSelect(val, option, value, index)
                    }
                    onSearch={() => {
                    }}
                    ref={dropdownNomenclatureRef}
                    filterOption={(input, option) =>
                      (typeof option?.label === 'string' ? option.label.toLowerCase() : '')
                        .includes(input.toLowerCase())
                    }
                  >
                    {toJS(nomenclaturesList)?.map((option: Nomenclature) => (
                      <Select.Option key={option.value} value={option.value} label={option.label}>
                        <div className="select-option">
                          <Tooltip title={getToolTipLabel(option)}>
                            <span className="select-option-main-label">{option.label}</span>
                          </Tooltip>
                          <span className="select-option-type-label">
                            {NomenclatureType[option.type]}
                          </span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                  {value.name !== null ?
                    <Tooltip placement="leftTop" title={getToolTipLabel({
                      label: value.name,
                      path: value.path,
                      category: value.category
                    })}>
                      {value.name}
                    </Tooltip> :
                    "Выберите номенклатуру"}
                </div>
                <div
                  className={`registry-document__table-element price f150 }`}
                >
                  {value.value == 0 ||
                  value.value === null ||
                  value.sum == 0 ||
                  value.sum === null
                    ? ""
                    : (value.sum / value.value) % 1 === 0
                      ? value.sum / value.value
                      : (value.sum / value.value).toFixed(2)}
                </div>
                <div
                  className={`registry-document__table-element amount f150 ${
                    selectedIdx === index + 0.4 ? "selected" : ""
                  } ${pickedIdx === index + 0.4 ? "picked" : ""} ${
                    value.value ? "picked" : "non-picked"
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleTableElementClick(event, index + 0.4, value.id);
                  }}
                >
                  <InvoiceValueInput value={value} index={index}/>
                  {value.value !== null ? value.value : ""}
                </div>
                <div
                  className={`registry-document__table-element type f72 ${
                    selectedIdx === index + 0.5 ? "selected" : ""
                  } ${pickedIdx === index + 0.5 ? "picked" : ""}`}
                >
                  {value.unit}
                </div>
                <div
                  className={`registry-document__table-element sum f150 ${
                    selectedIdx === index + 0.6 ? "selected" : ""
                  } ${pickedIdx === index + 0.6 ? "picked" : ""}  ${
                    value.sum ? "picked" : "non-picked"
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleTableElementClick(event, index + 0.6, value.id);
                  }
                  }
                >
                  {value.sum !== null ? value.sum : ""}
                  <SumInput value={value} index={index}/>
                </div>
                {advanceReport.status === "pending" && (
                  <div
                    className="registry-document__table-element"
                    onClick={() => fetchDeletePosition(value)}
                  >
                    <BucketIcon/>
                  </div>
                )}
              </div>
            ))
          ) : (
            <EmptyTableBody/>
          )}
        </div>
      </div>
      {advanceReport && advanceReport.status === "pending" && (
        <Button
          variant={EButtonVariant.createButton}
          text="Добавить позицию"
          maxWidth={"184px"}
          height={"32px"}
          borderRadius={"10px"}
          onClick={() => handleCreatePositon()}
        />
      )}
    </>
  );
});

export default NomenclatureTable;

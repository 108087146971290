import React, { useState } from "react";
import "./revenueInputField.scss";

interface IRevenueInputFieldType {
  summary: number;
  disabled?: boolean;
  maxWidth?: string;
  isNonPrice?: boolean;
  height?: string;
}

export const RevenueInputField: React.FC<IRevenueInputFieldType> = ({
  summary,
  disabled,
  maxWidth,
  height,
  isNonPrice,
}) => {
  const [value, setValue] = useState<number>(summary);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value) {
      const newValue = parseFloat(event.target.value.replace(/[^\d.]/g, ""));

      setValue(newValue);
    } else {
      setValue(0);
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = /^\d*\.?\d*$/; // only allow numbers and backspace (\b) and whitespace
    const inputValue = event.key;
    if (!regex.test(inputValue)) {
      event.preventDefault();
    }
  };

  return (
    <div className="revenueInputField__container">
      <input
        className={
          disabled
            ? `revenueInputField`
            : `revenueInputField revenueInputField_active`
        }
        type="text"
        disabled={disabled}
        value={value.toLocaleString("ru-RU")}
        placeholder={value.toLocaleString("ru-RU")}
        style={{ width: maxWidth, height: height }}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      ></input>
      {isNonPrice ? (
        <span
          className={
            disabled
              ? `revenueInputField__mark`
              : `revenueInputField__mark revenueInputField__mark_active`
          }
        ></span>
      ) : (
        <span
          className={
            disabled
              ? `revenueInputField__mark`
              : `revenueInputField__mark revenueInputField__mark_active`
          }
        >
          ₽
        </span>
      )}
    </div>
  );
};

import React,{ useEffect } from "react";
import "./styles.scss";
import {
  BigSkeleton,
  Button,
  EButtonVariant,
  EInputStyleVariant,
  EInputVariant,
  Input,
} from "ui";
import { EFontVariant } from "assets/styles/enums";
import { observer } from "mobx-react-lite";
import { Filters } from "../../ui/filters";
import { SortingArrow,TableHeaderDiveder } from "./icons";
import {
  AddDocumentModal,
  FillDocumentModal,
  ErrorAddDocumentModal,
  ContragentFilter,
  DocumentFilter,
  PeriodFilter,
  ResponsibleFilter,
  StatusFilter,
} from "./components";

import { useStore } from "store";
import { TableBody } from "./components/tableBody/tableBody";
import { TableFooterRegistry } from "./components/tableFooter/tableFooter";
import { RegSortType } from "./types";

export const RegistryPage = observer(() => {
  const { RootStore } = useStore();
  const {
    RegistryStore: {
      contragentsList,
      pickedRegistryStartDate,
      pickedRegistryEndDate,
      pickedRegistryStatus,
      pickedRegistryDocument,
      pickedRegistryUserContragent,
      pickedRegistryContragent,
      pickedRegistryResponsible,
      isRegistryLoading,
      firstRegistryModalIsOpen,
      secondRegistryModalIsOpen,
      errorRegistryModalIsOpen,
      setPickedRegistryContragent,
      downloadOperations,
      fetchArticles,
      fetchContragents,
      fetchResponsible,
      fetchDealNumbers,
      setFirstRegistryModalIsOpen,
      searchList,
      handleSearch,
      searchValue,
      setSearchValue,
      firstItem,
      lastItem,
      resetAllFilters,
      isResetActive,
      toggleSort,
      pickedSortType,
      pickedSortOrder,
      responsibleList,
      operationsList,
    },
  } = RootStore;


  // отправляем новый запрос когда значение одного из фильтров меняется
  useEffect(() => {
    downloadOperations();
  },[
    pickedRegistryDocument,
    pickedRegistryStatus,
    pickedRegistryStartDate,
    pickedRegistryEndDate,
    pickedRegistryContragent,
    pickedRegistryUserContragent,
    pickedRegistryResponsible,
  ]);

  useEffect(() => {
    fetchContragents();
    fetchResponsible();
    fetchArticles();
    fetchDealNumbers();
  },[]);

  return (
    <>
      <div className="registry__wrapper">
        <div className="registryHeader__wrapper">
          <div className="registryHeader__inner">
            <p className="registryHeader__title">Реестр документов</p>
            <Button
              variant={EButtonVariant.fillButton}
              font={EFontVariant.body2Regular}
              height={"32px"}
              maxWidth={"168px"}
              borderRadius={"10px"}
              text="Добавить документ"
              data-testid={"add-doc-btn"}
              onClick={() => {
                RootStore.setModalIsActive(true);
                setFirstRegistryModalIsOpen(true);
              }}
            />
          </div>
        </div>
        {
          //TODO: Переделать вывод модалок
        }
        {firstRegistryModalIsOpen && <AddDocumentModal />}
        {secondRegistryModalIsOpen && <FillDocumentModal />}
        {errorRegistryModalIsOpen && <ErrorAddDocumentModal />}
        <div className="registry__main">
          <div className="registry__underheader">
            <Filters
              haveResetButton={true}
              filtersReset={resetAllFilters}
              isResetActive={isResetActive}
              isFiltersVisible={isResetActive}
            >
              <PeriodFilter />
              <StatusFilter />
              <DocumentFilter />
              <ContragentFilter />
              <ResponsibleFilter />
            </Filters>

            <Input
              type={EInputVariant.text}
              variant={EInputStyleVariant.searchInput}
              height={"32px"}
              maxWidth={"260px"}
              borderRadius={"10px"}
              placeholder={"№ документа, сумма"}
              value={searchValue !== null ? searchValue : ""}
              onChange={(e) => handleSearch(e)}
              pattern={searchValue ? "[0-9]*" : undefined}
              withReset={true}
              onReset={() => {
                setSearchValue(null);
              }}
              data-testid={"search-input"}
            />
          </div>

          {isRegistryLoading ? (
            <BigSkeleton />
          ) : (
            <div className="registry-table">
              {/* header */}
              <div className="registry-table__header">
                <div className="registry-table__header-element f28" />
                <div className="registry-table__header-element f28" />
                <div
                  className="registry-table__header-element f130"
                  onClick={() => toggleSort(RegSortType.date)}
                >
                  <div className="registry-table__header-element-title">
                    Дата
                    <SortingArrow
                      sortType={
                        pickedSortType === RegSortType.date && pickedSortOrder
                      }
                    />
                  </div>
                  <TableHeaderDiveder />
                </div>
                <div
                  className="registry-table__header-element f200"
                  onClick={() => toggleSort(RegSortType.document)}
                >
                  <div className="registry-table__header-element-title">
                    Документ
                    <SortingArrow
                      sortType={
                        pickedSortType === RegSortType.document &&
                        pickedSortOrder
                      }
                    />
                  </div>
                  <TableHeaderDiveder />
                </div>
                <div
                  className="registry-table__header-element f180"
                  onClick={() => toggleSort(RegSortType.number)}
                >
                  <div className="registry-table__header-element-title">
                    № документа
                    <SortingArrow
                      sortType={
                        pickedSortType === RegSortType.number && pickedSortOrder
                      }
                    />
                  </div>
                  <TableHeaderDiveder />
                </div>
                <div
                  className="registry-table__header-element f232 "
                  onClick={() => toggleSort(RegSortType.contragent)}
                >
                  <div className="registry-table__header-element-title">
                    Контрагент
                    <SortingArrow
                      sortType={
                        pickedSortType === RegSortType.contragent &&
                        pickedSortOrder
                      }
                    />
                  </div>
                  <TableHeaderDiveder />
                </div>
                <div
                  className="registry-table__header-element f130"
                  onClick={() => toggleSort(RegSortType.sum)}
                >
                  <div className="registry-table__header-element-title">
                    Сумма, ₽
                    <SortingArrow
                      sortType={
                        pickedSortType === RegSortType.sum && pickedSortOrder
                      }
                    />
                  </div>
                  <TableHeaderDiveder />
                </div>
                <div
                  className="registry-table__header-element f232"
                  onClick={() => toggleSort(RegSortType.responsible)}
                >
                  <div className="registry-table__header-element-title">
                    Ответственный
                    <SortingArrow
                      sortType={
                        pickedSortType === RegSortType.responsible &&
                        pickedSortOrder
                      }
                    />
                  </div>
                </div>
              </div>

              <TableBody
                searchValue={searchValue}
                searchList={searchList}
                firstItem={firstItem}
                lastItem={lastItem}
              />

              <TableFooterRegistry />
            </div>
          )}
        </div>
      </div>
    </>
  );
});

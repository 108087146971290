import React from "react";

import "./styles.scss";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import StatusBar from "./components/statusBar/statusBar";
import FinanceAction from "./components/financeAction/financeAction";

export const FinancePage = observer(() => {
  const { RootStore } = useStore();
  const {
    FinanceStore: { currentStatusBarType, setCurrentStatusBarType },
  } = RootStore;

  return (
    <div className="finance">
      <p className="finance__header">Расчет финансовых результатов</p>
      <div className="finance__body">
        <StatusBar type={currentStatusBarType} />
        <FinanceAction />
      </div>
    </div>
  );
});

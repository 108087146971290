import { useCallback,useMemo,useState } from 'react';
import { Modal } from 'widgets/modal';
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { DateBar } from 'pages/spot/schedule-planning-new/components/date-bar';
import { CreateByTemplate } from './components/create-by-template';
import cls from './styles.module.scss';
import sharedCls from '../../styles.module.scss';
import { CreateByTime } from './components/create-by-time';
import { toJS } from 'mobx';
import { convertUnwanted } from './components/unwanted';
import { showNotificationModal } from 'ui-new/alert';

const CreateShiftModalComponent = () => {
  const {
    RootStore: {
      SchedulePlanningStore: {
        createShiftDate,
        setCreateShiftIsOpen,
        templates,
        shiftUnwanted,
        selectedWorker
      },
    },
  } = useStore();

  const [activeTab,setActiveTab] = useState(0);
  const onClose = useCallback(() => setCreateShiftIsOpen(false),[setCreateShiftIsOpen]);

  const onTemplatesTabClick = useCallback(() => {
    if (templates.length === 0) {
      showNotificationModal({ title: 'Ошибка',type: 'failure',errors: ['Для создания смен при помощи шаблонов создайте хотя бы один шаблон'] });
      return;
    }
    setActiveTab(0);
  },[toJS(templates).length]);

  const activeForm = useMemo(() => {
    if (templates.length === 0 || activeTab) return <CreateByTime onClose={onClose} />;
    return <CreateByTemplate onClose={onClose} />;
  },[activeTab,onClose,toJS(templates).length]);

  const unwantedData = useMemo(() => {
    if (shiftUnwanted.length === 0) return;

    return <div style={{ marginTop: '16px' }}>{convertUnwanted(toJS(shiftUnwanted))}</div>;
  },[toJS(shiftUnwanted)]);

  return (
    <Modal
      title={"Добавление смены"}
      maxWidth={"580px"}
      onClose={onClose}
    >
      <div className={cls['bar']}>
        <DateBar date={createShiftDate} />
        <span className={cls['worker-fio']}>Работник: {selectedWorker?.name ?? 'Не указан'}</span>
      </div>
      {unwantedData}
      <div className={sharedCls['tabs__container']} style={{ margin: '24px 0px' }}>
        <button onClick={onTemplatesTabClick} className={(templates.length !== 0 && activeTab === 0) ? sharedCls['tab__active'] : sharedCls['tab']}>Шаблоны</button>
        <button onClick={() => setActiveTab(1)} className={templates.length === 0 || activeTab === 1 ? sharedCls['tab__active'] : sharedCls['tab']}>Другое время</button>
      </div>
      {activeForm}
    </Modal>
  );
};

export const CreateShiftModal = observer(CreateShiftModalComponent);
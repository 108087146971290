import { observer } from 'mobx-react-lite';
import { FC,useMemo,useRef } from 'react';
import cls from './styles.module.scss';
import { Table } from './components/table';
import { useStore } from 'store';
import { toJS } from 'mobx';
import { PlusIcon } from '../icons';
import { ScheduleModals } from './modals/schedule-modals';
import { Timeline } from './components/timeline';
import { WorkerTitle } from './components/worker/worker-title';
import { Units } from './components/units-list';
import { DateRow } from './components/date-row';
import { BigSkeleton } from 'ui';
import { MonthSelector } from 'widgets/month-selector';

interface ScheduleTabProps {

}

const ScheduleTabComponent: FC<ScheduleTabProps> = () => {
  const { RootStore: { SchedulePlanningStore: { spotEmployees,setAddHelperModalIsOpen,selectedDate,setSelectedDate } } } = useStore();
  const tableRef = useRef<HTMLDivElement | null>(null);

  const [employeesData,unitListData] = useMemo(() => {
    const helpers = spotEmployees['helpers']?.unit_employees?.length > 0;
    const unitListData: any[] = [];

    const employeesData = Object.keys(spotEmployees).filter(key => toJS(spotEmployees)[key].unit_employees.length > 0)
      .map((key,i) => {
        const tableInfo = toJS(spotEmployees[key]);
        let button = null;
        let unitButton = null;
        if ((!helpers && i === 0) || key === 'helpers') {
          button = <button className={cls['add-helper-button']} onClick={() => setAddHelperModalIsOpen(true)}><PlusIcon />Добавить хелпера</button>;
          unitButton = <button className={cls['add-helper-button']} style={{ marginLeft: '-3px',marginTop: '19px' }} onClick={() => setAddHelperModalIsOpen(true)}><PlusIcon />Добавить хелпера</button>;
        }
        let unitList = [<div className={cls['thead-employee']}><p>{tableInfo.title}</p></div>];
        unitList = unitList.concat(
          tableInfo.unit_employees.map(worker => <WorkerTitle key={worker.id} worker={worker} tableType={tableInfo.title} />)
        );
        unitListData.push(
          <div>
            <div className={cls['employees-container']}>
              {unitList}
            </div>
            {unitButton}
          </div>
        );

        return <div>
          <Table key={tableInfo.title} withDates={i === 0} tableInfo={tableInfo} />
          {button}
        </div>;
      });

    return [employeesData,unitListData];
  }
    ,[setAddHelperModalIsOpen,spotEmployees]);

  return (
    <>
      <div style={{ marginTop: '16px' }}>
        <div style={{ marginLeft: '24px' }}>
          <MonthSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        </div>
        <div ref={tableRef} className={cls['schedule__wrapper']}>
          <Units parentRef={tableRef} unitListData={unitListData} />
          <DateRow parentRef={tableRef} />
          {Object.keys(spotEmployees).length === 0 ? <BigSkeleton padding={'0 0 0 24px'} />
            :
            <>
              {employeesData}
              <Timeline />
              <div></div>
            </>
          }
        </div>
      </div>
      <ScheduleModals />
    </>
  );
};

export const ScheduleTab = observer(ScheduleTabComponent);
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/index";
import ErrorBoundary from 'app/errorBoundary/ErrorBoundary';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
    <App />
  );

export type OperationStatus = 'pending' | 'delivery' | 'approved' | 'deleted' | 'waiting';

export interface Operation {
  id: string;
  document: string;
  document_id: number;
  date: string;
  contragent: string;
  contragent_id: number | null;
  responsible: string;
  responsible_id: number;
  status: OperationStatus;
  sum_debet: number;
  type: string;
  user_contragent_id: number | null;
  length: number;
  is_read: boolean;
  inn: number;
}

export interface Category {
  id: string;
  title: string;
}

export interface Deal {
  id: number;
  title: string;
  number: string;
}

export interface RegistryFilters {
  pickedRegistryStartDate: string | null;
  pickedRegistryEndDate: string | null;
  pickedRegistryStatus: string | null;
  pickedRegistryDocument: string | null;
  pickedRegistryUserContragent: string[];
  pickedRegistryContragent: string[];
  pickedRegistryResponsible: string[];
}

export interface IContragent {
  id?: number;
  contragent_id?: number;
  user_contragent_id?: number;
  title?: string;
  name?: string;
  surname?: string;
  inn?: string;
  checked?: boolean;
}

export interface IResponsible {
  id: number;
  surname: string;
  name: string;
  checked?: boolean;
}

export interface IFilterDocumentType {
  id: number;
  name_en: string;
  name_ru: string;
}

export interface ICategories {
  id: string;
  title: string;
}

export enum RegSortType {
  date = "date",
  document = "document",
  number = "number",
  contragent = "contragent",
  sum = "sum",
  responsible = "responsible",
}
export enum RegSortOrder {
  asc = "asc",
  desc = "desc",
}

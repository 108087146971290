import React from "react";
import { useStore } from "../../../store";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

interface BreadCrumbsProps {
  navigate: ReturnType<typeof useNavigate>;
  location: ReturnType<typeof useLocation>;
  type: string;
}

const BreadCrumbs = observer(
  ({ navigate, location, type }: BreadCrumbsProps) => {
    const { RootStore } = useStore();
    const {
      RegistryDocumentStore: { advanceReport },
    } = RootStore;

    return (
      <div className="registry-document__breadcrumbs">
        <span
          className="registry-document__link"
          onClick={() => {
            navigate("/main/registry");
          }}
        >
          Реестр документов
        </span>
        <span className="registry-document__slash">/</span>
        <span className="registry-document__link current">{type}</span>
      </div>
    );
  },
);

export default BreadCrumbs;

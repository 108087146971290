import { fetchORPData } from "api";
import { dateToNumbersAndDots } from "assets/utils";
import { subMonths, subDays } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";
import { parseResponseORP } from "./utils";
import { Value } from "react-calendar/src/shared/types";
import { RootStore } from "../../../store/rootStore";

export class ORPStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  dataORP: any = null;
  periodStartORP: Value = subMonths(new Date(), 1); // дефолтное начало периода - 1 месяц назад
  periodEndORP: Value = subDays(new Date(), 1); // дефолтный конец периода - 1 день назад (за сегодня инфы не должно быть)
  isLoadingORP = false;

  setDataORP = (newData: any) => {
    this.dataORP = newData;
  };
  setPeriodStartORP = (newDate: Value) => {
    this.periodStartORP = newDate;
  };
  setPeriodEndORP = (newDate: Value) => {
    this.periodEndORP = newDate;
  };
  setIsLoadingORP = (bool: boolean) => {
    this.isLoadingORP = bool;
  };

  handlePeriodChangeORP = (period: Value) => {
    if (!period || !Array.isArray(period)) return;
    this.setPeriodStartORP(period[0]);
    this.setPeriodEndORP(period[1]);
    this.getDataORP();
  };
  getDataORP = async () => {
    this.setIsLoadingORP(true);
    try {
      const response = await fetchORPData(
        dateToNumbersAndDots(this.periodStartORP),
        dateToNumbersAndDots(this.periodEndORP),
      );
      runInAction(() => {
        if (response?.data.data) {
          console.log("getDataORP success:");
          console.log(response);
          console.log(response.data.data);
          this.setDataORP(parseResponseORP(response.data.data));
        }
        this.setIsLoadingORP(false);
      });
    } catch (e) {
      this.setIsLoadingORP(false);
      console.log("getDataORP error:");
      console.log(e);
    }
  };
}

import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { PopupModal } from 'widgets';

const ErrorAddDocumentModalComponent = () => {
  const { RootStore: { RegistryStore: { setErrorRegistryModalIsOpen,errorRegistryMessage } } } = useStore();

  const onClose = () => setErrorRegistryModalIsOpen(false);

  return (<PopupModal
    title={"Ошибка добавления"}
    onSave={onClose}
    maxWidth={"440px"}
    buttonText={"Понятно"}
    onClose={onClose}
  >
    <div className='registry-modal__error' >
      <p className='message'>
        {errorRegistryMessage}
      </p>
    </div>
  </PopupModal>);
};

export const ErrorAddDocumentModal = observer(ErrorAddDocumentModalComponent);
import { FC,useMemo } from 'react';
import cls from '../styles.module.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';

interface ScheduleHeaderProps {
  activeTab: 0 | 1;
  setActiveTab: (tab: 0 | 1) => void;
}

const ScheduleHeaderComponent: FC<ScheduleHeaderProps> = ({ activeTab,setActiveTab }) => {
  const { RootStore: { SchedulePlanningStore: { templates,setCreateTemplateModalIsOpen } } } = useStore();

  // const title = activeTab ? 'Шаблон смен' : 'Планирование';
  const templatesLength = useMemo(() => templates.length,[templates.length]);

  const title = useMemo(() => {
    if (!activeTab)
      return <div className={cls["title__container"]}>
        <p className={cls['title']}>Планирование</p>
      </div>;

    if (!templatesLength) {
      return <div></div>;
    }

    return <div className={cls["title__container"]}>
      <p className={cls['title']}>Шаблоны смен</p>
      <button className={cls['button-primary']} onClick={() => setCreateTemplateModalIsOpen(true)} >Добавить шаблон</button>
    </div>;
  },[activeTab,setCreateTemplateModalIsOpen,templatesLength]);

  return (
    <div className={cls['schedule-planning__header']}>
      {title}
      <div className={cls['tabs__container']}>
        <button onClick={() => setActiveTab(0)} className={activeTab === 0 ? cls['tab__active'] : cls['tab']}>Планирование</button>
        <button onClick={() => setActiveTab(1)} className={activeTab === 1 ? cls['tab__active'] : cls['tab']}>Шаблоны</button>
      </div>
    </div>
  );
};

export const ScheduleHeader = observer(ScheduleHeaderComponent);
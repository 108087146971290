import React, { useEffect } from "react";
import "./styles.scss";
import { EColorVariant, EFontVariant } from "assets/styles/enums";
import {
  Button,
  EButtonVariant,
  EInputStyleVariant,
  EInputVariant,
  Input,
} from "ui";

//todo types
export const ShiftModal = (props: any) => {
  return (
    <div
      className={
        props.isModalActive
          ? "shiftModal__modal shiftModal__modal-active"
          : "shiftModal__modal"
      }
      // При клике по тёмной части
      onClick={() => props.setIsModalActive(false)}
    >
      <div
        className="shiftModal__modal-content"
        onClick={(event) => event.stopPropagation()}
      >
        <div className="shiftModal__modal-content__info">
          <div className="shiftModal__modal-content__header">
            <h2>Шаблон смены будней</h2>
            <Button
              variant={EButtonVariant.closeButton}
              maxWidth={"15px"}
              height={"15px"}
              onClick={() => {
                props.setIsModalActive(false);
              }}
            />
          </div>
          <label className="shiftModal__modal-content__shift-name">
            <span
              className={`shiftModal__modal-content__shift-name__text-container ${EFontVariant.body2Regular} ${EColorVariant.colorNeutral50}`}
            >
              Название новой смены
            </span>
            <Input
              className="body1Regular"
              type={EInputVariant.text}
              variant={EInputStyleVariant.basicInput}
              height={"44px"}
              borderRadius={"16px"}
              placeholder={"Введите название"}
              value={props.shiftTitle}
              onChange={(e) => props.setShiftTitle(e.currentTarget.value)}
            />
          </label>
          <span className={`shiftModal__modal-content__timepick-header`}>
            Время работы
          </span>
          <div className="shiftModal__modal-content__timepick-inputs">
            <Input
              className="body1Regular"
              type={EInputVariant.time}
              variant={EInputStyleVariant.basicInput}
              font={EFontVariant.body1Regular}
              maxWidth={"188px"}
              height={"44px"}
              borderRadius={"16px"}
              required={true}
              value={props.draftStartTime}
              onChange={(e) => props.setDraftStartTime(e.currentTarget.value)}
            />
            <Input
              type={EInputVariant.time}
              variant={EInputStyleVariant.basicInput}
              font={EFontVariant.body1Regular}
              maxWidth={"188px"}
              height={"44px"}
              borderRadius={"16px"}
              required={true}
              value={props.draftEndTime}
              onChange={(e) => props.setDraftEndTime(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className="shiftModal__buttons-container ">
          {props.isEventNew ? (
            <Button
              variant={EButtonVariant.fillButton}
              text={"Добавить шаблон"}
              height={"48px"}
              borderRadius={"16px"}
              onClick={() => {
                props.addNewEvent();
                props.setIsModalActive(false);
              }}
            />
          ) : (
            <>
              <Button
                variant={EButtonVariant.fillButton}
                text={"Сохранить"}
                height={"48px"}
                borderRadius={"16px"}
                onClick={() => {
                  props.saveEventChanges(
                    props.shiftId,
                    props.draftStartTime,
                    props.draftEndTime,
                  );
                  props.setIsModalActive(false);
                }}
              />
              <Button
                className="deleteButton"
                variant={EButtonVariant.strokeButton}
                text={"Удалить"}
                height={"48px"}
                borderRadius={"16px"}
                onClick={() => {
                  props.setDeleteModalIsActive(true);
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { EInputStyleVariant, Input } from "ui-new/input";
import SelectField from "ui-new/select";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { MoneyTransactionDynamicFormFields } from "types/money-transactions";

const taxRateOptions = [
  { label: "Без налога", value: 0 },
  { label: "20 %", value: 20 },
];

interface TaxComponentProps {
  allDisabled: boolean;
}

const TaxComponent: FC<TaxComponentProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { formFields },
    },
  } = useStore();

  const { control } = useFormContext<{
    [MoneyTransactionDynamicFormFields.TaxRate]: number;
    [MoneyTransactionDynamicFormFields.TaxAmount]: number;
  }>();

  return (
    <>
      {formFields?.tax_rate?.show && (
        <SelectField
          label="Ставка НДС"
          control={control}
          name={MoneyTransactionDynamicFormFields.TaxRate}
          disabled={allDisabled}
          options={taxRateOptions}
          selectProps={{
            placeholder: "Выберите ставку НДС",
            disabled: allDisabled || formFields.tax_rate.disabled,
          }}
        />
      )}
      {formFields?.tax_amount?.show && (
        <Input
          label="Сумма НДС"
          control={control}
          variant={EInputStyleVariant.basicInput}
          type="number"
          name={MoneyTransactionDynamicFormFields.TaxAmount}
          placeholder="0, ₽"
          disabled={allDisabled}
          inputProps={{
            disabled: allDisabled || formFields.tax_amount.disabled,
          }}
        />
      )}
    </>
  );
};

export const Tax = observer(TaxComponent);

import { fetchOFRData } from "api";
import { dateToMonthYear } from "assets/utils";
import { makeAutoObservable, runInAction } from "mobx";
import { Value } from "react-calendar/src/shared/types";
import { RootStore } from "../../../store/rootStore";

export class OFRStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  dataOFR: any = null;
  dateOFR = new Date();
  isLoadingOFR = false;

  setDataOFR = (newData: any) => {
    this.dataOFR = newData;
  };
  setDateOFR = (newDate: any) => {
    this.dateOFR = newDate;
  };
  setIsLoadingOFR = (bool: any) => {
    this.isLoadingOFR = bool;
  };

  handleDateChangeOFR = (newDate: Value) => {
    if (newDate !== this.dateOFR) {
      this.setDateOFR(newDate);
      this.getDataOFR();
    }
  };
  getDataOFR = async () => {
    this.setIsLoadingOFR(true);
    try {
      const response = await fetchOFRData(dateToMonthYear(this.dateOFR));
      runInAction(() => {
        if (response?.data.data) {
          this.setDataOFR(response.data.data);
        }
        this.setIsLoadingOFR(false);
      });
    } catch (e) {
      this.setIsLoadingOFR(false);
    }
  };
}

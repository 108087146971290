import React from "react";

export const DeleteIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 6.75H7.875V13.5H6.75V6.75ZM10.125 6.75H11.25V13.5H10.125V6.75Z"
      fill="#212129"
    />
    <path
      d="M2.25 3.375V4.5H3.375V15.75C3.375 16.0484 3.49353 16.3345 3.7045 16.5455C3.91548 16.7565 4.20163 16.875 4.5 16.875H13.5C13.7984 16.875 14.0845 16.7565 14.2955 16.5455C14.5065 16.3345 14.625 16.0484 14.625 15.75V4.5H15.75V3.375H2.25ZM4.5 15.75V4.5H13.5V15.75H4.5ZM6.75 1.125H11.25V2.25H6.75V1.125Z"
      fill="#212129"
    />
  </svg>
);

export const TableDividerIcon = () => (
  <svg
    width="1"
    height="16"
    viewBox="0 0 1 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="1" height="16" fill="#D3D6DB" />
  </svg>
);

export const AddShiftIcon = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 1.875C8.89303 1.875 7.32214 2.35152 5.986 3.24431C4.64985 4.1371 3.60844 5.40605 2.99348 6.8907C2.37852 8.37535 2.21762 10.009 2.53112 11.5851C2.84463 13.1612 3.61846 14.6089 4.75476 15.7452C5.89106 16.8815 7.3388 17.6554 8.9149 17.9689C10.491 18.2824 12.1247 18.1215 13.6093 17.5065C15.094 16.8916 16.3629 15.8502 17.2557 14.514C18.1485 13.1779 18.625 11.607 18.625 10C18.6227 7.84581 17.766 5.78051 16.2427 4.25727C14.7195 2.73403 12.6542 1.87727 10.5 1.875ZM10.5 16.875C9.14026 16.875 7.81105 16.4718 6.68046 15.7164C5.54987 14.9609 4.66868 13.8872 4.14833 12.6309C3.62798 11.3747 3.49183 9.99237 3.7571 8.65875C4.02238 7.32513 4.67716 6.10013 5.63864 5.13864C6.60013 4.17716 7.82514 3.52237 9.15876 3.2571C10.4924 2.99183 11.8747 3.12798 13.131 3.64833C14.3872 4.16868 15.4609 5.04987 16.2164 6.18045C16.9718 7.31104 17.375 8.64025 17.375 10C17.3729 11.8227 16.6479 13.5702 15.3591 14.8591C14.0702 16.1479 12.3227 16.8729 10.5 16.875ZM14.25 10C14.25 10.1658 14.1842 10.3247 14.0669 10.4419C13.9497 10.5592 13.7908 10.625 13.625 10.625H11.125V13.125C11.125 13.2908 11.0592 13.4497 10.9419 13.5669C10.8247 13.6842 10.6658 13.75 10.5 13.75C10.3342 13.75 10.1753 13.6842 10.0581 13.5669C9.94085 13.4497 9.875 13.2908 9.875 13.125V10.625H7.375C7.20924 10.625 7.05027 10.5592 6.93306 10.4419C6.81585 10.3247 6.75 10.1658 6.75 10C6.75 9.83424 6.81585 9.67527 6.93306 9.55806C7.05027 9.44085 7.20924 9.375 7.375 9.375H9.875V6.875C9.875 6.70924 9.94085 6.55027 10.0581 6.43306C10.1753 6.31585 10.3342 6.25 10.5 6.25C10.6658 6.25 10.8247 6.31585 10.9419 6.43306C11.0592 6.55027 11.125 6.70924 11.125 6.875V9.375H13.625C13.7908 9.375 13.9497 9.44085 14.0669 9.55806C14.1842 9.67527 14.25 9.83424 14.25 10Z"
      fill="#98A1B2"
    />
  </svg>
);

import { FC } from 'react';

interface IconProps {

}

export const ArrowIcon: FC<IconProps> = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_20089_9813)">
        <path d="M3.86523 16H27.8652" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.86523 16H27.8652" stroke="black" stroke-opacity="0.2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.8652 24L3.86523 16L11.8652 8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.8652 24L3.86523 16L11.8652 8" stroke="black" stroke-opacity="0.2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_20089_9813">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

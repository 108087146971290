import { FC } from 'react';

interface IconProps {
  color?: string;
}

export const BucketIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? '#999999';

  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 3.33325V14.6666H13V3.33325H3Z" stroke={fillColor} stroke-width="1.4" stroke-linejoin="round" />
    <path d="M6.66699 6.66675V11.0001" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.33301 6.66675V11.0001" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.33301 3.33325H14.6663" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.33301 3.33325L6.42933 1.33325H9.59205L10.6663 3.33325H5.33301Z" stroke={fillColor} stroke-width="1.4" stroke-linejoin="round" />
  </svg>;

};

export const EditIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? '#999999';

  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.77548 14.5L4.60382 14.5001L14.9747 4.12921L12.1463 1.30078L1.77539 11.6717L1.77548 14.5Z" stroke={fillColor} stroke-width="1.4" stroke-linejoin="round" />
    <path d="M9.31738 4.12915L12.1458 6.95758" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>;

};

export const DateIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? 'black';

  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.33301" y="2.6665" width="13.3333" height="12" rx="2" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.33301 6.66667H14.6663" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.66667 1.3335V4.00016" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.3337 1.3335V4.00016" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.667 4.3335L14.667 13.0002" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.33301 4.3335L1.33301 13.0002" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.66699 14.6665H11.3337" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
    ;
};

export const PlusIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? '#6D37F6';

  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6998 0.999805C6.6998 0.613205 6.3864 0.299805 5.9998 0.299805C5.61321 0.299805 5.2998 0.613205 5.2998 0.999805L5.2998 5.2998H0.999805C0.613205 5.2998 0.299805 5.6132 0.299805 5.9998C0.299805 6.3864 0.613205 6.6998 0.999805 6.6998H5.2998V10.9998C5.2998 11.3864 5.6132 11.6998 5.9998 11.6998C6.3864 11.6998 6.6998 11.3864 6.6998 10.9998L6.6998 6.6998H10.9998C11.3864 6.6998 11.6998 6.3864 11.6998 5.9998C11.6998 5.6132 11.3864 5.2998 10.9998 5.2998H6.6998V0.999805Z" fill={fillColor} />
  </svg>;

};

export const WarningIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? 'black';

  return <svg width="11" height="10" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.32092 1.16182C6.93575 0.496536 5.97523 0.496534 5.59006 1.16182L1.53504 8.16595C1.14907 8.83262 1.63013 9.66699 2.40046 9.66699H10.5105C11.2808 9.66699 11.7619 8.83262 11.3759 8.16596L7.32092 1.16182Z" fill={fillColor} stroke={fillColor} stroke-linejoin="round" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49625 7.08789C7.00008 7.08789 7.40851 7.49633 7.40851 8.00015V8.00025C7.40851 8.50408 7.00008 8.91251 6.49625 8.91251C5.99242 8.91251 5.58398 8.50408 5.58398 8.00025V8.00015C5.58398 7.49633 5.99242 7.08789 6.49625 7.08789Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49498 2.34022C6.99881 2.33952 7.40781 2.74739 7.40851 3.25122L7.41062 5.5004C7.41132 6.00423 7.00346 6.41323 6.49963 6.41393C5.9958 6.41463 5.5868 6.00676 5.5861 5.50294L5.58399 3.25375C5.58329 2.74992 5.99115 2.34092 6.49498 2.34022Z" fill="white" />
  </svg>;

};

export const TimeIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? 'black';

  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6818 14.6668 7.99992C14.6668 4.31802 11.6821 1.33325 8.00016 1.33325C4.31826 1.33325 1.3335 4.31802 1.3335 7.99992C1.3335 11.6818 4.31826 14.6666 8.00016 14.6666Z" stroke={fillColor} stroke-width="1.4" stroke-linejoin="round" />
    <path d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6818 14.6668 7.99992C14.6668 4.31802 11.6821 1.33325 8.00016 1.33325C4.31826 1.33325 1.3335 4.31802 1.3335 7.99992C1.3335 11.6818 4.31826 14.6666 8.00016 14.6666Z" stroke={fillColor} stroke-opacity="0.2" stroke-width="1.4" stroke-linejoin="round" />
    <path d="M8.00314 4.00003L8.00274 8.00298L10.8292 10.8294" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.00314 4.00003L8.00274 8.00298L10.8292 10.8294" stroke={fillColor} stroke-opacity="0.2" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>;
};
import { fetchOSVData } from "api";
import { dateToNumbersAndDots } from "assets/utils";
import { subMonths, subDays } from "date-fns";
import { makeAutoObservable } from "mobx";
import { Value } from "react-calendar/src/shared/types";
import { RootStore } from "../../../store/rootStore";

export class OSVStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }
  dataOSV: any = null;
  periodStartOSV: Value = subMonths(new Date(), 1); // дефолтное начало периода - 1 месяц назад
  periodEndOSV: Value = subDays(new Date(), 1); // дефолтный конец периода - 1 день назад (за сегодня инфы не должно быть)
  isLoadingOSV: boolean = false;

  setDataOSV = (newData: any) => {
    this.dataOSV = newData;
  };
  setPeriodStartOSV = (newDate: Value) => {
    this.periodStartOSV = newDate;
  };
  setPeriodEndOSV = (newDate: Value) => {
    this.periodEndOSV = newDate;
  };
  setIsLoadingOSV = (bool: boolean) => {
    this.isLoadingOSV = bool;
  };

  handlePeriodChangeOSV = (period: Value) => {
    if (!period || !Array.isArray(period)) return;
    this.setPeriodStartOSV(period[0]);
    this.setPeriodEndOSV(period[1]);
    this.getDataOSV();
  };
  getDataOSV = async () => {
    this.setIsLoadingOSV(true);
    try {
      console.log("getDataOSV:");
      const response = await fetchOSVData(
        dateToNumbersAndDots(this.periodStartOSV),
        dateToNumbersAndDots(this.periodEndOSV),
      );

      if (response?.data.data) {
        this.setDataOSV(response.data.data);
      }
      this.setIsLoadingOSV(false);
    } catch (e) {
      this.setIsLoadingOSV(false);
      console.log("getDataOSV error:");
      console.log(e);
    }
  };
}

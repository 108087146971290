import React from "react";
import { useStore } from "../../../../store";
import { toJS } from "mobx";
import { MonthPicker } from "../../../../widgets/datepickers";
import { Button, EButtonVariant } from "../../../../ui";
import { EFontVariant } from "../../../../assets/styles/enums";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";

const FinanceAction = observer(() => {
  const { RootStore } = useStore();
  const {
    FinanceStore: {
      currentAction,
      setCurrentAction,
      EActionType,
      setCurrentStatusBarType,
      ECurrentStatusType,
      pickedDate,
      setPickedDate,
      fetchFinance,
      isMonthNotAvailable,
    },
  } = RootStore;

  const navigate = useNavigate();

  return (
    <div className="finance__body__action">
      {toJS(currentAction).isPending ? (
        <div className="loader">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div className="loader-background"></div>
          </div>
          <div className="loader__title">Ожидайте результат</div>
        </div>
      ) : (
        <>
          {toJS(currentAction).title && (
            <div className="title">{toJS(currentAction).title}</div>
          )}
          {toJS(currentAction).subtitle && (
            <div className="subtitle">{toJS(currentAction).subtitle}</div>
          )}
          {toJS(currentAction).isCalendarActive && (
            <MonthPicker
              date={pickedDate}
              dateSetter={setPickedDate}
              isDropdownCenter
            />
          )}
          {toJS(currentAction).isCloseButtonActive && (
            <Button
              variant={EButtonVariant.fillButton}
              font={EFontVariant.body2Regular}
              height={"40px"}
              maxWidth={"132px"}
              borderRadius={"10px"}
              text="Закрыть месяц"
              disabled={isMonthNotAvailable}
              onClick={() => {
                setCurrentAction(EActionType.CLOSING);
                setCurrentStatusBarType(ECurrentStatusType.SECOND_STATUS);
                fetchFinance();
              }}
            />
          )}
          <div className={"buttons"}>
            {toJS(currentAction).isChangeButtonActive && (
              <Button
                variant={EButtonVariant.fillButton}
                font={EFontVariant.body2Regular}
                height={"40px"}
                maxWidth={"195px"}
                borderRadius={"10px"}
                text="Выбрать другой месяц"
                onClick={() => {
                  setCurrentAction(EActionType.MONTH);
                  setCurrentStatusBarType(ECurrentStatusType.FIRST_STATUS);
                }}
              />
            )}
            {toJS(currentAction).isMoveButtonActive && (
              <Button
                variant={EButtonVariant.fillLightButton}
                font={EFontVariant.body2Regular}
                height={"40px"}
                maxWidth={"130px"}
                borderRadius={"10px"}
                text="Перейти в ОФР"
                onClick={() => {
                  navigate("/main/reports/ofr");
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});

export default FinanceAction;

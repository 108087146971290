import React from "react";
import { DoneIcon } from "../../icons";

interface IStatusBarProps {
  type: {
    month: string | null;
    closing: string | null;
    result: string | null;
  };
}

const StatusBar = ({ type: { month, closing, result } }: IStatusBarProps) => {
  console.log(month, closing, result);
  return (
    <div className="finance__body__status">
      <div className="finance__body__status-element">
        <div
          className={`finance__body__status-element__number ${
            month && "active"
          }`}
        >
          {month === "done" ? <DoneIcon /> : 1}
        </div>
        <div
          className={`finance__body__status-element__title ${
            month && "active"
          }`}
        >
          Месяц
        </div>
      </div>
      <div
        className={`finance__body__status-bar  ${closing && "active"}`}
      ></div>
      <div className="finance__body__status-element">
        <div
          className={`finance__body__status-element__number ${
            closing && "active"
          }`}
        >
          {closing === "done" ? <DoneIcon /> : 2}
        </div>
        <div
          className={`finance__body__status-element__title ${
            closing && "active"
          }`}
        >
          Закрытие
        </div>
      </div>
      <div className={`finance__body__status-bar ${result && "active"}`}></div>
      <div className="finance__body__status-element">
        <div
          className={`finance__body__status-element__number ${
            result && "active"
          }`}
        >
          3
        </div>
        <div
          className={`finance__body__status-element__title ${
            result && "active"
          }`}
        >
          Результат
        </div>
      </div>
    </div>
  );
};

export default StatusBar;

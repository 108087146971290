import "./styles.scss";
import { useRef, useEffect, useState, MutableRefObject } from "react";
import Calendar from "react-calendar";
import {
  dateToFullRuMonthName,
  dropdownEscOrOutsideClickCloser,
  formatMonthYear,
} from "../../../assets/utils";
import { CalendarIcon } from "assets/icons";
import { MonthCalendar } from "ui/month-calendar";
import { Value } from "react-calendar/src/shared/types";

interface MonthPickerNewProps {
  date: Value;
  dateSetter: (newDate: Value) => void;
  isDropdownCenter?: boolean;
}

/** Выпадающий календарь с месяцами  */
export const MonthPicker = ({
  date,
  dateSetter,
  isDropdownCenter,
}: MonthPickerNewProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const monthPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dropdownEscOrOutsideClickCloser(monthPickerRef, () => setIsOpen(false));
  }, []);
  return (
    <>
      <div className="month-picker" ref={monthPickerRef}>
        <div
          className={`month-picker__month `}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <p>
            {dateToFullRuMonthName(date as Date)},{" "}
            {(date as Date).getFullYear()}
          </p>
          <CalendarIcon />
        </div>
        <div
          className={
            isOpen
              ? "month-picker__calendar"
              : "month-picker__calendar month-picker__calendar--hidden"
          }
          style={{ left: `${isDropdownCenter && "-49.5px"}` }}
        >
          <MonthCalendar
            dateSetter={dateSetter}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
      </div>
    </>
  );
};

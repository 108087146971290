import { Dropdown, Menu, Space } from "antd";
import { useStore } from "store";
import React, { useState, useEffect } from "react";
import { FilterArrow, FilterCross } from "../../icons";
import { Calendar } from "react-calendar";
import { PeriodPicker } from '../../../../widgets/datepickers';

export const PeriodFilter = () => {
  const { RootStore } = useStore();
  const {
    RegistryStore: {
      setPickedRegistryPeriod,
      pickedRegistryPeriod,
      setPickedRegistryEndDate,
      setPickedRegistryStartDate,
      pickedRegistryEndDate,
      pickedRegistryStartDate,
      setCurrentPage,
    },
  } = RootStore;

  // фильтр - период
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const handleDropdownOpenChange = (open: boolean) => {
    setDropdownOpen(open);
  };

  const handlePeriodFilterClick = (value: any) => {
    setCurrentPage(1);
    setPickedRegistryPeriod(value);
    setDropdownOpen(!dropdownOpen);
  };

  const periodFilterMenu = (
    <Menu>
      <Calendar
        selectRange={true}
        value={pickedRegistryPeriod as any}
        onChange={handlePeriodFilterClick}
      />
    </Menu>
  );

  useEffect(() => {
    if (!pickedRegistryPeriod) return;
    const [startDate, endDate] = pickedRegistryPeriod;

    if (startDate && endDate) {
      const start: Date = startDate;
      const end: Date = endDate;

      setPickedRegistryStartDate(
        start
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "."),
      );

      setPickedRegistryEndDate(
        end
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "."),
      );
    }
  }, [pickedRegistryPeriod]);

  return (
    <Dropdown
      overlay={periodFilterMenu}
      trigger={["click"]}
      open={dropdownOpen}
      onOpenChange={handleDropdownOpenChange}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {pickedRegistryPeriod.length > 0 ? (
            <>
              {pickedRegistryStartDate} -{pickedRegistryEndDate}
              <FilterCross
                onClick={(
                  event: React.MouseEvent<SVGSVGElement, MouseEvent>,
                ) => {
                  event.stopPropagation();
                  setPickedRegistryPeriod([]);
                  setPickedRegistryStartDate(null);
                  setPickedRegistryEndDate(null);
                }}
              />
            </>
          ) : (
            <>
              Период
              <FilterArrow />
            </>
          )}
        </Space>
      </a>
    </Dropdown>

  );
};

export interface Props {
    className?: string;
}

export const ArrowIcon = ({ className }: Props) => {
     return <>
         <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path
                 d="M7.74417 6.42325L11.3216 10L7.74417 13.5768C7.66676 13.6542 7.60535 13.7461 7.56346 13.8472C7.52156 13.9484 7.5 14.0568 7.5 14.1663C7.5 14.2758 7.52156 14.3842 7.56346 14.4853C7.60535 14.5865 7.66676 14.6784 7.74417 14.7558C7.82158 14.8332 7.91348 14.8946 8.01462 14.9365C8.11576 14.9784 8.22416 15 8.33364 15C8.44311 15 8.55152 14.9784 8.65266 14.9365C8.7538 14.8946 8.8457 14.8332 8.92311 14.7558L13.089 10.5895C13.1664 10.5121 13.2279 10.4202 13.2698 10.3191C13.3118 10.2179 13.3333 10.1095 13.3333 10C13.3333 9.8905 13.3118 9.78207 13.2698 9.68091C13.2279 9.57976 13.1664 9.48786 13.089 9.41047L8.92311 5.24419C8.8457 5.16677 8.7538 5.10536 8.65266 5.06346C8.55152 5.02156 8.44311 5 8.33364 5C8.22416 5 8.11576 5.02156 8.01462 5.06346C7.91348 5.10536 7.82158 5.16677 7.74417 5.24419C7.66676 5.32161 7.60535 5.41352 7.56346 5.51467C7.52156 5.61582 7.5 5.72423 7.5 5.83372C7.5 5.9432 7.52156 6.05162 7.56346 6.15277C7.60535 6.25392 7.66676 6.34583 7.74417 6.42325Z"
                 fill="currentColor"/>
         </svg>
     </>
}
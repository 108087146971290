import { FC } from 'react';

interface IconProps {

}

export const ArrowIcon: FC<IconProps> = () => {
  return (
    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.744167 1.42325L4.32161 5L0.744167 8.57675C0.666757 8.65417 0.605352 8.74608 0.563458 8.84723C0.521564 8.94838 0.5 9.05679 0.5 9.16628C0.5 9.27577 0.521564 9.38418 0.563458 9.48533C0.605352 9.58648 0.666757 9.67839 0.744167 9.75581C0.821577 9.83323 0.913477 9.89464 1.01462 9.93653C1.11576 9.97843 1.22416 10 1.33364 10C1.44311 10 1.55152 9.97843 1.65266 9.93653C1.7538 9.89464 1.8457 9.83323 1.92311 9.75581L6.08897 5.58953C6.16644 5.51214 6.22789 5.42024 6.26982 5.31909C6.31175 5.21793 6.33333 5.1095 6.33333 5C6.33333 4.8905 6.31175 4.78207 6.26982 4.68091C6.22789 4.57976 6.16644 4.48786 6.08897 4.41047L1.92311 0.24419C1.8457 0.166772 1.7538 0.105361 1.65266 0.0634622C1.55152 0.0215639 1.44311 0 1.33364 0C1.22416 0 1.11576 0.0215639 1.01462 0.0634622C0.913477 0.105361 0.821577 0.166772 0.744167 0.24419C0.666757 0.321608 0.605352 0.413517 0.563458 0.514668C0.521564 0.61582 0.5 0.724234 0.5 0.833719C0.5 0.943205 0.521564 1.05162 0.563458 1.15277C0.605352 1.25392 0.666757 1.34583 0.744167 1.42325Z" fill="#98A1B2" />
    </svg>
  );
};

export const InfoIcon: FC<IconProps> = () => {
  const fillColor = "#98A1B2";

  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00065 14.6673C9.8416 14.6673 11.5083 13.9211 12.7147 12.7147C13.9211 11.5083 14.6673 9.8416 14.6673 8.00065C14.6673 6.1597 13.9211 4.49304 12.7147 3.28661C11.5083 2.08018 9.8416 1.33398 8.00065 1.33398C6.1597 1.33398 4.49304 2.08018 3.28661 3.28661C2.08018 4.49304 1.33398 6.1597 1.33398 8.00065C1.33398 9.8416 2.08018 11.5083 3.28661 12.7147C4.49304 13.9211 6.1597 14.6673 8.00065 14.6673Z" stroke={fillColor} stroke-width="1.4" stroke-linejoin="round" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 3.66732C8.45959 3.66732 8.83268 4.04041 8.83268 4.50065C8.83268 4.96089 8.45959 5.33398 7.99935 5.33398C7.53911 5.33398 7.16602 4.96089 7.16602 4.50065C7.16602 4.04041 7.53911 3.66732 7.99935 3.66732Z" fill={fillColor} />
    <path d="M8.16667 11.334V6.66732H7.83333H7.5" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7 11.3333H9.33333" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>;

};

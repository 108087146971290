import React from "react";
import { useStore } from "../../../../store";
import { TableFooter } from "widgets/table-footer";
import { observer } from "mobx-react-lite";

export const TableFooterRegistry = observer(() => {
  const { RootStore } = useStore();
  const {
    RegistryStore: {
      setFirstItem,
      setLastItem,
      firstItem,
      lastItem,
      currentPage,
      setCurrentPage,
      maxOperationsNumber,
      setMaxOperationsNumber,
      registryDocumentsList,
    },
  } = RootStore;

  return (
    <>
      {registryDocumentsList && registryDocumentsList.length > 0 && (
        <TableFooter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          maxOperationsNumber={maxOperationsNumber}
          setMaxOperationsNumber={setMaxOperationsNumber}
          firstItem={firstItem}
          lastItem={lastItem}
          setFirstItem={setFirstItem}
          setLastItem={setLastItem}
          itemsList={registryDocumentsList}
        />
      )}
    </>
  );
});

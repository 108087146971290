import { PopupModal } from "../../../../widgets";
import { DropdownUI } from "../../../../widgets/dropdown";
import { toJS } from "mobx";
import { EInputStyleVariant,EInputVariant,Input } from "../../../../ui";
import React,{ useCallback,useEffect,useMemo,useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { DayPickerNew } from "../../../../widgets/datepickers/day-picker-new";
import { ICategories,IResponsible } from "../../types";
import { Checkbox,Dropdown,Menu } from "antd";
import { DropdownArrow } from "../../icons";
import { Value } from "react-calendar/src/shared/types";
import { InventDocument } from './inventDocument';

export const FillDocumentModal = observer(() => {
  const { RootStore } = useStore();
  const {
    RegistryStore: {
      setFirstRegistryModalIsOpen,
      setSecondRegistryModalIsOpen,
      fetchCashOrderUpdate,
      setPickedRegistryCashContragent,
      setPickedRegistryDeal,
      registrySum,
      registryComment,
      pickedRegistryDeal,
      pickedRegistryCashContragent,
      addedDocumentType,
      setRegistrySum,
      setRegistryComment,
      contragentsList,
      setPickedRegistryContragentId,
      setPickedRegistryDealId,
      dealList,
      shownRegistryArticle,
      setPickedRegistryArticle,
      setShownRegistryArticle,
      articlesList,
      setPickedStatementsContragentId,
      setPickedStatementsDocumentId,
      pickedCategories,
      setPickedCategories,
      responsibleList,
      pickedResponsibleList,
      setPickedResponsible,
      fetchCreateInventory,
    },
    setModalIsActive
  } = RootStore;

  const [date,setDate] = useState<Value>(new Date());

  const onModalSave = useCallback(
    () => {
      addedDocumentType === 3
        ? fetchCreateInventory(date)
        : fetchCashOrderUpdate();
      setModalIsActive(false);
      setSecondRegistryModalIsOpen(false);
    },[addedDocumentType,date]);

  const onModalBackButtonClick = useCallback(() => {
    setFirstRegistryModalIsOpen(true);
    setSecondRegistryModalIsOpen(false);
    setPickedRegistryCashContragent("Выберите контрагента");
    setPickedRegistryDeal("Выберите №");
    setRegistrySum(0);
    setRegistryComment(null);
  },[]);

  const handleModalContragentChange = (contragent: string) => {
    setPickedRegistryCashContragent(contragent);
  };

  //? Неиспользуемая часть. На всякий случай пока не стал удалять

  // const handleCategoriesDropdownClick = (id: string) => {
  //   if (pickedCategories.includes(id)) {
  //     setPickedCategories(
  //       pickedCategories.filter((item: string) => item != id),
  //     );
  //   } else {
  //     setPickedCategories([...pickedCategories,id]);
  //   }
  // };

  // const categoriesFilterMenu = (
  //   <Menu className="contragents">
  //     <p className="title">Выберите товарную категорию (-и)</p>
  //     {categoriesList &&
  //       categoriesList.map((category: ICategories) => (
  //         <Menu.Item
  //           key={category.id}
  //           id={category.id}
  //           title={category.title}
  //           onClick={() => handleCategoriesDropdownClick(category.id)}
  //         >
  //           <div>
  //             <Checkbox
  //               checked={pickedCategories.includes(category.id)}
  //             ></Checkbox>
  //             {category.title}
  //           </div>
  //         </Menu.Item>
  //       ))}
  //   </Menu>
  // );

  // const [visible, setVisible] = useState(false);

  // const handleVisibleChange = (flag: boolean) => {
  //   setVisible(flag);
  // };

  const checkIfButtonIsDisabled = useMemo(() => {
    if (addedDocumentType === 1) {
      return (
        pickedRegistryCashContragent === "Выберите контрагента" ||
        pickedRegistryDeal === "Выберите №" ||
        registrySum === null ||
        registryComment === null
      );
    } else if (addedDocumentType === 2) {
      return registrySum === null || registryComment === null;
    } else if (addedDocumentType === 3) {
      return pickedResponsibleList.length === 0;
    }
  },[addedDocumentType,pickedRegistryCashContragent,pickedRegistryDeal,toJS(pickedResponsibleList),registryComment,registrySum]);

  return (
    <PopupModal
      title={"Заполнение данных"}
      subtitle={"Описание"}
      onSave={onModalSave}
      maxWidth={"440px"}
      isButtonDisabled={checkIfButtonIsDisabled}
      backButton={true}
      onBackButtonClick={onModalBackButtonClick}
    >
      <div
        style={{
          width: "100%",
          padding: "8px 20px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {addedDocumentType === 1 && (
          <>
            <div className="popupModal__element">
              <div className="popupModal__element">
                <div className="label">Статьи</div>
                <DropdownUI
                  shown={shownRegistryArticle}
                  disabled={!addedDocumentType}
                >
                  {articlesList &&
                    articlesList.in.map((value: any) => (
                      <li
                        onClick={() => {
                          setPickedRegistryArticle(value.id);
                          setShownRegistryArticle(value.title);
                        }}
                        id={value.id}
                        key={value.id}
                      >
                        {value.title}
                      </li>
                    ))}
                </DropdownUI>
              </div>
              <div className="label">Контрагент*</div>
              <DropdownUI
                shown={pickedRegistryCashContragent}
                height={"44px"}
                borderRadius={"16px"}
                shownIsPlaceholder={
                  pickedRegistryCashContragent === "Выберите контрагента"
                }
              >
                <>
                  {contragentsList &&
                    contragentsList.map((value: any,index: number) => {
                      var isActive: boolean = false;
                      if (value.title) {
                        if (value.title === pickedRegistryCashContragent) {
                          isActive = true;
                          setPickedStatementsContragentId(
                            `сontragent_id.${value.contragent_id}`,
                          );
                        }
                      } else {
                        if (
                          `${value.surname}  ${value.name}` ===
                          pickedRegistryCashContragent
                        ) {
                          isActive = true;
                          setPickedStatementsContragentId(
                            `user_contragent_id.${value.user_contragent_id}`,
                          );
                        }
                      }
                      const userTitle: string = `${value.surname}  ${value.name}`;

                      return (
                        <li
                          onClick={(event) => {
                            handleModalContragentChange(
                              value.title ? value.title : userTitle,
                            );
                            RootStore.setIsDropdownOpen({
                              ...toJS(RootStore.isDropdownOpen),
                              [1]: false,
                            });
                            setPickedRegistryContragentId(
                              (event.target as HTMLLIElement).id,
                            );
                            setPickedStatementsContragentId(
                              (event.target as HTMLLIElement).id,
                            );
                          }}
                          className={`dropdown-item ${isActive ? "active" : ""
                            }`}
                          key={
                            value.contragent_id
                              ? `сontragent_id.${value.contragent_id}`
                              : `user_contragent_id.${value.user_contragent_id}`
                          }
                          id={
                            value.contragent_id
                              ? `сontragent_id.${value.contragent_id}`
                              : `user_contragent_id.${value.user_contragent_id}`
                          }
                          data-testid={"contragent-elem"}
                        >
                          {value.title ? value.title : userTitle}
                        </li>
                      );
                    })}
                </>
              </DropdownUI>
            </div>
            <div className="popupModal__element">
              <div className="label">№ Договора</div>
              <DropdownUI
                shown={pickedRegistryDeal}
                height={"44px"}
                borderRadius={"16px"}
                shownIsPlaceholder={pickedRegistryDeal === "Выберите №"}
              >
                <>
                  {dealList &&
                    dealList.map((value: any,index: any) => {
                      var isActive;
                      if (value.title === pickedRegistryDeal) {
                        isActive = true;
                        setPickedStatementsDocumentId(value.id.toString());
                      }
                      return (
                        <li
                          data-testid={"deal-elem"}
                          onClick={(event) => {
                            RootStore.setIsDropdownOpen({
                              ...toJS(RootStore.isDropdownOpen),
                              [3]: false,
                            });
                            setPickedRegistryDealId(
                              (event.target as HTMLLIElement).id,
                            );
                            setPickedRegistryDeal(value.title);
                          }}
                          className={`dropdown-item ${isActive ? "active" : ""
                            }`}
                          key={value.id}
                          id={value.id.toString()}
                        >
                          {value.title}
                        </li>
                      );
                    })}
                </>
              </DropdownUI>
            </div>
          </>
        )}
        {addedDocumentType === 2 && (
          <div className="popupModal__element">
            <div className="label">Статьи</div>
            <DropdownUI
              shown={shownRegistryArticle}
              disabled={!addedDocumentType}
            >
              {articlesList &&
                articlesList.out.map((value: any) => (
                  <li
                    onClick={() => {
                      setPickedRegistryArticle(value.id);
                      setShownRegistryArticle(value.title);
                    }}
                    id={value.id}
                    key={value.id}
                  >
                    {value.title}
                  </li>
                ))}
            </DropdownUI>
          </div>
        )}
        {addedDocumentType !== 3 && (
          <>
            <div className="popupModal__element">
              <div className="label">Сумма*</div>
              <Input
                type={EInputVariant.text}
                variant={EInputStyleVariant.basicInput}
                height={"44px"}
                borderRadius={"16px"}
                placeholder={"Впишите сумму"}
                value={registrySum ? registrySum.toString() : ""}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  const { value } = event.target as HTMLInputElement;

                  if (value === "" || /^\d+$/.test(value)) {
                    setRegistrySum(value === "" ? 0 : +value);
                  }
                }}
              />
            </div>

            <div className="popupModal__element">
              <div className="label">Комментарий</div>
              <Input
                type={EInputVariant.text}
                variant={EInputStyleVariant.basicInput}
                height={"44px"}
                borderRadius={"16px"}
                placeholder={"Добавьте описание"}
                value={registryComment ? registryComment : undefined}
                onChange={(e) => setRegistryComment(e.currentTarget.value)}
              />
            </div>
          </>
        )}
        {addedDocumentType === 3 && <InventDocument date={date} setDate={setDate} />}
      </div>
    </PopupModal>
  );
});

import cls from './label-styles.module.scss';
import { FC } from 'react';

interface LabelProps {
  label: React.ReactNode;
  value?: string;
}

export const ColorLabel: FC<LabelProps> = ({ label,value }) => {
  return <div className={cls['label-container']}>
    <div className={`${cls['color-square']} ${value ? '' : cls['with-border']}`} style={{ backgroundColor: value ?? 'transparent' }}></div>
    <span>{typeof label === 'string' ? label : value}</span>
  </div>;
};

export const ColorSquare: FC<LabelProps> = ({ value }) => {
  return <div key={value} className={`${cls['color-square']} ${value ? '' : cls['with-border']}`} style={{ backgroundColor: value ?? 'transparent' }}></div>;
};
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { CreateShiftModal } from './create-shift-modal';
import { EditShiftModal } from './edit-shift-modal';
import { useStore } from 'store';
import { AddHelperModal } from './add-helper-modal';

interface ModalsProps {

}

const ScheduleModalsComponent: FC<ModalsProps> = () => {
  const { RootStore: { SchedulePlanningStore: {
    createShiftIsOpen,editShiftIsOpen,addHelperModalIsOpen,
  } } } = useStore();

  return (<>
    {createShiftIsOpen && <CreateShiftModal />}
    {editShiftIsOpen && <EditShiftModal />}
    {addHelperModalIsOpen && <AddHelperModal />}
  </>
  );
};

export const ScheduleModals = observer(ScheduleModalsComponent);
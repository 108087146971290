import { Dropdown, Menu, Checkbox, Space } from "antd";
import { CloseFilterIcon, FilterArrowIcon } from "pages/money-transactions/icons";
import { useStore } from "store";
import { MenuInfo } from "rc-menu/lib/interface";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

const StatusFilterComponent = () => {
  const {
    RootStore: {
      MoneyTransactionsStore: {
        moneyTransactionStatuses,
        filterStatus,
        setFilterStatus,
        toggleStatuses,
      },
    },
  } = useStore();

  const statusesData = useMemo(
    () =>
      moneyTransactionStatuses
        .filter(status => status.title !== "Удалено")
        .map(item => (
          <Menu.Item key={item.id}>
            <Checkbox checked={filterStatus.includes(item.id)}></Checkbox>
            {item.title}
          </Menu.Item>
        )),
    [filterStatus, moneyTransactionStatuses]
  );

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => (
        <Menu
          onClick={(e: MenuInfo) => {
            const payType = moneyTransactionStatuses[+e.key - 1];
            toggleStatuses(payType.id);
          }}
        >
          {statusesData}
        </Menu>
      )}
    >
      <Space className="cursor-pointer" style={{ gap: "4px" }}>
        {
          <>
            Статус
            {filterStatus.length > 0 ? (
              <CloseFilterIcon onClick={() => setFilterStatus([])} />
            ) : (
              <FilterArrowIcon />
            )}
          </>
        }
      </Space>
    </Dropdown>
  );
};

export const StatusFilter = observer(StatusFilterComponent);

import { FC,ReactNode,useEffect,useMemo } from 'react';
import cls from './styles.module.scss';
interface FailureModalProps {
  title: string;
  onClose: () => void;
  minWidth?: string;
  maxWidth?: string;
  zIndex?: string;
  errors?: ReactNode[];
}

let timer: number;

export const FailureModal: FC<FailureModalProps> = ({ onClose,minWidth = '336px',maxWidth = '600px',title,zIndex,errors }) => {

  useEffect(() => {
    timer = window.setTimeout(onClose,10000);
    return () => { window.clearTimeout(timer); };
  },[]);

  const errorsData = useMemo(() => errors?.map(error => <p className={cls['error-text']}>{error}</p>),[errors]);

  return (
    <div
      className={cls["modal__overlay"]}
      onClick={onClose}
    >
      <div
        className={cls["modal__container"]}
        style={{ minWidth: `${minWidth}`,maxWidth: `${maxWidth}`,zIndex: `${zIndex}` }}
      >
        <div className={cls["modal__notification"]}>
          <div className={cls["modal__notification-icon"]}>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 33C21.4183 33 25.4183 31.2091 28.3137 28.3137C31.2091 25.4183 33 21.4183 33 17C33 12.5817 31.2091 8.58172 28.3137 5.68629C25.4183 2.79086 21.4183 1 17 1C12.5817 1 8.58172 2.79086 5.68629 5.68629C2.79086 8.58172 1 12.5817 1 17C1 21.4183 2.79086 25.4183 5.68629 28.3137C8.58172 31.2091 12.5817 33 17 33Z" stroke="#EB5757" stroke-linejoin="round" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0007 25.6668C17.9211 25.6668 18.6673 24.9206 18.6673 24.0002C18.6673 23.0797 17.9211 22.3335 17.0007 22.3335C16.0802 22.3335 15.334 23.0797 15.334 24.0002C15.334 24.9206 16.0802 25.6668 17.0007 25.6668Z" fill="#EB5757" />
              <path d="M16.9993 9V19.6667" stroke="#EB5757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </div>
          <p className={cls["title"]}>{title}</p>
          {errorsData}
        </div>
      </div>
    </div>
  );
};

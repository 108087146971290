import { FC,ReactNode } from 'react';
import { useScroll } from 'react-use';
import cls from '../styles.module.scss';
import { autoUpdate,offset,shift,useFloating } from '@floating-ui/react';

interface UnitsProps {
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  unitListData: ReactNode[];
}

export const Units: FC<UnitsProps> = ({ parentRef,unitListData }) => {
  const { x } = useScroll(parentRef);

  const { refs,floatingStyles } = useFloating({
    placement: 'left-start',middleware: [shift({ crossAxis: true,mainAxis: false }),
    offset({ mainAxis: 0 })],whileElementsMounted: autoUpdate
  });

  if (x <= 2) return null;

  return (<>
    <div ref={refs.setReference} style={{ position: 'absolute',zIndex: -10,visibility: 'hidden' }}></div>
    <div ref={refs.setFloating} style={floatingStyles} className={`${cls['employees-wrapper']}`}>{unitListData}</div>
  </>
  );
};

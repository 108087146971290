
export interface Props {
    className?: string;
}

export const WarningIcon = ({ className }: Props) => {
    return (
        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M8.00004 15.1654C9.84099 15.1654 11.5077 14.4192 12.7141 13.2127C13.9205 12.0063 14.6667 10.3396 14.6667 8.4987C14.6667 6.65775 13.9205 4.99108 12.7141 3.78465C11.5077 2.57822 9.84099 1.83203 8.00004 1.83203C6.15909 1.83203 4.49242 2.57822 3.286 3.78465C2.07957 4.99108 1.33337 6.65775 1.33337 8.4987C1.33337 10.3396 2.07957 12.0063 3.286 13.2127C4.49242 14.4192 6.15909 15.1654 8.00004 15.1654Z"
                stroke="#EB5757" strokeWidth="1.4" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.99996 12.8346C8.4602 12.8346 8.83329 12.4615 8.83329 12.0013C8.83329 11.5411 8.4602 11.168 7.99996 11.168C7.53972 11.168 7.16663 11.5411 7.16663 12.0013C7.16663 12.4615 7.53972 12.8346 7.99996 12.8346Z"
                  fill="#EB5757"/>
            <path d="M8.00004 4.5V9.83333" stroke="#EB5757" strokeWidth="1.4" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>

    )
}
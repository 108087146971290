import React, {type ReactNode} from 'react';
import styles from './styles.module.scss';
import {Button} from "ui-new/button";
import classNames from "classnames";

export interface Props {
    onClose: () => void;
    children: ReactNode;
    className?: string;
}

export const ModalCard = (props: Props) => {
    const {
        onClose,
        children,
        className,
    } = props;

    return (
        <div className={classNames(styles.modalCard, className)}>
            <div className={styles.closeButtonContainer}>
                <Button theme="empty" onClick={onClose}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M23.8389 9.76829C24.2295 9.37777 24.2295 8.7446 23.8389 8.35408C23.4484 7.96355 22.8153 7.96355 22.4247 8.35408L16.0605 14.7183L9.69637 8.35408C9.30584 7.96355 8.67268 7.96355 8.28215 8.35408C7.89163 8.7446 7.89163 9.37777 8.28215 9.76829L14.6463 16.1325L8.28259 22.4962C7.89207 22.8867 7.89207 23.5199 8.28259 23.9104C8.67312 24.3009 9.30628 24.301 9.69681 23.9104L16.0605 17.5467L22.4243 23.9104C22.8148 24.301 23.448 24.301 23.8385 23.9104C24.229 23.5199 24.229 22.8867 23.8385 22.4962L17.4748 16.1325L23.8389 9.76829Z"
                              fill="black"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M23.8389 9.76829C24.2295 9.37777 24.2295 8.7446 23.8389 8.35408C23.4484 7.96355 22.8153 7.96355 22.4247 8.35408L16.0605 14.7183L9.69637 8.35408C9.30584 7.96355 8.67268 7.96355 8.28215 8.35408C7.89163 8.7446 7.89163 9.37777 8.28215 9.76829L14.6463 16.1325L8.28259 22.4962C7.89207 22.8867 7.89207 23.5199 8.28259 23.9104C8.67312 24.3009 9.30628 24.301 9.69681 23.9104L16.0605 17.5467L22.4243 23.9104C22.8148 24.301 23.448 24.301 23.8385 23.9104C24.229 23.5199 24.229 22.8867 23.8385 22.4962L17.4748 16.1325L23.8389 9.76829Z"
                              fill="black" fill-opacity="0.2"/>
                    </svg>
                </Button>
            </div>
            {children}
        </div>
    );
};
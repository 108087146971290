import React from "react";
import { useNavigate } from "react-router";

import "./styles.scss";
import { Button, EButtonVariant } from "ui";
import { EFontVariant } from "assets/styles/enums";
import { SurfLogoMain } from "./icons";

export const InitPage = () => {
  const navigate = useNavigate();
  return (
    <div className="init__wrapper">
      <div className="init__link">
        <Button
          variant={EButtonVariant.fillButton}
          font={EFontVariant.body2Regular}
          height={"32px"}
          borderRadius={"16px"}
          text="Войти"
          onClick={() => {
            navigate("/login/auth");
          }}
        />
      </div>
      <SurfLogoMain />
    </div>
  );
};

import React from 'react';
import { DropdownUI } from '../../../../widgets/dropdown';
import { toJS } from 'mobx';
import { useStore } from '../../../../store';
import { observer } from 'mobx-react-lite';
import { Article } from '../../types';
import { EInputStyleVariant, EInputVariant, Input } from '../../../../ui';

interface cashPopupModalProps {
  setPickedDealId: (arg0: string | null) => void;
  handleModalContragentChange: (arg0: string) => void;
}

const CashPopupModal = observer(
  ({ setPickedDealId, handleModalContragentChange }: cashPopupModalProps) => {
    const { RootStore } = useStore();
    const {
      RegistryDocumentStore: {
        pickedDeal,
        dealList,
        setPickedDocumentId,
        isNeedContragent,
        setPickedDeal,
        pickedContragent,
        contragentsList,
        setPickedContragentId,
        articlesList,
        pickedArticle,
        setPickedArticle,
        setPickedArticleId,
        pickedArticleId,
        setContragentId,
        setIsNeedContragent
      }
    } = RootStore;

    const onDropdownClick = (value: Article) => {
      setPickedArticleId(value.id);
      setPickedArticle(value.title);
      setIsNeedContragent(value.need_contragent);
      RootStore.setIsDropdownOpen({
        ...toJS(RootStore.isDropdownOpen),
        [2]: false
      });
    };

    return (
      <>
        <div className='popupModal__element'>
          <div className='popupModal__element'>
            <div className='label'>Статья</div>
            <DropdownUI
              shown={pickedArticle}
              height={'44px'}
              borderRadius={'16px'}
            >
              <>
                {articlesList &&
                  articlesList.map((value, index) => {
                    var isActive;
                    if (value.id === pickedArticleId) {
                      isActive = true;
                    }
                    return (
                      <li
                        onClick={() => onDropdownClick(value)}
                        className={`dropdown-item ${isActive ? 'active' : ''}`}
                        key={value.id}
                        id={value.id.toString()}
                      >
                        {value.title}
                      </li>
                    );
                  })}
              </>
            </DropdownUI>
          </div>
          <div className='label'>Контрагент</div>
          <DropdownUI
            shown={pickedContragent}
            height={'44px'}
            borderRadius={'16px'}
          >
            <>
              {contragentsList &&
                contragentsList.map((value, index) => {
                  var isActive: boolean = false;
                  if (value.title) {
                    if (value.title === pickedContragent) {
                      isActive = true;
                      setPickedContragentId(
                        `contragent_id.${value.contragent_id}`
                      );
                    }
                  } else {
                    if (
                      `${value.surname}  ${value.name}` === pickedContragent
                    ) {
                      isActive = true;
                      setPickedContragentId(
                        `user_contragent_id.${value.user_contragent_id}`
                      );
                    }
                  }
                  const userTitle: string = `${value.surname}  ${value.name}`;

                  return (
                    <li
                      onClick={(event) => {
                        handleModalContragentChange(
                          value.title ? value.title : userTitle
                        );
                        RootStore.setIsDropdownOpen({
                          ...toJS(RootStore.isDropdownOpen),
                          [1]: false
                        });
                        setPickedContragentId(
                          (event.target as HTMLLIElement).id
                        );
                        setContragentId(
                          value.contragent_id
                            ? value.contragent_id
                            : value.user_contragent_id
                        );
                        setPickedDealId(null);
                        setPickedDeal("Выберите договор");
                      }}
                      className={`dropdown-item ${isActive ? 'active' : ''}`}
                      key={
                        value.contragent_id
                          ? `contragent_id.${value.contragent_id}`
                          : `user_contragent_id.${value.user_contragent_id}`
                      }
                      id={
                        value.contragent_id
                          ? `non-user_${value.contragent_id}`
                          : `user_${value.user_contragent_id}`
                      }
                      title={value.title ? value.title : userTitle}

                    >
                      {value.title ? value.title : userTitle}
                    </li>
                  );
                })}
            </>
          </DropdownUI>
        </div>
        {isNeedContragent &&
          <div className='popupModal__element'>
            <div className='label'>№ Договора</div>
            {dealList && (
              dealList.length > 1 ? (
                <DropdownUI
                  shown={pickedDeal}
                  height={'44px'}
                  borderRadius={'16px'}
                >
                  <>
                    {
                      dealList.map((value, index) => {
                        var isActive;
                        if (value.title === pickedDeal) {
                          isActive = true;
                          setPickedDocumentId(value.id.toString());
                        }
                        return (
                          <li
                            onClick={(event) => {
                              RootStore.setIsDropdownOpen({
                                ...toJS(RootStore.isDropdownOpen),
                                [3]: false
                              });
                              setPickedDealId((event.target as HTMLLIElement).id);
                              setPickedDeal(value.title);
                            }}
                            className={`dropdown-item ${isActive ? 'active' : ''}`}
                            key={value.id}
                            id={value.id.toString()}
                          >
                            {value.title}
                          </li>
                        );
                      })}
                  </>
                </DropdownUI>) : <Input
                type={EInputVariant.text}
                variant={EInputStyleVariant.basicInput}
                height={'44px'}
                borderRadius={'16px'}
                value={pickedDeal ? pickedDeal : ''}
                disabled
              />)
            }
          </div>
        }
      </>
    )
      ;
  }
);

export default CashPopupModal;

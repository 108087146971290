import "./styles.scss";
import { useRef, useEffect, useState } from "react";
import Calendar from "react-calendar";
import {
  dateToFullDate,
  dropdownEscOrOutsideClickCloser,
} from "../../../assets/utils";
import { CalendarIcon } from "./icons";
import { Value } from "react-calendar/src/shared/types";

interface DayPickerNewProps {
  date: any;
  dateSetter: (newDate: any) => void;
  disabled?: boolean;
}

/** Выпадающий календарь с месяцами  */
export const DayPickerNew = ({
  date,
  dateSetter,
  disabled,
}: DayPickerNewProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dayPickerRef = useRef<HTMLDivElement>(null);

  function handleChange(value: Value) {
    console.log("DayPickerNew handleChange");
    dateSetter(value);
    setIsOpen(false);
  }

  useEffect(() => {
    dropdownEscOrOutsideClickCloser(dayPickerRef, () => setIsOpen(false));
  }, []);
  return (
    <>
      <div className="header-calendar__wrapper day" ref={dayPickerRef}>
        <div
          className={`daypicker ${isOpen && "daypicker_active"} ${
            disabled ? "disabled" : ""
          }`}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {dateToFullDate(date)}
          <CalendarIcon />
        </div>
        <div
          className={
            isOpen
              ? "calendar-modal__wrapper_active day"
              : "calendar-modal__wrapper day"
          }
          style={{ left: "0" }}
        >
          <Calendar
            selectRange={false}
            locale={"ru-RU"}
            view="month"
            minDetail="month"
            maxDetail="month"
            showNavigation={true}
            className={
              isOpen
                ? "header-calendar day header-calendar_active"
                : "header-calendar day"
            }
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

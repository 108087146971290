import { IResponsible } from "pages/registry/types";
import { api } from "./api";

export async function getOperations(
  pickedRegistryStartDate: string | null,
  pickedRegistryEndDate: string | null,
  pickedRegistryStatus: string[],
  pickedRegistryDocument: string[],
  pickedRegistryUserContragent: string[],
  pickedRegistryContragent: any[],
  pickedRegistryResponsible: number[]
) {
  try {
    const response = await api.post(`actions/get`, {
      date_from: pickedRegistryStartDate ? `${pickedRegistryStartDate}` : null,
      date_to: pickedRegistryEndDate ? `${pickedRegistryEndDate}` : null,
      status: pickedRegistryStatus ? pickedRegistryStatus : null,
      type: pickedRegistryDocument ? pickedRegistryDocument : null,
      user_contragent_id:
        pickedRegistryUserContragent.length > 0
          ? pickedRegistryUserContragent
          : null,
      contragent_id:
        pickedRegistryContragent.length > 0 ? pickedRegistryContragent : null,
      responsible_id:
        pickedRegistryResponsible.length > 0 ? pickedRegistryResponsible : null,
    });
    return response;
  } catch (error) {
    return null;
  }
}

// получение всех контрагентов
export async function getContragents() {
  try {
    const response = await api.get(`contragents/documents/get`);
    return response.data.data;
  } catch (error) {
    return null;
  }
}

// получение всех ответственных
export async function getResponsible() {
  try {
    const response = await api.get(`responsible/get`);
    return response.data.data;
  } catch (error) {
    return null;
  }
}

// получение всех статей
export async function getArticles() {
  try {
    const response = await api.get(`docks/cash/articles/get`);
    return response.data.data;
  } catch (error) {
    return null;
  }
}

// изменение данных
export async function updateCashOrder(
  addedDocumentType: number | null,
  pickedRegistryDealId: string | null,
  registrySum: number | null,
  registryComment: string | null,
  pickedRegistryArticle: number | null,
  contragentId: string | null
) {
  try {
    const response = await api.post(`docks/cash/send`, {
      type: addedDocumentType === 1 ? "in" : "out",
      contragent_id: contragentId,
      deal_id: pickedRegistryDealId ? +pickedRegistryDealId : null,
      sum: registrySum ? registrySum : null,
      comment: registryComment ? registryComment : null,
      article_id: pickedRegistryArticle ? pickedRegistryArticle : null,
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

// номера договоров
export async function getDealNumbers() {
  try {
    const response = await api.get(`docks/deals/get`);
    return response.data.data;
  } catch (error) {
    return null;
  }
}

// типы документов
export async function getDocumentTypes() {
  try {
    const response = await api.get(`docks/type`);
    return response.data.data;
  } catch (error) {
    return null;
  }
}

// прочитанно / непрочитанно
export async function readDocument(
  documentId: number,
  documentType: string,
  isRead: boolean
) {
  try {
    await api.post(`docks/read`, {
      document_id: documentId,
      document_type: documentType,
      read: isRead,
    });
  } catch (error) {
    return null;
  }
}

//! Не используется (24.05.24)
// export async function getCategories() {
//   try {
//     const response = await api.get(`docks/inventory/categories`);
//     return response.data.data;
//   } catch (error) {
//     return null;
//   }
// }

export async function checkCreateInventory() {
  try {
    const response = await api.get<{ message?: string; success: boolean }>(
      `docks/inventory/create/check`
    );

    return response.data;
  } catch (error) {
    return null;
  }
}

export async function createInventory(
  date: string,
  responsibleIds: Array<IResponsible["id"]>
) {
  try {
    const response = await api.post(`docks/inventory/create`, {
      date: date,
      responsible: responsibleIds,
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

import { makeAutoObservable } from "mobx";
import { IActionType, IStatusBarType } from "./types";
import { fetchCloseMonth, fetchStatusCloseMonth } from "api/finance";
import { Value } from "react-calendar/src/shared/types";
import { RootStore } from "../../store/rootStore";

export class FinanceStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  pickedDate: Value = new Date();
  isMonthNotAvailable: boolean = false;

  setPickedDate = (val: Value) => {
    this.pickedDate = val;
    this.isMonthNotAvailable = val !== null && val > new Date();
  };

  ECurrentStatusType = {
    FIRST_STATUS: {
      month: "active",
      closing: null,
      result: null,
    },
    SECOND_STATUS: {
      month: "done",
      closing: "active",
      result: null,
    },
    THIRD_STATUS: {
      month: "done",
      closing: "done",
      result: "active",
    },
  };

  EActionType = {
    MONTH: {
      subtitle: "Выберите месяц, по которому рассчитать финансовый результат.",
      isCalendarActive: true,
      isCloseButtonActive: true,
    },
    CLOSING: {
      isPending: true,
    },
  };

  currentStatusBarType: IStatusBarType = this.ECurrentStatusType.FIRST_STATUS;

  setCurrentStatusBarType = (val: IStatusBarType) => {
    this.currentStatusBarType = val;
  };

  currentAction: IActionType = this.EActionType.MONTH;

  setCurrentAction = (val: IActionType) => {
    this.currentAction = val;
  };

  fetchStatusMonth = async () => {
    const response = await fetchStatusCloseMonth(
      (this.pickedDate as Date).toLocaleDateString("ru-RU", {
        month: "2-digit",
        year: "numeric",
      }),
    );
    if (response.data?.status === "Completed") {
      this.setCurrentStatusBarType(this.ECurrentStatusType.THIRD_STATUS);
      this.setCurrentAction({
        title: `${
          (this.pickedDate as Date)
            .toLocaleDateString("ru-RU", { month: "long" })
            .charAt(0)
            .toUpperCase() +
          (this.pickedDate as Date)
            .toLocaleDateString("ru-RU", { month: "long" })
            .slice(1)
        } успешно закрыт!`,
        subtitle:
          "Более подробную информацию Вы сможете посмотреть в отчетах о финансовых результатах (ОФР).",
        isChangeButtonActive: true,
        isMoveButtonActive: true,
        isCalendarActive: false,
      });
    }
    if (response.data?.status === "Active") {
      setTimeout(() => this.fetchStatusMonth(), 1000);
    }
    if (
      response.data?.status === "Failed" ||
      response.data?.status === "Canceled"
    ) {
      this.setCurrentStatusBarType(this.ECurrentStatusType.THIRD_STATUS);
      this.setCurrentAction({
        title: "Ошибка!",
        subtitle: "Что-то пошло не так, попробуйте еще раз. ",
        isChangeButtonActive: true,
        isCalendarActive: false,
      });
    }
  };

  fetchFinance = async () => {
    const response = await fetchCloseMonth(
      (this.pickedDate as Date).toLocaleDateString("ru-RU", {
        month: "2-digit",
        year: "numeric",
      }),
    );

    if (response) {
      if (response.success) {
        this.fetchStatusMonth();
      } else if (
        !response.success &&
        response.data &&
        response.data.status === "error"
      ) {
        this.setCurrentStatusBarType(this.ECurrentStatusType.THIRD_STATUS);
        if (!this.pickedDate) return;
        this.setCurrentAction({
          title: `Не удалось закрыть ${
            (this.pickedDate as Date)
              .toLocaleDateString("ru-RU", { month: "long" })
              .charAt(0)
              .toUpperCase() +
            (this.pickedDate as Date)
              .toLocaleDateString("ru-RU", { month: "long" })
              .slice(1)
          }`,
          subtitle: `Не закрыты предыдущие периоды. Закройте периоды с ${response.data.actual_date}`,
          isChangeButtonActive: true,
          isCalendarActive: false,
        });
      } else if (!response.success && response.data === null) {
        this.setCurrentStatusBarType(this.ECurrentStatusType.THIRD_STATUS);
        this.setCurrentAction({
          title: "Ошибка!",
          subtitle: "Что-то пошло не так, попробуйте еще раз. ",
          isChangeButtonActive: true,
          isCalendarActive: false,
        });
      }
    } else {
      this.setCurrentAction(this.EActionType.MONTH);
      this.setCurrentStatusBarType(this.ECurrentStatusType.FIRST_STATUS);
    }
  };
}

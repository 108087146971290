import { FC,useCallback,useMemo } from 'react';
import cls from '../styles.module.scss';
import { ModalButtons } from 'widgets/modal/buttons';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { ITemplate } from 'pages/spot/schedule-planning-new/types';
import * as yup from 'yup';
import { toJS } from 'mobx';
import { useStore } from 'store';
import { yupResolver } from '@hookform/resolvers/yup';
import { Template } from 'pages/spot/schedule-planning-new/templates/template';
import { format } from 'date-fns';
import { showAlertPopup } from 'ui/alert';
import { showNotificationModal } from 'ui-new/alert';

type FormValues = {
  template_id: ITemplate['id'];
};

const schema = yup.object({
  template_id: yup.number().required()
});

interface CreateByTemplateProps {
  onClose: () => void;
}

const CreateByTemplateComponent: FC<CreateByTemplateProps> = ({ onClose }) => {
  const {
    RootStore: {
      SchedulePlanningStore: {
        templates,
        createShiftDate,
        fetchCreateShift,
        selectedWorker
      },
    },
  } = useStore();

  const { handleSubmit,setValue,watch } = useForm<FormValues>({ resolver: yupResolver<FormValues>(schema),mode: 'onChange' });

  const template_id = watch('template_id');

  const templatesData = useMemo(() => {
    return toJS(templates).map((template: ITemplate) => {
      const isSelected = template_id === template.id;

      return <div key={template.id} onClick={() => setValue('template_id',template.id)}>
        <Template isNotEditable template={template}
          className={`${cls['shift-create__template']} ${isSelected ? cls['shift-create__template-selected'] : ''}`}
        />
      </div>;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[template_id,toJS(templates)]);

  const onSave = useCallback(({ template_id }: FormValues) => {
    if (!createShiftDate || !selectedWorker) {
      onClose();
      showNotificationModal({ title: 'Упс! Что-то пошло не так',errors: ['Попробуйте создать еще раз'],type: 'failure' });
      return;
    }
    const date = format(createShiftDate,'yyyy-MM-dd');
    fetchCreateShift({ template_id,date,user_id: selectedWorker.id });
  },[createShiftDate,fetchCreateShift,onClose,toJS(selectedWorker)]);

  const onErrorHandler = useCallback(() =>
    showNotificationModal({ title: 'Ошибка',type: 'failure',errors: ['Для добавления смены выберите шаблон'] }),[]);

  return (
    <div className={cls['shift-create__form']}>
      <div className={cls['templates__list']}>
        {templatesData}
      </div>
      <ModalButtons className={cls['buttons-group']} primaryButtonLabel={'Добавить'} onPrimaryButtonClick={handleSubmit(onSave,onErrorHandler)}
        secondaryButtonLabel={'Отменить'} onSecondaryButtonClick={onClose} />
    </div>
  );
};

export const CreateByTemplate = observer(CreateByTemplateComponent);
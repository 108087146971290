import React from "react";
import "./styles.scss";
import { EFontVariant } from "assets/styles/enums";
import { observer } from "mobx-react-lite";

export enum EInputStyleVariant {
  basicInput = "basicInput",
  basicInputError = "basicInputError",
  searchInput = "searchInput",
}

export enum EInputVariant {
  text = "text",
  password = "password",
  time = "time",
}

interface IInputType {
  type?: EInputVariant;
  variant?: EInputStyleVariant;
  placeholder?: string;
  maxWidth?: string;
  height?: string;
  font?: EFontVariant;
  borderRadius?: string;
  value?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  width?: string;
  required?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: () => void;
  onBlur?: () => void;
  dataDate?: string;
  dataLocation?: string;
  className?: string;
  pattern?: string;
  name?: string;
  withReset?: boolean;
  onReset?: () => void;
  maxLength?: number;
  min?: string;
  max?: string;
}

export const Input: React.FC<IInputType> = observer(
  ({
    type,
    variant,
    placeholder,
    font,
    maxWidth,
    width,
    height,
    borderRadius,
    value,
    autoFocus,
    disabled,
    required,
    onChange,
    onKeyDown,
    onBlur,
    dataDate,
    dataLocation,
    className,
    onInput,
    pattern,
    name,
    withReset,
    onReset,
    maxLength,
    min,
    max,
  }) => {
    return withReset ? (
      <div className="inputField" style={{ maxWidth: maxWidth }}>
        <input
          data-date={dataDate}
          data-location={dataLocation}
          name={dataDate ? dataDate : name}
          value={value}
          onChange={onChange}
          onInput={onInput}
          onKeyDown={onKeyDown}
          type={type}
          placeholder={placeholder}
          className={`${variant} ${font} ${className} `}
          style={{
            maxWidth: maxWidth,
            height: height,
            borderRadius: borderRadius,
            width: width,
          }}
          autoFocus={autoFocus}
          disabled={disabled}
          required={required}
          onBlur={onBlur}
          pattern={pattern}
          maxLength={maxLength}
          min={min}
          max={max}
        />

        {value && (
          <svg
            className="resetButton"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onReset}
          >
            <g id="Icon / close-filled">
              <path
                id="Vector"
                d="M12 3C6.98571 3 3 6.98571 3 12C3 17.0143 6.98571 21 12 21C17.0143 21 21 17.0143 21 12C21 6.98571 17.0143 3 12 3ZM15.4714 16.5L12 13.0286L8.52857 16.5L7.5 15.4714L10.9714 12L7.5 8.52857L8.52857 7.5L12 10.9714L15.4714 7.5L16.5 8.52857L13.0286 12L16.5 15.4714L15.4714 16.5Z"
                fill="#A6AEBB"
              />
            </g>
          </svg>
        )}
      </div>
    ) : (
      <input
        data-date={dataDate}
        data-location={dataLocation}
        name={dataDate ? dataDate : name}
        value={value}
        onChange={onChange}
        onInput={onInput}
        onKeyDown={onKeyDown}
        type={type}
        placeholder={placeholder}
        className={`${variant} ${font} ${className} `}
        style={{
          maxWidth: maxWidth,
          height: height,
          borderRadius: borderRadius,
        }}
        autoFocus={autoFocus}
        disabled={disabled}
        required={required}
        onBlur={onBlur}
        pattern={pattern}
        maxLength={maxLength}
        min={min}
        max={max}
      />
    );
  },
);

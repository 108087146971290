import { FC,useCallback,useEffect,useState } from 'react';
import Select,{ DefaultOptionType } from 'antd/es/select';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { defaultRound } from 'assets/utils';
import { IInventoryPositon,IInventoryReport,UpdatePositionTypes } from 'pages/registry-document/types';
import { showConfirmAlertPopup } from '../../../../ui/confirm-alert';
import { BucketIcon } from '../../icons';
import InventoryValueInput from './inventoryValueInput';
interface IInventoryPositionComponentProps {
  positionIndex: number;
  position: IInventoryPositon;

  nomenclaturesData?: JSX.Element[];
}


const nomenclatureSelectFilter = (input: string,option?: DefaultOptionType) =>
  (typeof option?.label === 'string' ? option.label.toLowerCase() : '')
    .includes(input.toLowerCase());

const InventoryPositionComponent: FC<IInventoryPositionComponentProps> = ({ positionIndex,position,nomenclaturesData }) => {
  const { RootStore: { RegistryDocumentStore: {
    advanceReport,
    fetchDeletePosition,
    setAdvanceReport,
    nomenclaturesList,
    setPickedNomenclatureId,
    pickedIdx,
    selectedIdx,
    updatePosition,
    setPickedIdx,
    setSelectedIdx,
    setPickedPositionId,
  } } } = useStore();

  const [isPendingStatus,setIsPendingStatus] = useState(false);
  useEffect(() => {
    setIsPendingStatus((advanceReport as IInventoryReport).status === 'pending');
  },[toJS(advanceReport)]);

  //* indexModifier - модификатор для стилей ред. полей (указаны в className) (редактируемые поля: номенклатура +.1, ед.изм +.2, кол-во факт, +.3) 
  const handleTableElementClick = useCallback((
    event: React.MouseEvent<HTMLDivElement>,
    indexModifier?: number
  ) => {
    if (!isPendingStatus) return;

    const index = positionIndex + (indexModifier ?? .1);

    if (event.detail === 1) {
      if (index === selectedIdx) {
        setPickedIdx(index);
      } else {
        setSelectedIdx(index);
        setPickedIdx(-1);
      }
    }

    if (event.detail === 2) {
      if (index === pickedIdx) {
        setPickedIdx(-1);
      } else {
        setPickedIdx(index);
      }
    }
    setPickedPositionId(position.id);
  },[toJS(advanceReport as IInventoryReport).status,pickedIdx,selectedIdx,position.id,positionIndex,isPendingStatus]);

  const onFactCountClick = useCallback((event: React.MouseEvent<HTMLDivElement,MouseEvent>) => {
    event.stopPropagation();
    handleTableElementClick(event,.3);
  },[handleTableElementClick]);

  const onDeleteHandler = useCallback(() => {
    showConfirmAlertPopup(
      'Подвердите действие',
      `Вы уверены, что хотите удалить позицию <strong>${position.name ?? ''}</strong> из списка?`,
      () => {
      },
      () => fetchDeletePosition(position),
      true
    );
  },[position]);

  const onNomenclatureSelectChange = useCallback((value: string) => {
    setPickedNomenclatureId(value);

    const updatedPositions = [...toJS(advanceReport).positions];
    const selectedNomenclature = nomenclaturesList?.find(
      (nom) => nom.value === value
    );
    if (selectedNomenclature) {
      updatedPositions[positionIndex].name = selectedNomenclature.label;
      updatedPositions[positionIndex].unit = selectedNomenclature.unit;
    }
    setAdvanceReport({
      ...toJS(advanceReport),
      positions: updatedPositions
    });
    updatePosition(position.id,UpdatePositionTypes.NOMENCLATURE_ID,value);
  },[toJS(advanceReport),toJS(nomenclaturesList),position.id,positionIndex,]);

  return <div className='registry-document__inv-table__body__row'>
    <div className='registry-document__inv-table__body__row__element'>
      {positionIndex + 1}
    </div>
    <div
      className={`registry-document__inv-table__body__row__element editable ${selectedIdx === positionIndex + 0.1 ? 'selected' : ''
        } ${pickedIdx === positionIndex + 0.1 ? 'picked' : ''} ${position.name ? 'picked' : 'non-picked'
        }`}
      onClick={handleTableElementClick}
    >
      {isPendingStatus &&
        <Select
          className={`search-select ${position.name ? 'picked' : 'non-picked'
            }`}
          showSearch
          placeholder={position.name !== null ? position.name : 'Выберите номенклатуру'}
          optionFilterProp='children'
          onChange={onNomenclatureSelectChange}
          filterOption={nomenclatureSelectFilter}
        >
          {nomenclaturesData}
        </Select>
      }
      {position.name !== null ? position.name : 'Выберите номенклатуру'}
    </div>
    <div
      className={`registry-document__inv-table__body__row__element editable ${selectedIdx === positionIndex + 0.2 ? 'selected' : ''
        } ${pickedIdx === positionIndex + 0.2 ? 'picked' : ''} ${position.name ? 'picked' : 'non-picked'
        }`}
    >
      {position.unit}
    </div>
    <div className='registry-document__inv-table__body__row__element'>
      {position.value}
    </div>
    <div
      className={`registry-document__inv-table__body__row__element editable ${selectedIdx === positionIndex + 0.3 ? 'selected' : ''
        } ${pickedIdx === positionIndex + 0.3 ? 'picked' : ''} ${position.name ? 'picked' : 'non-picked'
        }`}
      onClick={onFactCountClick}
    >
      {isPendingStatus &&
        <InventoryValueInput val={position} index={positionIndex} />
      }
      {position.value_fact !== null ? position.value_fact : ''}
    </div>
    <div className='registry-document__inv-table__body__row__element'>
      {position.value - position.value_fact}
    </div>
    <div className='registry-document__inv-table__body__row__element'>{position.cost}</div>
    <div className='registry-document__inv-table__body__row__element'>{position.sum}</div>
    <div className='registry-document__inv-table__body__row__element'>
      {position.sum_fact ? position.sum_fact : defaultRound(position.value_fact * position.cost)}
    </div>
    <div
      className='registry-document__inv-table__body__row__element'
      onClick={onDeleteHandler}
    >
      {isPendingStatus && <BucketIcon />}
    </div>
  </div>;
};

export default observer(InventoryPositionComponent);
import { api } from "./api";

export async function getRevenueData(selectedDate: Date) {
  try {
    const response = await api.post(`createRevenue`, {
      date: `${String(selectedDate.getMonth() + 1).padStart(
        2,
        "0",
      )}.${selectedDate.getFullYear()}`,
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function getSave(categories: any[], revenueBalance: any[]) {
  try {
    const response = await api.post(`revenue/refresh`, {
      categories: categories,
      revenue: revenueBalance,
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function getPlan(categories: any[], revenueBalance: any[]) {
  try {
    const response = await api.post(`saveRevenue`, {
      categories: categories,
      revenue: revenueBalance,
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./styles.scss";

interface BigSkeletonType {
  padding?: string;
}

export const BigSkeleton: React.FC<BigSkeletonType> = ({ padding }) => {
  return (
    <div
      className="registry-table-skeleton"
      style={{
        width: "100%",
        height: " calc(90vh - 200px)",
        padding: padding,
      }}
    >
      <SkeletonTheme duration={2} baseColor="#bcbcbc" highlightColor="#dddddd">
        <div
          style={{
            height: "100%",
            overflow: "hidden",
            padding: "0",
          }}
        >
          <Skeleton height={"100%"} borderRadius={16} />
        </div>
      </SkeletonTheme>
    </div>
  );
};

import { Operation } from "../../types";
import { EmptyTableBody } from "../../../../ui/empty-table-body";
import React from "react";
import { useStore } from "../../../../store";
import TableElement from "../tableElement/tableElement";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

interface TableBodyProps {
  searchValue: string | null;
  searchList: Operation[];
  firstItem?: number;
  lastItem?: number;
}

export const TableBody = observer(
  ({ searchValue, searchList, firstItem, lastItem }: TableBodyProps) => {
    const { RootStore } = useStore();
    const {
      RegistryStore: { operationsList, registryDocumentsList },
    } = RootStore;

    return (
      <div className="registry-table__body">
        {registryDocumentsList && registryDocumentsList.length > 0 ? (
          registryDocumentsList
            .slice(firstItem, lastItem)
            .map((operation: Operation) => {
              return <TableElement operation={toJS(operation)} />;
            })
        ) : (
          <EmptyTableBody />
        )}
      </div>
    );
  },
);

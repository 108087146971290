export const NotificationsIcon = ({ unread }: { unread: boolean }) => {
  return unread ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9231 12.749V10.9655H17.5385V13.0345C17.5385 13.2174 17.6115 13.3928 17.7413 13.5221L19.6154 15.389V16.4828H4.38462V15.389L6.25869 13.5221C6.38853 13.3928 6.4615 13.2174 6.46154 13.0345V10.2759C6.45961 9.30675 6.71444 8.3543 7.20029 7.5147C7.68614 6.6751 8.38581 5.97809 9.22862 5.49407C10.0714 5.01005 11.0275 4.75617 12.0004 4.75805C12.9732 4.75994 13.9283 5.01754 14.7692 5.50483V3.96345C14.1102 3.67277 13.4093 3.48751 12.6923 3.41448V2H11.3077V3.41379C9.60086 3.58685 8.01906 4.38422 6.86818 5.65171C5.71729 6.91921 5.07907 8.56678 5.07692 10.2759V12.749L3.20285 14.6159C3.073 14.7452 3.00004 14.9206 3 15.1034V17.1724C3 17.3553 3.07294 17.5307 3.20277 17.6601C3.33261 17.7894 3.5087 17.8621 3.69231 17.8621H8.53846V18.5517C8.53846 19.4663 8.90316 20.3433 9.55232 20.99C10.2015 21.6367 11.0819 22 12 22C12.9181 22 13.7985 21.6367 14.4477 20.99C15.0968 20.3433 15.4615 19.4663 15.4615 18.5517V17.8621H20.3077C20.4913 17.8621 20.6674 17.7894 20.7972 17.6601C20.9271 17.5307 21 17.3553 21 17.1724V15.1034C21 14.9206 20.927 14.7452 20.7972 14.6159L18.9231 12.749ZM14.0769 18.5517C14.0769 19.1004 13.8581 19.6267 13.4686 20.0147C13.0791 20.4027 12.5508 20.6207 12 20.6207C11.4492 20.6207 10.9209 20.4027 10.5314 20.0147C10.1419 19.6267 9.92308 19.1004 9.92308 18.5517V17.8621H14.0769V18.5517Z"
        fill="#272729"
      />
      <path
        d="M19 9C20.3807 9 21.5 7.88071 21.5 6.5C21.5 5.11929 20.3807 4 19 4C17.6193 4 16.5 5.11929 16.5 6.5C16.5 7.88071 17.6193 9 19 9Z"
        fill="#EE4C3E"
      />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7972 14.6157L18.9231 12.7488V10.2757C18.9209 8.56669 18.2827 6.91913 17.1318 5.65166C15.9809 4.38418 14.3991 3.58683 12.6923 3.41377V2H11.3077V3.41377C9.60086 3.58683 8.01906 4.38418 6.86818 5.65166C5.71729 6.91913 5.07907 8.56669 5.07692 10.2757V12.7488L3.20285 14.6157C3.073 14.745 3.00004 14.9204 3 15.1033V17.1722C3 17.3551 3.07294 17.5305 3.20277 17.6598C3.33261 17.7892 3.5087 17.8618 3.69231 17.8618H8.53846V18.3977C8.5234 19.2726 8.83297 20.1223 9.40785 20.7839C9.98273 21.4455 10.7825 21.8725 11.6538 21.9831C12.1351 22.0307 12.6211 21.9774 13.0804 21.8266C13.5398 21.6758 13.9623 21.431 14.321 21.1077C14.6796 20.7845 14.9664 20.3901 15.1628 19.9498C15.3592 19.5096 15.461 19.0333 15.4615 18.5515V17.8618H20.3077C20.4913 17.8618 20.6674 17.7892 20.7972 17.6598C20.9271 17.5305 21 17.3551 21 17.1722V15.1033C21 14.9204 20.927 14.745 20.7972 14.6157ZM14.0769 18.5515C14.0769 19.1002 13.8581 19.6264 13.4686 20.0144C13.0791 20.4024 12.5508 20.6204 12 20.6204C11.4492 20.6204 10.9209 20.4024 10.5314 20.0144C10.1419 19.6264 9.92308 19.1002 9.92308 18.5515V17.8618H14.0769V18.5515ZM19.6154 16.4825H4.38462V15.3888L6.25869 13.5219C6.38853 13.3926 6.4615 13.2172 6.46154 13.0343V10.2757C6.46154 8.8125 7.04505 7.40919 8.08372 6.37452C9.12238 5.33985 10.5311 4.75858 12 4.75858C13.4689 4.75858 14.8776 5.33985 15.9163 6.37452C16.9549 7.40919 17.5385 8.8125 17.5385 10.2757V13.0343C17.5385 13.2172 17.6115 13.3926 17.7413 13.5219L19.6154 15.3888V16.4825Z"
        fill="#212129"
      />
    </svg>
  );
};

import React, {memo, useEffect} from 'react';
import "pages/registry-document/customSelectOption.scss";
import "../ManualInputTable/ManualInputTable.scss";
import classNames from "classnames";
import {isDeepEqual} from "../../../../../assets/utils";
import type {ResponseAccount, ResponseSubconto, ResponseTransaction} from "../../../types";

interface Props {
    transaction: ResponseTransaction;
    index: number;
}

const formatAccount = (account: ResponseAccount) => {
    return `${account.code} ${account.work_name}`;
}

const formatSubconto = (subconto: ResponseSubconto) => {
    return subconto?.subconto_value?.work_name;
}

const TheManualInputTableRowNoEditable = (props: Props) => {
    const {
        transaction,
        index,
    } = props;

    return (
        <tr>
            <td colSpan={6}>
                <table className="table">
                    <tbody>
                    <tr className="bodyRow">
                        <td className={classNames("bodyColumn", "numberColumn")}>
                            <span className="rowNumberText" style={{ paddingLeft: '6px' }}>{index + 1}</span>
                        </td>
                        <td className={classNames("bodyColumn", "nameColumn", "no-editable-cell")}>
                            {formatAccount(transaction.debit_account)}
                        </td>
                        <td className={classNames("bodyColumn", "countColumn", "no-editable-cell")}>
                            {Number(transaction.debit_quantity)}
                        </td>
                        <td className={classNames("bodyColumn", "nameColumn", "no-editable-cell")}>
                            {formatAccount(transaction.credit_account)}
                        </td>
                        <td className={classNames("bodyColumn", "countColumn", "no-editable-cell", "disabledCell")}>
                            0
                        </td>
                        <td className={classNames("bodyColumn", "sumColumn", "no-editable-cell")}>
                            {Number(transaction.amount)}
                        </td>
                    </tr>
                    <tr className={"subkontoRow"}>
                        <td colSpan={6}>
                            <table className={"subkontoTable"}>
                                <tbody>
                                <tr>
                                    <td className={classNames("subkontoCell", "subkontoCellFirst", "no-editable-cell")}>
                                        {formatSubconto(transaction.debit_subconto[0])}
                                    </td>
                                    <td className={classNames("subkontoCell", "subkontoCellSecond", "no-editable-cell")}>
                                        {formatSubconto(transaction.credit_subconto[0])}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={classNames("subkontoCell", "subkontoCellFirst", "no-editable-cell")}>
                                        {formatSubconto(transaction.debit_subconto[1])}
                                    </td>
                                    <td className={classNames("subkontoCell", "subkontoCellSecond", "no-editable-cell")}>
                                        {formatSubconto(transaction.credit_subconto[1])}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={classNames("subkontoCell", "subkontoCellFirst", "no-editable-cell")}>
                                        {formatSubconto(transaction.debit_subconto[2])}
                                    </td>
                                    <td className={classNames("subkontoCell", "subkontoCellSecond", "no-editable-cell")}>
                                        {formatSubconto(transaction.credit_subconto[2])}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    );
};

export const ManualInputTableRowNoEditable = memo(
    TheManualInputTableRowNoEditable, (prev, next) => isDeepEqual(prev, next)
);
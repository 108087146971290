import { RootStore } from "store/rootStore";
import { Nullable } from "assets/types";
import { IEmploymentPeriod, RoleUserData } from "pages/access-rights/types";
import { GetProfileFetch } from "api/header";
import { makeAutoObservable } from "mobx";
import {
  getEmployees,
  getEmploymentPeriods,
  getEmploymentTypes,
  IEmployeeInfoUpdate,
  IEmploymentTypeUpdate,
  updateEmployeeInfo,
  updateEmploymentType,
} from "api/accessRights";
import { showConfirmModal } from "ui-new/alert/confirm";
import { IUser } from "pages/salary-fund/types";
import { showNotificationModal } from "ui-new/alert";

export class RoleStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  users: IUser[] = [];

  spotId: Nullable<number> = null;
  userId: Nullable<number> = null;
  name: Nullable<string> = null;
  surname: Nullable<string> = null;
  email: Nullable<string> = null;

  employmentTypes: { [key: number]: string } = {};
  employmentPeriods: IEmploymentPeriod[] = [];

  setUsers = (users: IUser[]) => {
    this.users = users;
  };
  setUserData = (userData: RoleUserData) => {
    this.name = userData.name;
    this.surname = userData.surname;
    this.email = userData.email;
    this.userId = userData.id;
    this.spotId = userData.spot_id;
  };

  fetchGetEmployees = async () => {
    const response = await getEmployees();
    if (!response) return;
    this.setUsers(response);
  };

  fetchUserData = async () => {
    const response = await GetProfileFetch();

    if (response?.data.data) {
      this.setUserData({
        id: response?.data.data.id,
        spot_id: response?.data.data.spot_id,
        email: response?.data.data.email,
        name: response?.data.data.name,
        surname: response?.data.data.surname,
      });
    }
  };

  fetchGetEmploymentTypes = async () => {
    const response = await getEmploymentTypes();
    if (!response) return;

    this.employmentTypes = response;
  };

  fetchGetEmploymentPeriods = async () => {
    const response = await getEmploymentPeriods();
    if (!response) return;

    this.employmentPeriods = response;
  };

  updateEmploymentConfirm = (
    params: IEmploymentTypeUpdate,
    onConfirm: () => void
  ) => {
    showConfirmModal({
      title: "Сохранить изменения?",
      messages: [
        "Вознаграждения работника за выбранный период будут пересчитаны",
      ],
      minWidth: "375px",
      confirmButtonLabel: "Да, сохранить",
      onConfirm: () => this.fetchUpdateEmploymentType(params).then(onConfirm),
    });
  };
  private fetchUpdateEmploymentType = async (params: IEmploymentTypeUpdate) => {
    const response = await updateEmploymentType(params);
    if (!response) return;

    this.fetchGetEmployees();
    showNotificationModal({ title: "Изменения сохранены", type: "success" });
  };

  updateEmployeeInfoConfirm = (
    params: IEmployeeInfoUpdate,
    onConfirm: () => void
  ) => {
    showConfirmModal({
      title: "Сохранить изменения",
      messages: ["Данные работника будут обновлены"],
      minWidth: "375px",
      confirmButtonLabel: "Да, сохранить",
      onConfirm: () => this.fetchUpdateEmployeeInfo(params).then(onConfirm),
    });
  };

  private fetchUpdateEmployeeInfo = async (params: IEmployeeInfoUpdate) => {
    const response = await updateEmployeeInfo(params);
    if (!response) return;

    this.fetchGetEmployees();
    showNotificationModal({ title: "Изменения сохранены", type: "success" });
  };
}

import type { ReactNode } from 'react';
import cls from './styles.module.scss';
import {DocumentHeaderSkeleton} from "./skeleton";

export interface HeaderItem {
    title: string;
    value?: number | string | null;
}

export interface Props {
    items: HeaderItem[];
    isLoading?: boolean;
    children?: ReactNode | ReactNode[];
}

export const DocumentHeader = (props: Props) => {

    const { items, children } = props;

    if (props?.isLoading) {
        return <DocumentHeaderSkeleton />
    }

    return (
        <header className={cls.documentHeader}>
            <ul className={cls.documentHeaderList}>
                {items.map((item, index) => (
                    <li className={cls.columnItem} key={index}>
                        <span className={cls.columnItemTitle}>{item.title}</span>
                        <span className={cls.columnItemValue}>{item.value ? item.value : '—'}</span>
                    </li>
                ))}
            </ul>

            <div className={cls.buttonsContainer}>
                {children}
            </div>
        </header>
    )
}
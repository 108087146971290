import {
  fetchNotifications,
  readAllNotifications,
  readSingleNotification,
} from "api/notifications";
import { makeAutoObservable } from "mobx";
import { Notification } from "./types";
import { RootStore } from "../../store/rootStore";

export class NotificationsStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  listNTF: Notification[] = [];
  setListNTF = (data: Notification[]) => {
    this.listNTF = data;
  };
  getAllNTF = async () => {
    try {
      const response = await fetchNotifications();
      if (response?.data.data) {
        this.setListNTF(response.data.data);
      } else this.setListNTF([]);
    } catch (e) {
      console.log("getAllNTF error:");
      console.log(e);
    }
  };
  readSingleNTF = async (id: number) => {
    readSingleNotification(id).then(() => this.getAllNTF());
  };
  readAllNTF = () => {
    readAllNotifications();
    this.getAllNTF();
  };
  get anyUnreadNotif(): boolean {
    if (this.listNTF.length > 0) {
      return this.listNTF.some((notif: Notification) => notif.unread === true);
    } else return false;
  }
}

import React, {useState} from 'react';
import cls from "../ManualInputTable/ManualInputTable.module.scss";
import classNames from "classnames";
import {tableHeader} from "../../../const";

export const ManualInputTableHeader = () => {

    const [header, setHeader] = useState([...tableHeader]);

    return (
        <thead className={cls.thead}>
            <tr>
                {header.map((item) => {
                    return (
                        <th className={classNames(cls.th, cls[item.className])}>
                            {item.label}
                        </th>
                    )
                })}
            </tr>
        </thead>
    );
};
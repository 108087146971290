import { subDays, subMonths } from "date-fns";
import { makeAutoObservable, toJS } from "mobx";
import { Value } from "react-calendar/src/shared/types";
import { RootStore } from "../../store/rootStore";
import {
  getListOfManualOperations,
  getSubcontoTypesOfManualOperations,
  getViewOfManualOperations,
} from "../../api/operationInput";
import { InputSortOrder, InputSortType, Operation } from "./types";
import { sortRegistryDocument } from "../registry/utils";
import { sortManualOperation } from "./utils";

export class OperationInputStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  periodStart: Value = subMonths(new Date(), 1);
  periodEnd: Value = subDays(new Date(), 0);

  pickedSortOrder: InputSortOrder | null = null;
  pickedSortType: InputSortType | null = null;
  searchValue: string | null = null;

  currentPage: number = 1;
  maxOperationsNumber: number = 10;

  setCurrentPage = (val: number) => {
    this.currentPage = val;
  };

  setMaxOperationsNumber = (val: number) => {
    this.maxOperationsNumber = val;
  };
  firstItem: number = 1;
  lastItem: number = 1;
  setFirstItem = (val: number) => {
    console.log(val);
    this.firstItem = val;
  };
  setLastItem = (val: number) => {
    this.lastItem = val;
  };

  setPickedSortOrder = (val: InputSortOrder) => {
    this.pickedSortOrder = val;
  };
  setPickedSortType = (val: InputSortType) => {
    this.pickedSortType = val;
  };

  setSearchValue = (val: string | null) => {
    this.searchValue = val;
  };

  toggleSort = (option: InputSortType) => {
    if (option === this.pickedSortType) {
      this.setPickedSortOrder(
        this.pickedSortOrder === InputSortOrder.asc
          ? InputSortOrder.desc
          : InputSortOrder.asc,
      );
    } else {
      this.setPickedSortType(option);
      this.setPickedSortOrder(InputSortOrder.asc);
    }
  };

  get manualOperationList(): Operation[] | undefined {
    return sortManualOperation(
      this.searchValue ? this.searchList : this.listOfManualOperations,
      this.pickedSortOrder,
      this.pickedSortType,
    );
  }

  setPeriod = (period: Value) => {
    if (!period || !Array.isArray(period)) return;
    this.periodStart = period[0];
    this.periodEnd = period[1];
  };

  isLoading: boolean = false;

  pickedStatementsStartDate = null;
  pickedStatementsEndDate = null;
  pickedStatementsPeriod = null;
  pickedStatementsType = null;
  pickedStatementsContragents = [];
  pickedStatementsContragentId = "";
  pickedStatementsDocumentId = "";
  listOfManualOperations: Operation[] = [];
  searchList: Operation[] = [];

  setSearchList = (arr: Operation[]) => {
    this.searchList = arr;
  };

  handleSearch = (event: any) => {
    const { value } = event.target as HTMLInputElement;
    this.setSearchValue(value === "" ? null : value);

    if (value !== "" && this.listOfManualOperations) {
      this.setSearchList(
        toJS(this.listOfManualOperations).filter((item: any) => {
          const comment = item.comment ? item.comment : "";
          return String(comment).includes(value);
        }),
      );
    } else {
      this.setSearchList(this.listOfManualOperations);
    }
  };

  setIsLoading = (bool: boolean) => {
    this.isLoading = bool;
  };
  setPickedStatementsStartDate = (str: any) => {
    this.pickedStatementsStartDate = str;
  };
  setPickedStatementsEndDate = (str: any) => {
    this.pickedStatementsEndDate = str;
  };
  setPickedStatementsPeriod = (arr: any) => {
    this.pickedStatementsPeriod = arr;
  };
  setPickedStatementsType = (str: any) => {
    this.pickedStatementsType = str;
  };
  setPickedStatementsContragents = (arr: any) => {
    this.pickedStatementsContragents = arr;
  };
  setPickedStatementsContragentId = (str: any) => {
    this.pickedStatementsContragentId = str;
  };
  setPickedStatementsDocumentId = (str: any) => {
    this.pickedStatementsDocumentId = str;
  };

  setListOfManualOperations = (val: Operation[]) => {
    this.listOfManualOperations = val;
  };

  fetchListOfManualOperations = async () => {
    this.setIsLoading(true);
    if (!this.periodStart && !this.periodEnd) return;
    const response = await getListOfManualOperations(
      (this.periodStart as Date).toLocaleDateString("ru-RU"),
      (this.periodEnd as Date).toLocaleDateString("ru-RU"),
    );
    if (response) {
      this.setListOfManualOperations(response.data);
    }
    this.setIsLoading(false);
  };
  fetchViewOfManualOperations = async (id: number) => {
    await getViewOfManualOperations(id);
  };
  fetchSubcontoTypesOfManualOperations = async () => {
    await getSubcontoTypesOfManualOperations();
  };
}

import { FC } from 'react';
import { useStore } from 'store';
import { MonthSelectorSeparated } from 'widgets/month-selector/separated';
import cls from '../styles.module.scss';
import { observer } from 'mobx-react-lite';

interface LoansHeaderProps {
  activeTab: number;
  setActiveTab: (tab: number) => void;
}

const LoansHeaderComponent: FC<LoansHeaderProps> = ({ activeTab,setActiveTab }) => {
  const { RootStore: { ContragentsLoansPage: { selectedDate,setSelectedDate } } } = useStore();

  return (<div className={cls['loans-header']}>
    <div className={cls['tabs__container']}>
      <button onClick={() => setActiveTab(0)} className={activeTab === 0 ? cls['tab__active'] : cls['tab']}>По поставщикам</button>
      <button onClick={() => setActiveTab(1)} className={activeTab === 1 ? cls['tab__active'] : cls['tab']}>По накладным</button>
    </div>
    <MonthSelectorSeparated minDate={new Date(2024,9,30)} maxDate={new Date()} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
  </div>);
};

export const LoansHeader = observer(LoansHeaderComponent);
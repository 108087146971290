import { Checkbox, Dropdown, Menu, Space } from "antd";
import { FilterArrow, FilterCross } from "../../icons";
import React from "react";
import { useStore } from "store";
import {toJS} from "mobx";

export const ResponsibleFilter = () => {
  const { RootStore } = useStore();
  const {
    RegistryStore: {
      pickedRegistryResponsible,
      setPickedRegistryResponsible,
      pickedRegistryResponsibleTitle,
      setPickedRegistryResponsibleTitle,
      responsibleList,
      setCurrentPage,
      setResponsibleList,
    },
  } = RootStore;

  const handleResponsibleFilterClick = (event: any) => {
    setCurrentPage(1);
    //console.log(event.item.props.id);
    if (pickedRegistryResponsible.includes(Number(event.key))) {
      setPickedRegistryResponsible(
        pickedRegistryResponsible.filter(
          (item: any) => item !== Number(event.key),
        ),
      );
    } else {
      setPickedRegistryResponsible([
        ...pickedRegistryResponsible,
        Number(event.key),
      ]);
    }
    if (pickedRegistryResponsibleTitle.includes(event.item.props.id)) {
      setPickedRegistryResponsibleTitle(
        pickedRegistryResponsibleTitle.filter(
          (item) => item !== event.item.props.id,
        ),
      );
    } else {
      setPickedRegistryResponsibleTitle([
        ...pickedRegistryResponsibleTitle,
        event.item.props.id,
      ]);
    }
  };

  const responsibleHandler = (item: any) => {
    setResponsibleList([...toJS(responsibleList).map(el => ({ ...el, checked: !el.checked ? el?.id === item.id : el.checked }))]);
  }

  const responsibleFilterMenu = (
    <Menu onClick={handleResponsibleFilterClick}>
      {responsibleList &&
        responsibleList.map((item: any) => (
          <Menu.Item
            data-testid={"responsible-elem"}
            key={item.id}
            id={`${item.surname} ${item.name}`}
            onClick={() => responsibleHandler(item)}
          >
            <Checkbox
              checked={item.checked}
            ></Checkbox>
            {`${item.surname} ${item.name}`}
          </Menu.Item>
        ))}
    </Menu>
  );
  return (
    <Dropdown overlay={responsibleFilterMenu} trigger={["click"]}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <div>
            {pickedRegistryResponsibleTitle.length > 0 ? (
              <>
                <span>{pickedRegistryResponsibleTitle.join(", ")}</span>

                <FilterCross
                  onClick={(
                    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
                  ) => {
                    event.stopPropagation();
                    setPickedRegistryResponsibleTitle([]);
                    setPickedRegistryResponsible([]);
                    setResponsibleList(toJS(responsibleList).map(item => ({ ...item, checked: false })));
                  }}
                />
              </>
            ) : (
              <>
                Ответственный
                <FilterArrow />
              </>
            )}
          </div>
        </Space>
      </a>
    </Dropdown>
  );
};

import { useState } from "react";
import { ODDSTableRow } from "./row";
import { ArrowState } from "ui";

interface ODDSGroupProps {
  name: String;
  number?: String | undefined;
  items: any[];
}

export const ODDSGroup = (props: ODDSGroupProps) => {
  const { name, items, number } = props;
  const [showitems, setShowitems] = useState<boolean>(true);

  return (
    <>
      <div
        onClick={() => {
          setShowitems(!showitems);
        }}
        style={{
          zIndex: 2,
        }}
      >
        <ODDSTableRow
          name={name}
          arrow={showitems ? ArrowState.OPENED : ArrowState.CLOSED}
          number={number}
          visible
        />
      </div>

      {items.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              zIndex: 1,
            }}
          >
            <ODDSTableRow
              key={index}
              name={item.title}
              number={item.sum}
              visible={showitems}
            />
          </div>
        );
      })}
    </>
  );
};

import { observer } from 'mobx-react-lite';
import { FC,useMemo } from 'react';
import cls from '../styles.module.scss';
import { useStore } from 'store';
import { WorkerRow } from './worker/worker-row';
import { DateHeader } from './date-header';
import { ISpotEmployees } from '../../types';

interface TableProps {
  withDates?: boolean;

  tableInfo: ISpotEmployees;
}
const TableComponent: FC<TableProps> = ({ withDates,tableInfo }) => {
  const { RootStore: { SchedulePlanningStore: { selectedDate } } } = useStore();
  const year = selectedDate.getFullYear();
  const month = selectedDate.getMonth();
  const days = new Date(year,month + 1,0).getDate();
  const arr = Array.from(Array(days).keys());
  arr.splice(15,0,100);
  arr.splice(arr.length,0,100,100);

  const dateHeaders = useMemo(() => {
    if (!withDates) return;

    return arr.map((d,i,arr) =>
      <DateHeader key={i} date={new Date(year,month,d + 1)} isLast={i === arr.length - 1} timeSum={d === 100} />
    );
  },[arr,month,withDates,year]);

  const workersData = useMemo(() => {
    return tableInfo.unit_employees.map(worker => <WorkerRow key={worker.id} worker={worker} tableType={tableInfo.title} />);
  },[tableInfo]);

  if (workersData.length === 0) return null;

  return (
    <div style={{ position: 'relative' }}>
      <table className={cls['schedule-table']} >
        <colgroup>
          <col width={268} />
          {arr.map(d => <col width={60} />)}
        </colgroup>
        <thead>
          <tr>
            <th className={cls['schedule-table__th']}>{tableInfo.title}</th>
            {dateHeaders}
          </tr>
        </thead>
        <tbody>
          {workersData}
        </tbody>
      </table>
    </div>
  );
};

export const Table = observer(TableComponent);
import "./revenueModal.scss";
import React from "react";
import { RevenueInput } from "./revenueInput";
import { EFontVariant } from "assets/styles/enums";
import { Button, EButtonVariant } from "ui";
import { observer } from "mobx-react-lite";
import { AddIcon } from "../icons";

interface IRevenueModalType {
  isActive?: boolean;
  modalClick: () => void;
}

export const RevenueModal: React.FC<IRevenueModalType> = observer(
  ({ isActive, modalClick }) => {
    return (
      <div
        className={
          isActive
            ? "revenueModal__container revenueModal__container_active"
            : "revenueModal__container"
        }
      >
        <div className="revenueModal__header">
          <p>Добавление плана</p>
          <span>1 апреля 2023, Суббота</span>
          <div onClick={modalClick} className="revenueModal__close-button">
            <AddIcon />
          </div>
          <div className="revenueModal__header__close-button"></div>
        </div>
        <div className="revenueModal__main">
          <RevenueInput
            title="Средний чек, руб"
            summary={1250}
            disabled={false}
            maxWidth={"318px"}
            height={"38px"}
            gap={"4px"}
          />
          <RevenueInput
            title="Гостевой поток, чел."
            summary={60}
            disabled={false}
            maxWidth={"318px"}
            height={"38px"}
            gap={"4px"}
            isNonPrice={true}
          />
          <RevenueInput
            title="Выручка"
            summary={0}
            disabled={true}
            maxWidth={"318px"}
            height={"38px"}
            gap={"4px"}
          />

          <div className="revenueModal__list-container">
            <p className="revenueModal__list-title ">Повторить заполнение</p>
            {/* <select className="revenueModal__list">
            <option className="revenueModal__option">
              Все дни
            </option>
            <option className="revenueModal__option">
              Все выходные дни
            </option>
            <option className="revenueModal__option">
              Аналогичные дни
            </option>
          </select> */}
            {/* <RevenueDropdown /> */}
            {/* <Dropdown 
            values={store.revenueDropdownValues}
          /> */}
          </div>
        </div>
        <Button
          variant={EButtonVariant.fillButton}
          font={EFontVariant.body2Regular}
          height={"38px"}
          maxWidth={"101px"}
          borderRadius={"12px"}
          text="Сохранить"
        />
      </div>
    );
  },
);

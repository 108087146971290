import { Navlink, ENavigateStyleVariant } from "ui";
import { EFontVariant } from "assets/styles/enums";

export const ReportsTabs = () => {
  return (
    <div className="reports__navigate ">
      <Navlink
        variant={({ isActive }) =>
          isActive
            ? `${ENavigateStyleVariant.choosenStandartNavItem} ${EFontVariant.body2Medium}`
            : `${ENavigateStyleVariant.standartNavItem} ${EFontVariant.body2Medium}`
        }
        maxWidth={"55px"}
        height={"34px"}
        borderRadius={"10px"}
        path="orp"
        text="ОРП"
        id="orp"
        dataTitle={"Отчет о розничных продажах"}
      />
      <span className="reports__navigate__divider" />
      <Navlink
        variant={({ isActive }) =>
          isActive
            ? `${ENavigateStyleVariant.choosenStandartNavItem} ${EFontVariant.body2Medium}`
            : `${ENavigateStyleVariant.standartNavItem} ${EFontVariant.body2Medium}`
        }
        maxWidth={"67px"}
        height={"34px"}
        borderRadius={"10px"}
        path="odds"
        text="ОДДС"
        id="odds"
        dataTitle={"Отчет о движении денежных средств"}
      />
      <span className="reports__navigate__divider" />
      <Navlink
        variant={({ isActive }) =>
          isActive
            ? `${ENavigateStyleVariant.choosenStandartNavItem} ${EFontVariant.body2Medium}`
            : `${ENavigateStyleVariant.standartNavItem} ${EFontVariant.body2Medium}`
        }
        maxWidth={"55px"}
        height={"34px"}
        borderRadius={"10px"}
        path="ofr"
        text="ОФР"
        id="ofr"
        dataTitle={"Отчет о финансовых результатах"}
      />
      <span className="reports__navigate__divider" />
      <Navlink
        variant={({ isActive }) =>
          isActive
            ? `${ENavigateStyleVariant.choosenStandartNavItem} ${EFontVariant.body2Medium}`
            : `${ENavigateStyleVariant.standartNavItem} ${EFontVariant.body2Medium}`
        }
        maxWidth={"74px"}
        height={"34px"}
        borderRadius={"10px"}
        path="balance"
        text={`Баланс`}
        id="balance"
        dataTitle={"Бухгалтерский баланс"}
      />
      <span className="reports__navigate__divider" />
      <Navlink
        variant={({ isActive }) =>
          isActive
            ? `${ENavigateStyleVariant.choosenStandartNavItem} ${EFontVariant.body2Medium}`
            : `${ENavigateStyleVariant.standartNavItem} ${EFontVariant.body2Medium}`
        }
        maxWidth={"55px"}
        height={"34px"}
        borderRadius={"10px"}
        path="osv"
        text="ОСВ"
        id="osv"
        dataTitle={"Оборотно-cальдовая ведомость"}
      />
    </div>
  );
};

import { useMemo } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { IResponsible } from 'pages/registry/types';
import { useStore } from 'store';
import { EInputStyleVariant,EInputVariant,Input } from 'ui';
import { DayPickerNew } from 'widgets/datepickers/day-picker-new';
import { DropdownUI } from 'widgets/dropdown';
import { Value } from "react-calendar/src/shared/types";
import { BucketIcon,Checked,Unchecked } from 'pages/registry-document/icons';
import './inventDocument.scss';

const InventDocumentComponent = ({ date,setDate }: { date: Value,setDate: React.Dispatch<React.SetStateAction<Value>>; }) => {
  const { RootStore: { RegistryStore:
    { addedDocumentType,responsibleList,pickedResponsibleList,togglePickedResponsibleId }
  } } = useStore();

  const responsibleData = useMemo(() => {
    if (!responsibleList) return null;

    return toJS(responsibleList).map((item: IResponsible) => {
      const isSelected = toJS(pickedResponsibleList).includes(item.id);

      const checkboxClassName = isSelected
        ? "checkbox checkbox_selected"
        : "checkbox";

      const onItemClickHandler = (event: React.MouseEvent<HTMLLIElement,MouseEvent>) => {
        event.stopPropagation();
        togglePickedResponsibleId(item.id);
      };

      return (
        <li
          id={item.id.toString()}
          key={item.id}
          className={'invent-modal__dropdown-item'}
          onClick={onItemClickHandler}
        >
          <div className={checkboxClassName}>
            <>
              {isSelected ? <Checked /> : <Unchecked />}
            </>
          </div>
          <div className="inner">
            {`${item.surname} ${item.name}`}
          </div>
        </li>
      );
    });
  },[toJS(responsibleList),toJS(pickedResponsibleList)]);

  const pickedResponsibleData = useMemo(() => toJS(pickedResponsibleList).reverse().map(id => {
    const responsibleItem = responsibleList.find(item => item.id === id);
    if (!responsibleItem) return null;

    return (
      <div key={id} className={`invent-modal__picked-responsibles__item`} >
        <div className={`invent-modal__picked-responsibles__item-title `}>
          <span className="name">
            {responsibleItem.surname} {responsibleItem.name}
          </span>
        </div>
        <div onClick={() => togglePickedResponsibleId(id)}>
          <BucketIcon />
        </div>
      </div>
    );
  }),[toJS(pickedResponsibleList),toJS(responsibleList)]);

  return (<>
    <div className="popupModal__element">
      <div className="label">Дата создания</div>
      <Input
        type={EInputVariant.text}
        variant={EInputStyleVariant.basicInput}
        height={"44px"}
        borderRadius={"16px"}
        placeholder={"Добавьте описание"}
        value={new Date().toLocaleDateString("ru-RU")}
        disabled
      />
    </div>
    <div className="popupModal__element">
      <div className="label">Дата пересчёта</div>
      <DayPickerNew date={date} dateSetter={setDate} />
    </div>
    <div className="popupModal__element">
      <div className="label">Ответственный сотрудник</div>
      <DropdownUI
        shown={"Выберите сотрудника"}
        disabled={!addedDocumentType}
        height={"44px"}
        borderRadius={"16px"}
      >
        {responsibleData}
      </DropdownUI>
    </div>
    <div className="popupModal__element">
      <div className='invent-modal__picked-responsibles'>
        {pickedResponsibleData}
      </div>
    </div>
  </>);
};

export const InventDocument = observer(InventDocumentComponent);
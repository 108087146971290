import { observer } from 'mobx-react-lite';
import { DateBar } from 'pages/spot/schedule-planning-new/components/date-bar';
import { FC,useEffect,useMemo,useState } from 'react';
import { useStore } from 'store';
import { Modal } from 'widgets/modal';
import cls from './styles.module.scss';
import { Template } from 'pages/spot/schedule-planning-new/templates/template';
import { toJS } from 'mobx';
import { TimeField } from 'ui-new/timepicker';
import { ModalButtons } from 'widgets/modal/buttons';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import dayjs,{ Dayjs } from 'dayjs';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';
import { yupResolver } from '@hookform/resolvers/yup';
import { EInputStyleVariant,Input } from 'ui-new/input';
import { BucketIcon } from 'pages/spot/schedule-planning-new/icons';
import { SelectTemplate } from './components/edit-template-modal';
import { ITemplate } from 'pages/spot/schedule-planning-new/types';
import EmptyTemplate from './components/empty-template';
import { convertUnwanted } from './components/unwanted';
import { Comment } from './components/comment';
import { showNotificationModal } from 'ui-new/alert';

type FormValues = {
  startTime: Dayjs;
  endTime: Dayjs;
  duration?: number;
  templateId: number | null;
};

//TODO: Ошибка если шаблонов нет но хотят выбрать другой шаблон

const schema = yup.object({
  startTime: yup.object<Dayjs>().required(ValidationMessages.get(ValidationTypes.required)),
  endTime: yup.object<Dayjs>().required(ValidationMessages.get(ValidationTypes.required)),
  templateId: yup.number().required().nullable(),
});

interface EditShiftModalProps {

}

const EditShiftModalComponent: FC<EditShiftModalProps> = () => {
  const { RootStore: { SchedulePlanningStore: {
    setEditShiftIsOpen,selectedWorker,editableShift,templates,confirmUpdateShift,deleteShiftConfirm,shiftUnwanted
  } } } = useStore();
  const [isSelectTemplate,setIsSelectTemplate] = useState(false);

  const defaultValues = useMemo(() => (editableShift ? {
    'startTime': dayjs(editableShift.start_time_fact,'HH:mm') as unknown as string,
    'endTime': dayjs(editableShift.end_time_fact,'HH:mm').locale('ru-RU') as unknown as string,
    'duration': editableShift.duration_fact,
    'templateId': editableShift.template_id
  } : {}),[toJS(editableShift)]);

  const { control,handleSubmit,watch,setValue } = useForm<FormValues>({ reValidateMode: 'onChange',resolver: yupResolver(schema) as any,defaultValues });
  const templateId = watch('templateId');
  const startTime = watch('startTime') as unknown as Dayjs;
  const endTime = watch('endTime') as unknown as Dayjs;

  const template = useMemo(() => templates.find(t => t.id === templateId)
    ,[toJS(templates),templateId]);

  useEffect(() => {
    if (!template) return;

    setValue('startTime',dayjs(template.start_time,'HH:mm'));
    setValue('endTime',dayjs(template.end_time,'HH:mm'));
  },[template]);

  useEffect(() => {
    if (!endTime || !startTime) return;
    const currentEndTime = endTime.clone();
    let duration = Math.floor(currentEndTime.diff(startTime) / 1000 / 60 / 60 * 10) / 10;
    if (duration < 0) duration += 24;
    setValue('duration',duration);

    if (template && (template.start_time !== startTime.format('HH:mm') || template.end_time !== endTime.format('HH:mm')))
      setValue('templateId',null);
  },[endTime,startTime]);

  const onSave = ({ endTime,startTime,templateId }: FormValues) => {
    if (!editableShift) return;

    confirmUpdateShift({
      shift_id: editableShift.id,
      user_id: editableShift.user_id,
      date: editableShift.start_date,
      start_time: startTime.format('HH:mm'),
      end_time: endTime.format('HH:mm'),
      template_id: templateId
    });
  };
  const onClose = () => setEditShiftIsOpen(false);
  const onErrorHandler = (error: any) => {
    console.log(error);
  };

  const onChangeTemplateHandler = () => {
    if (templates.length === 0) {
      showNotificationModal({ title: 'Ошибка',type: 'failure',errors: ['У спота нет шаблонов. Создайте новый в разделе Шаблоны.'] });
      return;
    }

    setIsSelectTemplate(true);
  };

  const unwantedData = useMemo(() => {
    if (shiftUnwanted.length === 0) return null;
    return <div className={cls['shift-edit__template-container']} style={{ alignSelf: 'stretch' }}>{convertUnwanted(toJS(shiftUnwanted))}</div>;
  }
    ,[toJS(shiftUnwanted)]);

  const dateBarDate = useMemo(() => {
    if (!editableShift) return null;

    const dateFact = new Date(editableShift.start_date_fact);

    if (isNaN(dateFact.getTime())) return new Date(editableShift.start_date);

    return dateFact;
  },[toJS(editableShift)]);

  if (!editableShift) return null;

  if (isSelectTemplate) {
    const selectedTemplate = templateId;
    const onBack = () => setIsSelectTemplate(false);
    const onSelect = (templateId: ITemplate['id']) => {
      setValue('templateId',templateId);
      onBack();
    };
    return <SelectTemplate selectedTemplate={selectedTemplate}
      onBack={onBack} onClose={onClose} onSelect={onSelect}
    />;
  }

  return (
    <Modal
      title='Редактирование'
      maxWidth='580px'
      onClose={onClose}
    >
      <div className={cls['shift-edit__form']}>
        <div className={cls["bar"]}>
          <DateBar date={dateBarDate} />
          <span className={cls['worker-fio']}>Работник: {selectedWorker?.name ?? 'Не указан'}</span>
        </div>
        <div className={cls['shift-edit__template-container']}>
          {template ? <Template template={template} isNotEditable /> : <EmptyTemplate />}
          <button className={cls['select-template__button']} onClick={onChangeTemplateHandler}>Выбрать {template && 'другой '}шаблон</button>
        </div>
        {unwantedData}
        {editableShift.comment &&
          <div className={cls['shift-edit__template-container']} style={{ alignSelf: 'stretch' }}>
            <Comment worker={selectedWorker?.name ?? ''} message={editableShift.comment} />
          </div>
        }
        <div>
          <div className={cls['shift-create__time-container']}>
            <TimeField name='startTime' placeholder='08:00' label='Начало смены' control={control} minuteStep={30} width={'150px'} />
            <TimeField name='endTime' placeholder='15:30' label='Конец смены' control={control} minuteStep={30} width={'150px'} />
            <Input variant={EInputStyleVariant.basicInput} type='number' name='duration' placeholder='0' label='Смена, ч' control={control} disabled />
          </div>
        </div>
        <div className={cls['shift-edit__buttons']}>
          <button className={cls['shift-edit__delete']} onClick={deleteShiftConfirm}><BucketIcon color={'#EB5757'} /> Удалить смену</button>
          <ModalButtons onPrimaryButtonClick={handleSubmit(onSave,onErrorHandler)} onSecondaryButtonClick={onClose}
            primaryButtonLabel={'Сохранить'} secondaryButtonLabel={'Отменить'} />
        </div>
      </div>
    </Modal>
  );
};

export const EditShiftModal = observer(EditShiftModalComponent);
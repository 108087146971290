import React from "react";
import { useNavigate } from "react-router";
import "./styles.scss";
import { SurfLogoIcon } from "./icons";
import { Button, EButtonVariant } from "../../ui";
import { EFontVariant } from "../../assets/styles/enums";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="not-found__wrapper">
      <div className="not-found">
        <SurfLogoIcon />
        <p>Данная страница не существует</p>
        <Button
          variant={EButtonVariant.fillButton}
          font={EFontVariant.body2Regular}
          height={"44px"}
          borderRadius={"16px"}
          maxWidth={"213px"}
          text="На главную"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
    </div>
  );
};

import "./styles.scss";
import { Route, Routes } from "react-router";
import { observer } from "mobx-react-lite";
import { ReportsTabs } from "./tabs";
import { Balance } from "./balance";
import { ODDS } from "./odds";
import { OSV } from "./osv";
import { OFR } from "./ofr";
import { ORP } from "./orp";
import { Navigate } from "react-router-dom";

export const ReportsPage = observer(() => {
  return (
    <div className="reports">
      <div className="reports__header">
        <h2>Отчёты</h2>
        <ReportsTabs />
      </div>
      <Routes>
        <Route path="orp" element={<ORP />} />
        <Route path="odds" element={<ODDS />} />
        <Route path="ofr" element={<OFR />} />
        <Route path="balance" element={<Balance />} />
        <Route index path="osv" element={<OSV />} />
        <Route path="/" element={<Navigate to="osv" />} />
      </Routes>
    </div>
  );
});

import type {TabButtonItem} from "../../../ui-new/tabs";
import type {DataTableHeader} from "../../../ui-new/table";
import type {Loan, LoanPosition} from "../../../types/loan";

export const TAB_ITEMS: TabButtonItem[] = [
    {
        id: 1,
        title: 'Мы должны'
    },
    {
        id: 2,
        title: 'Нам должны',
    }];


export const headers: DataTableHeader<Loan>[] = [
    { title: 'Дата открытия', value: 'created_at' },
    { title: 'Дата закрытия', value: 'closed_at' },
    { title: 'Предприятие', value: 'from_title' },
    { title: 'Общее кол-во', value: 'positions_count' },
    { title: 'Сумма', value: 'sum' },
    { title: 'Погашено', value: 'sum_fact' },
    { title: 'К погашению', value: 'to_repay' },
    { title: 'Статус', value: 'status' },
];

export const expandHeaders: DataTableHeader<LoanPosition>[] = [
    { title: 'Наименивание', value: 'nomenclature_title', textAlign: 'left' },
    { title: 'Количество', value: 'value', textAlign: 'left' },
    { title: 'Себестоимость', value: 'cost', textAlign: 'left' },
    { title: 'Статус', value: 'status', textAlign: 'left' },
];

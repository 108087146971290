import React,{ useEffect,useState } from "react";
import { BigSkeleton } from "ui";
import { observer } from "mobx-react-lite";
import { useLocation,useNavigate } from "react-router";
import { useStore } from "store";
import BreadCrumbs from "../components/breadCrumbs";
import DocumentHeader from "../components/documentHeader";
import InventoryBlock from "./components/inventoryBlock";

export const InventoryPage = observer(() => {
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: {
      setDocumentType,
      setDocumentId,
      advanceReport,
      setPickedContragent,
      fetchReport,
      fetchAllContragents,
      fetchResponsible,
      fetchUnits,
      fetchArticles,
      fetchNomenclatures,
      isLoading,
      setIsNeedContragent,
    },
  } = RootStore;

  const navigate = useNavigate();
  const location = useLocation();

  const [deleteModalIsActive,setDeleteModalIsActive] =
    useState<boolean>(false);

  function handleModalContragentChange(contragent: string) {
    setPickedContragent(contragent);
  }

  useEffect(() => {
    setDocumentId(+location.hash.slice(1));
    setDocumentType(location.pathname.split("/")[3]);
    fetchReport();
    fetchAllContragents();
    fetchResponsible();
    fetchUnits();
    fetchNomenclatures();
  },[location]);

  return (
    <>
      {advanceReport && (
        <>
          <div className="registry-document__header-container">
            <BreadCrumbs
              navigate={navigate}
              location={location}
              type="Инвентаризация"
            />

            <div className="registry-document__header">
              <DocumentHeader
                navigate={navigate}
                location={location}
                deleteModalIsActive={deleteModalIsActive}
                setDeleteModalIsActive={setDeleteModalIsActive}
                type="Инвентаризация"
              />
            </div>
          </div>

          {isLoading ? (
            <div style={{ padding: "0 20px" }}>
              <BigSkeleton />
            </div>
          ) : (
            <div className="registry-document__body">
              {/* инвентаризации */}
              <InventoryBlock />
              {/* инвентаризации */}
            </div>
          )}
        </>
      )}
    </>
  );
});

import { FC } from 'react';
import "../styles.scss";

interface HeaderProps {
  activeChars: { count: boolean,average: boolean; };
  changeActiveChar: (name: 'count' | 'average') => void;
}

export const ChartHeader: FC<HeaderProps> = ({ changeActiveChar,activeChars }) => (
  <div className="chart__header">
    <div className="chart-header__legend">
      <div onClick={() => changeActiveChar('count')} className="legend__item">
        <span style={activeChars.count ? { backgroundColor: '#d2bcee69' } : {}} />
        <p>Количество чеков шт.</p>
      </div>
      <div onClick={() => changeActiveChar('average')} className="legend__item">
        <span style={activeChars.average ? { backgroundColor: '#70b6f669' } : {}} />
        <p>Средний чек. руб.</p>
      </div>
    </div>
  </div>
);
import React, {useEffect} from "react";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
import { Button, EButtonVariant } from "../../../ui";
import {toJS} from "mobx";
import {format, isValid, parseISO} from "date-fns";

interface InfoBlockProps {
  setEditModalIsActive: (arg0: boolean) => void;
}

const InfoBlock = observer(({ setEditModalIsActive }: InfoBlockProps) => {
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: { advanceReport, setModalIsActive },
  } = RootStore;

  const getSumResult = () => {
    const result = advanceReport.sum ||
    advanceReport.positions.reduce(
      (sum: number, position: any) => sum + +position.sum,
      0,
    );

    return parseFloat(result.toFixed(2));
  };

  const formatDate = (isoDate: string) => {
    const date = parseISO(isoDate);

    if (!isValid(date)) {
      return isoDate;
    }

    return format(date, "dd.MM.yyyy");
  }

  useEffect(() => {
    console.log(toJS(advanceReport));
  }, []);

  return (
    <div className="registry-document__info">
      <div className="registry-document__info-column">
        <div className="registry-document__info-row">
          <div className="registry-document__info-element">
            {advanceReport.created_at && (
              <>
                <span className="registry-document__element-title">
                  Дата создания
                </span>
                <span className="registry-document__element-value">
                  {formatDate(advanceReport.created_at)}
                </span>
              </>
            )}
          </div>

          <div className="registry-document__info-element">
            {(advanceReport.deal_number || advanceReport.deal_num) && (
              <>
                <span className="registry-document__element-title">
                  № Договора
                </span>
                <span className="registry-document__element-value">
                  {advanceReport.deal_number || advanceReport.deal_num}
                </span>
              </>
            )}
          </div>

          <div className="registry-document__info-element">
            {advanceReport.date && (
              <>
                <span className="registry-document__element-title">
                  Дата проведения
                </span>
                <span className="registry-document__element-value">
                  {advanceReport.date}
                </span>
              </>
            )}
          </div>

          <div className="registry-document__info-element">
            {(advanceReport.document_num || advanceReport.number) && (
              <>
                <span className="registry-document__element-title">
                  № Документа
                </span>
                <span className="registry-document__element-value">
                  {advanceReport.document_num || advanceReport.number}
                </span>
              </>
            )}
          </div>
        </div>

        <div className="registry-document__info-row">
          <div className="registry-document__info-element">
            {(advanceReport.sum || advanceReport.positions) && (
              <>
                <span className="registry-document__element-title">
                  Сумма, ₽
                </span>
                <span className="registry-document__element-value">
                  {getSumResult()}
                </span>
              </>
            )}
          </div>

          <div className="registry-document__info-element">
            {(advanceReport.contragent_title || advanceReport.contragent) && (
              <>
                <span className="registry-document__element-title">
                  Контрагент
                </span>
                <span className="registry-document__element-value">
                  {advanceReport.contragent_title || advanceReport.contragent}
                </span>
              </>
            )}
          </div>

          <div className="registry-document__info-element">
            {advanceReport.responsible && (
              <>
                <span className="registry-document__element-title">
                  Ответственный
                </span>
                <span className="registry-document__element-value">
                  {advanceReport.responsible}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="registry-document__info-button">
        <Button
          variant={EButtonVariant.editButton}
          className={`registry-document__edit ${
            advanceReport.status === "pending" ? "" : "hidden"
          }`}
          onClick={() => {
            setModalIsActive(true);
            setEditModalIsActive(true);
            RootStore.setModalIsActive(true);
          }}
          text={"Редактировать"}
          maxWidth={"158px"}
          height={"32px"}
          borderRadius={"10px"}
        />
      </div>
    </div>
  );
});

export default InfoBlock;

import cls from './styles.module.scss';
import {type CSSProperties, type ReactNode, useState} from "react";
import { Button } from 'ui-new/button'
import {ExpandIcon} from "../../icons/expandIcon";
import classNames from "classnames";
import type {Nullable} from "assets/types";
import type {DataTableHeader, TableSortOptions} from "../../index";

export type TableSortDirection = 'ASC' | 'DESC';

export interface Props<T> {
    header: DataTableHeader<T>;
    children: ReactNode;
    style: CSSProperties;
    className: string;
    sortOptions?: TableSortOptions<T> | null;
    onSort: (options: TableSortOptions<T>) => void;
}

export function DataTableHeaderCell<T>({ children, style, className, onSort, header, sortOptions }: Props<T>) {

    const [activeDirection, setActiveDirection] = useState<Nullable<TableSortDirection>>(null);

    const onSortClick = (direction: TableSortDirection, sortOption: keyof T) => {
        onSort({direction, sortValue: header.value});
        setActiveDirection(direction);
    }

    return <th
        className={classNames(cls.dataTableHeaderCell, className)}
        style={style}
    >
        <div className={cls.dataTableHeaderCellContainer}>
            <span>{children}</span>

            {header.sort && <div className={cls.sortButtonsContainer}>
                <Button
                    theme='empty'
                    onClick={() => onSortClick('ASC', header.value)}
                    className={classNames(cls.up, cls.sortButton)}
                >
                    <ExpandIcon
                        className={classNames(cls.sortButtonIcon, header.value === sortOptions?.sortValue && activeDirection === 'ASC' ? cls.active : '')}/>
                </Button>

                <Button
                    theme='empty'
                    onClick={() => onSortClick('DESC', header.value)}
                    className={classNames(cls.down, cls.sortButton)}
                >
                    <ExpandIcon
                        className={classNames(cls.sortButtonIcon, header.value === sortOptions?.sortValue && sortOptions?.sortValue === header.value && activeDirection === 'DESC' ? cls.active : '')}
                    />
                </Button>
            </div>}
        </div>
    </th>
}
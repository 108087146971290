import React from "react";
import { DropdownUI } from "../../../../widgets/dropdown";
import { toJS } from "mobx";
import { useStore } from "../../../../store";
import { observer } from "mobx-react-lite";

const AdvancePopupModal = observer(() => {
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: {
      pickedResponsible,
      responsibleList,
      setPickedResponsible,
      setPickedResponsibleId,
    },
  } = RootStore;

  return (
    <>
      <div className="popupModal__element">
        <div className="label">Сотрудник</div>
        <DropdownUI
          shown={pickedResponsible}
          height={"44px"}
          borderRadius={"16px"}
        >
          <>
            {responsibleList &&
              responsibleList.map((value, index) => {
                var isActive: boolean = false;
                if (
                  value.name === pickedResponsible?.split(" ")[0] &&
                  value.surname === pickedResponsible?.split(" ")[1]
                ) {
                  isActive = true;
                }
                return (
                  <li
                    onClick={(e) => {
                      setPickedResponsible(`${value.surname}  ${value.name}`);
                      RootStore.setIsDropdownOpen({
                        ...toJS(RootStore.isDropdownOpen),
                        [4]: false,
                      });
                      setPickedResponsibleId((e.target as HTMLLIElement).id);
                    }}
                    className={`dropdown-item ${isActive ? "active" : ""}`}
                    key={value.id.toString()}
                    id={value.id.toString()}
                  >
                    {`${value.surname} ${value.name}`}
                  </li>
                );
              })}
          </>
        </DropdownUI>
      </div>
    </>
  );
});

export default AdvancePopupModal;

import {type ReactNode, useRef} from "react";
import {createPortal} from "react-dom";
import cls from './styles.module.scss';

export interface Props {
    children: ReactNode;
    onClose: () => void;
    isOpen: boolean;
    className?: string;
}

export const Modal = (props: Props) => {
    const {children, onClose, isOpen, className} = props;
    const modalWrapper = useRef(null);

    const closeModal = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        const targetId = target.id;

        if (targetId === 'modalWrapper') {
            onClose();
        }
    }

    return (
        createPortal(
            isOpen && <div
                id='modalWrapper'
                ref={modalWrapper}
                onClick={closeModal}
                className={cls.modalContainer}
            >
                <div className={className}>
                    {children}
                </div>
            </div>, document.getElementById('root') as HTMLElement
        )
    )
}
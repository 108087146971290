
export interface Props {
    className?: string;
}

export const SuccessIcon = ({ className }: Props) => {

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M3 8.5L7.09091 13L13 3" stroke="#6D37F6" stroke-width="1.4" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    )
}
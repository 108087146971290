import { StepType } from "@reactour/tour";
import colors from "../../../assets/styles/colors.scss";

const sidebarItemStyle: Partial<StepType> = {
  padding: 0,
  styles: {
    popover: (props) => ({
      ...props,
      left: "40px",
      maxWidth: "240px",
      borderRadius: "16px",
      padding: "32px 12px 12px 12px",
      border: "1px solid",
      borderColor: colors.colorNeutral30,
      background: colors.colorNeutral0,
      boxShadow:
        " 0px 4px 20px 0px rgba(0, 0, 0, 0.10), 0px 2px 8px 0px rgba(0, 0, 0, 0.05)",
    }),
  },
};

export const MainTourSteps: StepType[] = [
  {
    selector: ".header__profile",
    content:
      "Добро пожаловать в Личный кабинет! Сейчас расскажем что тут можно сделать, чтобы эффективно управлять спотом :)",
    padding: {
      mask: 3,
      popover: [0, 0, 0, 0],
    },
    resizeObservables: [".header__profile"],
  },
  //{ selector: '.1', content: 'Всегда можно вызвать обучающий тур по Личному кабинету, кликнув сюда', padding: 0 },
  {
    selector: ".header__notifications",
    content: "Напоминалки и важная информация - придут сюда",
    padding: 0,
  },
  {
    selector: ".sidebar__tour",
    content:
      "Всегда можно вызвать обучающий тур по Личному кабинету, кликнув сюда",
    padding: 0,
  },
];
if (process.env.REACT_APP_IS_DASHBOARD_ACTIVE === "true") {
  MainTourSteps.push({
    selector: ".nav__dashboard",
    content:
      "Краткая информация о продажах и графики - чтобы оперативно получать информацию",
    ...sidebarItemStyle,
  });
}
if (process.env.REACT_APP_IS_REGISTRY_ACTIVE === "true") {
  MainTourSteps.push({
    selector: ".nav__registry",
    content:
      "Утвердить первичные документы от бариста, работать с заявками на Инвентаризацию - в этом разделе",
    ...sidebarItemStyle,
  });
}
if (process.env.REACT_APP_IS_STATEMENTS_ACTIVE === "true") {
  MainTourSteps.push({
    selector: ".nav__bank",
    content: "Загрузи сюда файл с Выпиской из Личного кабинета вашего банка",
    ...sidebarItemStyle,
  });
}
if (process.env.REACT_APP_IS_INPUT_ACTIVE === "true") {
  MainTourSteps.push({
    selector: ".nav__input",
    content: "“Ручной привод”, для исправления ошибок в учете",
    ...sidebarItemStyle,
  });
}
if (process.env.REACT_APP_IS_REPORTS_ACTIVE === "true") {
  MainTourSteps.push({
    selector: ".nav__reports",
    content:
      "Бухгалтерские отчеты в разных разрезах - чтобы оперативно принимать решения",
    ...sidebarItemStyle,
  });
}
if (process.env.REACT_APP_IS_FINANCE_ACTIVE === "true") {
  MainTourSteps.push({
    selector: ".nav__finance",
    content:
      "Закрытие месяца, для ведения актуального состояния бухгалтерии прошедших периодов",
    ...sidebarItemStyle,
  });
}
if (process.env.REACT_APP_IS_REVENUE_ACTIVE === "true") {
  MainTourSteps.push({
    selector: ".nav__revenue",
    content:
      "Планирование выручки - по категориям и дням в разрезе месяца. Планируй заранее",
    ...sidebarItemStyle,
  });
}
if (process.env.REACT_APP_IS_SCHEDULE_ACTIVE === "true") {
  MainTourSteps.push({
    selector: ".nav__schedule",
    content:
      "Планирование графика - по дням планируй смены сотрудников на споте",
    ...sidebarItemStyle,
  });
}
if (process.env.REACT_APP_IS_TIMESHEET_ACTIVE === "true") {
  MainTourSteps.push({
    selector: ".nav__timesheet",
    content:
      "Фактический учет отработанного времени за месяц, по сменам сотрудников",
    ...sidebarItemStyle,
  });
}
if (process.env.REACT_APP_IS_FUND_ACTIVE === "true") {
  MainTourSteps.push({
    selector: ".nav__fund",
    content:
      "Премии, контракты и прочее - для итогового расчета дохода сотрудников",
    ...sidebarItemStyle,
  });
}

import React from "react";
import { useNavigate } from "react-router";
import "./styles.scss";
import { SurfLogoIcon } from "./icons";
import { Button, EButtonVariant } from "../../ui";
import { EFontVariant } from "../../assets/styles/enums";

export const ErrorPage = () => {

  const reloadPage = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  const navigate = useNavigate();
  return (
    <div className="not-found__wrapper">
      <div className="not-found">
        <SurfLogoIcon />
        <p>Произошла непредвиденная ошибка</p>
        <Button
          variant={EButtonVariant.fillButton}
          font={EFontVariant.body2Regular}
          height={"44px"}
          borderRadius={"16px"}
          maxWidth={"213px"}
          text="Обновить страницу"
          onClick={() => reloadPage()}
        />
      </div>
    </div>
  );
};

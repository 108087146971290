import React from 'react';
import classNames from "classnames";
import styles from './styles.module.scss';

export interface Props {
    width?: string;
    height?: string;
    radius?: string;
    removeAnimation?: boolean;
}

export const Skeleton = (props: Props) => {
    const {
        width = '100%',
        height = '16px',
        radius = '8px',
        removeAnimation = false,
    } = props;

    return (
        <div
            style={({ width, height, borderRadius: radius })}
            className={classNames(styles.skeleton, removeAnimation ? '' : styles.animation)}
        />
    );
};

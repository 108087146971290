import { makeAutoObservable } from "mobx";
import { showAlertPopup } from "../../../ui/alert";
import { getPlan, getRevenueData, getSave } from "../../../api/revenuePlanning";
import { RootStore } from "../../../store/rootStore";

export class RevenuePlanningStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  inputValuesSum: number = 0;
  isNotEditable: boolean = false;
  revenueData: any = null;
  difference: any = 0;
  selectedDate: Date = new Date();
  isLoading: boolean = false;
  categories: any[] = [];
  revenueBalance: any[] = [];
  daySum: any[] = [];
  sum: any[] = [];
  total: any = null;
  isBlocking: boolean = false;
  isChanged: boolean = false;

  setIsLoading = (val: boolean) => {
    this.isLoading = val;
  };

  setCategories = (val: any[]) => {
    this.categories = val;
  };

  setRevenueBalance = (val: any[]) => {
    this.revenueBalance = val;
  };

  setDaySum = (val: any[]) => {
    this.daySum = val;
  };
  setSum = (val: any[]) => {
    this.sum = val;
  };

  setIsNotEditable = (val: boolean) => {
    this.isNotEditable = val;
  };

  setRevenueData = (newData: any) => {
    this.revenueData = newData;
  };

  setDifference = (val: any) => {
    this.difference = val;
  };

  setSelectedDate = (date: any) => {
    this.selectedDate = date;
  };

  setInputValuesSum = (val: number) => {
    this.inputValuesSum = val;
  };

  setTotal = (val: any) => {
    this.total = val;
  };

  setIsBlocking = (val: boolean) => {
    this.isBlocking = val;
  };

  setIsChanged = (val: boolean) => {
    this.isChanged = val;
  };

  fetchRevenueData = async () => {
    this.setIsLoading(true);
    const responseRevenueData = await getRevenueData(this.selectedDate);
    this.setIsLoading(false);
    //console.log("@", 1111111, new Date().getDate() >= 16 && new Date().getMonth() - this.selectedDate.getMonth() > 1);

    if (responseRevenueData.success) {
      this.setInputValuesSum(0);
      this.setRevenueData(responseRevenueData.data);
      this.setCategories(responseRevenueData.data.categories);
      this.setIsLoading(false);
      this.setRevenueBalance(responseRevenueData.data.revenue);
      this.setDaySum(
        responseRevenueData.data.revenue
          ? responseRevenueData.data.revenue[0].sum
          : 0,
      );
      this.setSum(
        responseRevenueData.data.categories
          ? Object.values(responseRevenueData.data.categories)
          : [0, 0, 0, 0, 0, 0, 0],
      );
      this.setTotal(responseRevenueData.data.total);
      this.setIsNotEditable(responseRevenueData.data.is_create);
      //(new Date().getDate() >= 16 && new Date().getMonth() >= this.selectedDate.getMonth() - 1)
    } else {
      this.setIsNotEditable(true);
      this.setCategories([]);
      this.setRevenueBalance([]);
      this.setDaySum([]);
      this.setSum([]);
      this.setTotal(0);
      showAlertPopup("Ошибка", `${responseRevenueData.message}`, "error");
    }
  };

  fetchSave = async () => {
    this.setIsLoading(true);
    const responseSave = await getSave(this.categories, this.revenueBalance);
    this.setIsLoading(false);
    this.setIsBlocking(true);
    if (!responseSave.success) {
      showAlertPopup("Ошибка", `${responseSave.error}`, "error");
    }
    this.fetchRevenueData();
  };

  fetchPlan = async () => {
    this.setIsLoading(true);
    const responseSave = await getPlan(this.categories, this.revenueBalance);
    this.setIsLoading(false);
    this.setIsBlocking(true);
    this.fetchRevenueData();
  };
}

import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "../../../store/rootStore";
import {
  getAccountsOfManualOperations,
  getSubcontoTypesOfManualOperations,
  getViewOfManualOperations,
  postCreateManualOperation,
  postDeleteManualOperation,
} from "../../../api/operationInput";
import { Value } from "react-calendar/src/shared/types";
import { Account, Operation } from "../types";

export class OperationInputEditStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  pickedDate: Value = new Date();
  manualOperation: Operation | null = null;
  subcontoTypes: any = null;
  listOfAccounts: Account[] = [];
  isNewOperation: boolean = false;
  isLoading: boolean = false;

  setIsLoading = (val: boolean) => {
    this.isLoading = val;
  }

  setIsNewOperation = (val: boolean) => {
    this.isNewOperation = val;
  };

  setPickedDate = (val: Value) => {
    this.pickedDate = val;
    this.setManualOperation({
      ...toJS(this.manualOperation),
      date: `${(val as Date).toLocaleDateString("sv-SE")}T${toJS(
        this.manualOperation,
      )?.date.split("T")[1]}`,
    });
  };

  setInitDate = (date: Value) => {
    this.pickedDate = date;
  }

  setManualOperation = (val: any) => {
    this.manualOperation = val;
  };

  setSubcontoTypes = (val: any) => {
    this.subcontoTypes = val;
  };

  setListOfAccounts = (val: Account[]) => {
    this.listOfAccounts = val;
  };

  fetchViewOfManualOperations = async (id: number) => {
    this.setIsLoading(true);
    const response = await getViewOfManualOperations(id);
    if (response) {
      this.setManualOperation(response.data);
      this.setInitDate(new Date(response.data.date));
    }
    this.setIsLoading(false);
  };
  fetchSubcontoTypesOfManualOperations = async () => {
    const response = await getSubcontoTypesOfManualOperations();
    if (response) {
      this.setSubcontoTypes(response.data);
    }
  };
  fetchDeleteManualOperation = async (id: number) => {
    const response = await postDeleteManualOperation(id);
    if (response && this.manualOperation) {
      this.setManualOperation({
        ...toJS(this.manualOperation),
        status: 2,
        organization: {
          ...toJS(this.manualOperation).organization,
          deletion_mark: true,
        },
      });
    }
  };
  fetchAccountsManualOperations = async () => {
    const response = await getAccountsOfManualOperations();
    if (response) {
      this.setListOfAccounts(
        response.data.map((val: Account) => ({
          code: val.code,
          label: `${val.code} ${val.work_name}`,
          work_name: val.work_name,
          guid: val.guid,
          value: val.guid,
        })),
      );
    }
  };

  fetchCreateManualOperation = async () => {
    if (!this.manualOperation) return;

    this.manualOperation.transactions = toJS(this.manualOperation.transactions).map((item) => {
      if (!item.debit_quantity || !item.credit_quantity) {
        return {
          ...item,
          amount: +item?.amount,
        };
      }

      if (!item.debit_quantity && item.credit_quantity) {
        return {
          ...item,
          amount: +item?.amount,
          debit_quantity: +item.debit_quantity
        };
      }

      if (!item.credit_quantity && item.debit_quantity) {
        return {
          ...item,
          amount: +item?.amount,
          credit_quantity: +item.credit_quantity
        };
      }

      return {
        ...item,
        amount: +item?.amount,
        debit_quantity: +item?.debit_quantity,
        credit_quantity: +item?.credit_quantity,
      };
    });

    const response = await postCreateManualOperation(toJS(this.manualOperation));

    if (response) {
      return Promise.resolve(response.data);
    }
  };
}

import { FC,useCallback,useEffect,useState } from 'react';
import cls from './styles.module.scss';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';

let timer: number;

interface DateSelectorProps {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
}

const MonthSelectorComponent: FC<DateSelectorProps> = ({ selectedDate,setSelectedDate }) => {
  const [currentDate,setCurrentDate] = useState(selectedDate);

  useEffect(() => {
    timer = window.setTimeout(() => setSelectedDate(currentDate),500);
    return () => window.clearTimeout(timer);
  },[currentDate,setSelectedDate]);

  const setMonth = useCallback((step: number) => {
    setCurrentDate(prev => {
      const newDate = new Date(prev);
      newDate.setMonth(prev.getMonth() + step);
      return newDate;
    });
  },[]);

  return (<div className={cls['month-selector']}>
    <button onClick={() => setMonth(-1)} className={cls['button-prev']}></button>
    <p>{dayjs(currentDate).locale(ru).format('MMMM YYYY')}</p>
    <button onClick={() => setMonth(+1)} className={cls['button-next']}></button>
  </div>);
};

export const MonthSelector = MonthSelectorComponent;
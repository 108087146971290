import { FC,useEffect } from 'react';
import cls from './styles.module.scss';
interface SuccessModalProps {
  title: string;
  onClose: () => void;
  width?: string;
  maxWidth?: string;
  zIndex?: string;
}

let timer: number;

export const SuccessModal: FC<SuccessModalProps> = ({ onClose,width = '336px',maxWidth,title,zIndex,}) => {

  useEffect(() => {
    timer = window.setTimeout(onClose,5000);
    return () => { window.clearTimeout(timer); };
  },[]);

  return (
    <div
      className={cls["modal__overlay"]}
      onClick={onClose}
    >
      <div
        className={cls["modal__container"]}
        style={{ width: `${width}`,zIndex: `${zIndex}`,maxWidth: maxWidth ?? 'none' }}
      >
        <div className={cls["modal__notification"]}>
          <div className={cls["modal__notification-icon"]}>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.6" y="0.6" width="32.8" height="32.8" rx="16.4" stroke="#6D37F6" stroke-width="0.8" />
              <path d="M12 17.5L16.0909 22L22 12" stroke="#6D37F6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <p className={cls["title"]}>{title}</p>
        </div>
      </div>
    </div>
  );
};

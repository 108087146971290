import { useState } from 'react';
import { AddContract } from './add-contract-modal';
import { EditContract } from './edit-contract-modal';
import { TextButton } from 'ui-new/text-button';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';

const ModalsComponent = () => {
  const { RootStore: { SalaryFundStore: { editableContract,setEditableContract,currentContractStatus } } } = useStore();

  const [addIsOpen,setAddIsOpen] = useState(false);

  return (<>
    {!(currentContractStatus && currentContractStatus.value > 2) && <TextButton onClick={() => setAddIsOpen(true)}>Добавить контракт</TextButton>}
    {addIsOpen && <AddContract onClose={() => setAddIsOpen(false)} />}
    {editableContract && <EditContract onClose={() => setEditableContract(null)} />}
  </>);
};

export const Modals = observer(ModalsComponent);
import { addSpaces } from "assets/utils";

export function parseResponseORP(data: any) {
  const dataParsed: any = {
    sum: {
      name: "Всего",
      sum: addSpaces(data.total_revenu),
      ammount: data.total_num,
      vv: addSpaces(data.total_profit),
      sebes: addSpaces(data.total_sebest),
      vp: addSpaces(data.total_val_profit),
    },
  };

  dataParsed.days = data.days.map((day: any) => ({
    level: 0,
    name: day.OpenDate_Typed,
    sum: addSpaces(day.day_revenu),
    ammount: day.day_num,
    vv: addSpaces(day.day_profit),
    sebes: addSpaces(day.day_sebest),
    vp: addSpaces(day.day__val_profit),
    children: day.categories.map((category: any) => ({
      level: 1,
      name: category.category,
      sum: addSpaces(category.category_profit),
      ammount: category.category_num,
      vv: addSpaces(category.category_revenu),
      sebes: addSpaces(category.category_sebest),
      vp: addSpaces(category.category_val_profit),
      children: category.dish_groups.map((dish: any) => ({
        level: 2,
        name: dish.name,
        sum: addSpaces(dish.group_profit),
        ammount: dish.group_num,
        vv: addSpaces(dish.group_revenu),
        sebes: addSpaces(dish.group_sebest),
        vp: addSpaces(dish.group_val_profit),
      })),
    })),
  }));

  dataParsed.days.sort((a: any, b: any) => {
    const dateA: any = new Date(a.name.split(".").reverse().join("-"));
    const dateB: any = new Date(b.name.split(".").reverse().join("-"));
    return dateB - dateA;
  });

  return dataParsed;
}

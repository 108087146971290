import React from "react";
import { useStore } from "../../../../store";
import { observer } from "mobx-react-lite";
import { RegistryEdit } from "../../icons";

const CashOrderBlock = observer(() => {
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: { advanceReport },
  } = RootStore;

  return (
    <div className="registry-document__order">
      <div className="registry-document__info-column">
        <div className="registry-document__info-row">
          <div className="registry-document__info-element">
            <span className="registry-document__element-title">Тип</span>
            <span className="registry-document__element-value">
              {advanceReport.type === "out" ? "Из кассы" : "В кассу"}
            </span>
          </div>
          <div className="registry-document__info-element">
            <span className="registry-document__element-title">Статья</span>
            <span className="registry-document__element-value">
              {advanceReport.article_title}
            </span>
          </div>
          <div className="registry-document__info-element">
            <span className="registry-document__element-title">
              Комментарий
            </span>
            <span className="registry-document__element-value">
              {advanceReport.comment}
            </span>
          </div>
        </div>
      </div>
      <div className="registry-document__info-button">
        <div className="registry-document__edit">
          <RegistryEdit />
          Редактировать
        </div>
      </div>
    </div>
  );
});

export default CashOrderBlock;

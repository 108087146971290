import {Nomenclature, NomenclatureTypeKey} from "../registry-document/types";
import {Nullable} from "../../assets/types";

export interface Operation {
  guid: string;
  deletion_mark: boolean;
  operation_date: string;
  date: string;
  organization: any;
  amount: number;
  document_amount: number;
  comment: string;
  transactions: Transaction[];
  responsible_user: string;
  id: number;
  status: number;
}

export interface Account {
  guid: string;
  work_name: string;
  code: string;
  label: string;
  value: string;
}

export interface SubcontoAccount {
  name: string;
  label: string;
}

export interface SubcontoType {
  guid: string;
  work_name: string;
  tax_number: string;
  deletion_mark: boolean;
  label: string;
  value: string;
  type: NomenclatureTypeKey;
  path: string;
  category?: string;
}

export interface Transaction {
  row_number: number;
  debit_subconto: Subconto[];
  credit_subconto: Subconto[];
  credit_account: any;
  debit_account: any;
  amount: number;
  credit_quantity?: number;
  debit_quantity?: number;
}

export interface Subconto {
  subconto_type: string | null;
  subconto_value: string | null;
}

export enum InputSortType {
  date = "date",
  sum = "sum",
  responsible = "responsible",
  comment = "comment",
}
export enum InputSortOrder {
  asc = "asc",
  desc = "desc",
}

export interface NomenclatureTypeResponse {
  guid: string;
  work_name: string;
  deletion_mark: boolean;
  is_service: boolean;
}

export interface ResponseSubcontoValue {
  guid: string;
  work_name: string;
  deletion_mark: boolean;
  nomenclature_type: NomenclatureTypeResponse;
}

export interface ResponseSubconto {
  subconto_type: string;
  subconto_value: ResponseSubcontoValue;
}

export interface ResponseAccount {
  guid: string;
  code: string;
  work_name: string;
}

export interface ResponseTransaction {
  credit_quantity: Nullable<number>;
  debit_quantity: Nullable<number>;
  amount: number;
  credit_subconto: ResponseSubconto[];
  debit_subconto: ResponseSubconto[];
  debit_account: ResponseAccount;
  credit_account: ResponseAccount;
}

export interface Organization {
  guid: string;
  work_name: string;
  deletion_mark: boolean;
  transactions: ResponseTransaction[];
}

export interface ExistingTransactions {
  date: string;
  comment: Nullable<string>;
  status: number;
  responsible_user: string;
  document_amount: number;
  organization: Organization;
  transactions: ResponseTransaction[];
}
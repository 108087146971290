import { FC } from 'react';
import cls from '../styles.module.scss';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

interface DateHeaderProps {
  isLast: boolean;
  timeSum: boolean;

  date: Date;
}

const DateHeaderComponent: FC<DateHeaderProps> = ({ isLast,timeSum,date }) => {
  if (isLast) {
    return <th className={`${cls['schedule-table__th']} ${cls['th-sum']}`}></th>;
  }
  if (timeSum) {
    return <th className={`${cls['schedule-table__th']} ${cls['th-time']}`}></th>;
  }
  const dayOfWeek = format(date,'c',{ locale: ru });
  return <th className={`${cls['schedule-table__th']} ${(dayOfWeek === '6' || dayOfWeek === '7') && cls['weekend']}`}>
    <div className={`${cls['th-day']}`}>
      <p>{date.toLocaleString('ru-RU',{ day: '2-digit',month: '2-digit' })}</p>
      <span>{format(date,'EEEEEE',{ locale: ru })}</span>
    </div>
  </th>;
};

export const DateHeader = DateHeaderComponent;
import { FC,useMemo } from 'react';
import cls from '../styles.module.scss';
import { addSpaces } from 'assets/utils';
import { useStore } from 'store';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

interface EmployeesTableProps {

}

const ContractsTableComponent: FC<EmployeesTableProps> = () => {
  const { RootStore: { SalaryFundStore: { setEditableContract,tableContractsItems,netOrGross,contractStatuses,currentContractStatus } } } = useStore();

  const sumData = useMemo(() => {
    let planSum = 0;
    let factSum = 0;
    let approveSum = 0;
    let paidSum = 0;
    tableContractsItems.forEach((val) => {
      planSum += val[`plan_${netOrGross}`] ?? 0;
      factSum += val[`fact_${netOrGross}`] ?? 0;
      approveSum += val[`approved_${netOrGross}`] ?? 0;
      paidSum += val[`paid_${netOrGross}`] ?? 0;
    });
    planSum = Math.floor(planSum * 100) / 100;
    factSum = Math.floor(factSum * 100) / 100;
    approveSum = Math.floor(approveSum * 100) / 100;
    paidSum = Math.floor(paidSum * 100) / 100;

    return [
      <td className={cls['td']} style={currentContractStatus?.value === 1 ? { color: '#999CA6' } : {}}>
        {addSpaces(planSum)}
      </td>,
      <td className={cls['td']} style={(currentContractStatus?.value ?? 0) < 3 ? { color: '#999CA6' } : {}}>
        {addSpaces(factSum)}
      </td>,
      <td className={cls['td']} style={(currentContractStatus?.value ?? 0) < 3 ? { color: '#999CA6' } : {}}>
        {addSpaces(approveSum)}
      </td>,
      <td className={cls['td']} style={(currentContractStatus?.value ?? 0) < 4 ? { color: '#999CA6' } : {}}>
        {addSpaces(paidSum)}
      </td>
    ];
  },[tableContractsItems,currentContractStatus?.value,netOrGross]);

  const itemsData = useMemo(() => toJS(tableContractsItems).map((contractAward,i) => {
    const { contract,user,contract_status,} = contractAward;
    const contractStatus = contractStatuses.find(c => c.value === contract_status);

    return <tr
      style={{ cursor: 'pointer' }}
      onClick={() => setEditableContract(contractAward)}
    >
      <td className={`${cls['td']} ${cls['employee']} ${cls['num']}`}>{i + 1}</td>
      <td className={`${cls['td']} ${cls['employee']}`}><span title={contract}>{contract}</span></td>
      <td className={`${cls['td']} ${cls['employee']}`}><span title={user}>{user}</span></td>
      <td className={`${cls['td']} ${cls['employee']}`}
        style={contractStatus?.value === 3 ? { color: '#DC9F00' } : contractStatus?.value === 4 ? { color: '#03A700' } : contractStatus?.value === 5 ?
          { color: '#eb5757' } : {}}>
        <span title={contractStatus?.name_ru}>{contractStatus?.name_ru}</span>
      </td>
      <td className={`${cls['td']}`} style={currentContractStatus?.value === 1 ? { color: '#999CA6' } : {}}>
        {addSpaces(contractAward[`plan_${netOrGross}`] ?? 0)}
      </td>
      <td className={`${cls['td']}`} style={(currentContractStatus?.value ?? 0) < 3 ? { color: '#999CA6' } : {}}>
        {addSpaces(contractAward[`fact_${netOrGross}`] ?? 0)}
      </td>
      <td className={`${cls['td']}`} style={(currentContractStatus?.value ?? 0) < 3 ? { color: '#999CA6' } : {}}>
        {addSpaces(contractAward[`approved_${netOrGross}`] ?? 0)}
      </td>
      <td className={`${cls['td']} ${cls['td-bold']}`} style={(currentContractStatus?.value ?? 0) < 4 ? { color: '#999CA6' } : {}}>
        {addSpaces(contractAward[`paid_${netOrGross}`] ?? 0)}
      </td>
    </tr>;
  }),[contractStatuses,currentContractStatus?.value,netOrGross,setEditableContract,tableContractsItems]);

  return (
    <table className={`${cls['table']} ${cls['table-rounding__top']} ${cls['table-rounding__bottom']}`}>
      <colgroup>
        <col width={44} />
        <col width={207} />
        <col width={207} />
        <col width={159} />
        <col width={138} />
        <col width={136} />
        <col width={138} />
        <col width={136} />
      </colgroup>
      <thead className={`${cls['thead']} ${cls['rounding']}`}>
        <tr className={cls['tr']}>
          <th className={cls['th']} colSpan={4}>Общая информация</th>
          <th className={cls['th']} colSpan={4}>Вознаграждение за контракт,  ₽ </th>
        </tr>
        <tr className={cls['tr']}>
          <th className={cls['th']}>№</th>
          <th className={cls['th']}>Название контракта</th>
          <th className={cls['th']}>Ответственный</th>
          <th className={cls['th']}>Статус</th>
          <th className={cls['th']}>План</th>
          <th className={cls['th']}>Факт</th>
          <th className={cls['th']}>Утверждено</th>
          <th className={cls['th']}>Выплачено</th>
        </tr>
      </thead>
      <tfoot className={cls['tfoot']}>
        <tr className={cls['tr']}>
          <td className={cls['td']} colSpan={4}>Всего</td>
          {sumData}
        </tr>
      </tfoot>
      <tbody className={`${cls['tbody']} ${cls['rounding']}`}>
        {itemsData}
      </tbody>
    </table>
  );
};

export const ContractsTable = observer(ContractsTableComponent);
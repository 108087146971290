import { api } from "./api";

export async function fetchNotifications() {
  try {
    return api.get(`web/notifications/get`);
  } catch (e) {
    return null;
  }
}

export async function readSingleNotification(id: number) {
  try {
    return api.post(`web/notifications/read`, {
      notification_id: id,
    });
  } catch (e) {
    return null;
  }
}

export async function readAllNotifications() {
  try {
    return api.post(`web/notifications/read/all`);
  } catch (e) {
    return null;
  }
}

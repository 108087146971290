import { useEffect,useMemo,useState } from 'react';
import "@carbon/styles/css/styles.css";
import "@carbon/charts-react/styles.css";
import "./styles.scss";
import { observer } from "mobx-react-lite";
import Skeleton from "react-loading-skeleton";
import { useStore } from "store";
import NoData from "../../ui/no-data/noData";
import { ConfigProvider,DatePicker } from 'antd';
import dayjs,{ Dayjs } from 'dayjs';
import locale from 'antd/locale/ru_RU';
import { Board } from './components/board';
import { addSpaces } from 'assets/utils';


const DashboardComponent = () => {
  const {
    RootStore: {
      DashboardStore: {
        isLoading,
        fetchGetBills,
        billAverage,
        billsCount,
        data,
      },
    },
  } = useStore();

  const [day,setDay] = useState<Dayjs | null>(null);

  useEffect(() => {
    fetchGetBills(day?.format('YYYY-MM-DD'));
  },[day,fetchGetBills]);

  const board = useMemo(() => {
    if (isLoading) return <Skeleton width={"100%"} height={"500px"} />;

    if (data.length === 0) return <NoData />;

    return <Board />;
  },[isLoading,data.length]);

  return (
    <div className="dashboard">
      <h2 className="dashboard__title">Анализ продаж</h2>
      <div className="dashboard__options">
        <ConfigProvider locale={locale}>
          <DatePicker
            value={day}
            onChange={setDay}
            placeholder='Выбрать день'
            minDate={dayjs().add(-1,'month')}
            maxDate={dayjs().add(-1,'day')}
            format={{ format: 'DD.MM.YYYY' }}
          />
        </ConfigProvider>
        <div className='dashboard__info--container'>
          <div className='dashboard__info'>
            <h3>Количество<br /> чеков {day && <>на <br />{day.format('DD.MM.YYYY')}</>}</h3>
            <p>{addSpaces(billsCount)} шт.</p>
          </div>
          <div className='dashboard__info'>
            <h3>Средний<br /> чек {day && <>на <br />{day.format('DD.MM.YYYY')}</>}</h3>
            <p>{addSpaces(billAverage)} руб.</p>
          </div>
        </div>
      </div>
      {board}
    </div>
  );
};

export const DashboardPage = observer(DashboardComponent);
import React from "react";
import { EmployeesList } from "widgets";
import "./styles.scss";

export const OnboardingPageThree = () => {
  return (
    <div className="onboardingPageThree__container">
      <EmployeesList />
    </div>
  );
};

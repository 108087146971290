import { OSVStore } from "pages/reports/osv/store";
import { ORPStore } from "pages/reports/orp/store";
import { OFRStore } from "pages/reports/ofr/store";
import { ODDSStore } from "pages/reports/odds/store";
import { BalanceStore } from "pages/reports/balance/store";
import { RegistryStore } from "pages/registry/store";
import { AuthStore } from "pages/auth/store";
import { SalaryFundStore } from "pages/salary-fund/store";
import { DashboardStore } from "pages/dashboard/store";
import { NotificationsStore } from "widgets/notifications/store";
import { OperationInputStore } from "pages/operation-input/store";
import { RevenuePlanningStore } from "pages/spot/revenue-planning/store";
import { SchedulePlanningStore } from "pages/spot/schedule-planning-new/store";
import { RegistryDocumentStore } from "pages/registry-document/store";
import { TimeSheetStore } from "pages/timesheet/store";
import { EmployeesStore } from "widgets/employees-roster/store";
import { makeAutoObservable } from "mobx";
import { FinanceStore } from "../pages/finance/store";
import { isDropdownOpenType } from "../assets/types";
import Cookies from "js-cookie";
import { OperationInputEditStore } from "../pages/operation-input/operation-input-edit/store";
import { RoleStore } from "pages/access-rights/store";
import { HeaderStore } from "widgets/header/store";
import { SpotPageStore } from "pages/spot/spot-page/store";
import { ContractsStore } from "pages/contracts/store";

import { ContragentsLoansPage } from "pages/loans-contragents/store";
import { MoneyTransactionsStore } from "pages/money-transactions/store";
import { EditMoneyTransactionStore } from "pages/money-transactions/money-transaction-edit/store";

export class RootStore {
  AuthStore: AuthStore;
  OSVStore: OSVStore;
  ORPStore: ORPStore;
  OFRStore: OFRStore;
  ODDSStore: ODDSStore;
  BalanceStore: BalanceStore;
  RegistryStore: RegistryStore;
  SalaryFundStore: SalaryFundStore;
  MoneyTransactionsStore: MoneyTransactionsStore;
  EditMoneyTransactionStore: EditMoneyTransactionStore;
  DashboardStore: DashboardStore;
  NotificationsStore: NotificationsStore;
  OperationInputStore: OperationInputStore;
  OperationInputEditStore: OperationInputEditStore;
  RevenuePlanningStore: RevenuePlanningStore;
  SchedulePlanningStore: SchedulePlanningStore;
  RegistryDocumentStore: RegistryDocumentStore;
  TimeSheetStore: TimeSheetStore;
  EmployeesStore: EmployeesStore;
  FinanceStore: FinanceStore;
  ContractsStore: ContractsStore;
  RoleStore: RoleStore;
  HeaderStore: HeaderStore;
  SpotPageStore: SpotPageStore;

  ContragentsLoansPage: ContragentsLoansPage;

  constructor() {
    makeAutoObservable(this);
    this.AuthStore = new AuthStore(this);

    this.DashboardStore = new DashboardStore(this);

    this.RegistryDocumentStore = new RegistryDocumentStore(this);

    this.OSVStore = new OSVStore(this);
    this.ORPStore = new ORPStore(this);
    this.OFRStore = new OFRStore(this);
    this.ODDSStore = new ODDSStore(this);
    this.BalanceStore = new BalanceStore(this);

    this.RegistryStore = new RegistryStore(this);

    this.MoneyTransactionsStore = new MoneyTransactionsStore(this);
    this.EditMoneyTransactionStore = new EditMoneyTransactionStore(this);

    this.NotificationsStore = new NotificationsStore(this);
    this.OperationInputStore = new OperationInputStore(this);
    this.OperationInputEditStore = new OperationInputEditStore(this);

    this.TimeSheetStore = new TimeSheetStore(this);

    this.EmployeesStore = new EmployeesStore(this);

    this.RevenuePlanningStore = new RevenuePlanningStore(this);
    this.SchedulePlanningStore = new SchedulePlanningStore(this);

    this.SalaryFundStore = new SalaryFundStore(this);
    this.FinanceStore = new FinanceStore(this);

    this.RoleStore = new RoleStore(this);

    this.HeaderStore = new HeaderStore(this);
    this.ContragentsLoansPage = new ContragentsLoansPage(this);
    this.ContractsStore = new ContractsStore(this);
    this.SpotPageStore = new SpotPageStore(this);
  }

  isAuthenticated: boolean = Cookies.get("token") !== undefined;

  setIsAuthenticated = (val: boolean) => {
    this.isAuthenticated = val;
  };

  selectedOption: string = "";
  isDropdownOpen: isDropdownOpenType = {};
  modalIsActive: boolean = false;

  setIsDropdownOpen(obj: isDropdownOpenType) {
    this.isDropdownOpen = obj;
  }
  setModalIsActive = (bool: boolean) => {
    this.modalIsActive = bool;
  };
}

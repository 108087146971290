import React, {useCallback, useEffect, useMemo, useState} from 'react';
import cls from "./ManualInputTable.module.scss";
import {ManualInputTableHeader} from "../ManualInputTableHeader/ManualInputTableHeader";
import {ManualInputTableRow, type TransactionRequest} from "../ManualInputTableRow/ManualInputTableRow";
import type {ManualOperationAccount, Transaction} from "../../index";
import {ExistingTransactions} from "../../../types";
import {ManualInputTableRowNoEditable} from "../ManualInputTableRow/ManualInputTableRowNoEditable";
import {Nullable} from "../../../../../assets/types";
import {isArray} from "lodash";

interface Props {
    transactions: Transaction[];
    accounts: ManualOperationAccount[];
    accountsLoading: boolean;
    transactionsBodyUpdate: (transactions: TransactionRequest[]) => void;
    existingTransactions: Nullable<ExistingTransactions>;
    isNew: boolean;
}

interface ManualInputCreateRequest {
    amount: number;
    date: string;
    transactions: ManualInputTransaction[];
}

interface ManualInputTransaction {
    amount: number;
    credit_account: string;
    credit_quantity: number;
    dedit_account: string;
    debit_quantity: number;
    row_number: number;
    credit_subconto: Subconto[];
    debit_subconto: Subconto[];
}

interface Subconto {
    subconto_type: string;
    subconto_value: string;
}

export function ManualInputTable(props: Props) {

    const {
        transactions = [],
        accountsLoading,
        accounts,
        transactionsBodyUpdate,
        existingTransactions,
        isNew,
    } = props;

    const [transactionsList, setTransactionsList] = useState<TransactionRequest[]>([]);

    const updateRequestBody = (transactionItem: TransactionRequest, index: number) => {
        setTransactionsList((prev) => {
            if (!prev.length) return [transactionItem]
            return [...prev.filter(item => item.row_number !== transactionItem.row_number), transactionItem]
        });
    };

    useEffect(() => {
        transactionsBodyUpdate(transactionsList);
    }, [transactionsList]);

    const mappedTransactions = useMemo(() => {
        return transactions.map((transaction, index) =>
            <ManualInputTableRow
                accountsLoading={accountsLoading}
                accounts={accounts}
                transaction={transaction}
                updateRequestBody={updateRequestBody}
                index={index}
                key={index}
            />
        );
    }, [accounts, accountsLoading, transactions]);

    const mappedExistingTransactions = useMemo(() => {
        return existingTransactions?.transactions.map((transaction, index) =>
            <ManualInputTableRowNoEditable transaction={transaction} index={index} key={index} />
        )
    }, [existingTransactions]);

    return (
        <table className={cls.table}>
            {!!(existingTransactions?.transactions.length || transactions.length) && <ManualInputTableHeader/>}
            <tbody>
            {!existingTransactions?.transactions.length && mappedTransactions || mappedExistingTransactions}
            </tbody>
        </table>
    );
}

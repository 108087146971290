import { FC } from 'react';
import cls from '../empty-template-styles.module.scss';

interface EmptyTemplateProps {

}

const EmptyTemplate: FC<EmptyTemplateProps> = () => {
  return (<div className={`${cls['template-empty']}`}>
    <div className={cls['duration']}></div>
    <p className={cls['info']}>Смена не по шаблону</p>
  </div>);
};

export default EmptyTemplate;
import { Modal } from 'widgets/modal';
import { useCallback,useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { Input,EInputStyleVariant } from 'ui-new/input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';
import SelectField from 'ui-new/select';
import { showAlertPopup } from 'ui/alert';
import { Textarea } from 'ui-new/textarea';
import cls from './styles.module.scss';
import { TimeField } from 'ui-new/timepicker';
import { ColorLabel,ColorSquare } from '../components/color-select-label';
import { ModalButtons } from 'widgets/modal/buttons';
import { toJS } from 'mobx';
import dayjs from 'dayjs';
import { showConfirmModal } from 'ui-new/alert/confirm';
import { showNotificationModal } from 'ui-new/alert';


const TEMPLATE_COLORS = ['#FF9700','#FFEA3B','#4CB050',' #009788'," #00BCD4",' #2196F3',' #3F51B5',' #673BB7',' #9C28B1',
  '#FFB954','#FFF17C',' #87CA8A',' #54B9AF',' #54D2E2',' #6AB9F7',' #7F8BCD',' #997CCF','#BD6FCB',
  '#FFDCA9',"#FFF8BD","#C3E4C4",'#A9DCD7','#A9E8F0','#B4DCFB','#BEC4E6','#CCBDE7','#DEB7E5'];

type FormValues = {
  title: string;
  startTime: string;
  endTime: string;
  color: string;
  description?: string;
};

const schema = yup.object({
  title: yup.string().min(3,({ min }) => `${ValidationMessages.get(ValidationTypes.min)} ${min} символов`).max(50,({ max }) => `${ValidationMessages.get(ValidationTypes.max)} ${max} символов`)
    .required(ValidationMessages.get(ValidationTypes.required)),
  startTime: yup.string().required(ValidationMessages.get(ValidationTypes.required)),
  endTime: yup.string().required(ValidationMessages.get(ValidationTypes.required)),
  color: yup.string().required(ValidationMessages.get(ValidationTypes.required)),
  description: yup.string().max(200,({ max }) => `${ValidationMessages.get(ValidationTypes.max)} ${max} символов`)
});

const CreateTemplateModalComponent = () => {
  const {
    RootStore: {
      SchedulePlanningStore: {
        fetchCreateTemplate,
        fetchUpdateTemplate,
        setCreateTemplateModalIsOpen,
        setEditTemplateModalIsOpen,
        editableTemplate,
      },
    },
  } = useStore();

  const defaultValues: FormValues | undefined = useMemo(() => {
    if (!editableTemplate)
      return undefined;

    return {
      title: editableTemplate.title,
      color: editableTemplate.color,
      startTime: dayjs(editableTemplate.start_time,'HH:mm') as unknown as string,
      endTime: dayjs(editableTemplate.end_time,'HH:mm').locale('ru-RU') as unknown as string,
      description: editableTemplate.description ?? undefined
    };
  },[toJS(editableTemplate)]);

  const title = useMemo(() => editableTemplate ? 'Редактирование шаблона' : 'Новый шаблон',[toJS(editableTemplate)]);

  const { handleSubmit,control } = useForm<FormValues>({ resolver: yupResolver<FormValues>(schema),mode: 'onChange',defaultValues });
  const colorOptions = useMemo(() => TEMPLATE_COLORS.map(color => ({ value: color,label: <ColorSquare label={color} value={color} /> })),[]);

  const onSave = useCallback(({ endTime,startTime,color,title,description }: FormValues) => {
    const start_time = (new Date(startTime)).toLocaleTimeString('ru-RU',{ hour: '2-digit',minute: '2-digit' });
    const end_time = (new Date(endTime)).toLocaleTimeString('ru-RU',{ hour: '2-digit',minute: '2-digit' });

    if (editableTemplate) {
      const onEditConfirm = () => fetchUpdateTemplate({ template_id: editableTemplate.id,color,description: description ?? null,end_time,start_time,title,});
      showConfirmModal({
        title: 'Подтвердите изменения',messages: ['Изменения шаблона будут применены ко всем предстоящим сменам, связанным с этим шаблоном.'],confirmButtonLabel: 'Сохранить',onConfirm: onEditConfirm,maxWidth: '400px'
      });
      return;
    }

    fetchCreateTemplate({ end_time,start_time,color,title,description: description ?? null });
  },[toJS(editableTemplate)]);
  const onError = useCallback((errors: any) => {
    showNotificationModal({ title: 'Форма не заполнена',type: 'failure',errors: ['Заполните все обязательные поля'] });
  },[]);

  const onClose = useCallback(() => {
    if (editableTemplate) {
      setEditTemplateModalIsOpen(false);
      return;
    }

    setCreateTemplateModalIsOpen(false);
  },[toJS(editableTemplate)]);

  return (
    <Modal
      title={title}
      maxWidth={"480px"}
      onClose={onClose}
    >
      <div className={cls['form']}>
        <Input variant={EInputStyleVariant.basicInput} type='text' label='Название шаблона' placeholder={'Введи название шаблона'} control={control} name='title' />
        <div className={cls['row']}>
          <TimeField label='Начало смены' control={control} placeholder='07:00' name='startTime' width='188px' minuteStep={30} />
          <TimeField label='Конец смены' control={control} placeholder='15:00' name='endTime' width='188px' minuteStep={30} />
        </div>
        <SelectField label='Цвет шаблона' control={control}
          selectProps={{
            labelRender: ({ value,label }) => <ColorLabel value={value as string} label={label} />,
            placeholder: <ColorLabel label={'Выбери цвет шаблона'} />,
            popupClassName: cls['select-row-palette'],
            virtual: false
          }}
          name='color' options={colorOptions}
        />
        <Textarea label='Описание шаблона' control={control} placeholder='Расскажи для чего нужен этот шаблон' rows={3} name='description' />
        <ModalButtons className={cls['buttons-group']} primaryButtonLabel={'Сохранить'} onPrimaryButtonClick={handleSubmit(onSave,onError)}
          secondaryButtonLabel={'Отменить'} onSecondaryButtonClick={onClose} />
      </div>
    </Modal>
  );
};

export const CreateTemplateModal = observer(CreateTemplateModalComponent);
import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import SelectField from "ui-new/select";
import { useStore } from "store";
import { toJS } from "mobx";
import { MoneyTransactionDynamicFormFields } from "types/money-transactions";

interface PnLFieldProps {
  allDisabled: boolean;
}

const PnLFieldComponent: FC<PnLFieldProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { formFields, pnlArticles },
    },
  } = useStore();
  const { control } = useFormContext<{
    [MoneyTransactionDynamicFormFields.PnLArticleId]: number;
  }>();

  const pnlArticlesData = useMemo(
    () =>
      toJS(pnlArticles).sort((a, b) => {
        if (a.title > b.title) return 1;
        if (a.title < b.title) return -1;
        return 0;
      }),
    [pnlArticles]
  );

  return (
    <SelectField
      control={control}
      name={MoneyTransactionDynamicFormFields.PnLArticleId}
      label="Статья PnL"
      selectProps={{
        placeholder: "Название статьи",
        disabled: allDisabled || formFields?.pnl_article_id?.disabled,
        showSearch: true,
        optionFilterProp: "title",
        fieldNames: {
          label: "title",
          value: "id",
        },
      }}
      options={pnlArticlesData}
    />
  );
};

export const PnLField = observer(PnLFieldComponent);

import React from "react";
import "./revenueOverlay.scss";

interface IRevenueOverlayType {
  isActive: boolean;
  modalClick: () => void;
}

export const RevenueOverlay: React.FC<IRevenueOverlayType> = ({
  isActive,
  modalClick,
}) => {
  return (
    <div
      onClick={modalClick}
      className={
        isActive ? "revenueOverlay revenueOverlay_active" : "revenueOverlay"
      }
    ></div>
  );
};

import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  BigSkeleton,
  Button,
  EButtonVariant,
  EInputStyleVariant,
  EInputVariant,
  Input,
} from "../../ui";
import { EmptyTableBody } from "../../ui/empty-table-body";
import { useStore } from "store";
import { Filters } from "../../ui/filters";
import { useNavigate } from "react-router";
import { Checkbox, Dropdown, Menu, Space } from "antd";
import { Calendar } from "react-calendar";
import { TableFooter } from "widgets/table-footer";
import { PeriodPicker } from "widgets/datepickers/period-picker";
import { Value } from "react-calendar/src/shared/types";
import Table from "./components/table";
import { observer } from "mobx-react-lite";
import { FilterIcon } from "./icons";

export const OperationInputPage = observer(() => {
  const {
    RootStore: {
      OperationInputStore: {
        pickedStatementsType,
        pickedStatementsPeriod,
        isLoading,
        periodStart,
        periodEnd,
        setPeriod,
        fetchListOfManualOperations,
        fetchSubcontoTypesOfManualOperations,
        handleSearch,
        searchValue,
        setSearchValue,
      },
    },
  } = useStore();

  const navigate = useNavigate();

  // const contragentFilterMenu = (
  //   <Menu>
  //     <Menu.Item>
  //       <div>тест</div>
  //     </Menu.Item>
  //   </Menu>
  // );
  //
  // const statusTypes = [
  //   {
  //     label: "Проведен",
  //   },
  //   {
  //     label: "На удаление",
  //   },
  // ];

  // const statusFilterMenu = (
  //   <Menu>
  //     {statusTypes.map((item: any) => (
  //       <Menu.Item>
  //         <Checkbox
  //           checked={pickedStatementsType === item.key}
  //         ></Checkbox>
  //         {item.label}
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  const periodFilterMenu = (
    <Menu>
      <Calendar
        selectRange={true}
        value={pickedStatementsPeriod}
        // onChange={handlePeriodFilterClick}
      />
    </Menu>
  );

  useEffect(() => {
    fetchListOfManualOperations();
  }, [periodEnd, periodStart]);

  useEffect(() => {
    fetchSubcontoTypesOfManualOperations();
  }, []);

  console.log(periodEnd);

  return (
    <div className="operation-input">
      <div className="operation-input__header">
        <p>Ручной ввод операции</p>
        <Button
          variant={EButtonVariant.fillButton}
          text={"Добавить операцию"}
          maxWidth={"166px"}
          onClick={() => {
            navigate("/main/input/edit#new");
          }}
        />
      </div>
      {!isLoading ? (
        <div className="operation-input__table">
          <div className="operation-input__table__header">
            <Filters haveResetButton={true}>
              <PeriodPicker
                periodStart={periodStart}
                periodEnd={periodEnd}
                periodSetter={(period: Value) => setPeriod(period)}
                isFilter={true}
              />
              {/*<Dropdown*/}
              {/*  overlay={statusFilterMenu}*/}
              {/*  trigger={["click"]}*/}
              {/*  // open={dropdownOpen}*/}
              {/*  // onOpenChange={handleDropdownOpenChange}*/}
              {/*>*/}
              {/*  <a onClick={(e) => e.preventDefault()}>*/}
              {/*    <Space>*/}
              {/*      Статус*/}
              {/*      <FilterIcon />*/}
              {/*    </Space>*/}
              {/*  </a>*/}
              {/*</Dropdown>*/}
              {/*<Dropdown*/}
              {/*  overlay={contragentFilterMenu}*/}
              {/*  trigger={["click"]}*/}
              {/*  // open={dropdownOpen}*/}
              {/*  // onOpenChange={handleDropdownOpenChange}*/}
              {/*>*/}
              {/*  <a onClick={(e) => e.preventDefault()}>*/}
              {/*    <Space>*/}
              {/*      Ответственный*/}
              {/*<FilterIcon />*/}
              {/*    </Space>*/}
              {/*  </a>*/}
              {/*</Dropdown>*/}
            </Filters>
            <Input
              type={EInputVariant.text}
              variant={EInputStyleVariant.searchInput}
              height={"32px"}
              maxWidth={"300px"}
              borderRadius={"10px"}
              placeholder={"Поиск по строке комментария"}
              value={searchValue !== null ? searchValue : ""}
              onChange={(e) => handleSearch(e)}
              pattern={searchValue ? "[0-9]*" : undefined}
              withReset={true}
              onReset={() => {
                setSearchValue(null);
              }}
            />
          </div>
          <Table />
        </div>
      ) : (
        <BigSkeleton />
      )}
    </div>
  );
});

import { InputSortOrder, InputSortType, Operation } from "./types";
import { toJS } from "mobx";

export const sortManualOperation = (
  operationsList: Operation[],
  pickedSortOrder: InputSortOrder | null,
  pickedSortType: InputSortType | null,
) => {
  if (!pickedSortType) return toJS(operationsList);
  switch (pickedSortType) {
    case InputSortType.date: {
      return sortArrayByDate(toJS(operationsList), pickedSortOrder);
    }
    case InputSortType.sum: {
      return sortArrayBySum(toJS(operationsList), pickedSortOrder);
    }
    case InputSortType.responsible: {
      return sortArrayByResponsible(toJS(operationsList), pickedSortOrder);
    }
    case InputSortType.comment: {
      return sortArrayByComment(toJS(operationsList), pickedSortOrder);
    }
    case null: {
      return toJS(operationsList);
    }
    default: {
      return toJS(operationsList);
    }
  }
};

const sortArrayByDate = (
  operationsList: Operation[],
  pickedSortOrder: InputSortOrder | null,
) => {
  if (!operationsList.length) return;

  return operationsList.slice().sort((a: Operation, b: Operation) => {
    const dateA = new Date(a.operation_date);
    const dateB = new Date(b.operation_date);
    if (pickedSortOrder === InputSortOrder.asc) {
      return dateA.getTime() - dateB.getTime();
    } else {
      return dateB.getTime() - dateA.getTime();
    }
  });
};

const sortArrayBySum = (
  operationsList: Operation[],
  pickedSortOrder: InputSortOrder | null,
) => {
  if (!operationsList.length) return;

  return operationsList.slice().sort((a: Operation, b: Operation) => {
    const sumA = a.amount;
    const sumB = b.amount;

    if (pickedSortOrder === InputSortOrder.asc) {
      return sumA - sumB;
    } else {
      return sumB - sumA;
    }
  });
};

const sortArrayByResponsible = (
  operationsList: Operation[],
  pickedSortOrder: InputSortOrder | null,
) => {
  return operationsList.slice().sort((a: Operation, b: Operation) => {
    const responsibleA = a.responsible_user
      ? a.responsible_user.toLowerCase()
      : "";
    const responsibleB = b.responsible_user
      ? b.responsible_user.toLowerCase()
      : "";

    if (pickedSortOrder === InputSortOrder.asc) {
      return responsibleB.localeCompare(responsibleA);
    } else {
      return responsibleA.localeCompare(responsibleB);
    }
  });
};
const sortArrayByComment = (
  operationsList: Operation[],
  pickedSortOrder: InputSortOrder | null,
) => {
  return operationsList.slice().sort((a: Operation, b: Operation) => {
    const responsibleA = a.comment ? a.comment.toLowerCase() : "";
    const responsibleB = b.comment ? b.comment.toLowerCase() : "";

    if (pickedSortOrder === InputSortOrder.asc) {
      return responsibleB.localeCompare(responsibleA);
    } else {
      return responsibleA.localeCompare(responsibleB);
    }
  });
};

import { FC,ReactNode } from 'react';
import { Tooltip } from 'ui-new/tooltip/tooltip';
import { InfoIcon } from '../icons';

interface InfoTooltipProps {
  message: string;
}

const InfoTooltip: FC<InfoTooltipProps> = ({ message }) => {
  return (<Tooltip activator={<div style={{ width: '16px',height: '16px' }}><InfoIcon /></div>} text={message} />);
};

interface InfoProps {
  children: ReactNode;
  message: string;
}

export const InfoContainer: FC<InfoProps> = ({ children,message }) => {
  return <div style={{ justifyContent: 'space-between',display: 'flex',alignItems: 'center',gap: '8px' }}>
    {children}
    <InfoTooltip message={message} />
  </div>;
};

import { FC,useEffect,useMemo,useState } from 'react';
import { MonthSelector } from 'widgets/month-selector';
import cls from '../styles.module.scss';
import { Segmented } from 'antd';
import { FundTabs,IStatus } from '../types';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { TableInfoMessage } from './table-info-message';

interface FundHeaderProps {
  activeTab: FundTabs;
  setActiveTab: (tab: FundTabs) => void;
}

const FundHeaderComponent: FC<FundHeaderProps> = ({ activeTab,setActiveTab }) => {
  const [buttonsVisible,setButtonsVisible] = useState(false);
  const { RootStore: { SalaryFundStore: { selectedDate,setSelectedDate,currentBonusStatus,currentShiftsStatus,
    managerCurrentStatus,currentContractStatus,netOrGross,
    setNetOrGross,planBonusConfirm,approveBonusConfirm,planShiftsConfirm,payShiftsConfirm,
    approveShiftsConfirm,payBonusConfirm,approveManagersConfirm,planManagersConfirm,
    planContractAwardConfirm,approveContractAwardConfirm,payManagersConfirm,payContractAwardConfirm } } } = useStore();
  const [currentStatus,setCurrentStatus] = useState<IStatus | null>(null);

  const [planClick,approveClick,payClick] = useMemo(() => {
    switch (activeTab) {
      case FundTabs['Премии']:
        return [planBonusConfirm,approveBonusConfirm,payBonusConfirm];
      case FundTabs['Вознаграждения за смены']:
        return [planShiftsConfirm,approveShiftsConfirm,payShiftsConfirm];
      case FundTabs['Управляющий']:
        return [planManagersConfirm,approveManagersConfirm,payManagersConfirm];
      case FundTabs['Контракты']:
        return [planContractAwardConfirm,approveContractAwardConfirm,payContractAwardConfirm];
      default:
        return [() => { },() => { },() => { }];
    }
  },[activeTab,approveBonusConfirm,approveContractAwardConfirm,approveManagersConfirm,approveShiftsConfirm,payShiftsConfirm,
    payBonusConfirm,planBonusConfirm,planContractAwardConfirm,planManagersConfirm,planShiftsConfirm,
    payManagersConfirm,payContractAwardConfirm]);

  useEffect(() => {
    setButtonsVisible(prev => prev === (activeTab !== FundTabs['Общая информация']) ? prev : !prev);
  },[activeTab]);

  useEffect(() => {
    setCurrentStatus(prev => {
      if (activeTab === FundTabs['Премии']) return currentBonusStatus;
      if (activeTab === FundTabs['Вознаграждения за смены']) return currentShiftsStatus;
      if (activeTab === FundTabs['Контракты']) return currentContractStatus;
      if (activeTab === FundTabs['Управляющий']) return managerCurrentStatus;
      return null;
    });
  },[activeTab,currentBonusStatus,currentContractStatus,currentShiftsStatus,managerCurrentStatus]);

  const approveDisabledByDate = useMemo(() => {
    const now = dayjs(new Date());
    if (currentStatus?.value === 8) {
      const firstPeriod = dayjs(selectedDate).set('date',15);
      const isAfter = firstPeriod.isAfter(now,'D');
      if (isAfter) return true;
    }
    if (currentStatus?.value === 7) {
      const secondPeriod = dayjs(selectedDate).add(1,'M').set('D',1);


      const isAfter = secondPeriod.isAfter(now,'D');
      const isSame = secondPeriod.isSame(now,'D');
      if (isAfter || isSame) return true;
    }

    return false;
  },[currentStatus?.value,selectedDate]);

  const [planDisabled,approveDisabled,paidDisabled] = useMemo(() => {
    switch (currentStatus?.value) {
      case 1:
        return [false,true,true];
      case 2:
        return [true,false,true];
      case 3:
        return [true,true,false];
      case 5:
        return [false,true,true];
      case 6:
        return [true,true,false];
      case 7:
        return [true,approveDisabledByDate,true];
      case 8:
        return [true,approveDisabledByDate,true];
      case 9:
        return [true,true,false];
      default:
        return [true,true,true];
    }
  },[approveDisabledByDate,currentStatus?.value]);

  return (<div className={cls['fund__header']}>
    <div className={cls['fund__header-container']}>
      <div style={{ display: 'flex',gap: '20px',justifySelf: 'start' }}>
        <MonthSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        <div className={cls['tabs__container']}>
          <button className={netOrGross === 'gross' ? cls['tab__active'] : cls['tab']} disabled={netOrGross === 'gross'} onClick={() => setNetOrGross('gross')}>Начислено</button>
          <button className={netOrGross === 'net' ? cls['tab__active'] : cls['tab']} disabled={netOrGross === 'net'} onClick={() => setNetOrGross('net')}>На руки</button>
        </div>
      </div>
      <Segmented
        className={cls['tabs']}
        value={FundTabs[activeTab]}
        options={['Общая информация','Вознаграждения за смены','Контракты','Премии','Управляющий']}
        onChange={(val: string) => setActiveTab((FundTabs as any)[val])}
      />
      <TableInfoMessage />
      {buttonsVisible &&
        <div className={cls['buttons-group']} style={{ flexDirection: 'row' }}>
          <button className={cls['button-primary']} disabled={planDisabled} onClick={planClick}>Запланировать</button>
          <button className={cls['button-primary']} disabled={approveDisabled} onClick={approveClick}>Утвердить</button>
          <button className={cls['button-primary']} disabled={paidDisabled} onClick={payClick}>Выплатить</button>
        </div>
      }
    </div>
  </div>);
};

export const FundHeader = observer(FundHeaderComponent);
import "./styles.scss";
import { formatMonthYear } from "assets/utils";
import Calendar from "react-calendar";
import { Value } from "react-calendar/src/shared/types";

interface MonthCalendarProps {
  dateSetter: (newDate: Value) => void;
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
}

export const MonthCalendar = ({
  dateSetter,
  isOpen,
  setIsOpen,
}: MonthCalendarProps) => {
  function handleChange(value: Value) {
    dateSetter(value);
    setIsOpen(false);
  }

  return (
    <Calendar
      view="year"
      minDetail="year"
      maxDetail="year"
      showNavigation={true}
      className={`month-calendar ${isOpen ? "month-calendar--hidden" : ""}`}
      formatMonth={formatMonthYear}
      onChange={handleChange}
    />
  );
};

import { makeAutoObservable } from "mobx";
import {
  IEmployee,
  INewShift,
  INewShiftWithTemplate,
  IShift,
  IShiftUpdate,
  ISpotTable,
  ITemplate,
  ITemplateNew,
  ITemplateUpdate,
  ITotalResults,
  IUnwanted,
  WorkerShortInfo,
} from "./types";
import {
  addHelper,
  createShift,
  createTemplate,
  deleteHelper,
  deleteShift,
  deleteTemplate,
  getEmployees,
  getResults,
  getTemplates,
  getTimetable,
  updateShift,
  updateTemplate,
} from "api/schedulePlanning";
import { showNotificationModal } from "ui-new/alert";
import { showConfirmModal } from "ui-new/alert/confirm";
import dayjs from "dayjs";

export class SchedulePlanningStore {
  constructor(rootStore: any) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }
  rootStore;
  //#region Смены
  selectedDate = new Date();
  selectedWorker: WorkerShortInfo | null = null;
  createShiftIsOpen = false;
  createShiftDate: Date | null = null;

  spotEmployees: ISpotTable = {};
  results: ITotalResults | null = null;

  editableShift: IShift | null = null;
  shiftUnwanted: IUnwanted[] = [];
  editShiftIsOpen = false;
  //#endregion

  //#region Хелперы
  employees: IEmployee[] = [];
  addHelperModalIsOpen = false;
  addHelperIsFetching = false;
  //#endregion

  //#region Шаблоны
  templates: ITemplate[] = [];
  createTemplateIsOpen = false;
  editableTemplate: ITemplate | null = null;
  //#endregion

  setSelectedDate = (date: Date) => {
    this.selectedDate = date;
  };

  //#region Методы для смен
  setCreateShiftIsOpen = (
    isOpen: boolean,
    date?: Date | null,
    unwanted?: IUnwanted[],
    worker?: WorkerShortInfo
  ) => {
    this.createShiftIsOpen = isOpen;
    this.createShiftDate = date ?? null;
    this.shiftUnwanted = unwanted ?? [];
    this.selectedWorker = worker ?? null;
  };

  setEditShiftIsOpen = (
    isOpen: boolean,
    shift?: IShift | null,
    unwanted?: IUnwanted[],
    worker?: WorkerShortInfo
  ) => {
    this.editShiftIsOpen = isOpen;
    this.editableShift = shift ?? null;
    this.shiftUnwanted = unwanted ?? [];
    this.selectedWorker = worker ?? null;
  };

  updateEmployeeShift = async (shift: IShift, isDelete?: boolean) => {
    const newResults = await getResults(
      dayjs(this.selectedDate).format("YYYY-MM")
    );

    if (!newResults || typeof newResults === "number") return;

    this.results = newResults.results;

    const spot = this.spotEmployees;
    try {
      Object.keys(spot).forEach(key => {
        const index = spot[key].unit_employees.findIndex(
          emp => emp.id === shift.user_id
        );
        if (index > -1) {
          spot[key].unit_employees[index].results.total =
            newResults.spot_employees[key].unit_employees[index].results.total;
          spot[key].unit_employees[index].results.dates.forEach(date => {
            Object.keys(date).forEach(key => {
              if (
                dayjs(key, "DD.MM.YYYY").isSame(
                  dayjs(shift.start_date_fact, "YYYY-MM-DD")
                )
              ) {
                if (isDelete) {
                  date[key].shift = [];
                  date[key].total = 0;
                } else {
                  date[key].shift = [shift];
                  date[key].total = shift.duration_fact;
                }
              }
            });
          });
          this.spotEmployees = { ...spot };
          throw new Error("Найдено");
        }
      });
    } catch (error) {}
  };

  fetchGetTimetable = async () => {
    const response = await getTimetable(
      dayjs(this.selectedDate).format("YYYY-MM")
    );

    if (!response || typeof response === "number") return;

    this.spotEmployees = response.spot_employees;
    this.results = response.results;
  };

  fetchCreateShift = async (newShift: INewShiftWithTemplate | INewShift) => {
    const response = await createShift(newShift);
    if (!response || typeof response === "number") return;

    this.createShiftIsOpen = false;
    showNotificationModal({ title: "Смена создана", type: "success" });
    this.updateEmployeeShift(response);
  };

  confirmUpdateShift = (shift: IShiftUpdate) => {
    const onConfirm = () => this.fetchUpdateShift(shift);
    showConfirmModal({
      title: "Сохранить изменения?",
      confirmButtonLabel: "Да, сохранить",
      onConfirm,
      maxWidth: "360px",
    });
  };
  fetchUpdateShift = async (shift: IShiftUpdate) => {
    const response = await updateShift(shift);

    if (!response || typeof response === "number") return;

    showNotificationModal({ title: "Смена изменена", type: "success" });
    this.setEditShiftIsOpen(false);
    this.updateEmployeeShift(response);
  };

  deleteShiftConfirm = () => {
    const editableShift = this.editableShift;
    if (!editableShift) return;

    showConfirmModal({
      title: "Ты уверен, что хочешь удалить смену?",
      confirmButtonLabel: "Да, удалять",
      onConfirm: () => this.fetchDeleteShift(editableShift),
    });
  };

  fetchDeleteShift = async (shift: IShift) => {
    const response = await deleteShift(shift.id);
    if (!response) return;

    this.updateEmployeeShift(shift, true);
    this.setEditShiftIsOpen(false);
    showNotificationModal({ title: "Смена удалена", type: "success" });
  };
  //#endregion

  //#region Методы для хелперов
  setAddHelperModalIsOpen = (isOpen: boolean) => {
    this.addHelperModalIsOpen = isOpen;
  };
  fetchGetEmployees = async () => {
    const response = await getEmployees();

    if (!response) return;

    this.employees = response;
  };

  fetchAddHelper = async (userId: IEmployee["id"]) => {
    this.addHelperIsFetching = true;
    const response = await addHelper(
      userId,
      dayjs(this.selectedDate).format("YYYY-MM")
    );
    this.addHelperIsFetching = false;
    if (!response || typeof response === "number") {
      this.setAddHelperModalIsOpen(false);
      return;
    }

    this.fetchGetTimetable();
    this.setAddHelperModalIsOpen(false);
    showNotificationModal({ title: "Хелпер добавлен!", type: "success" });
  };

  confirmDeleteHelper = (userId: IEmployee["id"]) => {
    showConfirmModal({
      title: "Удалить хелпера?",
      confirmButtonLabel: "Да, удалить",
      onConfirm: () => this.fetchDeleteHelper(userId),
      maxWidth: "316px",
      minWidth: "316px",
    });
  };

  fetchDeleteHelper = async (userId: IEmployee["id"]) => {
    const response = await deleteHelper(
      userId,
      dayjs(this.selectedDate).format("YYYY-MM")
    );
    if (!response) return;

    try {
      const spot = { ...this.spotEmployees };
      Object.keys(spot).forEach(key => {
        const empIndex = spot[key].unit_employees.findIndex(
          emp => emp.id === userId
        );
        if (empIndex < 0) return;

        spot[key].unit_employees.splice(empIndex, 1);
        this.spotEmployees = { ...spot };
        throw new Error("Работник найден и удалён");
      });
    } catch (error) {}
  };

  //#region Методы для шаблонов
  setCreateTemplateModalIsOpen = (isOpen: boolean) => {
    this.createTemplateIsOpen = isOpen;
  };

  setEditTemplateModalIsOpen = (isOpen: boolean, template?: ITemplate) => {
    this.createTemplateIsOpen = isOpen;
    this.editableTemplate = template ?? null;
  };

  setTemplates = (templates: ITemplate[]) => {
    this.templates = [...templates];
  };

  fetchGetTemplates = async () => {
    const response = await getTemplates();

    if (!response) return;

    this.setTemplates(response);
  };

  fetchCreateTemplate = async (template: ITemplateNew) => {
    const response = await createTemplate(template);

    if (!response || typeof response === "number") return;

    this.templates.push(response);

    this.createTemplateIsOpen = false;
    showNotificationModal({ title: "Шаблон создан", type: "success" });
  };

  fetchUpdateTemplate = async (template: ITemplateUpdate) => {
    const response = await updateTemplate(template);

    if (!response || typeof response === "number") return;

    const tIndex = this.templates.findIndex(t => t.id === response.id);
    if (tIndex !== -1) {
      this.templates.splice(tIndex, 1, response);
    }

    this.setEditTemplateModalIsOpen(false);
    this.fetchGetTimetable();
    showNotificationModal({ title: "Шаблон сохранен", type: "success" });
  };

  confirmTemplateDelete = (templateId: ITemplate["id"]) => {
    showConfirmModal({
      title: "Удалить шаблон?",
      confirmButtonLabel: "Да, удалять",
      onConfirm: () => this.fetchDeleteTemplate(templateId),
    });
  };
  fetchDeleteTemplate = async (templateId: ITemplate["id"]) => {
    const response = await deleteTemplate(templateId);
    if (!response) return;

    showNotificationModal({ title: "Шаблон удален", type: "success" });
    this.fetchGetTemplates();
    this.fetchGetTimetable();
  };
  //#endregion
}

import { observer } from "mobx-react-lite";
import { FC } from "react";
import cls from "./styles.module.scss";
import { Dividends } from "./dividends/dividends";

interface SpotPageProps {}

const SpotPageComponent: FC<SpotPageProps> = () => {
  return (
    <div className={cls["spot__wrapper"]}>
      <h2 className={cls["spot__title"]}>Карточка спота</h2>
      <Dividends />
    </div>
  );
};

export const SpotPage = observer(SpotPageComponent);

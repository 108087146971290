import {Operation, type OperationStatus, RegSortOrder, RegSortType} from "./types";
import { toJS } from "mobx";

export const tooltipStatusText: Record<OperationStatus, string> = {
  waiting: 'Ожидает заполнения',
  approved: 'Утверждено',
  pending: 'В ожидании',
  deleted: 'На удаление',
  delivery: 'Ожидает доставки',
}

export const sortRegistryDocument = (
  operationsList: Operation[],
  pickedSortOrder: RegSortOrder | null,
  pickedSortType: RegSortType | null,
) => {
  if (!pickedSortType) return toJS(operationsList);
  switch (pickedSortType) {
    case RegSortType.date: {
      return sortArrayByDate(toJS(operationsList), pickedSortOrder);
    }
    case RegSortType.document: {
      return sortArrayByDocument(toJS(operationsList), pickedSortOrder);
    }
    case RegSortType.number: {
      return sortArrayByNumber(toJS(operationsList), pickedSortOrder);
    }
    case RegSortType.contragent: {
      return sortArrayByContragent(toJS(operationsList), pickedSortOrder);
    }
    case RegSortType.sum: {
      return sortArrayBySumDebet(toJS(operationsList), pickedSortOrder);
    }
    case RegSortType.responsible: {
      return sortArrayByResponsible(toJS(operationsList), pickedSortOrder);
    }
    case null: {
      return toJS(operationsList);
    }
    default: {
      return toJS(operationsList);
    }
  }
};

const sortArrayByDate = (
  operationsList: Operation[],
  pickedSortOrder: RegSortOrder | null,
) => {
  if (!operationsList.length) return;

  return operationsList.slice().sort((a: Operation, b: Operation) => {
    const dateA = new Date(a.date.split(".").reverse().join("-"));
    const dateB = new Date(b.date.split(".").reverse().join("-"));
    if (pickedSortOrder === "asc") {
      return dateA.getTime() - dateB.getTime();
    } else {
      return dateB.getTime() - dateA.getTime();
    }
  });
};

const sortArrayByDocument = (
  operationsList: Operation[],
  pickedSortOrder: RegSortOrder | null,
) => {
  if (!operationsList.length) return;
  return operationsList.slice().sort((a: Operation, b: Operation) => {
    const documentA = a.document.toLowerCase();
    const documentB = b.document.toLowerCase();

    return pickedSortOrder === "asc"
      ? documentB.localeCompare(documentA)
      : documentA.localeCompare(documentB);
  });
};

const sortArrayByNumber = (
  operationsList: Operation[],
  pickedSortOrder: RegSortOrder | null,
) => {
  if (!operationsList) return;

  return operationsList.slice().sort((a: Operation, b: Operation) => {
    const numberA = getDocumentNumber(a.document);
    const numberB = getDocumentNumber(b.document);

    return pickedSortOrder === "asc" ? numberA - numberB : numberB - numberA;
  });
};

const sortArrayByContragent = (
  operationsList: Operation[],
  pickedSortOrder: RegSortOrder | null,
) => {
  if (!operationsList.length) return;

  return operationsList.slice().sort((a: Operation, b: Operation) => {
    const contragentA = (a.contragent || "").toLowerCase();
    const contragentB = (b.contragent || "").toLowerCase();

    if (pickedSortOrder === "asc") {
      return contragentB.localeCompare(contragentA);
    } else {
      return contragentA.localeCompare(contragentB);
    }
  });
};

const sortArrayBySumDebet = (
  operationsList: Operation[],
  pickedSortOrder: RegSortOrder | null,
) => {
  if (!operationsList.length) return;

  return operationsList.slice().sort((a: Operation, b: Operation) => {
    const sumA = a.sum_debet;
    const sumB = b.sum_debet;

    if (pickedSortOrder === "asc") {
      return sumA - sumB;
    } else {
      return sumB - sumA;
    }
  });
};

const sortArrayByResponsible = (
  operationsList: Operation[],
  pickedSortOrder: RegSortOrder | null,
) => {
  return operationsList.slice().sort((a: Operation, b: Operation) => {
    const responsibleA = a.responsible ? a.responsible.toLowerCase() : "";
    const responsibleB = b.responsible ? b.responsible.toLowerCase() : "";

    if (pickedSortOrder === "asc") {
      return responsibleB.localeCompare(responsibleA);
    } else {
      return responsibleA.localeCompare(responsibleB);
    }
  });
};

const getDocumentNumber = (document: string | undefined): number => {
  if (!document || !document.includes("№")) {
    return 0; // Default value if document is undefined or does not contain "№"
  }

  const numberString = document.split("№")[1];
  const parsedNumber = parseInt(numberString, 10);

  return isNaN(parsedNumber) ? 0 : parsedNumber;
};

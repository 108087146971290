import { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import { FailureModal } from 'widgets/modal/failure-modal';
import { SuccessModal } from 'widgets/modal/success-modal';

interface ShowNotificationModalProps {
  title: string;
  onClose?: () => void;
  type: 'success' | 'failure';
  errors?: ReactNode[];

  width?: string;
  maxWidth?: string;
}

export const showNotificationModal = ({
  title,
  onClose,
  type,
  errors,
  width,
  maxWidth
}: ShowNotificationModalProps) => {
  const modalRoot = document.createElement("div");
  modalRoot.setAttribute("id","modal-root");
  document.body.appendChild(modalRoot);

  const root = createRoot(modalRoot);

  const onCloseHandler = () => {
    onClose && onClose();
    root.unmount();
    if (document.body.contains(modalRoot))
      document.body.removeChild(modalRoot);
  };

  root.render(type === 'success' ?
    <SuccessModal title={title} onClose={onCloseHandler} width={width ?? '336px'} maxWidth={maxWidth ?? ''} />
    :
    <FailureModal title={title} onClose={onCloseHandler} errors={errors} minWidth={width ?? '336px'} maxWidth={maxWidth ?? '400px'} />
  );
};
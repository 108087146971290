import React,{ ReactNode,useEffect } from "react";
import "./styles.scss";
import { Button,EButtonVariant } from "../../ui";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { EFontVariant } from "assets/styles/enums";

interface PopupModalProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
  onClose?: () => void;
  onSave: () => void;
  maxWidth: string;
  zIndex?: string;
  buttonText?: string;
  backButtonText?: string;
  isButtonDisabled?: boolean;
  backButton?: boolean;
  onBackButtonClick?: () => void;
  withoutSave?: boolean;
}

export const PopupModal: React.FC<PopupModalProps> = observer(
  ({
    title,
    subtitle,
    children,
    onClose,
    onSave,
    maxWidth,
    zIndex,
    buttonText,
    backButtonText,
    isButtonDisabled,
    backButton,
    onBackButtonClick,
    withoutSave
  }) => {
    const { RootStore } = useStore();

    useEffect(() => {
      function handleKeyDown(event: KeyboardEvent) {
        if (event.key === "Escape") {
          RootStore.setModalIsActive(false);
        }
      }

      document.addEventListener("keydown",handleKeyDown);

      return () => {
        document.removeEventListener("keydown",handleKeyDown);
      };
    },[]);
    return (
      <>
        {RootStore.modalIsActive && (
          <>
            <div
              className="popupModal__container"
              style={{ maxWidth: `${maxWidth}`,zIndex: `${zIndex}` }}
            >
              <div className="popupModal__header">
                <div className="popupModal__title">
                  <p className="title">{title}</p>
                  {subtitle && <p className="subtitle">{subtitle}</p>}
                </div>
                <button
                  className="popupModal__exitButton"
                  onClick={() => {
                    RootStore.setModalIsActive(false);
                    onClose && onClose();
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.6817 6.12631L10.0005 8.80838L7.31758 6.12547C7.23735 6.04702 7.12948 6.00331 7.01727 6.00378C6.90507 6.00425 6.79756 6.04888 6.718 6.128L6.12854 6.71831C6.08819 6.75701 6.05602 6.80343 6.03394 6.85481C6.01186 6.90618 6.00032 6.96146 6.00001 7.01738C5.99969 7.0733 6.01061 7.1287 6.03211 7.18032C6.05361 7.23194 6.08526 7.27872 6.12517 7.31788L8.80724 9.9991L6.12601 12.682C6.04767 12.7623 6.00411 12.8703 6.00474 12.9825C6.00537 13.0947 6.05015 13.2021 6.12938 13.2816L6.71885 13.8711C6.89232 14.0454 7.15337 14.0395 7.31842 13.8744L10.0013 11.1924L12.6826 13.8744C12.7629 13.9528 12.8708 13.9963 12.983 13.9957C13.0952 13.9951 13.2027 13.9503 13.2821 13.8711L13.8724 13.2816C13.9127 13.2428 13.9448 13.1964 13.9668 13.145C13.9889 13.0936 14.0003 13.0383 14.0005 12.9823C14.0008 12.9264 13.9898 12.871 13.9682 12.8194C13.9466 12.7679 13.9149 12.7211 13.875 12.682L11.1921 9.9991L13.875 7.31788C13.9534 7.23766 13.9971 7.12978 13.9967 7.01757C13.9962 6.90537 13.9516 6.79786 13.8724 6.71831L13.283 6.12884C13.2435 6.0884 13.1963 6.05619 13.1443 6.03407C13.0923 6.01196 13.0364 6.00038 12.9798 6C12.9244 6.00045 12.8696 6.01184 12.8186 6.03352C12.7676 6.0552 12.7214 6.08673 12.6826 6.12631H12.6817Z"
                      fill="#0C0C0D"
                    />
                  </svg>
                </button>
              </div>
              <div className="popupModal__main">{children}</div>
              <div className="popupModal__footer">
                {backButton && (
                  <Button
                    variant={EButtonVariant.strokeButton}
                    font={EFontVariant.body2Medium}
                    height={"38px"}
                    maxWidth={"125px"}
                    borderRadius={"12px"}
                    text={backButtonText ? `${backButtonText}` : "Назад"}
                    onClick={() => {
                      onBackButtonClick
                        ? onBackButtonClick()
                        : RootStore.setModalIsActive(false);
                    }}
                  />
                )}
                {!withoutSave &&
                  <Button
                    variant={EButtonVariant.fillButton}
                    font={EFontVariant.body2Medium}
                    height={"38px"}
                    maxWidth={"125px"}
                    borderRadius={"12px"}
                    text={buttonText ? `${buttonText}` : "Сохранить"}
                    onClick={onSave}
                    disabled={isButtonDisabled}
                    data-testid={"save-modal-btn"}
                  />
                }
              </div>
            </div>
            <div
              className="popupModal__overlay"
              onClick={() => {
                RootStore.setModalIsActive(false);
                onClose && onClose();
              }}
            ></div>
          </>
        )}
      </>
    );
  },
);

import React from "react";
import { lazy,Suspense } from "react";
import { Route,createRoutesFromElements,RouterProvider } from "react-router";
import "./styles.scss";
import { InitPage } from "pages";
import { AuthSkeleton,RevenuePlanningSkeleton } from "ui";
import { OnboardingProcess } from "app/processes";
import { MainProcess } from "app/processes/main-process";
import { useStore } from "store";
import { createBrowserRouter } from "react-router-dom";
import { TourProvider } from "@reactour/tour";
import { NotFoundPage } from "../pages/not-found";
import ProtectedRoute,{
  ProtectedRouteProps,
} from "./processes/protectedRoute";
import { tourProps } from "./tours/tourProps";
import { observer } from "mobx-react-lite";
import * as process from "process";
import ErrorBoundary from './errorBoundary/ErrorBoundary';

const LoginProcess = lazy(() =>
  import("app/processes").then((module) => ({ default: module.LoginProcess })),
);

const WrappedApp = observer(function App() {
  const {
    RootStore: { isAuthenticated },
  } = useStore();

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps,"outlet"> = {
    isAuthenticated,
    authenticationPath: "/login/auth",
  };

  const loginProtectedRouteProps: Omit<ProtectedRouteProps,"outlet"> = {
    isAuthenticated: !isAuthenticated,
    authenticationPath: "/main/registry",
  };

  if (process.env.REACT_APP_IS_DEV !== "true") {
    console = console || {};
    console.log = function () { };
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route
          path="/"
          element={
            <Suspense fallback="Loading...">
              <InitPage />
            </Suspense>
          }
        />

        <Route
          path="/login/*"
          element={
            <Suspense fallback={<AuthSkeleton />}>
              <ProtectedRoute
                {...loginProtectedRouteProps}
                outlet={<LoginProcess />}
              />
            </Suspense>
          }
        />

        <Route
          path="/onboarding/*"
          element={
            <Suspense fallback="Loading...">
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<OnboardingProcess />}
              />
            </Suspense>
          }
        />

        <Route
          path="/main/*"
          element={
            <Suspense fallback={<RevenuePlanningSkeleton />}>
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<MainProcess />}
              />
            </Suspense>
          }
        />

        <Route
          path="/*"
          element={
            <Suspense fallback={<RevenuePlanningSkeleton />}>
              <NotFoundPage />
            </Suspense>
          }
        />
      </Route>,
    ),
  );

  return (
    <TourProvider steps={[]} {...tourProps}>
      <RouterProvider router={router} />
    </TourProvider>
  );
});

export default WrappedApp;

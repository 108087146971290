import { api } from "./api";

export async function fetchCloseMonth(date: string) {
  try {
    const response = await api.post(`month/close`, {
      month: date,
    });
    return response.data;
  } catch (e) {
    return null;
  }
}
export async function fetchStatusCloseMonth(date: string) {
  try {
    const response = await api.post(`month/status`, {
      month: date,
    });
    return response.data;
  } catch (e) {
    return null;
  }
}

import * as yup from "yup";
import { ValidationMessages, ValidationTypes } from "assets/utils/validationMessages";
import {
  IMoneyTransactionFieldsByOperation,
  MoneyTransactionDynamicFormFields,
} from "types/money-transactions";

export const createSchema = (fields: IMoneyTransactionFieldsByOperation | null) => {
  let shape: Record<string, yup.AnySchema> = {};
  if (fields)
    shape = Object.entries(fields).reduce((acc, [field, val]) => {
      if (!val.show) return acc;

      switch (field) {
        case MoneyTransactionDynamicFormFields.CashFlowArticleId:
          acc[MoneyTransactionDynamicFormFields.CashFlowArticleId] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.required) as string)
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        case MoneyTransactionDynamicFormFields.Commission:
          acc[MoneyTransactionDynamicFormFields.Commission] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.number) as string)
            .required(ValidationMessages.get(ValidationTypes.required))
            .min(0, () => ValidationMessages.get(ValidationTypes.min) + ` 0,00`);
          break;
        case MoneyTransactionDynamicFormFields.ContragentId:
          acc[MoneyTransactionDynamicFormFields.ContragentId] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.required) as string)
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        case MoneyTransactionDynamicFormFields.Period:
          acc[MoneyTransactionDynamicFormFields.Period] = yup
            .date()
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        case MoneyTransactionDynamicFormFields.PnLArticleId:
          acc[MoneyTransactionDynamicFormFields.PnLArticleId] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.required) as string)
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        case MoneyTransactionDynamicFormFields.TaxAmount:
          acc[MoneyTransactionDynamicFormFields.TaxAmount] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.number) as string)
            .required(ValidationMessages.get(ValidationTypes.required))
            .min(0, () => ValidationMessages.get(ValidationTypes.min) + ` 0,00`);
          break;
        case MoneyTransactionDynamicFormFields.TaxRate:
          acc[MoneyTransactionDynamicFormFields.TaxRate] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.number) as string)
            .required(ValidationMessages.get(ValidationTypes.required))
            .min(0, ({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min}`)
            .max(100, ({ max }) => ValidationMessages.get(ValidationTypes.max) + ` ${max}`);
          break;
        case MoneyTransactionDynamicFormFields.TaxTypeId:
          acc[MoneyTransactionDynamicFormFields.TaxTypeId] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.required) as string)
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        case MoneyTransactionDynamicFormFields.TypeOfCommitmentId:
          acc[MoneyTransactionDynamicFormFields.TypeOfCommitmentId] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.required) as string)
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        default:
          acc[field] = yup.string();
      }

      acc[field] = acc[field].when((_, schema) => {
        if (!val.show) return schema.notRequired();
        return schema;
      });
      return acc;
    }, {} as Record<string, yup.AnySchema>);

  shape["date_delivery"] = yup
    .date()
    .max(new Date(), "Дата проведения не может быть больше сегодняшней даты")
    .required(ValidationMessages.get(ValidationTypes.required));
  shape["number"] = yup
    .string()
    .matches(/^[0-9]+$/, "Допустимы только буквы и цифры")
    .required(ValidationMessages.get(ValidationTypes.required));
  shape["pay_type_id"] = yup
    .number()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .required(ValidationMessages.get(ValidationTypes.required));
  shape["operation_type_id"] = yup
    .number()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .required(ValidationMessages.get(ValidationTypes.required));
  return yup.object().shape(shape);
};

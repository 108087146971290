import React from "react";
import "../styles.scss";

interface OFRTableRowProps {
  name: String;
  number: String;
  isGrey?: boolean | undefined;
  isBold?: boolean | undefined;
}

export const OFRTableRow = ({
  name,
  number,
  isBold,
  isGrey,
}: OFRTableRowProps) => {
  return (
    <div
      className={`ofr__table__row transition ${
        isGrey && "ofr__table__row__grey"
      }
      ${isBold && "ofr__table__row__bold"}
      `}
    >
      <div
        className={`ofr__table__row__name table-item 
        `}
      >
        {name}
      </div>
      <div className="ofr__table__row__number table-item">{number}</div>
    </div>
  );
};

import { observer } from 'mobx-react-lite';
import { useCallback,useState } from 'react';
import { Area,AreaChart,CartesianGrid,ResponsiveContainer,Tooltip,XAxis,YAxis } from 'recharts';
import { CustomTick } from './tick';
import { CustomTooltipContent } from './tooltip';
import { ChartHeader } from './header';
import "../styles.scss";
import { useStore } from 'store';

const BoardComponent = () => {
  const { RootStore: { DashboardStore: { data } } } = useStore();
  const [activeChars,setActiveChars] = useState({ count: true,average: true });

  const changeActiveChar = useCallback((name: keyof typeof activeChars) => {
    setActiveChars(prev => {
      prev[name] = !prev[name];
      return { ...prev };
    });
  },[]);

  return (
    <div className="dashboard__chart">
      <ChartHeader activeChars={activeChars} changeActiveChar={changeActiveChar} />
      <div className="chart__container">
        {/* Порядок компонентов внутри графика имеет большое значение. */}
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid vertical={true} />
            <XAxis
              dataKey={'period'}
              axisLine={false}
              tickLine={false}
              xAxisId={0}
              tick={<CustomTick />}
            />
            {/* XAxis снизу нужен, как ориентир для других компонентов. Мы обращаемся к нему с
                  помощью xAxisId. Его ключевое значение - id, параметр,
                  который мы вручную добавляли элементам в методе parseDashboardData(). Другого способа стабильно(!)
                  обращаться к элементу графика именно по его порядковому номеру - нет.   */}
            <XAxis dataKey={"id"} visibility={"hidden"} xAxisId={1} />
            <YAxis axisLine={false} tickLine={false} />
            <Tooltip
              position={{ y: 150 }}
              wrapperStyle={{ visibility: "visible",height: "100%" }}
              content={<CustomTooltipContent />}
            />
            {activeChars.count && <Area
              type="monotone"
              dataKey={'count'}
              stroke="#9747FF"
              fill="#D2BCEE69"
            />}
            {activeChars.average && <Area
              type="monotone"
              dataKey="average"
              stroke="#70B6F6"
              fill="#70B6F61F"
            />}
            {/* Фискированный курсор с точками в случае, если выбран элемент */}
            {/*activeId != null && (
              <>
                <ReferenceLine
                  stroke="#212129"
                  x={activeId}
                  xAxisId={1}
                />{" "}
                {activeChars.count && <ReferenceDot
                  isFront={true}
                  r={5}
                  fill="#892DFF"
                  stroke="white"
                  x={activeId}
                  xAxisId={1}
                  y={data[activeId].count}
                />}
                {activeChars.average && <ReferenceDot
                  isFront={true}
                  r={5}
                  fill="#70B6F6"
                  stroke="white"
                  x={activeId}
                  xAxisId={1}
                  y={data[activeId].average}
                />}
              </>
            )*/}
          </AreaChart>
        </ResponsiveContainer>
      </div>
      {/* {activeId !== null && <button className='active-date' onClick={() => setActiveId(null)}>Снять выделение</button>} */}
    </div>
  );
};

export const Board = observer(BoardComponent);
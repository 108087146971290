export const ExpandPlusMinus = (props: { expanded: boolean }) => {
  const { expanded } = props;
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="21" height="21" rx="7.5" fill="white" />
      <path
        d="M6.33334 10.5625H15.6667V11.4375H6.33334V10.5625Z"
        fill="#222C37"
      />
      {!expanded && (
        <path
          d="M6.33334 10.5625H15.6667V11.4375H6.33334V10.5625Z"
          fill="#222C37"
          transform="rotate(90 11 11)"
        />
      )}
      <rect x="0.5" y="0.5" width="21" height="21" rx="7.5" stroke="#E5E7EB" />
    </svg>
  );
};

interface IconProps {
  onClick?: () => void;
}

export const CloseIcon1 = ({ onClick }: IconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick()}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0605 8L15.6012 14.5407L14.5406 15.6014L7.99984 9.06066L1.4591 15.6014L0.398438 14.5407L6.93918 8L0.398439 1.45926L1.4591 0.398602L7.99984 6.93934L14.5406 0.398602L15.6012 1.45926L9.0605 8Z"
        fill="#171D27"
      />
    </svg>
  );
};

export const DropdownIcon = ({ onClick }: IconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.1386 5.86196L8 8.72391L10.8614 5.86196C10.9233 5.80003 10.9969 5.75091 11.0778 5.71739C11.1587 5.68388 11.2454 5.66663 11.333 5.66663C11.4206 5.66663 11.5073 5.68388 11.5883 5.71739C11.6692 5.75091 11.7427 5.80003 11.8046 5.86196C11.8666 5.92389 11.9157 5.99741 11.9492 6.07832C11.9827 6.15923 12 6.24596 12 6.33354C12 6.42112 11.9827 6.50784 11.9492 6.58875C11.9157 6.66966 11.8666 6.74318 11.8046 6.80511L8.47162 10.1378C8.40971 10.1998 8.33619 10.2489 8.25527 10.2825C8.17434 10.316 8.0876 10.3333 8 10.3333C7.9124 10.3333 7.82565 10.316 7.74473 10.2825C7.66381 10.2489 7.59029 10.1998 7.52838 10.1378L4.19535 6.80511C4.13342 6.74318 4.08429 6.66966 4.05077 6.58875C4.01725 6.50784 4 6.42112 4 6.33354C4 6.24596 4.01725 6.15923 4.05077 6.07832C4.08429 5.99741 4.13342 5.92389 4.19535 5.86196C4.25729 5.80003 4.33081 5.75091 4.41173 5.71739C4.49266 5.68388 4.57939 5.66663 4.66698 5.66663C4.75456 5.66663 4.84129 5.68388 4.92222 5.71739C5.00314 5.75091 5.07666 5.80003 5.1386 5.86196Z"
        fill="#272729"
      />
    </svg>
  );
};

export const CalendarIcon = () => {
  return (
    <svg
      className="revenueHeader__calendar-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 2.5H13.75V1.25H12.5V2.5H7.5V1.25H6.25V2.5H3.75C3.0625 2.5 2.5 3.0625 2.5 3.75V16.25C2.5 16.9375 3.0625 17.5 3.75 17.5H16.25C16.9375 17.5 17.5 16.9375 17.5 16.25V3.75C17.5 3.0625 16.9375 2.5 16.25 2.5ZM16.25 16.25H3.75V7.5H16.25V16.25ZM16.25 6.25H3.75V3.75H6.25V5H7.5V3.75H12.5V5H13.75V3.75H16.25V6.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

import "./styles.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BigSkeleton, Button, EButtonVariant } from "ui";
import { observer } from "mobx-react-lite";
import { EmptyTableBody } from "../../ui/empty-table-body";
import { TableFooter } from "widgets/table-footer";
import { UploaderModalBS } from "./components/uploaderModal";
import { useStore } from "store";
import { BankStatementsFilters } from "./components/filters/filters";
import { TableRowBS } from "./components/row";
import { TableHeader } from "./components/tableHeader";
import { IMoneyTransaction } from "types/money-transactions";
import { toJS } from "mobx";
import { UploadResultsModal } from "./components/upload-results-modal";

export const BankStatementsPageComponent = () => {
  const { RootStore } = useStore();
  const {
    MoneyTransactionsStore,
    EditMoneyTransactionStore: { fetchGetTransactionGuide },
  } = RootStore;
  const {
    isMoneyTransactionsLoading,
    fetchGetMoneyTransactions,
    parserInfo,
    moneyTransactions,
    filterContragents,
    filterOperationTypes,
    filterPayTypes,
    filterPeriodEnd,
    filterPeriodStart,
    filterMoneyTransactionTypes,
    filterStatus,
    searchText,
    sortOrder,
    sortParam,
  } = MoneyTransactionsStore;

  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [uploadResultsModalIsOpen, setUploadResultsModalIsOpen] = useState(false);

  const [firstItem, setFirstItem] = useState(1);
  const [lastItem, setLastItem] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [maxOperationsNumber, setMaxOperationsNumber] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchGetTransactionGuide();
  }, []);

  useEffect(() => {
    fetchGetMoneyTransactions();
  }, [
    fetchGetMoneyTransactions,
    filterContragents,
    filterOperationTypes,
    filterPayTypes,
    filterPeriodEnd,
    filterPeriodStart,
    filterStatus,
    filterMoneyTransactionTypes,
    searchText,
    sortOrder,
    sortParam,
  ]);

  useEffect(() => {
    if (!parserInfo) {
      setFirstItem(1);
      setLastItem(1);
      setCurrentPage(1);
      setTotalItems(0);
      return;
    }

    const { current_page, from, per_page, to, total } = parserInfo;
    setFirstItem(prev => (from === prev ? prev : from));
    setLastItem(prev => (to === prev ? prev : to));
    setTotalItems(prev => (prev === total ? prev : total));
    setMaxOperationsNumber(prev => (prev === per_page ? prev : per_page));
    setCurrentPage(prev => (prev === current_page ? prev : current_page));
  }, [parserInfo]);

  useEffect(() => {
    if (firstItem === null || lastItem === null)
      fetchGetMoneyTransactions({ page: 1, per_page: maxOperationsNumber });
  }, [firstItem, lastItem]);

  const onPageChange = useCallback(
    (currentPage: number) => {
      fetchGetMoneyTransactions({ page: currentPage, per_page: maxOperationsNumber });
    },
    [fetchGetMoneyTransactions, maxOperationsNumber]
  );

  const onMaxChange = useCallback(
    (maxSize: number) => {
      fetchGetMoneyTransactions({ per_page: maxSize, page: currentPage });
    },
    [currentPage, fetchGetMoneyTransactions]
  );

  const transactionsData = useMemo(() => {
    if (!(moneyTransactions.length > 0)) {
      return <EmptyTableBody />;
    }
    return toJS(moneyTransactions).map((statement: IMoneyTransaction) => (
      <TableRowBS rowData={statement} index={statement.id} />
    ));
  }, [moneyTransactions]);

  return (
    <div className="statements__wrapper">
      <div className="statements__header">
        <h2 className="statements__header__title">Денежные операции</h2>
        <Button
          variant={EButtonVariant.fillButton}
          text={"Загрузить выписку"}
          maxWidth={"165px"}
          onClick={() => setUploadModalIsOpen(true)}
        />
      </div>
      <BankStatementsFilters />
      {isMoneyTransactionsLoading ? (
        <BigSkeleton />
      ) : (
        <div className="statements-table__wrapper">
          <TableHeader />
          <div className="statements-table__body">{transactionsData}</div>
          {moneyTransactions.length > 0 && (
            <TableFooter
              currentPage={currentPage}
              setCurrentPage={onPageChange}
              maxOperationsNumber={maxOperationsNumber}
              setMaxOperationsNumber={onMaxChange}
              firstItem={firstItem}
              lastItem={lastItem}
              setFirstItem={() => {}}
              setLastItem={() => {}}
              itemsList={moneyTransactions}
              total={totalItems}
              fromResponse
            />
          )}
        </div>
      )}
      {uploadModalIsOpen && (
        <UploaderModalBS
          onClose={() => setUploadModalIsOpen(false)}
          onLoad={() => setUploadResultsModalIsOpen(true)}
        />
      )}
      {uploadResultsModalIsOpen && (
        <UploadResultsModal onClose={() => setUploadResultsModalIsOpen(false)} />
      )}
    </div>
  );
};

export const BankStatementsPage = observer(BankStatementsPageComponent);

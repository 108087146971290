import { api } from "./api";

export async function fetchBalanceData(month: string) {
  try {
    return api.post(`report/balance`, { date: month });
  } catch (e) {
    return null;
  }
}

export async function fetchOFRData(month: string) {
  try {
    return await api.post(`report/ofr`, { date: month });
  } catch (e) {
    return null;
  }
}

export async function fetchODDSData(periodStart: string, periodEnd: string) {
  try {
    return await api.post(`report/odds`, {
      date_start: periodStart,
      date_finish: periodEnd,
    });
  } catch (e) {
    return null;
  }
}

export async function fetchOSVData(periodStart: string, periodEnd: string) {
  try {
    return await api.post(`report/osv`, {
      date_start: periodStart,
      date_finish: periodEnd,
    });
  } catch (e) {
    return null;
  }
}

export async function fetchORPData(periodStart: string, periodEnd: string) {
  try {
    return await api.post(`report/orp`, {
      date_start: periodStart,
      date_finish: periodEnd,
    });
  } catch (e) {
    return null;
  }
}

export interface Props {
    className?: string;
}

export const CalendarIcon = ({ className }: Props) => {

    return (
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <rect x="1.3335" y="2.66797" width="13.3333" height="12" rx="2" stroke="black" strokeWidth="1.4"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.3335 6.66667H14.6668" stroke="black" strokeWidth="1.4" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M5.66667 1.33203V3.9987" stroke="black" strokeWidth="1.4" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M10.3332 1.33203V3.9987" stroke="black" strokeWidth="1.4" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M14.6665 4.33203L14.6665 12.9987" stroke="black" strokeWidth="1.4" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M1.3335 4.33203L1.3335 12.9987" stroke="black" strokeWidth="1.4" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M4.6665 14.668H11.3332" stroke="black" strokeWidth="1.4" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}
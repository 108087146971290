import { Notification } from "./types";
import { getWeekDay, monthsNames, todayString } from "../../assets/utils";
import arrow from "../../assets/icons/svg/notif-arrow.svg";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useStore } from "store";
import { toJS } from "mobx";

export const SingleNotification = ({
  id,
  date,
  time,
  unread,
  type,
  fields,
}: Notification) => {
  const { RootStore } = useStore();
  const { NotificationsStore } = RootStore;
  const { readSingleNTF } = NotificationsStore;
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const tagColor: string =
    type === "cancel"
      ? "red"
      : type === "document"
        ? "green"
        : type === "revenue" || type === "schedule"
          ? "blue"
          : "grey";
  function handleHeaderClick() {
    setOpen(!open);
    readSingleNTF(id);
  }

  return (
    <div className="notifications-item">
      {/* ШАПКА УВЕДОМЛЕНИЯ: ТЕГ, ДАТА И ТОЧКА ПРОЧИТАНО/НЕПРОЧИТАНО */}
      <div className="notifications-item__header" onClick={handleHeaderClick}>
        <div className="notifications-item__header__left">
          <p
            className={`notifications-item__tag notifications-item__tag--${tagColor}`}
          >
            {type === "cancel"
              ? "Отмена смены"
              : type === "revenue" || type === "schedule"
                ? "Планирование"
                : type === "document"
                  ? "Документы"
                  : "Система"}
          </p>
          <p className="notifications-item__time-date">
            {date === todayString() ? time : `${time} ${date}`}
          </p>
        </div>
        <div className="notifications-item__dot">
          <span
            className={`notifications-dot ${
              unread ? "notifications-dot--unread" : ""
            }`}
          />
        </div>
      </div>

      {/* ЗАГОЛОВОК УВЕДОМЛЕНИЯ И СТРЕЛКА ОТКРЫТИЯ ДЕТАЛЕЙ */}
      <div className="notifications-item__title" onClick={handleHeaderClick}>
        {type === "cancel" ? (
          <p className="notifications-item__title__text">
            <b>{fields.workerName} </b> хочет отменить смену{" "}
            <b style={{ whiteSpace: "nowrap" }}>{String(fields.date!)} </b>
          </p>
        ) : type === "document" ? (
          <p className="notifications-item__title__text">
            <b>{fields.uploaderName} </b>
            {"загрузил(а) новый документ - "}
            <b>{fields.docName}</b>
            {", нужно его проверить"}
          </p>
        ) : type === "revenue" ? (
          <p className="notifications-item__title__text">
            {"Пора запланировать выручку по споту на "}
            <b>{monthsNames[fields.month!]}</b>
          </p>
        ) : type === "schedule" ? (
          <p className="notifications-item__title__text">
            {"Пора запланировать график сотрудников по работе на споте на "}
            <b> {monthsNames[fields.month!]}</b>
          </p>
        ) : (
          <p className="notifications-item__title__text">{fields.title} </p>
        )}
        <div className="notifications-item__arrow">
          <img src={arrow} alt={"arrow"} />
        </div>
      </div>

      {/* ДЕТАЛИ: ПОДРОБНЫЙ ТЕКСТ И КНОПКА (ДЕЙСТВИЕ И НАЗВАНИЕ КНОПКИ ЗАВИСИТ ОТ ТИПА УВЕДОМЛЕНИЯ) */}
      {open ? (
        <div className="notifications-details">
          {type === "document" ? (
            <p className="notifications-details__title">Новый документ</p>
          ) : type === "revenue" ? (
            <p className="notifications-details__title">Планирование выручки</p>
          ) : type === "schedule" ? (
            <p className="notifications-details__title">Планирование графика</p>
          ) : type === "cancel" ? (
            <p className="notifications-details__title">
              Согласуй отмену смены
            </p>
          ) : (
            ""
          )}
          {type === "document" ? (
            <p className="notifications-details__text">
              <b> {fields.uploaderName}</b> загрузил(а) <b> {fields.docName} </b>
              <b>{fields.uploadedAt}</b>. Проверь правильность заполнения, если
              все корректно - утверди документ
            </p>
          ) : type === "revenue" ? (
            <p className="notifications-details__text">
              <b>{monthsNames[fields.month!]}</b> на подходе, нужно
              запланировать выручку по дням для спота
            </p>
          ) : type === "schedule" ? (
            <p className="notifications-details__text">
              <b>{monthsNames[fields.month!]}</b>
              {" на подходе, запланируй смены бариста для спота на месяц"}
            </p>
          ) : type === "cancel" ? (
            <p className="notifications-details__text">
              <b>{fields.workerName}</b> хочет отменить смену{" "}
              <b>
                {fields.date}
                {", "}с{" "}
                {new Date(
                  `2023-01-01T${fields.cancel_start}Z`,
                ).toLocaleTimeString("ru-RU")}{" "}
                по{" "}
                {new Date(
                  `2023-01-01T${fields.cancel_finish}Z`,
                ).toLocaleTimeString("ru-RU")}
              </b>
              , потому что <b>"{fields.reason}"</b> . Перейди в раздел
              Планирование графика и согласуй отмену или оставь смену в графике.
              Если согласуешь отмену - не забудь найти замену :)
            </p>
          ) : type === "system" ? (
            <p className="notifications-details__text"> {fields.text} </p>
          ) : (
            ""
          )}

          {type === "cancel" ? (
            <button
              onClick={() => navigate("/main/schedule")}
              className="notifications-details__button"
            >
              Открыть Планирование графика
            </button>
          ) : type === "document" ? (
            <button
              className="notifications-details__button"
              onClick={() =>
                navigate(
                  `/main/registry/${fields.documentType}#${fields.documentId}`,
                )
              }
            >
              Перейти к документу
            </button>
          ) : type === "revenue" ? (
            <button
              className="notifications-details__button"
              onClick={() => navigate("/main/spot")}
            >
              Запланировать
            </button>
          ) : // (открыть раздел Планирование выручки)
          type === "schedule" ? (
            <button
              className="notifications-details__button"
              onClick={() => navigate("/main/schedule")}
            >
              Запланировать
            </button>
          ) : // (открыть раздел Планирование графика)
          type === "system" ? (
            ""
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

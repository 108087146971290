import { api, IBaseResponse } from "./api";

export async function fetchDashboardData(dateStart: string, dateEnd: string) {
  try {
    const response = await api.post(`dashboard/info`, {
      dateStart,
      dateEnd,
    });
    return response;
  } catch (e) {
    return null;
  }
}

interface IBillsResponse {
  bills: {
    period: string[];
    average_result: number[];
    count_result: number[];
  };

  by_report: {
    average: number;
    count: number;
  };
}

//YYYY-MM-DD
export async function getBills(day?: string | null) {
  try {
    const response = await api.post<IBaseResponse<IBillsResponse>>(
      `${process.env.REACT_APP_API_BASE}/api/v3/dashboard/bills/get`,
      { day }
    );

    console.log(response);

    return response.data.data;
  } catch (error) {
    return null;
  }
}

import { getContragentsLoans } from "api/contragentsLoans";
import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { RootStore } from "store/rootStore";
import { IContragentLoan, IInvoice } from "./types";
import { IContragent } from "types/directories";

export class ContragentsLoansPage {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "ContragentsLoans",
      properties: ["selectedDate"],
      storage: window.sessionStorage,
    }).then(() => {
      if (typeof this.selectedDate === "string") this.selectedDate = new Date(this.selectedDate);
    });
    this.rootStore = rootStore;
  }

  selectedDate = new Date();

  setSelectedDate = (date: Date) => {
    this.selectedDate = date;
  };

  contragents: IContragent[] = [];

  byInvoices: IInvoice[] = [];
  byContragents: IContragentLoan[] = [];

  fetchGetContragentsLoans = async () => {
    const response = await getContragentsLoans(dayjs(this.selectedDate).format("YYYY-MM"));

    if (!response) return;

    this.byInvoices = response.byInvoices;
    this.byContragents = response.byContragents;
  };
}

import "./styles.scss";
import { SingleNotification } from "./singleNotification";
// import { list } from './mock';
import { getWeekDay, todayString } from "../../assets/utils";
import arrow from "../../assets/icons/svg/notif-arrow.svg";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { RefObject } from "react";
import { Notification } from "./types";

interface NotificationsProps {
  isOpen: boolean;
  notifRef: RefObject<HTMLDivElement>;
}

export const Notifications = observer(
  ({ isOpen, notifRef }: NotificationsProps) => {
    const { RootStore } = useStore();
    const { NotificationsStore } = RootStore;
    const { readAllNTF, listNTF } = NotificationsStore;

    return isOpen ? (
      <div className="notifications" ref={notifRef}>
        <div className="notifications__wrapper">
          <>
            <div className="notifications__header">
              <p className="notifications__title">Уведомления</p>
              <p
                className={`${
                  listNTF.length > 0
                    ? "notifications__read-all"
                    : "notifications__read-all notifications__read-all--disabled"
                }`}
                onClick={() => {
                  if (listNTF.length > 0) {
                    readAllNTF();
                  }
                }}
              >
                Отметить всё как прочитанное
              </p>
            </div>
            <div className="notifications__content">
              {listNTF.length !== 0 ? (
                <div className="notifications__list">
                  {listNTF.some(
                    (notif: Notification) => notif.date === todayString(),
                  ) ? (
                    <div className="notifications__group">
                      <p className="notifications__group__title">CЕГОДНЯ</p>
                      {listNTF
                        .filter(
                          (notif: Notification) => notif.date === todayString(),
                        )
                        .reverse()
                        .map((notification: Notification, index: number) => (
                          <SingleNotification key={index} {...notification} />
                        ))}
                    </div>
                  ) : (
                    ""
                  )}

                  {listNTF.some(
                    (notif: Notification) => notif.date !== todayString(),
                  ) ? (
                    <div className="notifications__group">
                      <p className="notifications__group__title">СТАРЫЕ</p>
                      {listNTF
                        .filter(
                          (notif: Notification) => notif.date !== todayString(),
                        )
                        .reverse()
                        .map((notification: Notification, index: number) => {
                          return (
                            <SingleNotification key={index} {...notification} />
                          );
                        })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="notifications__noitems">
                  <p>Пока что уведомлений нет</p>
                  <p>Здесь вы сможете просматривать уведомления</p>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
    ) : (
      <div></div>
    );
  },
);

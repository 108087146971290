import React from "react";
import { PopupModal } from "../../../widgets/popup-modal";
import { EInputStyleVariant, EInputVariant, Input } from "../../../ui";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";

interface DeleteModalProps {
  setDeleteModalIsActive: (arg0: boolean) => void;
}

const DeleteModal = observer(({ setDeleteModalIsActive }: DeleteModalProps) => {
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: { draftComment, setDraftComment, deleteStatus },
  } = RootStore;
  return (
    <PopupModal
      title={"Пометить на удаление"}
      subtitle={"Добавьте причину удаления."}
      onSave={() => {
        deleteStatus();
        setDeleteModalIsActive(false);
        RootStore.setModalIsActive(false);
      }}
      maxWidth={"440px"}
      onClose={() => {
        setDeleteModalIsActive(false);
        RootStore.setModalIsActive(false);
      }}
    >
      <div style={{ width: "100%", padding: "8px 20px" }}>
        <p className={"delete-modal__title"}>Причина удаления</p>
        <Input
          type={EInputVariant.text}
          variant={EInputStyleVariant.basicInput}
          height={"44px"}
          borderRadius={"16px"}
          value={draftComment}
          onChange={(e) => {
            setDraftComment(e.currentTarget.value);
          }}
        />
      </div>
    </PopupModal>
  );
});

export default DeleteModal;

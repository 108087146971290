import { makeAutoObservable } from "mobx";
import { Shift, Table } from "./types";
import {
  fetchDeleteShift,
  fetchTable,
  fetchUpdateTimesheet,
} from "../../api/timesheet";
import { RootStore } from "../../store/rootStore";
import { Value } from "react-calendar/src/shared/types";
import { showAlertPopup } from "../../ui/alert";

export class TimeSheetStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }
  selectedDate: Value = new Date();
  tableData: Table[] = [];
  pickedCellId: number = 0;
  pickedCellDate: string = "";
  pickedCellDateTitle: string = "";
  pickedCellUTCShifts: Shift[] = [];
  pickedCellShifts: Shift[] = [];
  shiftsToDelete: number[] = [];
  isLoading: boolean = false;
  modalSaveButtonIsDisabled = false;

  setTableData = (val: Table[]) => {
    this.tableData = val;
  };
  setSelectedDate = (newDate: Value) => {
    this.selectedDate = newDate;
  };

  setPickedCellId = (val: number) => {
    this.pickedCellId = val;
  };
  setPickedCellDate = (val: string) => {
    this.pickedCellDate = val;
  };
  setPickedCellDateTitle = (val: string) => {
    this.pickedCellDateTitle = val;
  };
  setPickedCellUTCShifts = (val: Shift[]) => {
    this.pickedCellUTCShifts = val;
  };
  setPickedCellShifts = (val: Shift[]) => {
    this.pickedCellShifts = val;
  };

  setShiftsToDelete = (val: number[]) => {
    this.shiftsToDelete = val;
  };

  setIsLoading = (val: boolean) => {
    this.isLoading = val;
  };

  setModalSaveButtonIsDisabled = (val: boolean) => {
    this.modalSaveButtonIsDisabled = val;
  };
  getTable = async () => {
    this.setIsLoading(true);
    const response = await fetchTable(this.selectedDate);
    this.setIsLoading(false);
    if (response) {
      this.setTableData(response);
    }
  };

  updateTimesheet = async () => {
    const response = await fetchUpdateTimesheet(
      this.pickedCellDate,
      this.pickedCellId,
      this.pickedCellShifts,
    );
    if (response) {
      this.setTableData(response);
      this.getTable();
      showAlertPopup("Смена обновлена!", "Данные успешно изменены", "success");
    }
  };

  deleteShift = async (shiftId: number) => {
    const response = await fetchDeleteShift(shiftId);
    if (response) {
      this.setShiftsToDelete(this.shiftsToDelete.filter((id) => id != shiftId));
      this.getTable();
      showAlertPopup("Смена обновлена!", "Данные успешно изменены", "success");
    }
  };
}

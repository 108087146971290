import { FC } from 'react';

interface IconProps {
  color?: string;
}

export const SortArrowsIcon: FC<IconProps> = ({ color }) => {
  return (
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 7.7781L3 11.3337L0 7.7781H6ZM0 4.22255L3 0.666992L6 4.22255H0Z" fill="#B4BAC6" />
    </svg>
  );
};

import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { EInputStyleVariant, Input } from "ui-new/input";

interface SourceFieldProps {
  allDisabled: boolean;
}

const SourceFieldComponent: FC<SourceFieldProps> = () => {
  const { control } = useFormContext<{ spot_bank_account_number: string }>();

  return (
    <Controller
      name="spot_bank_account_number"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          label="Источник"
          variant={EInputStyleVariant.basicInput}
          type="text"
          inputProps={{
            ...field,
            disabled: true,
            value: `Расчетный счет ${field.value || ""}`,
          }}
        />
      )}
    />
  );
};

export const SourceField = SourceFieldComponent;

import { FC } from 'react';
import cls from './worker-styles.module.scss';

interface CommentProps {
  message: string;
}

const Comment: FC<CommentProps> = ({ message }) => {
  return (<div className={cls['comment']}>
    <p className={cls['message']} >{message}</p>
  </div>);
};

export default Comment;
import { observer } from 'mobx-react-lite';
import cls from './styles.module.scss';
import { useStore } from 'store';

const infoText = 'Пока нет ни одного шаблона';

const EmptyLayoutComponent = () => {
  const { RootStore: { SchedulePlanningStore: { setCreateTemplateModalIsOpen } } } = useStore();

  return (
    <div className={cls['schedule-templates__empty-layout-container']}>
      <h2>{infoText}</h2>
      <button onClick={() => setCreateTemplateModalIsOpen(true)} className={cls['button-primary']}>Добавить шаблон</button>
    </div>
  );
};

export const EmptyLayout = observer(EmptyLayoutComponent);
import {
  OnboardingPageOne,
  OnboardingPageThree,
  OnboardingPageTwo,
} from "pages";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { OnboardingHeader } from "widgets";
import "./styles.scss";
import { useStore } from "store";
import Cookies from "js-cookie";

// const OnboardingPageOne = lazy(() => import('pages').then(module => ({ default: module.OnboardingPageOne })));
// const OnboardingPageTwo = lazy(() => import('pages').then(module => ({ default: module.OnboardingPageTwo })));
// const OnboardingPageThree = lazy(() => import('pages').then(module => ({ default: module.OnboardingPageThree })));

export const OnboardingProcess = () => {
  const {
    RootStore: { AuthStore },
  } = useStore();

  return (
    <>
      <OnboardingHeader />
      <Routes>
        <Route path="step1/*" element={<OnboardingPageOne />} />
        <Route path="step2/*" element={<OnboardingPageTwo />} />
        <Route path="step3" element={<OnboardingPageThree />} />
        <Route path="*" element={<h2>Ресурс не найден</h2>} />
      </Routes>
    </>
  );
};

import { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import { ConfirmModal } from 'widgets/modal/confirm-modal';

interface ShowNotificationModalProps {
  title: string;
  messages?: ReactNode[];
  onConfirm: () => void;
  onClose?: () => void;
  confirmButtonLabel: string;
  cancelButtonLabel?: string;
  maxWidth?: string;
  minWidth?: string;
}

export const showConfirmModal = ({
  title,
  messages,
  onConfirm,
  onClose,
  confirmButtonLabel,
  cancelButtonLabel,
  maxWidth,
  minWidth
}: ShowNotificationModalProps) => {
  const modalRoot = document.createElement("div");
  modalRoot.setAttribute("id","confirm-modal-root");
  document.body.appendChild(modalRoot);

  const root = createRoot(modalRoot);

  const onCloseHandler = () => {
    onClose && onClose();
    root.unmount();
    if (document.body.contains(modalRoot))
      document.body.removeChild(modalRoot);
  };

  const onConfirmHandler = () => {
    onConfirm();
    root.unmount();
    if (document.body.contains(modalRoot))
      document.body.removeChild(modalRoot);
  };

  root.render(
    <ConfirmModal title={title} messages={messages}
      onClose={onCloseHandler} onConfirm={onConfirmHandler}
      minWidth={minWidth} confirmButtonLabel={confirmButtonLabel}
      cancelButtonLabel={cancelButtonLabel} maxWidth={maxWidth}
    />
  );
};
export enum ArrowState {
  NONE,
  OPENED,
  CLOSED,
}

export const ExpandArrow = (props: { state: ArrowState }) => {
  const { state } = props;
  return (
    <div
      className={`arrow ${state === ArrowState.CLOSED ? "arrow__closed" : ""}`}
    >
      <svg
        width="7"
        height="10"
        viewBox="0 0 7 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.744167 1.42325L4.32161 5L0.744167 8.57675C0.666757 8.65417 0.605352 8.74608 0.563458 8.84723C0.521564 8.94838 0.5 9.05679 0.5 9.16628C0.5 9.27577 0.521564 9.38418 0.563458 9.48533C0.605352 9.58648 0.666757 9.67839 0.744167 9.75581C0.821577 9.83323 0.913477 9.89464 1.01462 9.93653C1.11576 9.97843 1.22416 10 1.33364 10C1.44311 10 1.55152 9.97843 1.65266 9.93653C1.7538 9.89464 1.8457 9.83323 1.92311 9.75581L6.08897 5.58953C6.16644 5.51214 6.22789 5.42024 6.26982 5.31909C6.31175 5.21793 6.33333 5.1095 6.33333 5C6.33333 4.8905 6.31175 4.78207 6.26982 4.68091C6.22789 4.57976 6.16644 4.48786 6.08897 4.41047L1.92311 0.24419C1.8457 0.166772 1.7538 0.105361 1.65266 0.0634622C1.55152 0.0215639 1.44311 0 1.33364 0C1.22416 0 1.11576 0.0215639 1.01462 0.0634622C0.913477 0.105361 0.821577 0.166772 0.744167 0.24419C0.666757 0.321608 0.605352 0.413517 0.563458 0.514668C0.521564 0.61582 0.5 0.724234 0.5 0.833719C0.5 0.943205 0.521564 1.05162 0.563458 1.15277C0.605352 1.25392 0.666757 1.34583 0.744167 1.42325Z"
          fill="#98A1B2"
        />
      </svg>
    </div>
  );
};

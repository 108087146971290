import { fetchBalanceData } from "api";
import { dateToMonthYear } from "assets/utils";
import { makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "../../../store/rootStore";

export class BalanceStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  dataBAL: any = null;
  dateBAL = new Date();
  isLoadingBAL = false;

  setDataBAL = (newData: any) => {
    this.dataBAL = newData;
  };
  setDateBAL = (newDate: any) => {
    this.dateBAL = newDate;
  };
  setIsLoadingBAL = (bool: any) => {
    this.isLoadingBAL = bool;
  };

  handleDateChangeBAL = (newDate: any) => {
    if (newDate !== this.dateBAL) {
      this.setDateBAL(newDate);
      this.getDataBAL();
    }
  };
  getDataBAL = async () => {
    this.setIsLoadingBAL(true);
    try {
      const response = await fetchBalanceData(dateToMonthYear(this.dateBAL));
      if (response?.data.data) {
        this.setDataBAL(response.data.data);
      }
      this.setIsLoadingBAL(false);
    } catch (e) {
      this.setIsLoadingBAL(false);
    }
  };
}

import { observer } from "mobx-react-lite";
import { FC } from "react";
import cls from "../../styles.module.scss";
import { useStore } from "store";

interface DescriptionsProps {}

const DescriptionComponent: FC<DescriptionsProps> = () => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { transaction },
    },
  } = useStore();

  return (
    <div className={cls["transaction-description"]}>
      <h3>Назначение платежа</h3>
      <p>{transaction?.description}</p>
    </div>
  );
};

export const Description = observer(DescriptionComponent);

import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { FC,useMemo } from 'react';
import { DateIcon } from '../icons';
import cls from './date-bar-styles.module.scss';

interface DateBarProps {
  date: Date | null;
}

export const DateBar: FC<DateBarProps> = ({ date }) => {
  const formatDate = useMemo(() => date ? format(date,'iiii, dd.MM.yyyy',{ locale: ru }) : 'Не указана',[date]);

  return (<div className={cls['date-bar__container']} >
    <DateIcon />
    <span>{formatDate}</span>
  </div>);
};

import { useState } from "react";
import { OSVItem } from "./item";

export const OSVGroup = ({ groupData, items }: any) => {
  const [showItems, setShowItems] = useState(true);

  return (
    <>
      <OSVItem
        data={groupData}
        showItems={showItems}
        isBold
        opener={() => {
          setShowItems(!showItems);
        }}
      />
      {showItems &&
        items.map((item: any, index: any) => {
          return <OSVItem key={index} data={item} />;
        })}
    </>
  );
};

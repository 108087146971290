// получение всех выписок

import { api } from "./api";
import { Operation } from "../pages/operation-input/types";

export async function getListOfManualOperations(
  startDate?: string,
  endDate?: string,
) {
  try {
    const response = await api.get(`manualOperation/list`, {
      params: {
        date_start: startDate,
        date_end: endDate,
      },
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function getViewOfManualOperations(id: number) {
  try {
    const response = await api.get(`manualOperation/get`, {
      params: {
        id,
      },
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function getSubcontoTypesOfManualOperations() {
  try {
    const response = await api.get(`manualOperation/subconto_type`);
    return response.data;
  } catch (error) {
    return null;
  }
}
export async function postDeleteManualOperation(id: number) {
  try {
    const response = await api.post(`manualOperation/deletion_mark`, {
      id: id,
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function getAccountsOfManualOperations() {
  try {
    const response = await api.get(`manualOperation/accounts`);
    return response?.data;
  } catch (error) {
    return null;
  }
}
export async function getAccountSubkontoOfManualOperations(account: string) {
  try {
    const response = await api.get(`manualOperation/account_subkonto`, {
      params: {
        account,
      },
    });
    return response.data;
  } catch (error) {
    return null;
  }
}
export async function getSubkontoListOfManualOperations(subcontoType: string) {
  try {
    const response = await api.get(`manualOperation/subkonto`, {
      params: {
        subconto_type: subcontoType,
      },
    });
    return response?.data;
  } catch (error) {
    return null;
  }
}

export async function postCreateManualOperation(operation: any) {
  try {
    const response = await api.post(`manualOperation/create`, operation);
    return response.data;
  } catch (error) {
    return null;
  }
}

import React from "react";
import "./styles.scss";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router";
import { InvoicePage } from "./invoice/invoice";
import { CashPage } from "./cash/cash";
import { AdvancePage } from "./advance/advance";
import { InventoryPage } from "./inventory/inventory";

export const RegistryDocument = observer(() => {
  return (
    <>
      <Routes>
        <Route path="invoice" element={<InvoicePage />} />
        <Route path="invoice_out" element={<InvoicePage />} />
        <Route path="cash" element={<CashPage />} />
        <Route path="advance" element={<AdvancePage />} />
        <Route path="inventory" element={<InventoryPage />} />
      </Routes>
    </>
  );
});

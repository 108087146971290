import { Checkbox } from 'antd';
import { ReactNode } from 'react';
import { Control,Controller,FieldValues,Path } from 'react-hook-form';

interface ControllerProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  children?: ReactNode;
  disabled?: boolean;
}

export const CheckBoxController = <T extends FieldValues>(props: ControllerProps<T>) => {
  const { control,name,children,disabled } = props;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange,value } }) => (
        <Checkbox disabled={disabled} onChange={onChange} checked={value}>
          {children}
        </Checkbox>
      )}
    />);
};

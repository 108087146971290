import classNames from "classnames";
import cls from './styles.module.scss';

export interface TabButtonItem {
    id: number | string;
    title: string;
}

export interface Props {
    currentTab: TabButtonItem;
    items: TabButtonItem[];
    onTabHandler: (item: TabButtonItem) => void;
}

export const Tabs = (props: Props) => {

    const {
        currentTab,
        items,
        onTabHandler
    } = props;

    const isActive = (item: TabButtonItem) => {
        return item.title === currentTab.title ? cls.active : '';
    }

    return (
        <ul className={cls.tabButtonList}>
            {
                items.map((item) => (
                    <li key={item.id}>
                        <button
                            className={classNames(cls.tabButton, isActive(item))}
                            onClick={() => onTabHandler(item)}
                        >
                            {item.title}
                        </button>
                    </li>
                ))
            }
        </ul>
    )
}
import { AllWorkShifts } from "../../../widgets/all-work-shifts";
import "./styles.scss";

export const OnboardingPageTwo = () => {
  return (
    <div className="onboardingPageTwo__container">
      <h2 className="onboardingPageTwo__header">
        Добавь шаблоны для будущих смен
      </h2>
      <AllWorkShifts defaultView="day" />
    </div>
  );
};

import React from "react";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
import { AttachmentDownload, AttachmentEye } from "../icons";

const AttachmentsBlock = observer(() => {
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: { advanceReport },
  } = RootStore;

  return (
    <div className="registry-document__container">
      <div className="registry-document__previews">
        {advanceReport.files.map((value: any) => (
          <div className="registry-document__document" key={value.id}>
            <div className="registry-document__view">
              <a href={`${value.ref}`} target="_blank">
                <AttachmentEye />
              </a>
              <a href={`${value.ref}`} download={true}>
                <AttachmentDownload />
              </a>
            </div>
            <img src={`${value.ref}`} alt=""></img>
          </div>
        ))}
      </div>
    </div>
  );
});

export default AttachmentsBlock;

import React, { useRef } from 'react';
import { UpdatePositionTypes } from '../../types';
import { toJS } from 'mobx';
import { useStore } from '../../../../store';

interface InventoryValueInputProps{
  val: any,
  index: number
}

const InventoryValueInput: React.FC<InventoryValueInputProps> = ({val, index}) => {
  const timerRef = useRef<number | NodeJS.Timeout | null>(null);
  const typingInterval = 1000;
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: {
      advanceReport,
      setAdvanceReport,
      updatePosition,
    },
  } = RootStore;

  return (
    <input
      className={`registry-document__inv-table__body__row__element input`}
      value={val.value_fact !== null ? val.value_fact : ''}
      onChange={(event) => {
        if (timerRef.current !== null) {
          clearTimeout(timerRef.current);
        }
        const { value } = event.target as HTMLInputElement;
        if (value === '' || /^\d+(\.\d{0,3})?$/.test(value)) {
          timerRef.current = setTimeout(() => updatePosition(val.id, UpdatePositionTypes.VALUE_FACT, +value), typingInterval)

          const newValue = event.target.value;
          const updatedPositions = [
            ...advanceReport.positions
          ];
          updatedPositions[index].value_fact = newValue;
          updatedPositions[index].sum_fact = null;
          setAdvanceReport({
            ...toJS(advanceReport),
            sum: null,
            positions: updatedPositions
          });
        }
      }}
    />
  );
};

export default InventoryValueInput;
import {
    SubcontosTypeData,
    TransactionRequest
} from "./operation-input-edit/components/ManualInputTableRow/ManualInputTableRow";

export const tableHeader = [
    {
        className: "numberColumn",
        label: "№",
    },
    {
        className: "nameColumn",
        label: "Дебет",
    },
    {
        className: "countColumn",
        label: "Кол-во",
    },
    {
        className: "nameColumn",
        label: "Кредит",
    },
    {
        className: "countColumn",
        label: "Кол-во",
    },
    {
        className: "sumColumn",
        label: "Сумма",
    },
]

export const emptyTransaction: TransactionRequest = {
    amount: "",
    row_number: 0,
    credit_account: "",
    debit_account: "",
    debit_quantity: 0,
    credit_quantity: 0,
    credit_subconto: [],
    debit_subconto: [],
}

export const subcontoEmptyData = {
    credit: [],
    debit: [],
}

export const transactionsRequestBody = {
    amount: 0,
    date: new Date().toLocaleDateString(),
    transactions: []
}

export const subcontoTypesData: SubcontosTypeData = {
    credit: [],
    debit: [],
}
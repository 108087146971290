import React from "react";
import { RevenueInputField } from "./revenueInputField";
import "./revenueInput.scss";

interface IRevenueInputType {
  title: string;
  summary: number;
  disabled: boolean;
  maxWidth?: string;
  isNonPrice?: boolean;
  height?: string;
  gap?: string;
}

export const RevenueInput: React.FC<IRevenueInputType> = ({
  title,
  summary,
  disabled,
  maxWidth,
  height,
  gap,
  isNonPrice,
}) => {
  return (
    <div
      className="revenueInput__container"
      style={{ maxWidth: maxWidth, gap: gap }}
    >
      <p className="revenueInput__title">{title}</p>
      <RevenueInputField
        summary={summary}
        disabled={disabled}
        maxWidth={maxWidth}
        isNonPrice={isNonPrice}
        height={height}
      />
    </div>
  );
};

import {
  getMoneyTransactions,
  addStatementsFile,
  IStatementsListMeta,
  IGetBankStatementsListParams,
  getTransactionContragents,
  getOperationTypes,
  getTransactionPaymentTypes,
  getTransactionTypes,
  getTransactionStatuses,
} from "api";
import { subMonths, subDays } from "date-fns";
import { makeAutoObservable } from "mobx";
import { SortOrder } from "assets/types";
import { toggleArrElem } from "assets/utils";
import { Value } from "react-calendar/src/shared/types";
import { RootStore } from "../../store/rootStore";
import dayjs from "dayjs";
import {
  IMoneyTransaction,
  IMoneyTransactionOperationType,
  IMoneyTransactionPayType,
  IMoneyTransactionStatus,
  IMoneyTransactionType,
} from "types/money-transactions";
import { IContragent } from "types/directories";

export class MoneyTransactionsStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  moneyTransactions: IMoneyTransaction[] = [];
  contragents: IContragent[] = [];
  operationTypes: IMoneyTransactionOperationType[] = [];
  payTypes: IMoneyTransactionPayType[] = [];
  moneyTransactionTypes: IMoneyTransactionType[] = [];
  moneyTransactionStatuses: IMoneyTransactionStatus[] = [];

  isMoneyTransactionsLoading: boolean = false;
  isUploading: boolean = false;
  filterPeriodStart: Value = null;
  filterPeriodEnd: Value = null;
  filterPayTypes: number[] = []; // фильтры
  filterOperationTypes: number[] = [];
  filterStatus: number[] = [];
  filterMoneyTransactionTypes: number[] = [];
  filterContragents: IContragent["id"][] = [];
  searchText: string = "";
  sortParam: keyof IMoneyTransaction | null = null;
  sortOrder: SortOrder = "asc";
  file: any = null;

  currentPage: number = 1;
  maxOperationsNumber: number = 10;
  //!Новые
  //#region Новые данные
  parserInfo: IStatementsListMeta | null = null;

  isUploadingError: boolean = false;
  uploadedInfo: { error: number; success: number } | null = null;

  setUploadedInfo = (info: typeof this.uploadedInfo) => {
    this.uploadedInfo = info;
  };

  setIsUploadingError = (val: boolean) => {
    this.isUploadingError = val;
  };
  //#endregion

  get isFilterDefault(): boolean {
    return (
      this.filterPayTypes.length === 0 &&
      this.filterOperationTypes.length === 0 &&
      this.filterContragents.length === 0 &&
      this.filterMoneyTransactionTypes.length === 0 &&
      this.filterStatus.length === 0
    );
  }

  setFile = (val: any) => {
    this.file = val;
  };
  setIsMoneyTransactionLoading = (bool: boolean) => {
    this.isMoneyTransactionsLoading = bool;
  };
  setUploading = (bool: boolean) => {
    this.isUploading = bool;
  };
  setDataBS = (arr: any[]) => {
    this.moneyTransactions = arr;
  };
  setContragents = (arr: IContragent[]) => {
    this.contragents = arr;
  };
  setFilterPeriodStart = (date: Value) => {
    this.filterPeriodStart = date;
  };
  setFilterPeriodEnd = (date: Value) => {
    this.filterPeriodEnd = date;
  };
  setFilterMoneyTransactionTypes = (arr: typeof this.filterMoneyTransactionTypes) => {
    this.filterMoneyTransactionTypes = arr;
  };
  setFilterStatus = (arr: any[]) => {
    this.filterStatus = arr;
  };
  setFilterPayTypes = (arr: any[]) => {
    this.filterPayTypes = arr;
  };
  setFilterOperationTypes = (arr: any[]) => {
    this.filterOperationTypes = arr;
  };
  setFilterContragents = (contragent_id: IContragent["id"][]) => {
    this.filterContragents = contragent_id;
  };
  setSearchText = (text: string) => {
    this.searchText = text;
  };
  setSortParam = (param: keyof IMoneyTransaction) => {
    this.sortParam = param;
  };
  setSortOrder = (str: SortOrder) => {
    this.sortOrder = str;
  };

  resetPayType = () => {
    this.filterPayTypes = [];
  };
  resetContragent = () => {
    this.filterContragents = [];
  };
  clearSearchText = () => {
    this.searchText = "";
  };
  resetPeriod = () => {
    this.filterPeriodStart = subMonths(new Date(), 1);
    this.filterPeriodEnd = subDays(new Date(), 1);
    this.fetchGetMoneyTransactions();
  };
  resetFilters = () => {
    this.filterPayTypes = [];
    this.filterOperationTypes = [];
    this.filterContragents = [];
    this.filterMoneyTransactionTypes = [];
    this.filterStatus = [];
  };
  handleFilterPeriodChange = (period: Value) => {
    if (!period || !Array.isArray(period)) return;
    this.setFilterPeriodStart(period[0]);
    this.setFilterPeriodEnd(period[1]);
    this.fetchGetMoneyTransactions();
  };
  handleColumnTitleClick = (param: keyof IMoneyTransaction) => {
    if (this.sortParam === param) {
      this.toggleSortOrder();
    } else {
      this.setSortOrder("asc");
      this.setSortParam(param);
    }
  };
  toggleSortOrder = () => {
    if (this.sortOrder === "asc") {
      this.setSortOrder("desc");
    } else {
      this.setSortOrder("asc");
    }
  };
  toggleMoneyTransactionType = (moneyTransactionType: number) => {
    this.filterMoneyTransactionTypes = toggleArrElem(
      moneyTransactionType,
      this.filterMoneyTransactionTypes
    );
  };
  toggleStatuses = (statuses: number) => {
    this.filterStatus = toggleArrElem(statuses, this.filterStatus);
  };
  togglePayType = (payType: number) => {
    this.filterPayTypes = toggleArrElem(payType, this.filterPayTypes);
  };
  toggleOperationType = (operationType: number) => {
    this.filterOperationTypes = toggleArrElem(operationType, this.filterOperationTypes);
  };
  toggleContragent = (contragent: IContragent) => {
    this.filterContragents = toggleArrElem(contragent.id, this.filterContragents);
  };

  fetchGetMoneyTransactions = async (args?: { page?: number; per_page?: number }) => {
    this.setIsMoneyTransactionLoading(true);

    const filterArgs: IGetBankStatementsListParams = {
      page: args?.page ?? this.currentPage,
      per_page: args?.per_page ?? this.maxOperationsNumber,
      date_from: this.filterPeriodStart
        ? dayjs(this.filterPeriodStart.toString()).format("YYYY-MM-DD")
        : undefined,
      date_to: this.filterPeriodEnd
        ? dayjs(this.filterPeriodEnd.toString()).format("YYYY-MM-DD")
        : undefined,
      sort_direction: this.sortOrder,
    };
    if (this.filterContragents.length > 0) filterArgs.contragent = this.filterContragents;
    if (this.searchText.length > 0) filterArgs.search = this.searchText;
    if (this.filterOperationTypes.length > 0) filterArgs.operation_type = this.filterOperationTypes;
    if (this.filterPayTypes.length > 0) filterArgs.pay_type = this.filterPayTypes;
    if (this.filterStatus.length > 0) filterArgs.status = this.filterStatus;
    if (this.filterMoneyTransactionTypes.length > 0)
      filterArgs.type = this.filterMoneyTransactionTypes;
    if (this.sortParam !== null) filterArgs.sort_by = this.sortParam;

    const response = await getMoneyTransactions(filterArgs);
    if (!response) return;
    this.setDataBS(response.data);
    this.parserInfo = response.meta;
    this.setIsMoneyTransactionLoading(false);
  };
  fetchUploadMoneyTransactions = async () => {
    if (this.file !== null) {
      this.setUploading(true);
      const formData = new FormData();
      formData.append("file", this.file);
      const response = await addStatementsFile(formData);
      if (!response) {
        this.setUploading(false);
        this.setIsUploadingError(true);
        return;
      }

      this.setUploadedInfo(response);
      this.fetchGetMoneyTransactions();
      this.setFile(null);

      this.setUploading(false);
    }
  };

  fetchGetContragents = async () => {
    const response = await getTransactionContragents();
    if (!response) return;
    this.setContragents(response);
  };

  fetchGetOperationTypes = async () => {
    const response = await getOperationTypes();
    if (!response) return null;
    this.operationTypes = response;
  };
  fetchGetPayTypes = async () => {
    const response = await getTransactionPaymentTypes();
    if (!response) return;
    this.payTypes = response;
  };
  fetchGetMoneyTransactionTypes = async () => {
    const response = await getTransactionTypes();
    if (!response) return;

    this.moneyTransactionTypes = response;
  };
  fetchGetMoneyTransactionStatuses = async () => {
    const response = await getTransactionStatuses();
    if (!response) return;
    this.moneyTransactionStatuses = response;
  };
}

import React,{ useEffect,useMemo,useRef } from 'react';
import { toJS } from 'mobx';
import { IInventoryPositon,IInventoryReport } from '../../types';
import { EmptyTableBody } from '../../../../ui/empty-table-body';
import { useStore } from '../../../../store';
import { observer } from 'mobx-react-lite';
import { Button,EButtonVariant } from '../../../../ui';
import { defaultRound } from '../../../../assets/utils';
import InventoryTable from './inventoryTable';
import "pages/registry-document/customSelectOption.scss";

const InventoryBlock = () => {
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: {
      advanceReport,
      createNewPosition,
      setAdvanceReport,
      setPickedNomenclatureId,
      setPickedUnitId,
      setPickedValueFact,
      setPickedIdx,
      setSelectedIdx,
      setPickedValue,
      setPickedSum
    }
  } = RootStore;

  async function handleCreateInventoryPositon() {
    const id = await createNewPosition();
    setAdvanceReport({
      ...toJS(advanceReport),
      positions: [
        ...toJS(advanceReport).positions,
        {
          id: id,
          name: null,
          cost: 0,
          sum: 0,
          sum_fact: 0,
          unit: '',
          value: 0,
          value_fact: null
        }
      ]
    });
  }

  const tableRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setPickedNomenclatureId(null);
    setPickedSum(null);
    setPickedValue(null);
    setPickedValueFact(null);
    setPickedUnitId(null);

    function handleClickOutside(event: MouseEvent) {
      if (
        tableRef.current &&
        !tableRef.current.contains(event.target as Node)
      ) {
        setSelectedIdx(0);
        setPickedIdx(0);
      }
    }

    document.addEventListener('mousedown',handleClickOutside,{
      capture: true
    });

    return () => {
      document.removeEventListener('mousedown',handleClickOutside,{
        capture: true
      });
    };
  },[]);

  const receiversData = useMemo(() => {
    if (!advanceReport.receivers) return null;

    return (
      <>
        <span className='registry-document__element-title'>
          Ответственные за пересчёт
        </span>
        {(toJS(advanceReport) as IInventoryReport).receivers.map((rec) =>
          <span className='registry-document__element-value'>{rec.name}</span>
        )}
      </>
    );
  },[toJS(advanceReport.receivers)]);

  const currentSum = useMemo(() => {
    if (!(advanceReport as IInventoryReport).sum)
      return (toJS(advanceReport as IInventoryReport)).positions
        .map((position: IInventoryPositon) => defaultRound(position.value_fact * position.cost))
        .reduce((acc: number,val) => +acc + +val,0);

    return advanceReport.sum;
  },[toJS(advanceReport.sum),toJS(advanceReport.positions)]);

  return (
    <>
      <div className='registry-document__info'>
        <div className='registry-document__info-column'>
          <div className='registry-document__info-row'>
            <div className='registry-document__info-element'>
              {advanceReport.status_ru && (
                <>
                  <span className='registry-document__element-title'>
                    Статус
                  </span>
                  <span className='registry-document__element-value'>
                    {advanceReport.status_ru}
                  </span>
                </>
              )}
            </div>

            <div className='registry-document__info-element'>
              {advanceReport.number !== null && (
                <>
                  <span className='registry-document__element-title'>
                    Номер заявки
                  </span>
                  <span className='registry-document__element-value'>
                    {advanceReport.number}
                  </span>
                </>
              )}
            </div>

            <div className='registry-document__info-element'>
              {advanceReport.created_at && (
                <>
                  <span className='registry-document__element-title'>
                    Дата проведения
                  </span>
                  <span className='registry-document__element-value'>
                    {advanceReport.date}
                  </span>
                </>
              )}
            </div>
          </div>

          <div className='registry-document__info-row'>
            <div className='registry-document__info-element'>
              <>
                <span className='registry-document__element-title'>
                  Сумма, ₽
                </span>
                <span className='registry-document__element-value'>
                  {currentSum}
                </span>
              </>
            </div>

            <div className='registry-document__info-element'>
              {advanceReport.responsible && (
                <>
                  <span className='registry-document__element-title'>
                    Ответственный за инвентаризацию
                  </span>
                  <span className='registry-document__element-value'>
                    {advanceReport.responsible}
                  </span>
                </>
              )}
            </div>

            <div className='registry-document__info-element'>
              {receiversData}
            </div>
          </div>
        </div>
        <div className='registry-document__info-button'></div>
      </div>
      {advanceReport.status !== 'waiting' && (
        <div className='registry-document__inv-table' ref={tableRef}>
          <div className='registry-document__inv-table__header'>
            <div className='registry-document__inv-table__header__element'></div>
            <div className='registry-document__inv-table__header__element editable'>
              Номенклатура
            </div>
            <div className='registry-document__inv-table__header__element editable'>
              Ед.изм
            </div>
            <div className='registry-document__inv-table__header__element'>
              Кол-во (план)
            </div>
            <div className='registry-document__inv-table__header__element editable'>
              Кол-во (факт)
            </div>
            <div className='registry-document__inv-table__header__element'>
              Отклонение
            </div>
            <div className='registry-document__inv-table__header__element'>
              Цена, ₽
            </div>
            <div className='registry-document__inv-table__header__element'>
              Сумма (план)
            </div>
            <div className='registry-document__inv-table__header__element'>
              Сумма (факт)
            </div>

            {advanceReport.status === 'pending' && (
              <div className='registry-document__inv-table__header__element delete'></div>
            )}
          </div>
          <div className='registry-document__inv-table__body'>
            {toJS(advanceReport as IInventoryReport).positions?.length ? <InventoryTable /> : <EmptyTableBody />}
          </div>
        </div>
      )}

      {advanceReport.status === 'pending' && (
        <Button
          variant={EButtonVariant.createButton}
          text='Добавить позицию'
          maxWidth={'184px'}
          height={'32px'}
          borderRadius={'10px'}
          onClick={() => handleCreateInventoryPositon()}
        />
      )}
    </>
  );
};

export default observer(InventoryBlock);

import { Checkbox, Dropdown, Menu, Space } from "antd";
import { FilterArrow, FilterCross } from "../../icons";
import React, { useEffect } from "react";
import { useStore } from "store";

export const DocumentFilter = () => {
  const { RootStore } = useStore();
  const {
    RegistryStore: {
      pickedRegistryDocument,
      pickedRegistryDocumentTitle,
      setPickedRegistryDocument,
      setPickedRegistryDocumentTitle,
      fetchDocumentTypes,
      listOfDocTypes,
      setCurrentPage,
    },
  } = RootStore;

  const handleDocumentFilterClick = (event: any) => {
    setCurrentPage(1);

    if (pickedRegistryDocument.includes(event.key)) {
      setPickedRegistryDocument(
        pickedRegistryDocument.filter((item: any) => item !== event.key),
      );
    } else {
      setPickedRegistryDocument([...pickedRegistryDocument, event.key]);
    }
    if (pickedRegistryDocumentTitle.includes(event.item.props.title)) {
      setPickedRegistryDocumentTitle(
        pickedRegistryDocumentTitle.filter(
          (item) => item !== event.item.props.title,
        ),
      );
    } else {
      setPickedRegistryDocumentTitle([
        ...pickedRegistryDocumentTitle,
        event.item.props.title,
      ]);
    }
  };

  const documentFilterMenu = (
    <Menu onClick={handleDocumentFilterClick}>
      {listOfDocTypes &&
        listOfDocTypes.map((item: any) => (
          <Menu.Item key={item.name_en} title={item.name_ru}>
            <Checkbox
              checked={pickedRegistryDocument.includes(item.name_en)}
            ></Checkbox>
            {item.name_ru}
          </Menu.Item>
        ))}
    </Menu>
  );

  useEffect(() => {
    fetchDocumentTypes();
  }, []);
  return (
    <Dropdown overlay={documentFilterMenu} trigger={["click"]}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <div>
            {pickedRegistryDocument.length ? (
              <>
                <span>{pickedRegistryDocumentTitle.join(", ")}</span>

                <FilterCross
                  onClick={(
                    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
                  ) => {
                    event.stopPropagation();
                    setPickedRegistryDocument([]);
                    setPickedRegistryDocumentTitle([]);
                  }}
                />
              </>
            ) : (
              <>
                Документ
                <FilterArrow />
              </>
            )}
          </div>
        </Space>
      </a>
    </Dropdown>
  );
};

import { Dropdown, Menu, Checkbox, Space } from "antd";
import { CloseFilterIcon, FilterArrowIcon } from "pages/money-transactions/icons";
import { useStore } from "store";
import { MenuInfo } from "rc-menu/lib/interface";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { toJS } from "mobx";

const OperationTypeFilterComponent = () => {
  const {
    RootStore: {
      MoneyTransactionsStore: {
        operationTypes,
        filterOperationTypes,
        setFilterOperationTypes,
        toggleOperationType,
      },
    },
  } = useStore();

  const operationTypesData = useMemo(
    () =>
      toJS(operationTypes).map(item => (
        <Menu.Item key={item.id}>
          <Checkbox checked={filterOperationTypes.includes(item.id)}></Checkbox>
          {item.title}
        </Menu.Item>
      )),
    [filterOperationTypes, operationTypes]
  );

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => (
        <Menu
          onClick={(e: MenuInfo) => {
            const operationType = operationTypes[+e.key - 1];
            toggleOperationType(operationType.id);
          }}
        >
          {operationTypesData}
        </Menu>
      )}
    >
      <Space className="cursor-pointer" style={{ gap: "4px" }}>
        {filterOperationTypes.length > 0 ? (
          <>
            Вид операции
            <CloseFilterIcon onClick={() => setFilterOperationTypes([])} />
          </>
        ) : (
          <>
            Вид операции <FilterArrowIcon />{" "}
          </>
        )}
      </Space>
    </Dropdown>
  );
};

export const OperationTypeFilter = observer(OperationTypeFilterComponent);

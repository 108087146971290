import { ExpandArrow, ArrowState } from "ui";

interface ODDSTableRowProps {
  arrow?: ArrowState | undefined;
  isGrey?: boolean;
  isBold?: boolean;
  visible: boolean;
  name: String;
  number?: String | undefined;
  isTitle?: boolean | undefined;
}

export const ODDSTableRow = ({
  arrow,
  isGrey,
  isBold,
  name,
  number,
  visible,
  isTitle,
}: ODDSTableRowProps) => {
  return (
    <div
      className={`odds__table__row transition ${
        isGrey && "odds__table__row__grey"
      } ${visible ? "visible" : "invisible"}`}
    >
      <div className="odds__table__row__arrow table-item">
        {arrow && <ExpandArrow state={arrow} />}
      </div>
      <div
        className={`odds__table__row__name table-item ${
          isBold && "odds__table__row__bold"
        }`}
      >
        {name}
      </div>
      <div className={`odds__table__row__number`}>
        <p
          style={{
            borderLeft: isTitle ? "1px solid #D3D6DB" : "",
            paddingLeft: "12px",
          }}
        >
          {number && number}
        </p>
      </div>
    </div>
  );
};

import styles from './styles.module.scss';
import {Input, Select} from "antd";
import {useEffect, useState} from "react";
import type {Contragent} from "../../../../types/loan";
import classNames from 'classnames';

export interface Props {
    onUpdate: (contragent: any, responsible: any, documentNumber: string) => void;
    contragents: any[];
    responsible: any[];
}

export const CreateLoanHeader = ({ onUpdate, contragents, responsible }: Props) => {

    const [documentNumber, setDocumentNumber] = useState<string>("");
    const [currentContragent, setCurrentContragent] = useState<null | Contragent>(null);
    const [currentResponsible, setCurrentResponsible] = useState(null);

    const onContragentSelect = (event: any) => {
        setCurrentContragent(event);
    }

    const onResponsibleSelect = (event: any) => {
        setCurrentResponsible(event);
    }

    useEffect(() => {
        onUpdate(currentContragent, currentResponsible, documentNumber);
    }, [currentContragent, currentResponsible, documentNumber])

    return <div className={styles.header}>
        <div className={styles.headerItem}>
            <label className={styles.inputLabel}>Номер документа</label>
            <Input onInput={(e: any) => setDocumentNumber(e.target.value)} className={styles.inputItem} placeholder='Номер документа'/>
        </div>

        <div className={styles.headerItem}>
            <label className={styles.inputLabel}>Ответственный</label>
            <Select className={classNames(styles.inputItem, styles.select)} placeholder="Выбор ответственного" onChange={onResponsibleSelect}>
                {responsible?.map((item) => (
                    <Select.Option key={item.id} value={item.id} label={item.name}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
        </div>

        <div className={styles.headerItem}>
            <label className={styles.inputLabel}>Котрагент</label>
            <Select className={classNames(styles.inputItem, styles.select)} placeholder="Выбор контрагента" onChange={onContragentSelect}>
                {contragents.map((contragent) => (
                    <Select.Option key={contragent.id} value={contragent.id} label={contragent.title}>
                        {contragent.title}
                    </Select.Option>
                ))}
            </Select>
        </div>
    </div>
}
import React, {useEffect, useState} from "react";
import { EInputStyleVariant, EInputVariant, Input } from "../../../../ui";
import { DropdownUI } from "../../../../widgets/dropdown";
import { toJS } from "mobx";
import { useStore } from "../../../../store";
import { observer } from "mobx-react-lite";
import {DayPickerNew} from "../../../../widgets/datepickers/day-picker-new";

interface InvoicePopupModalProps {
  setPickedDealId: (arg0: string | null) => void;
  handleModalContragentChange: (arg0: string) => void;
}

const InvoicePopupModal = observer(
  ({
    setPickedDealId,
    handleModalContragentChange,
  }: InvoicePopupModalProps) => {
    const { RootStore } = useStore();
    const {
      RegistryDocumentStore: {
        pickedDeal,
        dealList,
        setPickedDocumentId,
        setPickedDeal,
        pickedContragent,
        contragentsList,
        setPickedContragentId,
        documentNumber,
        setDocumentNumber,
        setContragentId,
        carryingDate,
        setCarryingDate
      },
    } = RootStore;

    return (
      <>
        <div className="popupModal__element">
          <div className="label">№ Документа</div>
          <Input
            type={EInputVariant.text}
            variant={EInputStyleVariant.basicInput}
            height={"44px"}
            borderRadius={"16px"}
            value={documentNumber}
            onChange={(e) => setDocumentNumber(e.currentTarget.value)}
          />
        </div>
        <div className="popupModal__element">
          <div className="label">Дата проведения</div>
          <DayPickerNew date={carryingDate} dateSetter={setCarryingDate} />
        </div>
        <div className="popupModal__element">
          <div className="label">Контрагент</div>
          <DropdownUI
            shown={pickedContragent}
            height={"44px"}
            borderRadius={"16px"}
          >
            <>
              {contragentsList &&
                contragentsList.map((value, index) => {
                  var isActive: boolean = false;
                  if (value.title) {
                    if (value.title === pickedContragent) {
                      isActive = true;
                      setPickedContragentId(
                        `contragent_id.${value.contragent_id}`,
                      );
                    }
                  } else {
                    if (
                      `${value.surname}  ${value.name}` === pickedContragent
                    ) {
                      isActive = true;
                      setPickedContragentId(
                        `user_contragent_id.${value.user_contragent_id}`,
                      );
                    }
                  }
                  const userTitle: string = `${value.surname}  ${value.name}`;

                  return (
                    <li
                      onClick={(event) => {
                        handleModalContragentChange(
                          value.title ? value.title : userTitle,
                        );
                        RootStore.setIsDropdownOpen({
                          ...toJS(RootStore.isDropdownOpen),
                          [1]: false,
                        });
                        setPickedContragentId(
                          (event.target as HTMLLIElement).id,
                        );
                        setContragentId(
                          value.user_contragent_id
                            ? value.user_contragent_id
                            : value.contragent_id,
                        );
                        setPickedDealId(null);
                        setPickedDeal('Выберите договор');
                      }}
                      className={`dropdown-item ${isActive ? "active" : ""}`}
                      key={
                        value.contragent_id
                          ? `contragent_id.${value.contragent_id}`
                          : `user_contragent_id.${value.user_contragent_id}`
                      }
                      id={
                        value.contragent_id
                          ? `contragent_id.${value.contragent_id}`
                          : `user_contragent_id.${value.user_contragent_id}`
                      }
                    >
                      {value.title ? value.title : userTitle}
                    </li>
                  );
                })}
            </>
          </DropdownUI>
        </div>
        <div className="popupModal__element">
          <div className="label">№ Договора</div>
          {
            dealList && (
              dealList.length > 1 ? (
                <DropdownUI shown={pickedDeal} height={"44px"} borderRadius={"16px"}>
                  <>
                    {
                      dealList.map((value, index) => {
                        var isActive;
                        if (value.title === pickedDeal) {
                          isActive = true;
                          setPickedDocumentId(value.id.toString());
                          // setPickedDeal(value.title);
                        }
                        return (
                          <li
                            onClick={(event) => {
                              RootStore.setIsDropdownOpen({
                                ...toJS(RootStore.isDropdownOpen),
                                [3]: false,
                              });
                              setPickedDealId((event.target as HTMLLIElement).id);
                              setPickedDeal(value.title);
                            }}
                            className={`dropdown-item ${isActive ? "active" : ""}`}
                            key={value.id}
                            id={value.id.toString()}
                          >
                            {value.title}
                          </li>
                        );
                      })}
                  </>
                </DropdownUI>
              ) : (
                <Input
                  type={EInputVariant.text}
                  variant={EInputStyleVariant.basicInput}
                  height={"44px"}
                  borderRadius={"16px"}
                  value={pickedDeal ? pickedDeal : ''}
                  disabled
                />
              )
            )
          }
        </div>
      </>
    );
  },
);

export default InvoicePopupModal;

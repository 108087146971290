import { makeAutoObservable, toJS } from "mobx";
import {
  checkInventory,
  deletePosition,
  fetchApproveStatus,
  fetchCreateNewPosition,
  fetchDeleteStatus,
  fetchUpdateAdvanceReport,
  fetchUpdateCashOrder,
  fetchUpdateInvoice,
  fetchUpdatePosition,
  getAllContragents,
  getArticles,
  getDealNumbers,
  getNomenclatures,
  getReport,
  getResponsible,
  getUnits,
  getUserContragents,
} from "../../api/registryDocument";
import {
  Article,
  Contragent,
  Deal, type DocumentData,
  IInventoryPositon,
  IResponsibleStatus,
  Nomenclature,
  NomenclatureTypeKey,
  UpdatePositionTypes,
} from "./types";
import { showAlertPopup } from "../../ui/alert";
import { RootStore } from "../../store/rootStore";
import { Value } from "react-calendar/src/shared/types";
import { format } from "date-fns";

export class RegistryDocumentStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  mockInvData: any[] = [
    {
      num: 1,
      nomenclature: "ТЕСТОВЫЕ ДАННЫЕ",
      unit: "шт",
      numPlan: 50,
      numFact: 55,
      diff: -5,
      price: 85.0,
      sumPlan: "4 250.0",
      sumFact: "4 675.0",
    },
    {
      num: 2,
      nomenclature: "РЕДАКТИРОВАНИЕ",
      unit: "шт",
      numPlan: 50,
      numFact: 55,
      diff: -5,
      price: 85.0,
      sumPlan: "4 250.0",
      sumFact: "4 675.0",
    },
    {
      num: 3,
      nomenclature: "НЕ ПРИВЯЗАННО",
      unit: "шт",
      numPlan: 50,
      numFact: 55,
      diff: -5,
      price: 85.0,
      sumPlan: "4 250.0",
      sumFact: "4 675.0",
    },
  ];

  mockInvNewPosition = {
    num: this.mockInvData.length + 1,
    nomenclature: "Позиция",
    unit: "шт",
    numPlan: 0,
    numFact: 0,
    diff: 0,
    price: 0,
    sumPlan: "0",
    sumFact: "0",
  };

  setMockInvData = (val: any[]) => {
    this.mockInvData = val;
  };

  documentId: number = 0;
  documentType: string = "";

  setDocumentId = (val: number) => {
    this.documentId = val;
  };
  setDocumentType = (val: string) => {
    this.documentType = val;
  };

  pickedContragentId: any;
  pickedDocumentId: any;
  modalIsActive: boolean = false;

  setModalIsActive = (val: boolean) => {
    this.modalIsActive = val;
  };

  setPickedContragentId = (id: any) => {
    this.pickedContragentId = id;
  };

  setPickedDocumentId = (id: any) => {
    this.pickedDocumentId = id;
  };
  pickedNomenclatureId: string | null = null;
  pickedUnitId: number | null = null;
  pickedPositionId: number | null = null;
  pickedValue: number | null = null;
  pickedValueFact: number | null = null;
  pickedSum: number | null = null;

  setPickedNomenclatureId = (val: string | null) => {
    this.pickedNomenclatureId = val;
  };
  setPickedUnitId = (val: number | null) => {
    this.pickedUnitId = val;
  };
  setPickedPositionId = (val: number | null) => {
    this.pickedPositionId = val;
  };
  setPickedValue = (val: number | null) => {
    this.pickedValue = val;
  };
  setPickedValueFact = (val: number | null) => {
    this.pickedValueFact = val;
  };
  setPickedSum = (val: number | null) => {
    this.pickedSum = val;
  };

  isLoading: boolean = false;
  advanceReport: any = null;
  pickedContragent: string | null = null;
  pickedDeal: string | null = null;
  pickedArticle: string | null = null;
  documentNumber: string = "";
  pickedResponsible: string | null = null;
  dealList: Deal[] | null = null;
  contragentsList: Contragent[] | null = null;
  responsibleList: Contragent[] | null = null;
  unitsList: any = null;
  articlesList: Article[] | null = null;
  nomenclaturesList: Nomenclature[] | undefined = undefined;
  pickedResponsibleId: string | null = null;
  pickedArticleId: number | null = null;
  draftComment: string = "";
  newPositionId: number | null = null;
  pickedIdx: number = 0;
  selectedIdx: number = 0;
  isStatusChangePending: boolean = false;
  contragentId: number = 0;
  isNeedContragent: boolean | null = null;
  carryingDate: string | Date = new Date();
  documentData: null | DocumentData = null;

  setAdvanceReport = (val: any) => {
    this.advanceReport = val;
  };
  setPickedContragent = (val: string | null) => {
    this.pickedContragent = val;
  };
  setContragentId = (val: number) => {
    this.contragentId = val;
    this.fetchDealNumbers();
  };
  setPickedDeal = (val: string | null) => {
    this.pickedDeal = val;
  };
  setPickedArticle = (val: string | null) => {
    this.pickedArticle = val;
  };
  setDocumentNumber = (val: string) => {
    this.documentNumber = val;
  };
  setPickedResponsible = (val: string | null) => {
    this.pickedResponsible = val;
  };
  setDealList = (val: Deal[] | null) => {
    this.dealList = val;
  };
  setContragentsList = (val: Contragent[] | null) => {
    this.contragentsList = val;
  };
  setResponsibleList = (val: Contragent[] | null) => {
    this.responsibleList = val;
  };
  setUnitsList = (val: any) => {
    this.unitsList = val;
  };
  setArticlesList = (val: Article[] | null) => {
    this.articlesList = val;
  };
  setNomenclaturesList = (val: Nomenclature[] | undefined) => {
    this.nomenclaturesList = val;
  };
  setPickedResponsibleId = (val: string | null) => {
    this.pickedResponsibleId = val;
  };
  setPickedArticleId = (val: number | null) => {
    this.pickedArticleId = val;
  };
  setDraftComment = (val: string) => {
    this.draftComment = val;
  };

  setNewPositionId = (val: number) => {
    this.newPositionId = val;
  };

  setIsLoading = (val: boolean) => {
    this.isLoading = val;
  };

  setPickedIdx = (val: number) => {
    this.pickedIdx = val;
  };

  setSelectedIdx = (val: number) => {
    this.selectedIdx = val;
  };

  setIsStatusChangePending = (val: boolean) => {
    this.isStatusChangePending = val;
  };

  setCarryingDate = (val: string) => {
    this.carryingDate = val;
  };

  setIsNeedContragent = (val: boolean | null) => {
    if (val !== this.isNeedContragent) {
      this.setPickedContragent(null);
      this.setPickedContragentId(null);
      this.setContragentId(0);
    }
    this.isNeedContragent = val;
    if (val === null) return;
    val ? this.fetchAllContragents() : this.fetchUserContragents();
  };

  fetchReport = async (pageType?: string) => {
    this.setIsLoading(true);
    const response = await getReport(this.documentType, this.documentId);
    if (response) {
      this.setAdvanceReport(response);
      await this.fetchArticles();

      if (pageType !== "advance" || !pageType) {
        this.setPickedContragent(
          response.contragent_title || response.contragent
        );
        this.setPickedContragentId(response.contragent_id);
        this.setContragentId(response.contragent_id);
      }

      this.setPickedArticle(response.article_title);
      this.setPickedDeal(response.deal_number || response.deal_num);
      this.setDocumentNumber(response.number || response.document_number);
      this.setPickedResponsible(response.responsible);
      if (typeof response.article_need_contragent === "boolean") {
        this.setIsNeedContragent(response.article_need_contragent);
      }
    } else {
      this.setAdvanceReport({});
    }
    this.setIsLoading(false);
  };

  fetchDealNumbers = async () => {
    if (this.isNeedContragent === false) return;
    const response = await getDealNumbers(this.contragentId);
    if (response) {
      this.setDealList(response);
      if (response.length === 1) {
        this.setPickedDeal(response[0].title);
        this.setPickedDocumentId(response[0].id);
      }
    }
  };

  fetchAllContragents = async () => {
    const response = await getAllContragents();
    if (response) {
      this.setContragentsList(response);
      this.setContragentId(
        response.find(
          (contragent: Contragent) => contragent.title === this.pickedContragent
        )?.id
      );

      this.fetchDealNumbers();
    }
  };

  fetchUserContragents = async () => {
    const response = await getUserContragents();
    if (response) {
      this.setContragentsList(response);
      this.setContragentId(
        response.find(
          (contragent: Contragent) => contragent.title === this.pickedContragent
        )?.id
      );
      this.fetchDealNumbers();
    }
  };

  fetchResponsible = async () => {
    const response = await getResponsible();
    if (response) {
      this.setResponsibleList(response);
    }
  };
  fetchUnits = async () => {
    const response = await getUnits();

    if (response) {
      this.setUnitsList(
        response.map((value: any) => ({
          label: value.title,
          value: value.id,
        }))
      );
    }
  };

  fetchArticles = async () => {
    const response = await getArticles();
    if (response) {
      if (this.advanceReport?.type === "out") {
        this.setArticlesList(response.out);
      } else {
        this.setArticlesList(response.in);
      }
    }
  };

  fetchNomenclatures = async (body?: NomenclatureTypeKey[]) => {
    const response = await getNomenclatures(body);
    if (response) {
      this.setNomenclaturesList(
        response.map((value: any) => ({
          label: value.name,
          value: value.id,
          type: value.type,
          unit: value.unit,
          category: value.category,
          path: value.path,
        }))
      );
    }
  };

  updateAdvanceReport = async () => {
    const response = await fetchUpdateAdvanceReport(
      this.documentId,
      this.pickedResponsibleId
    );
    if (response) {
      this.setModalIsActive(false);
      this.fetchReport();
    }
  };

  updateInvoice = async () => {
    let formattedDate;

    if (typeof this.carryingDate === "string") {
      const [day, month, year] = this.carryingDate.split(".");
      formattedDate = format(new Date(+year, +month - 1, +day), "dd.MM.yyyy");
    } else {
      formattedDate = format(this.carryingDate, "dd.MM.yyyy");
    }

    const response = await fetchUpdateInvoice(
      this.documentId,
      this.documentNumber,
      this.pickedContragentId,
      this.pickedDocumentId,
      formattedDate
    );
    this.documentData = response.data;
    if (response) {
      this.setModalIsActive(false);
      this.fetchReport();
    }
  };

  updateCashOrder = async () => {
    const response = await fetchUpdateCashOrder(
      this.documentId,
      this.pickedContragentId,
      this.pickedDocumentId,
      this.pickedArticleId
    );
    if (response) {
      this.setModalIsActive(false);
      this.fetchReport();
    }
  };

  fetchCheckInventory = async () => {
    const response = await checkInventory(this.documentId);

    if (response) {
      return response;
    }

    return null;
  };

  approveStatus = async () => {
    const response = await fetchApproveStatus(
      this.documentId,
      this.documentType
    );
    if (response) {
      showAlertPopup(
        "Документ обновлен!",
        "Данные успешно изменены",
        "success"
      );
      this.setAdvanceReport({
        ...toJS(this.advanceReport),
        status: "approved",
      });
    } else {
    }
    this.setIsStatusChangePending(false);
  };

  deleteStatus = async () => {
    const response = await fetchDeleteStatus(
      this.draftComment,
      this.documentId,
      this.documentType
    );
    console.log(response);
    if (response) {
      showAlertPopup(
        "Документ обновлен!",
        "Данные успешно изменены",
        "success"
      );
      this.setAdvanceReport({
        ...toJS(this.advanceReport),
        status: "deleted",
      });
    } else {
      showAlertPopup("Ошибка!", "Что-то пошло не так", "error");
    }
    this.setIsStatusChangePending(false);
  };

  updatePosition = async (
    positionId: number,
    type: UpdatePositionTypes,
    value: number | string
  ) => {
    const response = await fetchUpdatePosition(
      this.documentType,
      this.documentId,
      positionId,
      type,
      value
    );
    this.setPickedNomenclatureId(null);
    this.setPickedSum(null);
    this.setPickedValue(null);
    this.setPickedValueFact(null);
    this.setPickedUnitId(null);
    return response;
    // return Promise.resolve(response);
  };

  fetchDeletePosition = async (object: IInventoryPositon) => {
    const response = await deletePosition(object.id);
    if (response) {
      this.setAdvanceReport({
        ...this.advanceReport,
        positions: toJS(this.advanceReport).positions.filter(
          (item: IInventoryPositon) => item.id !== object.id
        ),
      });
    }
  };

  createNewPosition = async () => {
    const response = await fetchCreateNewPosition(
      this.documentId,
      this.documentType
    );
    if (response) {
      return response.id;
    }
  };
}

import { api } from "./api";
import { Shift } from "../pages/timesheet/types";
import { Value } from "react-calendar/src/shared/types";

export async function fetchTable(selectedDate: Value) {
  if (!selectedDate) return;
  try {
    //todo: options
    // const url =
    const response = await api.get(
      `table/get?month=${
        ((selectedDate as Date).getMonth() + 1).toString().length === 1
          ? `0${(selectedDate as Date).getMonth() + 1}`
          : (selectedDate as Date).getMonth() + 1
      }.${(selectedDate as Date).getFullYear()}`,
    );
    return response.data.data;
  } catch (err) {
    return null;
  }
}

export async function fetchUpdateTimesheet(
  pickedCellDate: string,
  pickedCellId: number,
  pickedCellShifts: Shift[],
) {
  //todo: options
  // const url =
  try {
    const response = await api.post(`table/update`, {
      date: pickedCellDate,
      user_id: pickedCellId,
      shifts:
        pickedCellShifts &&
        pickedCellShifts.map((shift: any) => {
          const [day, month, year] = pickedCellDate.split(".");
          const startDate = new Date(+year, +month - 1, +day);
          const [startHours, startMinutes] = shift.fact_st.split(":");
          startDate.setHours(+startHours, +startMinutes, 0, 0);
          const [endHours, endMinutes] = shift.fact_end.split(":");
          const endDate = new Date(+year, +month - 1, +day);
          endDate.setHours(+endHours, +endMinutes, 0, 0);
          return {
            id: shift.id,
            time_start: startDate.toISOString(),
            time_finish: endDate.toISOString(),
          };
        }),
    });
    return response.data.data;
  } catch (error) {
    return null;
  }
}
export async function fetchDeleteShift(shift_id: number) {
  try {
    const response = await api.post(`table/delete`, {
      shift_id: shift_id,
    });

    return response.data.success;
  } catch (error) {
    return null;
  }
}

import "./styles.scss";
import React, { useState, useEffect } from "react";
import { RevenueInput } from "./components/revenueInput";
import {
  BigSkeleton,
  Button,
  EButtonVariant,
  EInputStyleVariant,
  EInputVariant,
  Input,
} from "ui";
import { EFontVariant } from "assets/styles/enums";
import RevenueCalendar from "./components/revenueCalendar";
import { RevenueOverlay } from "./components/revenueOverlay";
import { RevenueModal } from "./components/revenueModal";
import { observer } from "mobx-react-lite";
import { MonthPicker } from "widgets/datepickers/month-picker";
import { useStore } from "store";
import { toJS } from "mobx";
import { showConfirmAlertPopup } from "../../../ui/confirm-alert";
import { BlockerAlert } from "../../../ui/blocker-alert";

export const RevenuePlanningPage = observer(() => {
  const { RootStore } = useStore();
  const {
    RevenuePlanningStore: {
      selectedDate,
      setSelectedDate,
      revenueData,
      isNotEditable,
      inputValuesSum,
      revenueBalance,
      setRevenueBalance,
      categories,
      fetchRevenueData,
      total,
      setCategories,
      daySum,
      isLoading,
      isBlocking,
      setIsBlocking,
      fetchSave,
      fetchPlan,
      isChanged,
    },
  } = RootStore;
  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    fetchRevenueData();
  }, [selectedDate]);

  const handleMonthChange = (month: Date) => {
    setSelectedDate(month);
  };

  console.log(inputValuesSum, total, isChanged);

  function handleSelectDate(date: Date) {
    setSelectedDate(date);
  }

  useEffect(() => {
    if (!toJS(revenueData)) return;
    if (
      JSON.stringify({
        total: toJS(revenueData).total,
        is_create: toJS(revenueData).is_create,
        categories: toJS(categories),
        revenue: toJS(revenueBalance),
      }) !== JSON.stringify(toJS(revenueData))
    ) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [categories, toJS(revenueBalance)]);

  return (
    <div className="revenuePlanning__wrapper">
      <BlockerAlert
        isBlocking={isBlocking}
        title={"Подвердите действие"}
        content={
          "В планирование были внесены изменения.\n" +
          "Вы уверены, что хотите уйти без сохранения?\n" +
          "Чтобы не потерять новые данные, нажмите на кнопку “Сохранить план”."
        }
      />
      <div className="revenuePlanning__header">
        <div className="revenuePlanning__header__title">
          <h2>Планирование выручки</h2>
          <div className="revenuePlanning__header__title__buttons">
            <Button
              variant={EButtonVariant.fillLightButton}
              font={EFontVariant.body2Regular}
              height={"32px"}
              maxWidth={"93px"}
              borderRadius={"10px"}
              text="Отменить"
              onClick={() => {
                showConfirmAlertPopup(
                  "Подвердите действие",
                  "Были внесены изменения в планирования выручки, Вы уверены, что хотите их отменить?",
                  () => {},
                  () => fetchRevenueData(),
                );
              }}
              disabled={isNotEditable}
            />
            <Button
              variant={EButtonVariant.fillLightButton}
              font={EFontVariant.body2Regular}
              height={"32px"}
              maxWidth={"99px"}
              borderRadius={"10px"}
              text="Сохранить"
              onClick={() => {
                if (Math.abs(((inputValuesSum - total) / total) * 100) > 15) {
                  showConfirmAlertPopup(
                    "Подвердите действие",
                    `Отклонение от плана составило ${(
                      ((inputValuesSum - total) / total) *
                      100
                    ).toFixed(2)}%.\n` +
                      "К вам может обратиться Инструктор и план нужно будет скорректировать.\n" +
                      `Вы уверены, что хотите сохранить план?`,
                    () => {},
                    () => fetchSave(),
                  );
                } else {
                  fetchSave();
                }
              }}
              disabled={isNotEditable}
            />
            <Button
              variant={EButtonVariant.fillButton}
              font={EFontVariant.body2Regular}
              height={"32px"}
              maxWidth={"131px"}
              borderRadius={"10px"}
              text="Запланировать"
              disabled={isNotEditable}
              onClick={() => {
                if (Math.abs(((inputValuesSum - total) / total) * 100) > 15) {
                  showConfirmAlertPopup(
                    "Подвердите действие",
                    `Отклонение от плана составило ${(
                      ((inputValuesSum - total) / total) *
                      100
                    ).toFixed(2)}%.\n` +
                      "К вам может обратиться Инструктор и план нужно будет скорректировать.\n" +
                      `Вы уверены, что хотите запланировать выручку?`,
                    () => {},
                    () => fetchPlan(),
                  );
                } else {
                  showConfirmAlertPopup(
                    "Подвердите действие",
                    "Вы уверены, что хотите запланировать выручку на следующий месяц?\n" +
                      "Внести изменения в этот месяц уже не получится.",
                    () => {},
                    () => fetchPlan(),
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="revenue-planning__underheader">
          <MonthPicker date={selectedDate} dateSetter={setSelectedDate} />
          <div className="revenue-planning__info">
            <span>Запланировать выручку на следующий месяц</span>
            можно только до 10:00 МСК 16 числа текущего месяца
          </div>
        </div>
      </div>
      <div className="revenuePlanning__main">
        {isLoading ? (
          <BigSkeleton padding="0 20px 40px" />
        ) : (
          <>
            <RevenueOverlay isActive={opened} modalClick={() => {}} />
            <RevenueModal isActive={opened} modalClick={() => {}} />
            <div className="revenuePlanning__container">
              <div className="revenuePlanning__summary-revenue">
                {toJS(revenueData) && (
                  <>
                    <p className="title">Суммарная плановая выручка за месяц</p>
                    <div>
                      {new Intl.NumberFormat("fr-FR").format(
                        isChanged ? inputValuesSum : total,
                      )}{" "}
                      ₽
                      <span className="revenuePlanning__difference">
                        {inputValuesSum - total === 0 ? (
                          <></>
                        ) : (
                          <>
                            {inputValuesSum - total > 0 ? (
                              <p className="colorSuccess">
                                +{(inputValuesSum - total).toFixed(2)}
                                <span>
                                  {" "}
                                  {(
                                    ((inputValuesSum - total) / total) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </span>
                              </p>
                            ) : (
                              <p className="colorError">
                                {(inputValuesSum - total).toFixed(2)}
                                <span>
                                  {" "}
                                  {(
                                    ((inputValuesSum - total) / total) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </span>
                              </p>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="revenuePlanning__detail-revenue">
                <p>Планирование выручки по категориям</p>
                {toJS(categories)
                  ? toJS(categories).map((object: any, index: any) => (
                      <div className="revenue-planing__detail-container">
                        <RevenueInput
                          summary={object.sum} // значения буду считаться по формулам из access`а
                          title={object.name}
                          key={index}
                          disabled={true}
                          maxWidth={"203px"}
                        />
                        <div className="revenue-planning__detail-percent">
                          <Input
                            value={`${object.percent}`}
                            type={EInputVariant.text}
                            variant={EInputStyleVariant.basicInput}
                            height={"34px"}
                            borderRadius={"12px"}
                            maxWidth={`67px`}
                            onChange={(
                              event: React.FormEvent<HTMLInputElement>,
                            ) => {
                              const { value } =
                                event.target as HTMLInputElement;

                              if (
                                (value === "" || /^\d+$/.test(value)) &&
                                +value >= 0 &&
                                +value <= 100
                              ) {
                                setCategories([
                                  ...toJS(categories).map((item: any) => {
                                    if (
                                      item.revenue_category_id ===
                                      object.revenue_category_id
                                    ) {
                                      return {
                                        ...object,
                                        percent: value === "" ? 0 : +value,
                                      };
                                    }
                                    return item;
                                  }),
                                ]);
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
            <div className="revenuePlanning__tabel">
              <p>Планирование выручки по дням</p>
              <RevenueCalendar
                handleMonthChange={handleMonthChange}
                modalClick={() => {}}
                value={selectedDate}
                handleSelectDate={handleSelectDate}
                summary={toJS(daySum)}
                revenueBalance={toJS(revenueBalance)}
                setRevenueBalance={setRevenueBalance}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
});

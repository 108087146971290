import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import "./styles.scss";
import {
  Input,
  EInputVariant,
  EInputStyleVariant,
  Logo,
  ELogoVariant,
  Button,
  EButtonVariant,
  Checkbox,
  ECheckboxChecked,
  ECheckboxUnchecked,
  Navlink,
  ENavigateStyleVariant,
  Spinner,
  ESpinnerVariant,
} from "ui";
import { useStore } from "store";
import { EFontVariant } from "assets/styles/enums";

export const AuthPage = observer(() => {
  const navigate = useNavigate();
  const {
    RootStore: {
      setIsAuthenticated,
      AuthStore: {
        login,
        setLogin,
        password,
        setPassword,
        passwordRepeatVision,
        setPasswordRepeatVision,
        fetchAuth,
        isLoading,
        setIsLoading,
        setSaveAuth,
        saveAuth,
      },
    },
  } = useStore();

  return (
    <div className="auth__wrapper">
      <div className="auth__container">
        <div className="auth__logo-container">
          <Logo variant={ELogoVariant.logo1} />
        </div>
        <span className="auth__line"></span>
        <div className="auth__authContainer">
          <h2 className={`auth__authContainer-header`}>Вход в систему</h2>
          <span className={`auth__authContainer-wellcome_message`}>
            В качестве логина используйте email-адрес.
          </span>
          <label className="auth__authContainer-login">
            <span className={`auth__authContainer-login__text-container`}>
              Логин
            </span>
            <Input
              type={EInputVariant.text}
              variant={EInputStyleVariant.basicInput}
              height={"44px"}
              borderRadius={"16px"}
              value={login}
              autoFocus={true}
              onChange={(e) => setLogin(e.currentTarget.value)}
            />
          </label>
          <label className="auth__authContainer-pass">
            <span className={`auth__authContainer-pass__text-container`}>
              Пароль
            </span>
            <Input
              type={
                passwordRepeatVision
                  ? EInputVariant.text
                  : EInputVariant.password
              }
              variant={EInputStyleVariant.basicInput}
              height={"44px"}
              borderRadius={"16px"}
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <div className="auth__authContainer-pass__button-container">
              {passwordRepeatVision ? (
                <Button
                  variant={EButtonVariant.eyeButton}
                  maxWidth={"24px"}
                  height={"24px"}
                  onClick={() => setPasswordRepeatVision(false)}
                />
              ) : (
                <Button
                  variant={EButtonVariant.shapeButton}
                  maxWidth={"24px"}
                  height={"24px"}
                  onClick={() => setPasswordRepeatVision(true)}
                />
              )}
            </div>
          </label>
          <div className="auth__restore-and-save-container">
            <label className="auth__checkbox-container">
              <Checkbox
                checkedVariant={ECheckboxChecked.checkedVariant1}
                uncheckedVariant={ECheckboxUnchecked.uncheckedVariant1}
                styles={{ marginRight: 12 }}
                onChange={() => {
                  saveAuth === false ? setSaveAuth(true) : setSaveAuth(false);
                }}
              />
              <span className={`auth__remember-text`}>Запомнить меня</span>
            </label>
            <Navlink
              variant={`${ENavigateStyleVariant.textNavItem1} body2Medium`}
              path={"/login/reset-pass"}
              text={"Забыли пароль?"}
            />
          </div>
          {login !== "" &&
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(login) &&
            password !== "" &&
            password.length >= 3 ? (
            isLoading ? (
              <Spinner
                variant={ESpinnerVariant.spinnerGeneral}
                maxWidth={"336px"}
                height={"48px"}
                borderRadius={"16px"}
              />
            ) : (
              <Button
                variant={EButtonVariant.fillButton}
                font={EFontVariant.body2Regular}
                height={"48px"}
                borderRadius={"16px"}
                text="Войти в систему"
                onClick={() => {
                  fetchAuth()
                    .then(() => {
                      setIsAuthenticated(true);
                      navigate("/main/registry");
                      if (!localStorage.getItem("isFirstAuth")) {
                        localStorage.setItem("isFirstAuth","true");
                      }
                    })
                    .catch(() => { });
                  setIsLoading(true);
                }}
              />
            )
          ) : (
            <Button
              variant={EButtonVariant.fillButton}
              font={EFontVariant.body2Regular}
              disabled={true}
              height={"48px"}
              borderRadius={"16px"}
              text="Войти в систему"
            />
          )}
        </div>
      </div>
    </div>
  );
});

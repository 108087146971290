import { IUnwanted } from 'pages/spot/schedule-planning-new/types';
import { FC,useMemo } from 'react';
import cls from './unwanted-style.module.scss';
import { TimeIcon } from 'pages/spot/schedule-planning-new/icons';
import { formatTime } from 'pages/spot/schedule-planning-new/format-time';

interface UnwantedProps {
  unwanted: IUnwanted;
}

export const Unwanted: FC<UnwantedProps> = ({ unwanted: { type,start_time,end_time,reason } }) => {
  const time = useMemo(() => {
    if (type === 'full_day') return 'Весь день';
    if (!start_time || !end_time) return 'Время указано некорректно';
    else return `${formatTime(start_time)} - ${formatTime(end_time)}`;
  },[end_time,start_time,type]);

  return (
    <div className={cls['unwanted']}>
      <TimeIcon color='#EB5757' />
      <span className={cls['unwanted-time']}>{time}</span>
      {reason && <p className={cls['unwanted-reason']} title={reason.title}>{reason.title}</p>}
    </div>
  );
};

export const convertUnwanted = (unwantedList: IUnwanted[]) => {
  return unwantedList.map((unwanted,i) => <Unwanted key={i} unwanted={unwanted} />);
};
import { observer } from 'mobx-react-lite';
import { FC,useCallback } from 'react';
import { TimeField } from 'ui-new/timepicker';
import * as yup from 'yup';
import { ModalButtons } from 'widgets/modal/buttons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';
import cls from '../styles.module.scss';
import { useStore } from 'store';
import { format } from 'date-fns';
import { showNotificationModal } from 'ui-new/alert';
import { toJS } from 'mobx';

type FormValues = {
  startTime: string;
  endTime: string;
};

const schema = yup.object({
  startTime: yup.string().required(ValidationMessages.get(ValidationTypes.required)),
  endTime: yup.string().required(ValidationMessages.get(ValidationTypes.required)),
});

interface CreateByTimeProps {
  onClose: () => void;
}

const CreateByTimeComponent: FC<CreateByTimeProps> = ({ onClose }) => {
  const { RootStore: { SchedulePlanningStore: { fetchCreateShift,createShiftDate,selectedWorker } } } = useStore();
  const { handleSubmit,control } = useForm<FormValues>({ resolver: yupResolver<FormValues>(schema),mode: 'onChange' });

  const onSave = useCallback(({ endTime,startTime }: FormValues) => {
    if (!createShiftDate || !selectedWorker) {
      onClose();
      showNotificationModal({ title: 'Упс! Что-то пошло не так',errors: ['Попробуйте создать еще раз'],type: 'failure' });
      return;
    }
    const date = format(createShiftDate,'yyyy-MM-dd');
    const start_time = (new Date(startTime)).toLocaleTimeString('ru-RU',{ hour: '2-digit',minute: '2-digit' });
    const end_time = (new Date(endTime)).toLocaleTimeString('ru-RU',{ hour: '2-digit',minute: '2-digit' });
    fetchCreateShift({ start_time,end_time,date,user_id: selectedWorker.id });
  },[createShiftDate,fetchCreateShift,toJS(selectedWorker)]);

  const onErrorHandler = useCallback(() =>
    showNotificationModal({ title: 'Ошибка',type: 'failure',errors: ['Для добавления смены выберите время начала и окончания'],maxWidth: '360px' }),[]);

  return (
    <div className={cls['shift-create__time-container']}>
      <TimeField name='startTime' placeholder='08:00' label='Начало смены' control={control} minuteStep={30} width={'104px'} />
      <TimeField name='endTime' placeholder='15:30' label='Конец смены' control={control} minuteStep={30} width={'104px'} />
      <div className={cls['buttons']}>
        <ModalButtons onPrimaryButtonClick={handleSubmit(onSave,onErrorHandler)} onSecondaryButtonClick={onClose} primaryButtonLabel={'Добавить'} secondaryButtonLabel={'Отменить'} />
      </div>
    </div>
  );
};

export const CreateByTime = observer(CreateByTimeComponent);
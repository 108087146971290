import { forwardRef,ReactNode,useMemo } from 'react';
import cls from './worker-styles.module.scss';
import { IUnwanted } from 'pages/spot/schedule-planning-new/types';

interface ShiftClearProps {
  onCellClick: () => void;

  unwanted: IUnwanted[];

  children: ReactNode;
  referenceProps: Record<string,unknown>;
}

export const ShiftClear = forwardRef<any,ShiftClearProps>(
  ({ onCellClick,unwanted,children,referenceProps },forwardRef) => {
    const allDayUnwanted = useMemo(() => unwanted.findIndex(unwanted => unwanted.type === 'full_day') > -1,[unwanted]);

    return (
      <td ref={forwardRef} {...referenceProps} className={`${cls['shift']}
          ${unwanted.length > 0 ?
          (allDayUnwanted ?
            cls['shift__clear-unwanted_all'] : cls['shift__clear-unwanted'])
          :
          cls['shift__clear']
        }`}
        onClick={onCellClick}
      >
        {children}
      </td>
    );
  }
);

import { FC,ReactNode } from 'react';
import { Modal } from 'widgets/modal';
import { ModalButtons } from 'widgets/modal/buttons';
import cls from './confirm-modal.module.scss';

interface ConfirmModalProps {
  onClose: () => void;
  onConfirm: () => void;

  title: string;
  messages?: ReactNode[];

  confirmButtonLabel: string;
  cancelButtonLabel?: string;

  maxWidth?: string;
  minWidth?: string;
}

const ConfirmModalComponent: FC<ConfirmModalProps> = ({ onClose,onConfirm,confirmButtonLabel,cancelButtonLabel,title,messages,maxWidth,minWidth }) => {
  const messagesData = messages?.map(message => <p className={cls['message']}>{message}</p>);

  return (
    <Modal
      title={messages ? title : null}
      maxWidth={maxWidth ?? '350px'}
      minWidth={minWidth}
      onClose={onClose}
    >
      <div className={cls['container']} style={messages && { marginTop: '-8px' }}>
        {!messages && <p className={cls['title']}>{title}</p>}
        {messagesData}
        <ModalButtons
          primaryButtonLabel={confirmButtonLabel} onPrimaryButtonClick={onConfirm}
          secondaryButtonLabel={cancelButtonLabel ?? 'Отменить'} onSecondaryButtonClick={onClose}
        />
      </div>
    </Modal>
  );
};

export const ConfirmModal = ConfirmModalComponent;
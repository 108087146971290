import { FC,ReactNode,useMemo } from 'react';
import cls from '../../styles.module.scss';
import { observer } from 'mobx-react-lite';
import { Shift } from './shift';
import { ITotal,IWorker } from 'pages/spot/schedule-planning-new/types';
import dayjs from 'dayjs';
import { WorkerTitle } from './worker-title';

const round = (duration: number) => {
  return Math.floor(duration * 10) / 10;
};

interface WorkerRowProps {
  worker: IWorker;
  tableType: string;
}

const WorkerRowComponent: FC<WorkerRowProps> = ({ worker,tableType }) => {
  const { id,name,title,results: { dates,total } } = worker;
  const monthData = useMemo(() => {
    const shifts = dates.reduce((acc: ReactNode[],workerDates,i) => {
      acc.push(Object.keys(workerDates).reduce((acc: ReactNode[],key) => {

        acc.push(<Shift date={dayjs(key,'DD.MM.YYYY').toDate()} workerInfo={{ id,title,name }} workerDate={workerDates[key]} />);
        return acc;
      },[]));
      return acc;
    },[]);
    if (shifts.length === 0) return null;

    shifts.splice(15,0,<td className={cls['column-time']}>{round(total.first_part)}</td>);
    shifts.push(<td className={cls['column-time']}>{round(total.last_part)}</td>,
      <td className={cls['column-sum']}>{round(total.all_parts)}</td>);
    return shifts;
  },[dates,id,name,title,total.all_parts,total.first_part,total.last_part]);


  return (<tr>
    <td>
      <WorkerTitle worker={worker} tableType={tableType} />
    </td>
    {monthData}
  </tr>);
};

export const WorkerRow = observer(WorkerRowComponent);
export enum EFontVariant {
  subtitleSemiBold = "subtitleSemiBold",

  body1Regular = "body1Regular",
  body1Medium = "body1Medium",
  body1SemiBold = "body1SemiBold",
  body2Regular = "body2Regular",
  body2Medium = "body2Medium",
  body2Bold = "body2Bold",

  captionRegular = "captionRegular",
  captionMedium = "captionMedium",
  captionSemiBold = "captionSemiBold",

}

export enum EColorVariant {
  colorNeutral0 = "colorNeutral0",
  colorNeutral10 = "colorNeutral10",
  colorNeutral20 = "colorNeutral20",
  colorNeutral30 = "colorNeutral30",
  colorNeutral40 = "colorNeutral40",
  colorNeutral50 = "colorNeutral50",
  colorNeutral60 = "colorNeutral60",
  colorNeutral70 = "colorNeutral70",
  colorNeutral80 = "colorNeutral80",
  colorNeutral90 = "colorNeutral90",
  colorNeutral100 = "colorNeutral100",
  colorAccent = "colorAccent",
  colorError = "colorError",
  colorWarning = "colorWarning",
  colorSuccess = "colorSuccess",
}

import { ProviderProps } from "@reactour/tour";
import colors from "../../assets/styles/colors.scss";

export const tourProps: Partial<ProviderProps> = {
  showDots: false,
  badgeContent: (badgeProps) =>
    `${badgeProps.currentStep + 1}/${badgeProps.totalSteps}`,
  styles: {
    badge: (props, state) => ({
      ...props,
      background: colors.colorInfoFill,
      padding: "8px",
      boxShadow:
        "0px 1px 3px 0px rgba(77, 77, 77, 0.20), 0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
      color: colors.colorNeutral0,
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "12px",
      display: "flex",
      alignItems: "center",
    }),
    popover: (props) => {
      return {
        ...props,
        borderRadius: "16px",
        padding: "32px 12px 12px 12px",
        border: "1px solid",
        borderColor: colors.colorNeutral30,
        background: colors.colorNeutral0,
        boxShadow:
          " 0px 4px 20px 0px rgba(0, 0, 0, 0.10), 0px 2px 8px 0px rgba(0, 0, 0, 0.05)",
      };
    },
  },
};

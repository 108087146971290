import { useEffect, useState } from "react";
import "../styles.scss";
import { ExpandArrow, ArrowState } from "ui";

interface ORPSTableRowProps {
  name: String;
  sum: number;
  ammount: number;
  vv: number;
  sebes: number;
  vp: number;

  children?: any[] | undefined;
  level: number;
  arrow?: ArrowState | undefined;

  isGrey?: boolean;
  isBold?: boolean;
  visible?: boolean | undefined;
  parentVisible?: boolean | undefined;
}

export const ORPTableRow = (props: ORPSTableRowProps) => {
  const {
    name,
    sum,
    ammount,
    vv,
    sebes,
    vp,

    children,
    level,
    arrow,

    isGrey,
    isBold,

    visible,
    parentVisible,
  } = props;
  const [showChildren, setShowChildren] = useState(false);
  useEffect(() => {
    if (parentVisible === false) setShowChildren(false);
  }, [parentVisible]);

  return children ? (
    <>
      <div
        onClick={() => {
          console.log("show");
          setShowChildren(!showChildren);
        }}
        className={` ${showChildren && level === 0 ? "blue0" : ""}
        ${level === 1 ? "blue1" : ""}`}
        style={{
          zIndex: 10 - level,
        }}
      >
        <ORPTableRow
          isGrey={isGrey}
          isBold={isBold}
          name={name}
          sum={sum}
          level={level}
          visible={visible}
          ammount={ammount}
          vv={vv}
          sebes={sebes}
          vp={vp}
          arrow={showChildren ? ArrowState.OPENED : ArrowState.CLOSED}
        />
      </div>

      {children.map((child, index) => {
        return (
          <ORPTableRow
            key={index}
            children={child.children}
            level={child.level}
            isGrey={child.isGrey}
            isBold={child.isBold}
            name={child.name}
            sum={child.sum}
            visible={showChildren}
            ammount={child.ammount}
            vv={child.vv}
            sebes={child.sebes}
            vp={child.vp}
            parentVisible={showChildren}
          />
        );
      })}
    </>
  ) : (
    <div
      className={`orp__table__row__wrapper  ${
        visible ? "visible" : "invisible"
      }`}
    >
      <div
        className={`orp__table__row  ${isGrey && "orp__table__row__grey"} 
        `}
        style={{
          zIndex: 10 - level,
        }}
      >
        <div
          className={`orp__table__row__name ${isBold && "orp__table__row__bold"}
          ${level !== 0 ? "levelPadding" + level : ""}`}
        >
          <div className={`orp__table__row__arrow table-item `}>
            {arrow ? <ExpandArrow state={arrow} /> : ""}
          </div>
          <p className="table-item">{name}</p>
        </div>
        <div className="table-item orp__table__row__sum">{sum && sum}</div>
        <div className="table-item orp__table__row__ammount">
          {ammount && ammount}
        </div>
        <div className="table-item orp__table__row__vv">{vv && vv}</div>
        <div className="table-item orp__table__row__sebes">
          {sebes && sebes}
        </div>
        <div className="table-item orp__table__row__vp">{vp && vp}</div>
      </div>
    </div>
  );
};

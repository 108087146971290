import { observer } from 'mobx-react-lite';
import { ScheduleHeader } from './components/header';
import cls from './styles.module.scss';
import { useEffect,useMemo,useState } from 'react';
import { ScheduleTab } from './schedule/schedule';
import { TemplatesTab } from './templates/templates';
import { useStore } from 'store';
import { showNotificationModal } from 'ui-new/alert';

const SchedulePlanningComponent = () => {
  const { RootStore: { SchedulePlanningStore: { fetchGetTemplates,fetchGetTimetable,selectedDate },HeaderStore: { permissions } } } = useStore();
  const [activeTab,setActiveTab] = useState<0 | 1>(0);

  //TODO: Костыль, переделать, когда будут реализовываться права доступа
  const canScheduleView = useMemo(() => {
    if (!permissions) return false;

    const schedulePermissions = permissions['Scheduling'];
    if (!schedulePermissions) return false;

    const viewPermission = !Object.entries(schedulePermissions).every(([key,value]) => !value.active);

    return viewPermission;

  },[permissions]);

  useEffect(() => {
    if (!canScheduleView) {
      showNotificationModal({ title: 'Ошибка прав доступа',errors: ['Не соответствие прав доступа, невозможно выполнить операцию'],type: 'failure' });
    }
  },[canScheduleView]);

  useEffect(() => {
    fetchGetTemplates();
  },[]);
  useEffect(() => {
    fetchGetTimetable();
  },[selectedDate]);

  if (!canScheduleView) return null;

  return (<div className={cls['schedule-planning__wrapper']}>
    <ScheduleHeader activeTab={activeTab} setActiveTab={setActiveTab} />
    {!activeTab ? <ScheduleTab /> : <TemplatesTab />}
  </div>);
};

export const SchedulePlanningPage = observer(SchedulePlanningComponent);
export const DoneIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7342 5.77344C15.8184 5.85968 15.8853 5.96217 15.9309 6.07501C15.9765 6.18785 16 6.30883 16 6.43102C16 6.5532 15.9765 6.67418 15.9309 6.78703C15.8853 6.89987 15.8184 7.00235 15.7342 7.0886L8.76202 14.227C8.67807 14.3135 8.5783 14.3822 8.46845 14.429C8.35861 14.4759 8.24083 14.5 8.12189 14.5C8.00295 14.5 7.88518 14.4759 7.77533 14.429C7.66548 14.3822 7.56572 14.3135 7.48176 14.227L4.26515 10.936C4.18109 10.8497 4.11441 10.7471 4.06891 10.6343C4.02342 10.5215 4 10.4006 4 10.2784C4 10.1563 4.02342 10.0354 4.06891 9.92256C4.11441 9.80973 4.18109 9.70721 4.26515 9.62085C4.34921 9.5345 4.44901 9.466 4.55885 9.41926C4.66868 9.37253 4.7864 9.34847 4.90528 9.34847C5.02417 9.34847 5.14188 9.37253 5.25172 9.41926C5.36155 9.466 5.46135 9.5345 5.54541 9.62085L8.12265 12.2534L14.4554 5.77111C14.5395 5.68495 14.6393 5.61666 14.7491 5.57014C14.8589 5.52362 14.9765 5.49979 15.0953 5.5C15.214 5.50022 15.3316 5.52448 15.4412 5.5714C15.5509 5.61831 15.6504 5.68697 15.7342 5.77344Z"
        fill="white"
      />
    </svg>
  );
};

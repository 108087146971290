import React,{ FC } from "react";
import { addSpaces } from "../../../assets/utils";
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import { TooltipProps } from 'recharts';

enum DataKey {
  'count' = 'Количество чеков',
  'average' = 'Средний чек'
}

const showLabel = (date: string) => {
  const day = dayjs(date,'DD.MM.YYYY');
  if (!day.isValid()) return date;

  return day.locale(ru).format('DD.MM.YYYY, dd');
};

interface CustomTooltipProps extends TooltipProps<string | number | (string | number)[],string | number> {
}

export const CustomTooltipContent: FC<CustomTooltipProps> = ({
  payload,
  label,
}) => {

  if (payload && payload.length > 0) {

    return <div className="chart-tooltip">
      <p>
        {showLabel(label)}
      </p>
      {payload?.map((p: any) =>
        <p>
          {(DataKey as any)[p.dataKey]}
          <span className={`tooltip-${p.dataKey}`}> {addSpaces(p.value)}</span>
        </p>
      )}
    </div>;
  }

  // setFixedTooltipVis(prev => prev ? prev : true);
  // if (activeId !== null)
  //   return <div
  //     className={`chart-tooltip  ${fixedTooltipX ?? 0 > 300
  //       ? "chart-tooltip__fixed--left"
  //       : "chart-tooltip__fixed--right"
  //       }`}
  //   >
  //     <p>
  //       {showLabel(label)}
  //     </p>
  //     {activeChars.count && <p>
  //       Количество чеков
  //       <span className={`tooltip-count`}> {addSpaces(data[activeId].count)}</span>
  //     </p>}
  //     {activeChars.average && <p>
  //       Средний чек
  //       <span className={`tooltip-average`}> {addSpaces(data[activeId].average)}</span>
  //     </p>}
  //   </div>;

  return null;
};

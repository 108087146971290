import { makeAutoObservable } from "mobx";
import { getBills } from "api";
import { RootStore } from "../../store/rootStore";

export class DashboardStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  isLoading: boolean = false;
  billsCount: number = 0;
  billAverage: number = 0;

  period: string[] = [];
  averageResult: number[] = [];
  countResult: number[] = [];
  data: { count: number; average: number; period: string }[] = [];

  setIsLoading = (bool: boolean) => {
    this.isLoading = bool;
  };

  fetchGetBills = async (day?: string | null) => {
    this.setIsLoading(true);
    const response = await getBills(day);
    this.setIsLoading(false);
    if (!response) {
      this.period = [];
      this.countResult = [];
      this.averageResult = [];
      this.billAverage = 0;
      this.billsCount = 0;
      this.data = [];
      return;
    }

    this.period = response.bills.period;
    this.countResult = response.bills.count_result;
    this.averageResult = response.bills.average_result;
    this.billAverage = response.by_report.average;
    this.billsCount = response.by_report.count;
    this.data = response.bills.period.map((p, i) => {
      return {
        period: p,
        average: this.averageResult[i] ?? 0,
        count: this.countResult[i] ?? 0,
      };
    });
  };
}

import { observer } from 'mobx-react-lite';
import { FC,useMemo } from 'react';
import { IInvoice } from '../types';
import dayjs from 'dayjs';
import { toJS } from 'mobx';
import cls from './inside-table-styles.module.scss';
import bigTableCls from '../table-styles.module.scss';
import { addSpaces } from 'assets/utils';

interface InvoicesTableProps {
  invoices: IInvoice[];
}

const InvoicesTableComponent: FC<InvoicesTableProps> = ({ invoices }) => {
  const invoicesData = useMemo(() => toJS(invoices).sort((a,b) => dayjs(b.dateFor).diff(a.dateFor)).map(invoice => {
    return <tr>
      <td>№{invoice.number}</td>
      <td>{invoice.date}</td>
      <td className={invoice.expired ? bigTableCls['date-expired'] : ''}>{invoice.dateFor}</td>
      <td>{addSpaces(invoice.sum.toFixed(2))}</td>
      <td>{addSpaces(invoice.paid.toFixed(2))}</td>
      <td>{addSpaces(invoice.credit.toFixed(2))}</td>
      <td>{addSpaces(invoice.from0To10days.toFixed(2))}</td>
      <td>{addSpaces(invoice.from11To30days.toFixed(2))}</td>
      <td>{addSpaces(invoice.from31To60days.toFixed(2))}</td>
    </tr>;
  }),[invoices]);

  return (
    <div className={cls['wrapper']}>
      <table className={cls['table']}>
        <colgroup>
          <col width={140} />
          <col width={114} />
          <col width={165} />
          <col width={165} />
          <col width={165} />
          <col width={165} />
          <col width={165} />
          <col width={165} />
          <col width={149} />
        </colgroup>
        <thead>
          <tr>
            <th>Накладная</th>
            <th>Дата</th>
            <th>Погасить до</th>
            <th>Сумма общая</th>
            <th>Оплачено</th>
            <th>Остаток задолженности</th>
            <th>До 10 дней</th>
            <th>От 11 до 30 дней</th>
            <th>От 31 до 60 дней</th>
          </tr>
        </thead>
        <tbody>
          {invoicesData.length === 0 &&
            <tr>
              <td colSpan={9}>
                <p style={{ textAlign: 'center',padding: '10px 16px' }}>По выбранному поставщику нет накладных</p>
              </td>
            </tr>}
          {invoicesData}
        </tbody>
      </table>
    </div>
  );
};

export const InvoicesTable = observer(InvoicesTableComponent);
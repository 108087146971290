import { FC,ReactNode } from 'react';
import { SortArrowsIcon } from 'ui-new/icons/sort-arrows-icon';
import cls from './sort-arrow-styles.module.scss';

interface SortArrowsProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
}

export const SortArrowsComponent: FC<SortArrowsProps> = ({ children,...props }) => {

  return (
    <button className={cls['sort-button']} {...props}>
      {children}
      <SortArrowsIcon />
    </button>
  );
};

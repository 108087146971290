import "../styles.scss";
import { useState } from "react";
import { ExpandPlusMinus } from "ui";

interface IBalanceSection {
  sectionData: any;
  active: boolean;
  sectionTotal: number;
}

export const BalanceSection = ({
  sectionData,
  active,
  sectionTotal,
}: IBalanceSection) => {
  const [data, setData] = useState(
    sectionData.map((group: any) => ({ ...group, expanded: true })),
  );
  const [showSection, setShowSection] = useState(true);

  return (
    <>
      {data.length ? (
        <div className="reportTable__balanceItem">
          {/* НАИМЕНОВАНИЕ */}
          <div className="reportTable__nominations">
            <div
              className="reportTable__nominationsTitle body2Medium colorNeutral50"
              onClick={() => setShowSection(!showSection)}
            >
              <ExpandPlusMinus expanded={showSection} />
              {active ? "Актив" : "Пассив"}
            </div>

            {showSection &&
              data.map((group: any, index: any) => {
                return (
                  <div
                    key={index}
                    className="reportTable__nominationsItem body2Medium colorNeutral90"
                  >
                    <div
                      className="reportTable__nominationsItemMain"
                      onClick={() => {
                        setData((prev: any) => {
                          const updtd = JSON.parse(JSON.stringify(prev));
                          updtd[index].expanded = !updtd[index].expanded;
                          console.log(updtd);
                          return updtd;
                        });
                      }}
                    >
                      <ExpandPlusMinus expanded={group.expanded} />
                      {group.name}
                    </div>

                    {group.expanded &&
                      group.balance_items.map((item: any, indexBill: any) => {
                        return (
                          <div
                            key={indexBill}
                            className="reportTable__nominationsItemUnit body2Regular colorNeutral9 0"
                          >
                            {item.name}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </div>

          {/* СУММЫ ЗА СЕКЦИЮ (актив/пассив), группу и итем группы */}
          <div className="reportTable__summary body2Medium colorNeutral50">
            <div className="summary__item summary__item_main">
              <span>Итого</span>
              <span>{sectionTotal}</span>
            </div>

            {showSection &&
              data.map((group: any) => {
                return (
                  <>
                    <div className="summary__item summary__item_bold body2Bold colorNeutral90">
                      {group.total}
                    </div>
                    {group.balance_items.map((item: any, index: any) => {
                      return (
                        group.expanded && (
                          <div className="summary__item body2Regular colorNeutral90">
                            {item.value}
                          </div>
                        )
                      );
                    })}
                  </>
                );
              })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const BalanceSectionStretcher = () => (
  <div className="reportTable__balanceItem">
    <div className="reportTable__nominations">
      <div className="reportTable__nominationsTitle body2Medium colorNeutral50">
        'Актив'
      </div>
      <div className="reportTable__nominationsItem body2Medium colorNeutral90">
        <div className="reportTable__nominationsItemMain" />
        <div className="reportTable__nominationsItemUnit body2Regular colorNeutral9 0" />
      </div>
    </div>
  </div>
);

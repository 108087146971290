import { observer } from "mobx-react-lite";
import { FC, useMemo, useState } from "react";
import { ArrowIcon } from "../icons";
import cls from "../table-styles.module.scss";
import { addSpaces } from "assets/utils";
import { useStore } from "store";
import { IContragentLoan } from "../types";
import { InfoContainer } from "../components/info-tooltip";
import { InvoicesTable } from "./invoices-table";

const INFO_MESSAGE = "Сумма предстоящих выплат поставщику в указанном периоде";

interface LoansTableProps {}

const ContragentsTableComponent: FC<LoansTableProps> = () => {
  const {
    RootStore: {
      ContragentsLoansPage: { byContragents },
    },
  } = useStore();

  const contragentsData = useMemo(
    () => byContragents.map(c => <Contragent key={c.id} contragent={c} />),
    [byContragents]
  );

  const [credit, paid, credit_final, from0To10days, from11To30days, from31To60days] =
    useMemo(() => {
      let credit = 0;
      let paid = 0;
      let credit_final = 0;
      let from0To10days = 0;
      let from11To30days = 0;
      let from31To60days = 0;
      byContragents.forEach(c => {
        credit += c.credit;
        paid += c.paid;
        credit_final += c.credit_final;
        from0To10days += c.from0To10days;
        from11To30days += c.from11To30days;
        from31To60days += c.from31To60days;
      });

      return [credit, paid, credit_final, from0To10days, from11To30days, from31To60days];
    }, [byContragents]);

  return (
    <table className={cls["table"]}>
      <colgroup>
        <col width={40} />
        <col width={230} />
        <col width={165} />
        <col width={165} />
        <col width={165} />
        <col width={165} />
        <col width={165} />
        <col width={165} />
        <col width={165} />
      </colgroup>
      <thead className={cls["thead"]}>
        <tr className={cls["tr"]}>
          <th className={cls["th"]} style={{ borderRight: "none" }}></th>
          <th className={cls["th"]} style={{ borderLeft: "none" }}>
            Поставщик
          </th>
          <th className={cls["th"]}>№ Договора</th>
          <th className={cls["th"]}>Сумма общая</th>
          <th className={cls["th"]}>Оплачено</th>
          <th className={cls["th"]}>Остаток задолженности</th>
          <th className={cls["th"]}>
            <InfoContainer message={INFO_MESSAGE}>До 10 дней</InfoContainer>
          </th>
          <th className={cls["th"]}>
            <InfoContainer message={INFO_MESSAGE}>От 11 до 30 дней</InfoContainer>
          </th>
          <th className={cls["th"]}>
            <InfoContainer message={INFO_MESSAGE}>От 31 до 60 дней</InfoContainer>
          </th>
        </tr>
      </thead>
      <tbody className={cls["tbody"]}>{contragentsData}</tbody>
      <tfoot className={cls["tfoot"]}>
        <tr className={cls["tr"]}>
          <td className={cls["td"]}></td>
          <td className={`${cls["td"]} ${cls["sum-title"]}`}>Всего</td>
          <td className={cls["td"]}></td>
          <td className={cls["td"]}>{addSpaces(credit.toFixed(2))}</td>
          <td className={cls["td"]}>{addSpaces(paid.toFixed(2))}</td>
          <td className={cls["td"]}>{addSpaces(credit_final.toFixed(2))}</td>
          <td className={cls["td"]}>{addSpaces(from0To10days.toFixed(2))}</td>
          <td className={cls["td"]}>{addSpaces(from11To30days.toFixed(2))}</td>
          <td className={cls["td"]}>{addSpaces(from31To60days.toFixed(2))}</td>
        </tr>
      </tfoot>
    </table>
  );
};

interface ContragentProps {
  contragent: IContragentLoan;
}

const Contragent: FC<ContragentProps> = ({
  contragent: {
    title,
    inn,
    deal,
    credit,
    paid,
    credit_final,
    from0To10days,
    from11To30days,
    from31To60days,
    invoices,
  },
}) => {
  const [invoicesIsOpen, setInvoicesIsOpen] = useState(false);

  return (
    <>
      <tr className={cls["tr"]}>
        <td
          className={`${cls["td"]} ${cls["clickable"]}`}
          style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}
          onClick={() => setInvoicesIsOpen(prev => !prev)}
        >
          <div
            className={`${cls["invoices-button"]} ${
              invoicesIsOpen && cls["invoices-button__active"]
            }`}
          >
            <ArrowIcon />
          </div>
        </td>
        <td className={cls["td"]}>
          <p className={cls["contragent-title"]}>{title}</p>
          <span className={cls["contragent-inn"]}>ИНН {inn}</span>
        </td>
        <td className={cls["td"]}>{deal}</td>
        <td className={cls["td"]}>{addSpaces(credit.toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces(paid.toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces(credit_final.toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces(from0To10days.toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces(from11To30days.toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces(from31To60days.toFixed(2))}</td>
      </tr>
      {invoicesIsOpen && (
        <tr>
          <td colSpan={9}>
            <InvoicesTable invoices={invoices} />
          </td>
        </tr>
      )}
    </>
  );
};

export const ContragentsTable = observer(ContragentsTableComponent);

import React, { useEffect } from "react";
import Calendar from "react-calendar";
// eslint-disable-next-line no-unused-vars
import { tileProps } from "react-calendar/dist/cjs/shared/propTypes";
// eslint-disable-next-line no-unused-vars
import styles from "./revenueCalendar.scss";
import { EInputStyleVariant, EInputVariant, Input } from "ui";
import PropTypes from "prop-types";
import { useStore } from "store";

const RevenueCalendar = ({
  modalClick,
  value,
  handleMonthChange,
  handleSelectDate,
  summary,
  revenueBalance,
  setRevenueBalance,
}) => {
  const { RootStore } = useStore();
  const {
    RevenuePlanningStore: {
      isNotEditable,
      setInputValuesSum,
      setIsChanged,
      total,
    },
  } = RootStore;

  RevenueCalendar.propTypes = {
    handleMonthChange: PropTypes.func.isRequired,
    modalClick: PropTypes.func.isRequired,
    value: PropTypes.instanceOf(Date).isRequired,
    handleSelectDate: PropTypes.func.isRequired,
    summary: PropTypes.any, // or PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  };

  const tileClassName = () => {
    return "main__tile";
  };

  const onClickDay = (event) => {
    // event.preventDefault();
    console.log(event);
    modalClick();
  };

  const handleInputChange = (event, dateString, myObject) => {
    setIsChanged(true);
    if (isNotEditable) return;

    const input = event.target;
    const oldValue = input.value;
    const newValue = oldValue.replace(/[^\d.]/g, "");
    const parsedValue = newValue.length === 0 ? 0 : parseFloat(newValue);

    if (/^\d*\.?\d*$/.test(parsedValue)) {
      setRevenueBalance([
        ...revenueBalance.map((item) => {
          if (item.date === dateString) {
            return { ...myObject, sum: parsedValue === "" ? 0 : +newValue };
          }
          return item;
        }),
      ]);
    }
  };

  useEffect(() => {
    if (!revenueBalance) return;
    const val = revenueBalance
      .reduce((accumulator, value) => {
        return accumulator + value.sum;
      }, 0)
      .toFixed(2);
    setInputValuesSum(val);
  }, [revenueBalance]);

  let formatShortWeekday = (locale, date) =>
    [
      "Воскресенье",
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ][date.getDay()];

  function tileContent({ date, view }) {
    let dateString = `${
      String(date.getDate()).length === 1
        ? `0${date.getDate()}`
        : date.getDate()
    }.${
      String(date.getMonth() + 1).length === 1
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1
    }.${date.getFullYear()}`;
    let myObject;
    if (!revenueBalance) return;
    const tileValue = revenueBalance.find((obj) => obj.date === dateString);
    myObject = tileValue ? tileValue : 0;

    if (view === "month" && date.getMonth() === value.getMonth()) {
      return (
        <div>
          <div className="revenueCalendar__tile-revenue ">
            <span>
              <div
                className={
                  !isNotEditable
                    ? `active revenue-calendar__input-wrapper`
                    : `disabled revenue-calendar__input-wrapper`
                }
              >
                <Input
                  dataDate={dateString}
                  type={EInputVariant.text}
                  variant={EInputStyleVariant.basicInput}
                  height={"20px"}
                  borderRadius={"0px"}
                  required={true}
                  value={myObject.sum}
                  // value={(inputValues[dateString] && inputValues[dateString].toString().length !== 0) ? (parseInt(inputValues[dateString], 10)) : (myObject && myObject.sum)}
                  onChange={(event) =>
                    handleInputChange(event, dateString, myObject)
                  }
                />
              </div>
            </span>
            {/* значение = плановая выручка \ кол-во дней */}
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <Calendar
      tileClassName={tileClassName}
      firstDayOfWeek="monday"
      locale="ru-RU"
      className="main-calendar"
      formatShortWeekday={formatShortWeekday}
      // tileContent={revenueBalance ? tileContent : ''}
      tileContent={tileContent}
      onChange={() => {}}
      onClickDay={onClickDay}
      value={value}
    />
  );
};

export default RevenueCalendar;

import { fetchODDSData, fetchORPData, fetchOSVData } from "api";
import { dateToNumbersAndDots } from "assets/utils";
import { subMonths, subDays } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";
import { Value } from "react-calendar/src/shared/types";
import { RootStore } from "../../../store/rootStore";

export class ODDSStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  dataODDS: any = null;
  periodStartODDS: Value = subMonths(new Date(), 1); // дефолтное начало периода - 1 месяц назад
  periodEndODDS: Value = subDays(new Date(), 1); // дефолтный конец периода - 1 день назад (за сегодня инфы не должно быть)
  isLoadingODDS = false;

  setDataODDS = (newData: any) => {
    this.dataODDS = newData;
  };
  setPeriodStartODDS = (newDate: Value) => {
    this.periodStartODDS = newDate;
  };
  setPeriodEndODDS = (newDate: Value) => {
    this.periodEndODDS = newDate;
  };
  setIsLoadingODDS = (bool: boolean) => {
    this.isLoadingODDS = bool;
  };

  handlePeriodChangeODDS = (period: Value) => {
    if (!period || !Array.isArray(period)) return;
    this.setPeriodStartODDS(period[0]);
    this.setPeriodEndODDS(period[1]);
    this.getDataODDS();
  };
  getDataODDS = async () => {
    this.setIsLoadingODDS(true);
    try {
      const response = await fetchODDSData(
        dateToNumbersAndDots(this.periodStartODDS),
        dateToNumbersAndDots(this.periodEndODDS),
      );
      runInAction(() => {
        if (response?.data.data) {
          this.setDataODDS(response.data.data);
        }
        this.setIsLoadingODDS(false);
      });
    } catch (e) {
      this.setIsLoadingODDS(false);
    }
  };
}

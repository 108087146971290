import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { EInputStyleVariant, Input } from "ui-new/input";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import { MoneyTransactionDynamicFormFields } from "types/money-transactions";

interface PeriodFieldProps {
  allDisabled: boolean;
}

//Предшествующий месяц от даты платежа
const PeriodFieldComponent: FC<PeriodFieldProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { formFields },
    },
  } = useStore();

  const { control, watch } = useFormContext<{
    [MoneyTransactionDynamicFormFields.Period]: Date;
    [MoneyTransactionDynamicFormFields.TaxTypeId]: number;
  }>();
  const taxTypeId = watch("tax_type_id");

  const isPeriodFieldShow = useMemo(() => {
    if (formFields?.tax_type_id?.show) {
      if (taxTypeId !== 5) return false;
    }
    return true;
  }, [formFields?.tax_type_id?.show, taxTypeId]);

  if (!isPeriodFieldShow) return null;

  return (
    <Input
      label='Период "За"'
      control={control}
      variant={EInputStyleVariant.basicInput}
      type="date"
      name={MoneyTransactionDynamicFormFields.Period}
      disabled={allDisabled}
      inputProps={{ disabled: formFields?.period?.disabled }}
    />
  );
};

export const PeriodField = observer(PeriodFieldComponent);

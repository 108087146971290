import { observer } from 'mobx-react-lite';
import { FC,useMemo } from 'react';
import { useStore } from 'store';
import { EmptyLayout } from './empty-layout';
import TemplateList from './template-list';
import { CreateTemplateModal } from './create-template-modal';
import { toJS } from 'mobx';

interface TemplatesTabProps {

}

const TemplatesTabComponent: FC<TemplatesTabProps> = () => {
  const { RootStore: { SchedulePlanningStore: {
    templates,createTemplateIsOpen
  } } } = useStore();

  const screen = useMemo(() => {
    if (templates.length === 0)
      return <EmptyLayout />;

    return <TemplateList templates={templates} />;
  },[toJS(templates)]);

  return (<>
    <div style={{ paddingLeft: '24px' }}>
      {screen}
    </div>
    {createTemplateIsOpen && <CreateTemplateModal />}
  </>
  );
};

export const TemplatesTab = observer(TemplatesTabComponent);
import React, { ReactNode, useState } from "react";
import "./styles.scss";

interface IFiltersButtonType {
  filtersReset?: () => void;
  haveResetButton: boolean;
  children: ReactNode;
  isResetActive?: boolean;
  isFiltersVisible?: boolean;
}

export const Filters: React.FC<IFiltersButtonType> = ({
  children,
  haveResetButton,
  filtersReset,
  isResetActive,
  isFiltersVisible,
}) => {
  const [filtersIsVisible, setFiltersIsVisible] = useState<boolean>(!!isFiltersVisible);
  return (
    <div
      className={
        filtersIsVisible
          ? `registry__filters-wrapper registry__filters-wrapper_active`
          : `registry__filters-wrapper `
      }
    >
      <div className="registry__filters__buttons">
        <button
          className="registry__filters-button"
          onClick={() => {
            setFiltersIsVisible(!filtersIsVisible);
          }}
          data-testid={"filter-btn"}
        >
          <svg
            className="registry__filters-button__vector"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.625 10C15.625 10.1658 15.5592 10.3247 15.4419 10.4419C15.3247 10.5592 15.1658 10.625 15 10.625H5C4.83424 10.625 4.67527 10.5592 4.55806 10.4419C4.44085 10.3247 4.375 10.1658 4.375 10C4.375 9.83424 4.44085 9.67527 4.55806 9.55806C4.67527 9.44085 4.83424 9.375 5 9.375H15C15.1658 9.375 15.3247 9.44085 15.4419 9.55806C15.5592 9.67527 15.625 9.83424 15.625 10ZM18.125 5.625H1.875C1.70924 5.625 1.55027 5.69085 1.43306 5.80806C1.31585 5.92527 1.25 6.08424 1.25 6.25C1.25 6.41576 1.31585 6.57473 1.43306 6.69194C1.55027 6.80915 1.70924 6.875 1.875 6.875H18.125C18.2908 6.875 18.4497 6.80915 18.5669 6.69194C18.6842 6.57473 18.75 6.41576 18.75 6.25C18.75 6.08424 18.6842 5.92527 18.5669 5.80806C18.4497 5.69085 18.2908 5.625 18.125 5.625ZM11.875 13.125H8.125C7.95924 13.125 7.80027 13.1908 7.68306 13.3081C7.56585 13.4253 7.5 13.5842 7.5 13.75C7.5 13.9158 7.56585 14.0747 7.68306 14.1919C7.80027 14.3092 7.95924 14.375 8.125 14.375H11.875C12.0408 14.375 12.1997 14.3092 12.3169 14.1919C12.4342 14.0747 12.5 13.9158 12.5 13.75C12.5 13.5842 12.4342 13.4253 12.3169 13.3081C12.1997 13.1908 12.0408 13.125 11.875 13.125Z"
              fill="#212129"
            />
          </svg>
          Фильтр
        </button>
        {isResetActive && (
          <button
            className="registry__filters-resetButton"
            onClick={() => {
              filtersReset && filtersReset();
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.7499 4.37503V8.12503C18.7499 8.29079 18.6841 8.44976 18.5669 8.56697C18.4497 8.68418 18.2907 8.75003 18.1249 8.75003H14.3749C14.2092 8.75003 14.0502 8.68418 13.933 8.56697C13.8158 8.44976 13.7499 8.29079 13.7499 8.12503C13.7499 7.95927 13.8158 7.8003 13.933 7.68309C14.0502 7.56588 14.2092 7.50003 14.3749 7.50003H16.5155L14.4382 5.5969L14.4187 5.57815C13.55 4.70979 12.4447 4.11664 11.2408 3.8728C10.037 3.62896 8.78798 3.74525 7.64984 4.20714C6.5117 4.66903 5.53489 5.45603 4.84144 6.46984C4.14798 7.48365 3.76863 8.67932 3.75076 9.90748C3.73289 11.1356 4.0773 12.3418 4.74097 13.3754C5.40463 14.409 6.35813 15.224 7.48235 15.7189C8.60657 16.2137 9.85166 16.3662 11.0621 16.1575C12.2725 15.9488 13.3946 15.3881 14.2882 14.5453C14.4087 14.4314 14.5695 14.37 14.7353 14.3746C14.9011 14.3792 15.0582 14.4495 15.1722 14.57C15.2861 14.6904 15.3476 14.8513 15.343 15.017C15.3383 15.1828 15.2681 15.34 15.1476 15.4539C13.7581 16.7717 11.9149 17.5043 9.99992 17.5H9.8968C8.66847 17.4832 7.46308 17.1649 6.38656 16.5731C5.31005 15.9814 4.39538 15.1342 3.72294 14.1062C3.0505 13.0781 2.64088 11.9006 2.53008 10.6772C2.41928 9.45374 2.6107 8.22182 3.08751 7.08968C3.56432 5.95755 4.31192 4.95986 5.2646 4.18432C6.21729 3.40878 7.34589 2.87912 8.55121 2.6419C9.75654 2.40468 11.0017 2.46716 12.1772 2.82385C13.3527 3.18054 14.4226 3.82051 15.2929 4.68753L17.4999 6.70315V4.37503C17.4999 4.20927 17.5658 4.0503 17.683 3.93309C17.8002 3.81588 17.9592 3.75003 18.1249 3.75003C18.2907 3.75003 18.4497 3.81588 18.5669 3.93309C18.6841 4.0503 18.7499 4.20927 18.7499 4.37503Z"
                fill="#98A1B2"
              />
            </svg>
            Сбросить все
          </button>
        )}
      </div>

      <div
        className={
          filtersIsVisible
            ? `registry__filters-container registry__filters-container_active`
            : `registry__filters-container `
        }
      >
        {children}
      </div>
    </div>
  );
};

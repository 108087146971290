import React, { useEffect } from "react";
import { EColorVariant, EFontVariant } from "assets/styles/enums";
import "./styles.scss";
import {
  Navlink,
  ENavigateStyleVariant,
  EInputVariant,
  EInputStyleVariant,
  Input,
  Button,
  EButtonVariant,
} from "ui";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

export const SpotWorkingHours = observer(() => {
  const { RootStore } = useStore();
  const { AuthStore } = RootStore;
  const { setOnboardingStep } = AuthStore;

  useEffect(() => {
    setOnboardingStep(1, true);
    setOnboardingStep(2, false);
    setOnboardingStep(3, false);
  }, []);

  const navigate = useNavigate();

  const location = useLocation();

  return (
    <div className="spotWorkingHours__container">
      <h2 className={`spotWorkingHours__header`}>Время работы спота</h2>
      <div className="spotWorkingHours__choose">
        <Navlink
          variant={({ isActive }) =>
            isActive
              ? `${ENavigateStyleVariant.choosenStandartNavItem} ${EFontVariant.body1Regular}`
              : `${ENavigateStyleVariant.standartNavItem} ${EFontVariant.body1Regular}`
          }
          maxWidth={"230px"}
          height={"40px"}
          borderRadius={"12px"}
          path="alldays"
          text="Все дни"
        />
        <Navlink
          variant={({ isActive }) =>
            isActive
              ? `${ENavigateStyleVariant.choosenStandartNavItem} ${EFontVariant.body1Regular}`
              : `${ENavigateStyleVariant.standartNavItem} ${EFontVariant.body1Regular}`
          }
          maxWidth={"230px"}
          height={"40px"}
          borderRadius={"12px"}
          path="weekdays-weekend"
          text="Будни и выходные"
        />
      </div>
      <Routes>
        <Route
          path="alldays"
          element={
            <>
              <div className="timePick__container">
                <h3 className={`timePick__header`}>Все дни</h3>
                <div className="timePick__inputs">
                  <Input
                    value={AuthStore.allDaysStart}
                    onChange={(e) =>
                      AuthStore.setSpotTime(
                        "allDaysStart",
                        e.currentTarget.value,
                      )
                    }
                    type={EInputVariant.time}
                    variant={EInputStyleVariant.basicInput}
                    font={EFontVariant.body1Regular}
                    maxWidth={"211px"}
                    height={"44px"}
                    borderRadius={"16px"}
                    required={true}
                  />
                  <Input
                    value={AuthStore.allDaysEnd}
                    onChange={(e) =>
                      AuthStore.setSpotTime("allDaysEnd", e.currentTarget.value)
                    }
                    type={EInputVariant.time}
                    variant={EInputStyleVariant.basicInput}
                    font={EFontVariant.body1Regular}
                    maxWidth={"211px"}
                    height={"44px"}
                    borderRadius={"16px"}
                    required={true}
                  />
                </div>
              </div>

              {AuthStore.allDaysStart !== "" && AuthStore.allDaysEnd !== "" ? (
                <Button
                  onClick={() => {
                    AuthStore.setOnboardingStep(2, true);
                    navigate(
                      `../step2/${location.pathname.substring(
                        location.pathname.lastIndexOf("/") + 1,
                      )}`,
                    );
                  }}
                  variant={EButtonVariant.fillButton}
                  font={EFontVariant.body2Regular}
                  maxWidth={"300px"}
                  margin={"auto 0px 32px 0px"}
                  height={"48px"}
                  borderRadius={"16px"}
                  text={"Продолжить"}
                />
              ) : (
                <Button
                  variant={EButtonVariant.fillButton}
                  font={EFontVariant.body2Regular}
                  maxWidth={"300px"}
                  height={"48px"}
                  borderRadius={"16px"}
                  text={"Продолжить"}
                  disabled={true}
                  margin={"auto 0px 32px 0px"}
                />
              )}
            </>
          }
        />
        <Route
          path="weekdays-weekend"
          element={
            <>
              <div className="timePick__container">
                <h3 className={`timePick__header`}>Будни</h3>
                <div className="timePick__inputs">
                  <Input
                    value={AuthStore.weekdaysStart}
                    onChange={(e) =>
                      AuthStore.setSpotTime(
                        "weekdaysStart",
                        e.currentTarget.value,
                      )
                    }
                    type={EInputVariant.time}
                    variant={EInputStyleVariant.basicInput}
                    font={EFontVariant.body1Regular}
                    maxWidth={"211px"}
                    height={"44px"}
                    borderRadius={"16px"}
                    required={true}
                  />
                  <Input
                    value={AuthStore.weekdaysEnd}
                    onChange={(e) =>
                      AuthStore.setSpotTime(
                        "weekdaysEnd",
                        e.currentTarget.value,
                      )
                    }
                    type={EInputVariant.time}
                    variant={EInputStyleVariant.basicInput}
                    font={EFontVariant.body1Regular}
                    maxWidth={"211px"}
                    height={"44px"}
                    borderRadius={"16px"}
                    required={true}
                  />
                </div>
              </div>
              <div className="timePick__container">
                <h3 className={`timePick__header`}>Выходные</h3>
                <div className="timePick__inputs">
                  <Input
                    value={AuthStore.weekendStart}
                    onChange={(e) =>
                      AuthStore.setSpotTime(
                        "weekendStart",
                        e.currentTarget.value,
                      )
                    }
                    type={EInputVariant.time}
                    variant={EInputStyleVariant.basicInput}
                    font={EFontVariant.body1Regular}
                    maxWidth={"211px"}
                    height={"44px"}
                    borderRadius={"16px"}
                    required={true}
                  />
                  <Input
                    value={AuthStore.weekendEnd}
                    onChange={(e) =>
                      AuthStore.setSpotTime("weekendEnd", e.currentTarget.value)
                    }
                    type={EInputVariant.time}
                    variant={EInputStyleVariant.basicInput}
                    font={EFontVariant.body1Regular}
                    maxWidth={"211px"}
                    height={"44px"}
                    borderRadius={"16px"}
                    required={true}
                  />
                </div>
              </div>
              {AuthStore.weekdaysStart !== "" &&
              AuthStore.weekdaysEnd !== "" &&
              AuthStore.weekendStart !== "" &&
              AuthStore.weekendEnd !== "" ? (
                <Button
                  onClick={() => {
                    AuthStore.setOnboardingStep(2, true);
                    navigate(
                      `../step2/${location.pathname.substring(
                        location.pathname.lastIndexOf("/") + 1,
                      )}`,
                    );
                  }}
                  variant={EButtonVariant.fillButton}
                  font={EFontVariant.body2Regular}
                  maxWidth={"300px"}
                  height={"48px"}
                  borderRadius={"16px"}
                  margin={"auto 0px 32px 0px"}
                  text={"Продолжить"}
                />
              ) : (
                <Button
                  variant={EButtonVariant.fillButton}
                  font={EFontVariant.body2Regular}
                  maxWidth={"300px"}
                  height={"48px"}
                  margin={"auto 0px 32px 0px"}
                  borderRadius={"16px"}
                  text={"Продолжить"}
                  disabled={true}
                />
              )}
            </>
          }
        />
        <Route path="*" element={<h2>Выберите опицию &#8593;</h2>} />
      </Routes>
    </div>
  );
});

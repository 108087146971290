import { FC } from 'react';
import cls from './comment-style.module.scss';

interface CommentProps {
  worker: string;
  message: string;
}

export const Comment: FC<CommentProps> = ({ message,worker }) => {
  return (
    <div className={cls['comment']}>
      <p className={cls['worker']} title={worker}>{worker}</p>
      <p className={cls['message']} title={message}>{message}</p>
    </div>
  );
};
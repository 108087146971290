import { useMemo,useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { Select,Tooltip } from 'antd';
import { IInventoryPositon,Nomenclature,NomenclatureType } from '../../types';
import { useStore } from 'store';
import { TableFooter } from 'widgets/table-footer';
import InventoryPosition from './inventoryPosition';

const ToolTipLabel = ({ option }: { option: Nomenclature; }) => {
  return (
    <div className="select-tooltip-container">
      <p className="select-tooltip-item select-tooltip-item-label">{option.label} <br /> </p>
      <p className="select-tooltip-item">{option?.path || ""} <br /></p>
      <p className="select-tooltip-item">{option?.category || ""} <br /></p>
    </div>
  );
};

const InventoryTableComponent = () => {
  const { RootStore: { RegistryDocumentStore: {
    advanceReport: { positions,status },
    nomenclaturesList,
  } } } = useStore();

  const [page,setPage] = useState(1);
  const [maxOperationsNumber,setMaxOperationsNumber] = useState(10);
  const [firstItem,setFirstItem] = useState(1);
  const [lastItem,setLastItem] = useState(1);

  const nomenclaturesData = useMemo(() => {
    if (status !== 'pending') return [];

    return nomenclaturesList?.map((option: Nomenclature) => (
      <Select.Option key={option.value} value={option.value} label={option.label}>
        <div className="select-option">
          <Tooltip title={<ToolTipLabel option={option} />}>
            <span className="select-option-main-label">{option.label}</span>
          </Tooltip>
          <span className="select-option-type-label">
            {NomenclatureType[option.type]}
          </span>
        </div>
      </Select.Option>
    ));
  },[toJS(nomenclaturesList),toJS(status)]);

  const positionsData = useMemo(() =>
    toJS(positions as IInventoryPositon[]).map((position: IInventoryPositon,index: number) =>
      <InventoryPosition key={position.id} positionIndex={index} position={position} nomenclaturesData={nomenclaturesData} />),
    [toJS(positions),nomenclaturesData]);

  return <>
    {positionsData.slice(firstItem,lastItem)}
    <TableFooter
      currentPage={page}
      setCurrentPage={setPage}
      maxOperationsNumber={maxOperationsNumber}
      setMaxOperationsNumber={setMaxOperationsNumber}
      firstItem={firstItem}
      lastItem={lastItem}
      setFirstItem={setFirstItem}
      setLastItem={setLastItem}
      itemsList={positions}
    />
  </>;
};


export default observer(InventoryTableComponent);
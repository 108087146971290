import {api} from "./api";

export async function getAuth(email: string, password: string) {
  try {
    const response = await api.post("login", {
      email: email,
      password: password,
    });
    return response.data;
  } catch (e) {
    return null;
  }
}

export async function changePassRequest(email: string) {
  try {
    const response = await api.post("recover", {
      email
    });
    return response.data;
  } catch (e) {
    return null;
  }
}
